import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject, filter, map, Observable, of, Subscription, switchMap, withLatestFrom } from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';

import {
  GlobalBookingTripDates,
  isPriceCalendarWithPricesParams,
  MultivariateTestId,
  PriceCalendarCTAParams,
  PriceCalendarParams,
  PriceCalendarService,
  TestVariant,
} from '@fcom/common';
import { isNotEmpty, isPresent, LocalDate, unsubscribe } from '@fcom/core/utils';
import { ButtonMode, ButtonSize, ButtonTheme, DateRange } from '@fcom/ui-components';
import { GlobalBookingTravelClass, TripType } from '@fcom/core';
import { PaxAmount } from '@fcom/dapi';
import { CommonFeatureState, LocationPair } from '@fcom/common/store';
import { LanguageService } from '@fcom/ui-translate';
import {
  PriceCalendarComponent,
  PriceCalendarType,
} from '@fcom/common/components/price-calendar/price-calendar.component';
import { finShare } from '@fcom/rx';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';

import { NotificationWarning } from '../../interfaces';
import { BookingWidgetActions, BookingWidgetAppState } from '../../store';
import { seasonalNotificationHandler } from '../../utils/utils';

@Component({
  selector: 'fin-travel-dates-selector',
  templateUrl: 'travel-dates-selector.component.html',
  styleUrls: ['./travel-dates-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelDatesSelectorComponent implements OnInit, OnDestroy {
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;
  readonly PriceCalendarType = PriceCalendarType;

  @Input()
  priceCalendarType: PriceCalendarType = PriceCalendarType.PRICES;

  @Input()
  tripType$: Observable<TripType> = of(undefined);

  @Input()
  travelClass$: Observable<GlobalBookingTravelClass> = of(undefined);

  @Input()
  paxAmount$: Observable<PaxAmount> = of(undefined);

  @Input()
  locations$: Observable<LocationPair> = of(undefined);

  @Input()
  disabled = false;

  @Input()
  previousFlightDepartureDate$: Observable<LocalDate> = of(null);

  @Input()
  travelDates$: Observable<GlobalBookingTripDates>;

  @Input()
  isOneway$: Observable<boolean> = of(false);

  @Input()
  flexibleDates = false;

  @Input()
  showFlexibleDatesSelection = true;

  @Input()
  showTripPriceFrom = true;

  @Input()
  showPerAdultPrice = true;

  @Input()
  showSingular?: keyof GlobalBookingTripDates;

  @Input()
  showSeparator = true;

  @Input()
  showAddReturn = false;

  @Input()
  showSubtitle = false;

  @Input()
  enableNewSearchAutomatically = false;

  @Input()
  isSearchEnabled = false;

  @Output()
  setTravelDates = new EventEmitter<GlobalBookingTripDates>();

  @Output()
  setFlexibleDates = new EventEmitter<boolean>();

  @Output()
  addReturn = new EventEmitter<void>();

  @Output()
  startSearch = new EventEmitter<void>();

  @Output()
  cancelSelection = new EventEmitter<void>();

  @ViewChild(PriceCalendarComponent)
  private priceCalendar: PriceCalendarComponent;

  subscription = new Subscription();

  modalOpen$ = new BehaviorSubject<boolean>(false);
  disabledDateRanges$: Observable<DateRange[]>;

  priceCalendarParams$: BehaviorSubject<PriceCalendarParams> = new BehaviorSubject(null);
  showEnhancedCalendar$: Observable<boolean>;

  constructor(
    private priceCalendarService: PriceCalendarService,
    private store$: Store<CommonFeatureState & BookingWidgetAppState>,
    private languageService: LanguageService,
    private multivariateTestService: MultivariateTestService
  ) {}

  ngOnInit(): void {
    this.showEnhancedCalendar$ = this.multivariateTestService
      .getTestVariant(MultivariateTestId.ENHANCED_CALENDAR_STYLING)
      .pipe(
        map(({ variant }) => variant === TestVariant.B),
        finShare()
      );

    this.subscription.add(
      this.priceCalendarParams$
        .pipe(
          filter(isPresent),
          filter(isPriceCalendarWithPricesParams),
          switchMap((params) => this.priceCalendarService.getPricesForFullYear(params)),
          withLatestFrom(this.tripType$, this.languageService.translate('date')),
          filter(([, tripType]) => tripType !== TripType.MULTICITY)
        )
        .subscribe(([response, , dateTranslations]) => {
          if (isNotEmpty(response?.availability)) {
            const seasonalNotificationData = seasonalNotificationHandler(response.availability, dateTranslations);
            this.store$.dispatch(
              BookingWidgetActions.setNotificationWarning({
                key: NotificationWarning.SEASONAL_ROUTE,
                isActive: true,
                data: seasonalNotificationData,
              })
            );
          }
        })
    );

    this.subscription.add(
      this.modalOpen$
        .pipe(
          filter(Boolean),
          withLatestFrom(
            this.tripType$,
            this.travelClass$,
            this.paxAmount$,
            this.locations$,
            this.travelDates$,
            this.isOneway$
          ),
          map(([, tripType, travelClass, paxAmount, { origin, destination }, travelDates, isOneway]) => {
            return {
              origin: origin?.locationCode,
              destination: destination?.locationCode,
              tripType: isOneway ? TripType.ONEWAY : tripType,
              travelClass,
              paxAmount,
              initialTravelDates: travelDates,
            };
          })
        )
        .subscribe((params) => this.priceCalendarParams$.next(params))
    );

    this.disabledDateRanges$ = this.previousFlightDepartureDate$.pipe(
      map((departureDate: LocalDate) => (departureDate ? [[LocalDate.now(), departureDate.minusDays(1)]] : []))
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  openModal(): void {
    this.modalOpen$.next(true);
  }

  closePriceCalendar(): void {
    if (this.enableNewSearchAutomatically) {
      this.cancelSelection.emit();
      this.priceCalendar.startSearchWithSelectedDates(true);
    }
    this.modalOpen$.next(false);
  }

  priceCalendarCTAClicked(event: PriceCalendarCTAParams): void {
    this.setFlexibleDates.emit(event.flexibleDates);
    this.closePriceCalendar();
  }

  addReturnClicked(): void {
    this.addReturn.emit();
  }

  travelDatesChanged(travelDates: GlobalBookingTripDates): void {
    this.setTravelDates.emit(travelDates);
  }
}
