export class CustomError extends Error {
  [key: string]: unknown;

  constructor(value: unknown) {
    let message = 'Non-error captured';

    if (typeof value === 'object' && value !== null) {
      if ('message' in value) {
        message = String(value['message']);
      }
    } else {
      message = String(value);
    }

    super(message);

    Object.entries(value)
      .filter(([key]) => key !== 'message' && key !== 'name')
      .forEach(([key, val]) => {
        this[key] = val;
      });

    this.name = value['name'] ?? 'CustomError';
  }
}
