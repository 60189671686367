<div *ngFor="let bound of bounds$ | async; let isLast = last; let index = index" class="ms-medium-t">
  <fin-flight-card
    [flightOrBound]="bound"
    [title]="bound.locationTitle"
    [locations]="locations$ | async"
    [passengers]="cancelRefund$ | finMap: 'order.passengers' | async"
    [hideModify]="true"
    (viewDetails)="viewDetails(index)"
  >
  </fin-flight-card>
</div>

<fcom-modal
  class="itinerary-modal"
  [title]="'itinerary.title' | finLocalization"
  [buttons]="ModalButtons.DONE"
  [(open)]="itineraryModal.open"
>
  <fin-itinerary
    *ngIf="itineraryModal.data && (itineraryModal.data.outbound$ || itineraryModal.data.inbound$)"
    class="block pr-medium"
    [selectionPhase$]="itineraryModal.data.selectionPhase$"
    [outbound$]="itineraryModal.data.outbound$"
    [inbound$]="itineraryModal.data.inbound$"
    [locations$]="itineraryModal.data.locations$"
  >
  </fin-itinerary>
</fcom-modal>
