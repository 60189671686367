import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProfileTier } from '@fcom/core-api/login';
import { AppState } from '@fcom/core/interfaces';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { NativeBridgeService } from '@fcom/core/services';

import { QualtricsSurvey, QualtricsSurveys } from '../../qualtrics/qualtrics.utils';

/**
 * This service will manage the visibility and values of feedback widget.
 * If user is corporate user, the widget will be always visible and survey
 * will be corporate survey regardless of values set in components.
 */
@Injectable()
export class FeedbackService {
  public readonly visibility$: Observable<boolean>;
  public readonly feedbackSurvey$: Observable<QualtricsSurvey>;

  private feedbackWidgetVisibility$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private survey$ = new ReplaySubject<QualtricsSurvey>(1);
  private isCorporateUser$: Observable<boolean>;

  constructor(
    store$: Store<AppState>,
    private nativeBridgeService: NativeBridgeService
  ) {
    this.isCorporateUser$ = store$.pipe(
      profileOrUndefinedIfNotLoggedIn(),
      map((profile) => profile?.tier === ProfileTier.CORPORATE)
    );
    this.visibility$ = this.nativeBridgeService.isInsideNativeWebView
      ? of(false)
      : combineLatest([this.feedbackWidgetVisibility$, this.isCorporateUser$]).pipe(
          map(([visible, isCorporateUser]) => visible || isCorporateUser)
        );
    this.feedbackSurvey$ = combineLatest([this.survey$, this.isCorporateUser$]).pipe(
      map(([survey, isCorporateUser]) => {
        if (isCorporateUser) {
          return QualtricsSurveys.CORPORATE_FEEDBACK;
        }
        return survey;
      }),
      finShare()
    );
  }

  setFeedbackWidgetVisibility(visibility: boolean): void {
    this.feedbackWidgetVisibility$.next(visibility);
  }

  setSurvey(survey: QualtricsSurvey): void {
    this.survey$.next(survey);
  }
}
