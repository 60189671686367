@if ((amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.DP) {
  <div class="dates-selector-root relative fill grow separator">
    <ng-container>
      <fin-booking-widget-selector
        [disabled]="disabled"
        (click)="openModal()"
        [double]="{
          first: {
            label: 'bookingSearch.boundSelection.departureDate' | finLocalization,
            placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
            filled: (travelDates$ | async)?.['departureDate'] !== undefined,
          },
          second: {
            label: 'bookingSearch.boundSelection.returnDate' | finLocalization,
            placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
            filled: (travelDates$ | async)?.['returnDate'] !== undefined,
          },
        }"
        [label]="'bookingSearch.boundSelection.departureDate' | finLocalization"
        [placeholder]="'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization"
        [filled]="(travelDates$ | async)?.['departureDate'] !== undefined"
        [attr.data-testid]="'am-dates-selector-both'"
      >
        <span first *ngIf="(travelDates$ | async)?.['departureDate'] as selectedDate" data-testid="departure-date">{{
          selectedDate | finDateFormat: 'FULL_NO_YEAR'
        }}</span>
        <div separator class="flex flex--center flex--self-center absolute white-bg date-spacer ps-xxsmall z1">
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.MINUS"></fcom-icon>
        </div>
        <span second *ngIf="(travelDates$ | async)?.['returnDate'] as selectedDate" data-testid="return-date">{{
          selectedDate | finDateFormat: 'FULL_NO_YEAR'
        }}</span>
      </fin-booking-widget-selector>
    </ng-container>
  </div>
} @else {
  <div>
    <div class="df-laptop-up">
      <fin-am-duration-selector
        [selectedDuration]="selectedDuration$ | async"
        [durationOptions]="amDestination$ | finMap: 'holidayTypeItem.items' | async"
        (setDuration)="selectDuration($event)"
        class="flex flex--basis-half border-right"
      ></fin-am-duration-selector>
      <fin-booking-widget-selector
        class="flex flex-1"
        [disabled]="disabled"
        [position]="'right'"
        [label]="'bookingSearch.boundSelection.departureDate' | finLocalization"
        [attr.data-testid]="'travel-dates-selector-am'"
        (click)="openModal()"
      >
        <span *ngIf="(travelDates$ | async)?.departureDate as departureDate" data-testid="departure-date">{{
          departureDate | finDateFormat: 'FULL_NO_YEAR'
        }}</span>
      </fin-booking-widget-selector>
    </div>
  </div>
}

<fcom-modal
  [open]="modalOpen"
  [fitToContent]="true"
  [buttons]="null"
  [fullHeight]="true"
  [extraWide]="true"
  [roundedContent]="true"
  (close)="closeModal()"
  data-testid="travel-dates-modal"
>
  <fcom-datepicker
    [uiLabels]="'datepicker' | finLocalization"
    [dateLabels]="'date' | finLocalization"
    [titleAreaConfiguration]="{
      titleAreaStyle: 'ps-large-x ps-xsmall-y',
      title: datePickerTitleLabel$ | async | finLocalization,
      titleStyle: 'font-heading-4',
    }"
    [isDateRange]="(amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.DP"
    [selectableDatesRange]="calendarRange"
    [disabledDateRanges]="disabledDateRanges$ | async"
    [selectedDates]="[(travelDates$ | async)?.departureDate, (travelDates$ | async)?.returnDate]"
    [doneBtnHidden]="true"
    [showTags]="false"
    [dayTemplate]="dayTemplate"
    (selectedDatesChange)="updateCalendarDates($event)"
    (close)="closeModal()"
  ></fcom-datepicker>

  <div
    class="df-tablet-up flex--middle ps-large-x grey-50-bg font-body-1 ps-small"
    [ngClass]="{
      'travel-dates-sticky-footer': datesSelected$ | async,
    }"
    data-testid="travel-dates-footer"
  >
    <span class="flex flex--space-between fill travel-dates-footer-container">
      @if ((amDestination$ | finMap: 'holidayType' | async) === AmHolidayType.AM) {
        <span class="flex flex--middle">
          <fcom-icon class="icon-medium ms-xsmall-r" [name]="SvgLibraryIcon.AM_LOGO"></fcom-icon>
          <span>{{ 'aurinkomatkat.calendar.recommendedTravelDates' | finLocalization }}</span>
        </span>
      }
    </span>
    <div class="done-button align-center">
      <fcom-button
        [text]="'actions.done' | finLocalization"
        (clickBtn)="closeModal()"
        data-testid="date-modal-close-btn"
      ></fcom-button>
    </div>
  </div>
</fcom-modal>

<ng-template #dayTemplate let-dayValue="dayValue" let-dayString="dayString">
  <div class="calendar-day" [attr.data-testid]="'calendar-day-' + dayValue.id">
    <div
      class="ps-xxsmall-t"
      [ngClass]="{ 'aurinkomatkat-orange-text': (suggestedAmTravelDays$ | async)?.includes(dayValue.id) }"
    >
      {{ dayString }}
    </div>
  </div>
</ng-template>
