/* eslint-disable */

/**
 * List of ineligibility reasons for voluntary change flow.* `FLIGHT_DISRUPTED_BY_TIME_CHANGE` - Disruption happened + customer is eligible to acknowledge + customer is not eligible to flight re-accommodation
 * * `FLIGHT_DISRUPTED_BY_CANCEL` - Disruption happened + customer is not eligible to acknowledge + customer is not eligible to flight re-accommodation + one flight cancelled
 * * `AMADEUS_FLIGHT_DISRUPTED` - Disruption happened + customer is eligible to acknowledge + customer is eligible to flight re-accommodation
 */
export enum FinnairChangeIneligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  AMADEUS_REASON = 'AMADEUS_REASON',
  AMADEUS_GROUPS_NOT_SUPPORTED = 'AMADEUS_GROUPS_NOT_SUPPORTED',
  AMADEUS_TICKET_PROBLEM = 'AMADEUS_TICKET_PROBLEM',
  AMADEUS_UNISSUED_SERVICE_PRESENT = 'AMADEUS_UNISSUED_SERVICE_PRESENT',
  AMADEUS_COMPLEX_ITINERARY_NOT_ALLOWED = 'AMADEUS_COMPLEX_ITINERARY_NOT_ALLOWED',
  AMADEUS_FLIGHT_GENERAL_STATUS = 'AMADEUS_FLIGHT_GENERAL_STATUS',
  AMADEUS_DISCREPANCY_IN_ORDER = 'AMADEUS_DISCREPANCY_IN_ORDER',
  AMADEUS_OFFER_CONDITION_RULE = 'AMADEUS_OFFER_CONDITION_RULE',
  AMADEUS_FLIGHT_DISRUPTED = 'AMADEUS_FLIGHT_DISRUPTED',
  FLIGHT_DISRUPTED_BY_TIME_CHANGE = 'FLIGHT_DISRUPTED_BY_TIME_CHANGE',
  FLIGHT_DISRUPTED_BY_CANCEL = 'FLIGHT_DISRUPTED_BY_CANCEL',
  TOUROPERATOR_TICKETS = 'TOUROPERATOR_TICKETS',
  AWARD_BOOKING = 'AWARD_BOOKING',
  BOUND_ALREADY_CHECKED_IN = 'BOUND_ALREADY_CHECKED_IN',
  BOUND_ALREADY_FLOWN_OR_USED = 'BOUND_ALREADY_FLOWN_OR_USED',
  ID_TICKETS = 'ID_TICKETS',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  NO_AY_FLIGHTS = 'NO_AY_FLIGHTS',
  NOT_ELIGIBLE_SSR = 'NOT_ELIGIBLE_SSR',
  PETC_FROM_TO_AGP = 'PETC_FROM_TO_AGP',
  INDIAN_BOOKING = 'INDIAN_BOOKING'
}

