export enum ModalButtons {
  /**
   * Show secondary "Done" button in modal footer.
   * Closes the modal and calls onClose()
   */
  DONE = 'DONE',

  /**
   * Show secondary "OK" button in modal footer.
   * Closes the modal and calls onClose()
   */
  OK = 'OK',

  /**
   * Show primary continue and secondary cancel button.
   * These use the onContinue and onCancel callbacks
   */
  CONTINUE_CANCEL = 'CONTINUE_CANCEL',

  /**
   * Show secondary "Close" button in modal footer.
   * Closes the modal and calls onClose()
   */
  CLOSE = 'CLOSE',

  /**
   * No buttons will be shown within the modal. It is expected that the parent component will pass
   * the buttons through as part of ng-content.
   *
   * The ng-content selector to use is "buttons"
   *
   * Warning.
   * - By using custom buttons you will need to handle disabling these buttons when needed.
   * - The parent component will need to handle the modal close behavior by listening to click
   * events.
   */
  CUSTOM = 'CUSTOM',
}
