import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';

import { TagRoundingStyle } from './interfaces';
import { IconName } from '../icons';

export class TaggableComponent {
  castTagRoundingStyle(roundingStyle: string): TagRoundingStyle {
    return (roundingStyle as TagRoundingStyle) ?? TagRoundingStyle.DEFAULT;
  }

  castIconName(iconName: string): IconName {
    return iconName as IconName;
  }

  castIconCategory(iconCategory: string): IconLibrary {
    return iconCategory as IconLibrary;
  }
}
