<div
  *ngIf="fareFamily"
  class="upsell-option relative flex flex--column grow rounded-large overflow-hidden"
  [ngClass]="{ wide: (isMobile$ | async) === true }"
>
  <div>
    <div class="fill ps-xxsmall-x ps-xsmall-y highlight top-bar">
      <div class="font-body-1 bold-type">{{ 'upsellCard.title' | finLocalization }}</div>
    </div>
  </div>
  <div class="pr-xsmall-b ps-xxsmall-l ps-small-r">
    <div class="rounded-large overflow-hidden" *ngIf="imageData$ | async as imageData">
      <div class="relative">
        <fcom-image [imageData]="imageData" [ratio]="enableBenefitChanges === true ? '4x3' : '1x1'"></fcom-image>
        <fcom-tag class="absolute avios-points ms-xsmall-l ms-xsmall-b" *ngIf="isNotAward">
          <fcom-icon class="ms-xxsmall-r icon-small" [name]="SvgLibraryIcon.AVIOS_CURRENCY"></fcom-icon>
          <span *ngIf="fareFamily.totalAviosAccrual">
            {{ 'avios.collectPointsTag' | finLocalization: { points: fareFamily.totalAviosAccrual | finPoints } }}
          </span>
          <span *ngIf="!fareFamily.totalAviosAccrual">
            {{ 'avios.collectUndefinedPoints' | finLocalization }}
          </span>
        </fcom-tag>
      </div>
    </div>
    <div>
      <div class="flex ms-xsmall-t">
        <fcom-icon class="ms-xxsmall-t icon-large" [name]="SvgLibraryIcon.UPGRADE_OUTLINE"></fcom-icon>
        <div class="ms-xsmall-l">
          <div class="font-body-1 medium-type">
            {{
              ('travelClass.' + fareFamily.cabinClass.toLowerCase() | finLocalization) ||
                ('travelClass.mixed' | finLocalization)
            }}
          </div>

          <div class="flex flex--middle flex--wrap">
            <span class="font-body-2 flex flex--middle ps-xxsmall-r">
              {{ 'pricesStartingFrom' | finLocalization }}
            </span>

            <span *ngIf="fareFamily.originalPrice" class="font-body-2 ps-xsmall-r strike-through">
              {{ { amount: fareFamily.originalPrice, currencyCode: currencyCode$ | async } | finPrice }}
            </span>

            <span class="font-heading-5 price">
              {{ { amount: fareFamily.price, currencyCode: currencyCode$ | async } | finPrice }}
            </span>
          </div>
        </div>
      </div>
      <span class="font-body-1 ms-xsmall-t">{{ descriptionKey$ | async | finLocalization }}</span>
    </div>
    <fcom-button
      [attr.data-testid]="'cabin-upsell-option-next-class-button'"
      class="ms-xsmall-t"
      [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
      [mode]="ButtonMode.FILL"
      [icon]="{ name: SvgLibraryIcon.ARROW_RIGHT, position: IconPosition.RIGHT }"
      [text]="'upsellCard.button' | finLocalization"
      (clickBtn)="selectNextCabinClass.emit(fareFamily)"
    >
    </fcom-button>
    <div class="align-center ms-small-t" *ngIf="(hideCabinClassModalLink$ | async) !== true">
      <a
        class="nordic-blue-900-text font-body-1 bold-type"
        href
        (click)="emitOpenCabinClassModal($event, fareFamily.cabinClass)"
        >{{ 'upsellCard.modalLink.allClasses' | finLocalization }}</a
      >
    </div>
  </div>
</div>
<div
  *ngIf="showCompareBlock$ | async"
  class="upsell-option relative flex flex--column grow rounded-large overflow-hidden ps-xxsmall-l ps-small-r"
>
  <div class="rounded-large overflow-hidden" *ngIf="imageData$ | async as imageData">
    <div class="relative">
      <fcom-image [imageData]="imageData" [ratio]="'1x1'"></fcom-image>
    </div>
  </div>
  <span class="font-body-1 ms-xsmall-t" data-testid="gen-desc">{{ descriptionKey$ | async | finLocalization }}</span>
  <div class="ms-xsmall-t">
    <a class="nordic-blue-900-text font-body-1 bold-type" href (click)="emitOpenCabinClassModal($event, 'Business')">{{
      'upsellCard.modalLink.allClasses' | finLocalization
    }}</a>
  </div>
</div>
