import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, combineLatest, filter, forkJoin, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { isPresent, LocalDate, unsubscribe } from '@fcom/core/utils';
import { ButtonMode, ButtonSize, ButtonTheme, DateRange, LoaderTheme } from '@fcom/ui-components';
import { Amount } from '@fcom/dapi';
import { LanguageService } from '@fcom/ui-translate';
import { LocationRouteCffService } from '@fcom/core';
import { TripType } from '@fcom/core/interfaces';
import {
  Direction,
  ElementTypes,
  FlightSegment,
  GlobalBookingTripDates,
  PriceCalendarParams,
  PriceCalendarWithPricesParams,
} from '@fcom/common/interfaces';
import { ElementActions, GaContext, SearchType } from '@fcom/common/interfaces/gtm.interface';

import { DatePickerPrices, HistogramBar, PriceCalendarCTAParams } from '../../interfaces';
import { PriceCalendarService } from '../../services';
import {
  calendarDatesToGlobalBookingTripDates,
  priceCalendarBaseParamsAreValid,
  shouldUpdateCalendarDates,
} from '../../utils';
import { GtmService } from '../../gtm';

export enum PriceCalendarType {
  PRICES = 'prices',
  ONLY_DATES = 'onlyDates',
}

@Component({
  selector: 'fin-price-calendar',
  templateUrl: 'price-calendar.component.html',
  styleUrls: ['./price-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceCalendarComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly TripType = TripType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly LoaderTheme = LoaderTheme;
  readonly CMS_STYLE_OVERRIDE = {
    'margin-top': '0px',
  };
  readonly PriceCalendarType = PriceCalendarType;

  @Input()
  type: PriceCalendarType = PriceCalendarType.PRICES;

  @Input()
  openWithParams$: Observable<PriceCalendarParams | null>;

  @Input()
  showAddReturn = false;

  @Input()
  identifier: string;

  @Input()
  disabledDateRanges$: Observable<DateRange[]> = of([]);

  @Input()
  showFlexibleDatesSelection = false;

  @Input()
  showTripPriceFrom = true;

  @Input()
  showSubtitle = false;

  @Input()
  ctaLabel = '';

  @Input()
  enablePreFlightSearchTracking = true;

  @Input()
  enableNewSearchAutomatically = false;

  @Input()
  isSearchEnabled = false;

  @Input()
  showEnhancedCalendar$: Observable<boolean> = of(false);

  @Output()
  addReturnClicked = new EventEmitter();

  @Output()
  modalClose = new EventEmitter<boolean>();

  @Output()
  ctaClicked = new EventEmitter<PriceCalendarCTAParams>();

  @Output()
  travelDatesChanged = new EventEmitter<GlobalBookingTripDates>();

  @Output()
  startSearch = new EventEmitter<void>();

  @Output()
  cancelSelection = new EventEmitter<void>();

  subscription = new Subscription();

  calendarRange: DateRange = [LocalDate.now(), LocalDate.now().plusDays(360)];
  pricePerAdult$: Observable<Amount> = of(undefined);
  datesSelected$: Observable<boolean> = of(false);
  datePickerTitleLabel$: Observable<string> = of('');
  subTitle$: Observable<{ origin: string; destination: string }>;
  calendarPrices$: Observable<DatePickerPrices>;
  tripType$: Observable<TripType> = of(undefined);
  travelDates$: Observable<GlobalBookingTripDates>;
  flexibleDates$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ctaDisabled$: Observable<boolean> = of(false);
  isLoading$: Observable<boolean> = of(true);
  selectedHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  scrollToHistogramMonth$: BehaviorSubject<number> = new BehaviorSubject(undefined);

  constructor(
    private priceCalendarService: PriceCalendarService,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.startSearchWithSelectedDates();
    this.isLoading$ = this.type === PriceCalendarType.PRICES ? this.priceCalendarService.isLoading$ : of(false);
    this.travelDates$ = this.priceCalendarService.travelDates$;
    this.tripType$ = this.priceCalendarService.tripType$;

    if (this.type === PriceCalendarType.PRICES) {
      this.calendarPrices$ = this.priceCalendarService.calendarPrices$;
      this.pricePerAdult$ = this.priceCalendarService.startingFromPrice$;
    }

    this.datesSelected$ = combineLatest([this.travelDates$, this.tripType$]).pipe(
      map(
        ([{ departureDate, returnDate }, tripType]) =>
          isPresent(departureDate) && (tripType === TripType.ONEWAY || isPresent(returnDate))
      )
    );

    this.datePickerTitleLabel$ = combineLatest([this.tripType$, this.travelDates$]).pipe(
      map(([tripType, { departureDate }]) => {
        if (tripType === TripType.ONEWAY) {
          return 'bookingWidget.priceCalendar.selectDeparture.oneWay';
        }
        return isPresent(departureDate)
          ? 'bookingWidget.priceCalendar.selectReturn'
          : 'bookingWidget.priceCalendar.selectDeparture.roundTrip';
      })
    );

    this.ctaDisabled$ = combineLatest([this.tripType$, this.travelDates$]).pipe(
      map(([tripType, travelDates]) => {
        if (tripType === TripType.RETURN) {
          return !isPresent(travelDates.departureDate) || !isPresent(travelDates.returnDate);
        }
        return !isPresent(travelDates.departureDate);
      })
    );

    if (this.showSubtitle) {
      this.subTitle$ = this.priceCalendarService.params$.pipe(
        filter(Boolean),
        switchMap(({ origin, destination }) => {
          return forkJoin({
            origin: this.locationRouteCffService.bestGuessFor(origin, this.languageService.localeValue),
            destination: this.locationRouteCffService.bestGuessFor(destination, this.languageService.localeValue),
          });
        }),
        map(({ origin, destination }) => {
          const originCity = origin ? origin.cityName : '';
          const destinationCity = destination ? destination.cityName : '';
          return { origin: originCity, destination: destinationCity };
        })
      );
    }

    if (this.enablePreFlightSearchTracking) {
      this.subscription.add(
        this.datesSelected$
          .pipe(
            filter((datesSelected) => datesSelected),
            switchMap(() => this.priceCalendarService.getPriceCalendarCTAParams())
          )
          .subscribe((ctaParams) => {
            const { tripType, paxAmount, travelClass, flights } = ctaParams;

            const departureFlight: FlightSegment = {
              origin: flights[0]?.origin?.locationCode,
              destination: flights[0]?.destination?.locationCode,
              departureDate: flights[0]?.departureDate,
            };

            const returnFlight: FlightSegment = {
              origin: flights[1]?.origin?.locationCode,
              destination: flights[1]?.destination?.locationCode,
              departureDate: flights[1]?.departureDate,
            };

            const flightSegments: FlightSegment[] =
              tripType === TripType.RETURN ? [departureFlight, returnFlight] : [departureFlight];
            if (paxAmount && travelClass) {
              this.gtmService.preFlightSearch({
                flights: flightSegments,
                paxAmount,
                travelClass,
                isAward: this.type === PriceCalendarType.ONLY_DATES,
              });
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  closeModal(): void {
    this.modalClose.emit(false);
  }

  addReturn(): void {
    this.priceCalendarService.convertToReturnTrip();
    this.addReturnClicked.emit();
  }

  updateCalendarDates(calendarDates: [string] | [string, string]): void {
    const newDates = calendarDatesToGlobalBookingTripDates(calendarDates);
    if (shouldUpdateCalendarDates(newDates, this.priceCalendarService.travelDatesValues)) {
      this.priceCalendarService.updateCalendarDates(newDates);
      this.travelDatesChanged.emit(newDates);
    }
  }

  setHistogramMonth(selectedMonthIndex = 0): void {
    this.selectedHistogramMonth$.next(selectedMonthIndex);
  }

  handleHistogramClick(selectedBar: HistogramBar): void {
    if (!selectedBar.noFlight) {
      this.gtmService.trackElement(
        'histogram',
        'flight-search',
        ElementTypes.BOOKING_WIDGET,
        selectedBar?.isCheapest ? 'lowest-month-click' : 'other-month-click',
        ElementActions.CLICK,
        undefined,
        this.type === PriceCalendarType.ONLY_DATES ? SearchType.AWARD : SearchType.BOOKING_FLOW
      );
    }
    this.scrollToHistogramMonth$.next(selectedBar.index);
  }

  handleBarsScrolled(_direction: Direction): void {
    //TODO: Check what this doing ?
    this.gtmService.trackElement(
      'histogram',
      'flight-search',
      ElementTypes.BOOKING_WIDGET,
      'arrow-click',
      ElementActions.CLICK,
      undefined,
      this.type === PriceCalendarType.ONLY_DATES ? SearchType.AWARD : SearchType.BOOKING_FLOW
    );
  }

  emitCTAClicked(): void {
    this.subscription.add(
      this.priceCalendarService
        .getPriceCalendarCTAParams()
        .pipe(take(1))
        .subscribe((ctaParams) => {
          this.ctaClicked.emit({
            ...ctaParams,
            flexibleDates: this.flexibleDates$.value,
          });
        })
    );
  }

  setFlexibleDates(isChecked: boolean): void {
    this.flexibleDates$.next(isChecked);
  }

  onCancelSelection(): void {
    this.cancelSelection.emit();
    this.startSearchWithSelectedDates(true);
    this.closeModal();
  }

  onClose(): void {
    if (this.enableNewSearchAutomatically) {
      this.onCancelSelection();

      this.gtmService.trackElement(
        'global-widget-cancel-new-search-from-price-calendar',
        GaContext.BOOKING_FLOW,
        ElementTypes.BOOKING_WIDGET,
        ElementTypes.BUTTON,
        '',
        ElementActions.CLICK
      );
    }
  }

  startNewSearch(): void {
    this.startSearch.emit();
    this.modalClose.next(true);

    this.gtmService.trackElement(
      'global-widget-start-new-search-from-price-calendar',
      GaContext.BOOKING_FLOW,
      ElementTypes.BOOKING_WIDGET,
      ElementTypes.BUTTON,
      '',
      ElementActions.CLICK
    );
  }

  startSearchWithSelectedDates(onlyOnce = false): void {
    this.subscription.add(
      this.openWithParams$
        .pipe(
          onlyOnce ? take(1) : map((params) => params),
          filter((params) => isPresent(params) && priceCalendarBaseParamsAreValid(params)),
          distinctUntilChanged(
            (pre: PriceCalendarWithPricesParams, next: PriceCalendarWithPricesParams) =>
              pre.origin === next.origin &&
              pre.destination === next.destination &&
              pre.tripType === next.tripType &&
              pre.travelClass === next.travelClass
          )
        )
        .subscribe((params) => {
          this.selectedHistogramMonth$.next(undefined);
          this.priceCalendarService.updateParams({
            ...params,
            initialTravelDates: {
              ...params.initialTravelDates,
              returnDate: params.tripType !== TripType.RETURN ? undefined : params.initialTravelDates?.returnDate,
            },
          });
        })
    );
  }
}
