export enum TextInputType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
}

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
}

export enum CheckBoxTheme {
  SQUARE = 'square',
  ROUNDED = 'rounded',
}

export enum RadioButtonTheme {
  BOX = 'box',
  ROUND = 'round',
}

export enum CursorType {
  POINTER = 'pointer',
}
