import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';

import { CustomIcon, IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { NEVER, Observable } from 'rxjs';

import { NativeBridgeService } from '@fcom/core/services/native-bridge/native-bridge.service';

@Component({
  selector: 'fin-header-boxes',
  styleUrls: ['./header-boxes.component.scss'],
  templateUrl: './header-boxes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBoxesComponent implements OnInit {
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;

  @Input()
  homeLink$: Observable<string> = NEVER;

  showLink: boolean;

  @Output()
  homeGtmEvent: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(
    private nativeBridgeService: NativeBridgeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.nativeBridgeService.isInsideNativeWebView) {
      this.showLink = false;
    } else {
      this.showLink = true;
    }
    this.cdr.markForCheck();
  }
}
