/* eslint-disable */
export enum FinnairCancelFlowType {
  RULE_24_HOUR = 'RULE_24_HOUR',
  MANUAL_REFUNDABLE = 'MANUAL_REFUNDABLE',
  AUTOMATIC_INVOLUNTARY = 'AUTOMATIC_INVOLUNTARY',
  MANUAL_INVOLUNTARY = 'MANUAL_INVOLUNTARY',
  AUTOMATIC_RULE_24_HOUR = 'AUTOMATIC_RULE_24_HOUR',
  MANUAL_RULE_24_HOUR = 'MANUAL_RULE_24_HOUR',
  RULE_CACR = 'RULE_CACR',
  AUTOMATIC_RULE_TICKET_FLEX = 'AUTOMATIC_RULE_TICKET_FLEX',
  AUTOMATIC_RULE_TICKET_SLIGHTCLASSIC = 'AUTOMATIC_RULE_TICKET_SLIGHTCLASSIC',
  AUTOMATIC_RULE_TICKET_OTHER = 'AUTOMATIC_RULE_TICKET_OTHER',
  MANUAL_RULE_TICKET_FLEX = 'MANUAL_RULE_TICKET_FLEX',
  MANUAL_RULE_TICKET_SLIGHTCLASSIC = 'MANUAL_RULE_TICKET_SLIGHTCLASSIC',
  MANUAL_RULE_TICKET_AWARD = 'MANUAL_RULE_TICKET_AWARD',
  MANUAL_RULE_TICKET_OTHER = 'MANUAL_RULE_TICKET_OTHER'
}

