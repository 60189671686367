import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { DRAWER_ANIMATION_DELAY } from './constants';
import { DrawerDirection } from './enums';
import { IconButtonTheme } from '../buttons';

/**
 * The drawer component contains only the design of the drawer and the open/close mechanism.
 *
 * The content is displayed thanks to the `ng-content` tags. The `open` input is using angular 2
 * way data binding, and you should set a boolean property (such as `drawerOpen` in the example
 * below) in the parent component to control open and close behavior.
 *
 * By default, the z-index is the same as the one used for the modal.
 *
 * The drawer will be displayed either on the left, right or the bottom of the page depending on the input value
 * and will be automatically closed after clicking on the * overlay thanks to the `fcomFocusOut` directive.
 *
 * @example
 * <button (click)="drawerOpen = true">Open drawer</button>
 * <fcom-drawer [(open)]="drawerOpen">
 *   <header>
 *     <h2 class="font-heading-3 flex-1 truncate overflow-hidden nordic-blue-900-text">
 *       TRIP
 *     </h2>
 *     <button type="button" (click)="drawerOpen = false">
 *       <fcom-icon class="icon-small" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
 *     </button>
 *   </header>
 * </fcom-drawer>
 */
@Component({
  selector: 'fcom-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [
    trigger('sliderInOut', [
      state('right', style({ transform: 'translateX(0)' })),
      state('left', style({ transform: 'translateX(0)' })),
      state('bottom', style({ transform: 'translateY(0)' })),
      transition('void => right', [style({ transform: 'translateX(100%)' }), animate(DRAWER_ANIMATION_DELAY)]),
      transition('right => void', [animate(DRAWER_ANIMATION_DELAY, style({ transform: 'translateX(100%)' }))]),
      transition('void => left', [style({ transform: 'translateX(-100%)' }), animate(DRAWER_ANIMATION_DELAY)]),
      transition('left => void', [animate(DRAWER_ANIMATION_DELAY, style({ transform: 'translateX(-100%)' }))]),
      transition('void => bottom', [style({ transform: 'translateY(100%)' }), animate(DRAWER_ANIMATION_DELAY)]),
      transition('bottom => void', [animate(DRAWER_ANIMATION_DELAY, style({ transform: 'translateY(100%)' }))]),
    ]),
    trigger('overlayInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate(DRAWER_ANIMATION_DELAY)]),
      transition(':leave', [animate(DRAWER_ANIMATION_DELAY)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
  public readonly drawerId = uuid();
  public readonly drawerTitleId = `drawer-${this.drawerId}-title`;
  public readonly drawerDescriptionId = `drawer-${this.drawerId}-description`;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
  public readonly DrawerDirection = DrawerDirection;
  public readonly IconButtonTheme = IconButtonTheme;

  @HostBinding('class') hostClasses = 'flex flex--center';

  @ViewChild('drawerHeading')
  drawerHeading: ElementRef;

  private _openState = false;

  @Input()
  set open(_open: boolean) {
    this._openState = _open;

    if (_open) {
      this.onOpen();
    } else {
      this.onClose();
    }

    this.openChange.emit(this._openState);
  }

  get open(): boolean {
    return this._openState;
  }

  /**
   * The title to use in the header of the drawer.
   */
  @Input()
  title: string;

  /**
   * The direction from which the drawer will appear.
   *
   * This variable defines the side of the screen from which the drawer will slide out.
   *
   * Possible values are:
   * - DrawerDirection.FROM_LEFT: The drawer will appear from the left side of the screen.
   * - DrawerDirection.FROM_RIGHT: The drawer will appear from the right side of the screen.
   * - DrawerDirection.FROM_BOTTOM: The drawer will appear from the bottom of the screen.
   *
   * Default value is DrawerDirection.FROM_RIGHT.
   */
  @Input()
  direction: DrawerDirection = DrawerDirection.FROM_RIGHT;

  /**
   * Indicates whether fixed CTA area should be displayed.
   */
  @Input()
  showButtons: boolean;

  /**
   * The aria tag for the close icon button.
   */
  @Input()
  ariaClose = 'Close';

  /**
   * Emits an event when the drawer is closed
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  @Output()
  openChange = new EventEmitter<boolean>();

  showDrawer$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2
  ) {}

  onOpen(): void {
    this.showDrawer$.next(true);
    this.renderer.addClass(this.document.body, 'modal-open');
    this.changeDetectorRef.detectChanges();
    this.drawerHeading.nativeElement.focus();
  }

  onClose(): void {
    this.showDrawer$.next(false);
    this.closed.emit();
    this.renderer.removeClass(this.document.body, 'modal-open');
    this.changeDetectorRef.detectChanges();
  }
}
