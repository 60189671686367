import { Pipe, PipeTransform } from '@angular/core';

import { Observable, EMPTY, of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { Amount } from '@fcom/dapi';
import { GlobalBookingTravelClass } from '@fcom/core';
import { TripType } from '@fcom/core/interfaces';
import { finShare } from '@fcom/rx';

import { CheapestPriceForAllDestinationsService } from '../services';
import { MarketingOffer } from '../interfaces';

interface PricePerType {
  [TripType.ONEWAY]: Amount;
  [TripType.RETURN]: Amount;
}

@Pipe({
  name: 'finCheapestPricePipe',
})
export class CheapestPricePipe implements PipeTransform {
  constructor(private cheapestPriceForAllDestinationsService: CheapestPriceForAllDestinationsService) {}

  transform(
    destinationCode: string | Observable<string>,
    originCode?: string | Observable<string>
  ): Observable<PricePerType | undefined> {
    if (!destinationCode) {
      return EMPTY;
    }
    const destination$ = destinationCode instanceof Observable ? destinationCode : of(destinationCode);
    const origin$ = originCode instanceof Observable ? originCode : of(originCode);
    const economyReturn$ = this.getCheapestForLocation$(origin$, destination$, false);
    const economyOneway$ = this.getCheapestForLocation$(origin$, destination$, true);

    return combineLatest([economyReturn$, economyOneway$]).pipe(
      map(([economyReturn, economyOneway]) => ({
        [TripType.ONEWAY]: economyOneway,
        [TripType.RETURN]: economyReturn,
      }))
    );
  }

  private getCheapestForLocation$(
    origin$: Observable<string>,
    destination$: Observable<string>,
    isOneWay: boolean
  ): Observable<Amount> {
    return combineLatest([origin$, destination$]).pipe(
      switchMap(([origin, destination]) =>
        this.cheapestPriceForAllDestinationsService.offerForSingleTrip(destination, isOneWay, origin)
      ),
      map(
        (offer: MarketingOffer) =>
          offer.prices.find((t) => t.travelClass.toUpperCase() === GlobalBookingTravelClass.ECONOMY)?.price
      ),
      finShare()
    );
  }
}
