import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, pluck, share } from 'rxjs/operators';

import { CmsContentType, CmsImageData, CmsPicture, CmsTaxonomyPicture } from '@fcom/core-api';
import { finShare, retryWithBackoff } from '@fcom/rx';

import { TripType, GlobalBookingTravelClass } from '../../interfaces';
import { getWhitelistedCmsParams, stringHashCode, urlToPathname } from '../../utils';
import { CampaignTagToLabelKey } from '../../constants';
import { ConfigService } from '../config/config.service';
import { StateTransferService } from '../state-transfer/state-transfer.service';

export enum I18nKey {
  GLOBAL = 'global',
  BOOKING = 'booking',
  TAX_CODES = 'taxCodes',
  COUNTRY_REGIONS = 'countryRegions',
  MMB = 'mmb',
  REACCOMMODATION = 'reaccommodation',
  LOYALTY = 'loyalty',
  SERVICE_FORMS = 'serviceForms',
  REFUNDS = 'refunds',
  CORPORATE = 'corporate',
  TRAVEL_READY = 'travelReady',
}

export enum I18nLazyLoadingPrefix {
  TAX_CODES = 'TAX_CODES',
  COUNTRY_REGIONS = 'COUNTRY_REGIONS',
  MMB = 'MMB',
  REACCOMMODATION = 'REACCOMMODATION',
  LOYALTY = 'LOYALTY',
  SERVICE_FORMS = 'SERVICE_FORMS',
  REFUNDS = 'REFUNDS',
  CORPORATE = 'CORPORATE',
  TRAVEL_READY = 'TRAVEL_READY',
}

export type I18nData = {
  key: I18nKey;
  getUrl: (translationsApiUrl: string, lang: string) => string;
  lazyLoadingPrefix?: I18nLazyLoadingPrefix;
};

export const i18nKeyToDataMap: Record<I18nKey, I18nData> = {
  global: {
    key: I18nKey.GLOBAL,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}`,
  },
  booking: {
    key: I18nKey.BOOKING,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/booking`,
  },
  taxCodes: {
    key: I18nKey.TAX_CODES,
    getUrl: (translationsApiUrl: string, lang: string) =>
      `${translationsApiUrl}/translations/${lang}/booking/tax-codes`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.TAX_CODES,
  },
  countryRegions: {
    key: I18nKey.COUNTRY_REGIONS,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/country-regions`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.COUNTRY_REGIONS,
  },
  mmb: {
    key: I18nKey.MMB,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/manage-booking`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.MMB,
  },
  reaccommodation: {
    key: I18nKey.REACCOMMODATION,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/reaccommodation`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.REACCOMMODATION,
  },
  loyalty: {
    key: I18nKey.LOYALTY,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/loyalty`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.LOYALTY,
  },
  serviceForms: {
    key: I18nKey.SERVICE_FORMS,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/service-forms`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.SERVICE_FORMS,
  },
  refunds: {
    key: I18nKey.REFUNDS,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/refunds`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.REFUNDS,
  },
  corporate: {
    key: I18nKey.CORPORATE,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/corporate`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.CORPORATE,
  },
  travelReady: {
    key: I18nKey.TRAVEL_READY,
    getUrl: (translationsApiUrl: string, lang: string) => `${translationsApiUrl}/translations/${lang}/travel-ready`,
    lazyLoadingPrefix: I18nLazyLoadingPrefix.TRAVEL_READY,
  },
};

export interface CmsSearchParams {
  lang?: string;
  locale: string;
  documentType: string;
  propertySet: string;
  subjectTaxonomyExtRef: string;
  segment?: string;
}

export interface CmsSearchHits<T> {
  hits: T[];
  numHits: number;
}

export interface PromoCodeTermsSearchHit {
  teaserText: string;
  teaserTitle: string;
}

export interface PromoCodeTerms {
  title: string;
  description: string;
}

export interface CampaignSearchHit {
  localSettings: {
    selectedTripType: TripType;
    selectedTravelClass: GlobalBookingTravelClass;
    campaignEndText?: string;
    enableBlackTeaser?: boolean;
    restrictToDestinations: {
      destination: string[];
      destinationsByOrigin: {
        [origin: string]: string[];
      };
    };
  };
  subjectTaxonomyTags: CampaignTagToLabelKey[];
}

export interface CmsCampaign {
  destinations?: string[];
  destinationsByOrigin?: { [origin: string]: string[] };
  campaignEndText: string;
  enableBlackTeaser: boolean;
  campaignLabel?: CampaignTagToLabelKey;
  campaignTravelClass?: GlobalBookingTravelClass;
}

export const stateKey = (templateUrl: string): StateKey<any> => makeStateKey<any>(`cds-${stringHashCode(templateUrl)}`);

@Injectable()
export class CmsDataService {
  constructor(
    protected http: HttpClient,
    protected config: ConfigService,
    protected stateTransferService: StateTransferService
  ) {}

  /**
   * Function to fetch JSON content from the CMS server
   *
   * @param url url to send request to
   * @param extraParams object of additional parameters to set on the CMS request.
   * e.g. { tier: 'Basic'}
   */
  getFragmentJson<T>(url: string, extraParams?: { [key: string]: string }): Observable<T> {
    const params = { ...getWhitelistedCmsParams(url), ...extraParams, view: 'fin-fragment-json' };
    const search = Object.keys(params).reduce((acc, cur) => {
      return acc.set(cur, params[cur]);
    }, new HttpParams());
    const fragmentJsonUrl = `${this.config.cmsUrl}${urlToPathname(url)}`;
    return this.stateTransferService.wrapToStateCache(stateKey(`${fragmentJsonUrl}?${search.toString()}`), () =>
      this.getJson(fragmentJsonUrl, search)
    );
  }

  // Should not be overridden in sub class
  getLocalization(
    lang: string,
    i18nData: I18nData,
    pluckProperty: string[] = ['localSettings']
  ): Observable<{ [key: string]: any }> {
    return this.getPage(i18nData, lang).pipe(
      pluck(...pluckProperty),
      map((data: any) =>
        i18nData.lazyLoadingPrefix ? { [i18nData.lazyLoadingPrefix]: { loaded: true, ...data } } : data
      ),
      share()
    );
  }

  getPicturesByTaxonomyTags<
    T extends CmsTaxonomyPicture[],
    R = T extends Array<infer K extends CmsTaxonomyPicture> ? { [key in K]: CmsPicture | undefined } : never,
  >(taxonomyTags: T, lang: string, locale: string): Observable<R> {
    const prepareResult = (response?: CmsSearchHits<CmsPicture>): R => {
      return taxonomyTags.reduce((previousValue, currentValue) => {
        previousValue[currentValue] = response?.hits?.find((item) => item.subjectTaxonomyTags.includes(currentValue));
        return previousValue;
      }, {} as R);
    };

    return this.search<CmsPicture>({
      lang,
      locale,
      documentType: CmsContentType.CMPicture,
      propertySet: 'finnaircom',
      subjectTaxonomyExtRef: taxonomyTags.map((item) => `Subject/${item}`).join(','),
    }).pipe(
      map((response) => {
        return prepareResult(response);
      }),
      catchError(() => of(prepareResult()))
    );
  }

  // Should not be overridden in sub class
  getBookingPicture(lang: string): Observable<CmsImageData> {
    return this.getPage(i18nKeyToDataMap.booking, lang).pipe(pluck('picture'), share());
  }

  getPromoCodeTerms(promoCode: string, lang: string, locale: string): Observable<PromoCodeTerms | undefined> {
    return this.search<PromoCodeTermsSearchHit>({
      lang,
      locale,
      documentType: 'CMArticle',
      propertySet: 'finnaircom',
      subjectTaxonomyExtRef: 'Subject/discountCodeTerms',
      segment: promoCode.toLowerCase(),
    }).pipe(
      map((response) =>
        response?.hits?.length > 0
          ? { title: response.hits[0].teaserTitle, description: response.hits[0].teaserText }
          : undefined
      )
    );
  }

  getOngoingCampaigns(lang: string, locale: string): Observable<CmsCampaign[]> {
    return this.stateTransferService.wrapToStateCache(stateKey(`campaigns-${lang}-${locale}`), () =>
      this.search<CampaignSearchHit>({
        lang,
        locale,
        documentType: 'CMPlaceholder',
        propertySet: 'finnaircom',
        subjectTaxonomyExtRef: 'Subject/fcomCampaign',
      }).pipe(
        map((response) => (response?.numHits > 0 ? response.hits : [])),
        map((hits) =>
          hits.map((hit) => ({
            destinations: hit?.localSettings?.restrictToDestinations?.destination,
            destinationsByOrigin: hit?.localSettings?.restrictToDestinations?.destinationsByOrigin,
            campaignEndText: hit?.localSettings?.campaignEndText || '',
            enableBlackTeaser: hit?.localSettings?.enableBlackTeaser || false,
            campaignLabel: CampaignTagToLabelKey[hit?.subjectTaxonomyTags?.[0]],
            campaignTravelClass: hit?.localSettings?.selectedTravelClass || GlobalBookingTravelClass.ECONOMY,
          }))
        ),
        finShare(),
        catchError(() => of([]))
      )
    );
  }

  protected search<T>(params: CmsSearchParams): Observable<CmsSearchHits<T>> {
    const urlParamLang = params.lang ? `/${params.lang}` : '';
    const urlParamSegment = params.segment ? `&segment=${params.segment}` : '';

    return this.getJson(
      `${this.config.cmsUrl}/service/json/v1/search${urlParamLang}?documenttype=${params.documentType}&locale=${params.locale}&propertySet=${params.propertySet}&subjectTaxonomyExtRef=${params.subjectTaxonomyExtRef}${urlParamSegment}`
    );
  }

  protected getPageJson<T>(i18nData: I18nData, lang: string): Observable<T> {
    return this.getJson<T>(i18nData.getUrl(this.config.cfg.translationsApiUrl, lang));
  }

  private getPage(i18nData: I18nData, lang: string): Observable<any> {
    const url = i18nData.getUrl(this.config.cfg.translationsApiUrl, lang);
    return this.stateTransferService.wrapToStateCache(stateKey(url), () => this.getPageJson(i18nData, lang));
  }

  protected getJson<T>(url: string, params: HttpParams = undefined): Observable<T> {
    return this.http
      .get<T>(url, {
        withCredentials: this.config.cfg.enableCorsCredentials,
        params: params,
      })
      .pipe(
        retryWithBackoff(1),
        map((data) => {
          // @TODO: Remove delete responsiveImageData when CMS has migrated away from these
          if (data?.['picture']) {
            delete data['picture']['responsiveImageData'];
          }
          if (Array.isArray(data?.['media'])) {
            data['media'].forEach((media) => {
              delete media['responsiveImageData'];
            });
          }
          return data;
        }),
        finShare()
      );
  }
}
