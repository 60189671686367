import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { map, Observable, Subscription, take } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { GtmService } from '@fcom/common/gtm/services/gtm.service';
import {
  ButtonTheme,
  IconButtonSize,
  IconButtonTheme,
  IconPosition,
  PopoverOptions,
  PopoverPosition,
} from '@fcom/ui-components';
import { CountryState } from '@fcom/core/interfaces';
import { allowedConfs } from '@fcom/core/reducers/langs';
import { unsubscribe } from '@fcom/core/utils';

import { PopoverService } from '../../../ui-components/components/popover';
import { ElementActions, ElementTypes, GaContext } from '../../interfaces';
import { CountryAndLanguageSelection } from '../country-and-language-selector/country-and-language-selector.component';
import { PageMetaService } from '../../services/page-meta/page-meta.service';
import { getLocalizedLinkForLangCode } from '../../utils/language.utils';

export enum LanguageSelectorContext {
  BOOKING,
  TOP_NAVI,
}

const CONTEXT_TO_GA_CONTEXT = {
  [LanguageSelectorContext.BOOKING]: GaContext.BOOKING_FLOW,
  [LanguageSelectorContext.TOP_NAVI]: GaContext.DESKTOP_MAIN_NAVIGATION,
};

@Component({
  selector: 'fin-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  styles: CSSStyleDeclaration;
  popoverOptions: PopoverOptions;

  readonly ButtonTheme = ButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  readonly currentLanguage$: Observable<string>;

  @Input()
  showWarning = false;

  @Input()
  skipRedirect = false;

  @Input()
  context: LanguageSelectorContext;

  @Output()
  applySelection = new EventEmitter<CountryAndLanguageSelection>();

  countries$: Observable<CountryState>;
  subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platform: unknown,
    private languageService: LanguageService,
    private router: Router,
    private pageMetaService: PageMetaService,
    private gtmService: GtmService,
    private popoverService: PopoverService
  ) {
    this.currentLanguage$ = this.languageService.langKey;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.styles = getComputedStyle(document.documentElement);
      this.popoverOptions = {
        popoverWidth: 340,
        showArrowCaret: false,
        showLeftBorder: false,
        roundedCorners: true,
        hasPadding: true,
        fullWidthContent: true,
        showFullscreenOnBreakpoint: 'tablet_down',
        popoverPosition: PopoverPosition.BOTTOM,
        useFixedPosition: true,
        zIndex: parseInt(this.styles.getPropertyValue('--z-index-nav')),
      };
    }
    this.countries$ = this.initListOfCountries();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  openLanguageSelector(): void {
    this.gtmService.trackElement(
      'language-selector-open',
      CONTEXT_TO_GA_CONTEXT[this.context],
      ElementTypes.BUTTON,
      undefined,
      ElementActions.OPEN
    );
  }

  closeLanguageSelector(): void {
    this.popoverService.close();
  }

  selectLanguageAndRedirect(selection: CountryAndLanguageSelection): void {
    this.gtmService.trackElement(
      'language-selector-select',
      CONTEXT_TO_GA_CONTEXT[this.context],
      ElementTypes.BUTTON,
      selection.languageCode,
      ElementActions.SUBMIT
    );

    if (!this.skipRedirect && this.languageService.langValue !== selection.languageCode) {
      switch (this.context) {
        case LanguageSelectorContext.BOOKING:
          this.subscriptions.add(
            this.pageMetaService.prevPageUrl$.pipe(take(1)).subscribe((prevPageUrl) => {
              this.router.navigateByUrl(`/${selection.languageCode}${prevPageUrl}`);
            })
          );
          break;
        case LanguageSelectorContext.TOP_NAVI:
          this.subscriptions.add(
            this.pageMetaService.links.subscribe((links) => {
              const url = getLocalizedLinkForLangCode(selection.languageCode, links, this.getPreviousUrl());
              this.router.navigateByUrl(url);
            })
          );
      }
    }

    this.applySelection.emit(selection);

    this.closeLanguageSelector();
  }

  private initListOfCountries(): Observable<CountryState> {
    return this.languageService.countryCode.pipe(
      map(
        (countryCode) =>
          allowedConfs.find((country) => country.code === countryCode) || {
            languages: [],
            name: '',
            code: '',
          }
      )
    );
  }

  private getPreviousUrl(): string | undefined {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/corporate/')) {
      return `/${currentUrl.split('/').slice(2).join('/')}`;
    }
  }
}
