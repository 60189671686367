import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { GlobalBookingTravelClass } from '@fcom/core';
import { TripType, FinnairMultiCitySearchInterface, FlightSearchParams, GlobalBookingTripDates } from '@fcom/common';
import { AvailabilityEntry } from '@fcom/dapi/api/models';
import {
  DateFormat,
  DISCOUNT_CODE_PATTERN,
  GIFT_CARD_PATTERN,
  isNotBlank,
  isPresent,
  LocalDate,
  TzDate,
} from '@fcom/core/utils';
import { Pattern } from '@fcom/core/utils/date.format';
import { Amount } from '@fcom/dapi';
import { GlobalBookingFlight } from '@fcom/common/store';

import { DatePickerPrices, PreviousSearch, SeasonalNotificationData } from '../interfaces';

export const createDeeplinkPathFromFlightSearchParams = (
  { locale, flights, paxAmount, travelClass, promoCode, isAward, isCorporate, tripType }: FlightSearchParams,
  corporateTravelClassLimitEnabled = false
): string => {
  const { adults, c15s, children, infants } = paxAmount;

  const urlParams: FinnairMultiCitySearchInterface = {
    flights: mapFlightsToParams(flights),
    cabin: getBookingWidgetCabinClass(
      travelClass,
      isCorporate,
      corporateTravelClassLimitEnabled,
      tripType === TripType.MULTICITY
    ),
    adults,
    c15s,
    children,
    infants,
    isAward: isAward || undefined,
    promoCode: isAward ? undefined : promoCode,
  };

  Object.keys(urlParams).forEach((key) => {
    if (urlParams[key] === (undefined || null)) {
      delete urlParams[key];
    }
  });

  const params = encodeURIComponent(JSON.stringify(urlParams));
  return `/${locale}/booking/flight-selection?json=${params}`;
};

export const mapFlightsToParams = (
  flights: GlobalBookingFlight[]
): { origin: string; destination: string; departureDate: string }[] => {
  return flights.map(({ origin, destination, departureDate }) => {
    return {
      origin: origin.locationCode,
      destination: destination.locationCode,
      departureDate: departureDate.toString(),
    };
  });
};

export const getBookingWidgetCabinClass = (
  travelClass: GlobalBookingTravelClass,
  isCorporate: boolean,
  corporateTravelClassLimitEnabled: boolean,
  isMultiCity: boolean = false
): GlobalBookingTravelClass => {
  if ((corporateTravelClassLimitEnabled && isCorporate) || isMultiCity) {
    return travelClass;
  }

  return travelClass === GlobalBookingTravelClass.ECONOMY ? GlobalBookingTravelClass.MIXED : travelClass;
};

export const seasonalNotificationHandler = (
  availabilityEntry: AvailabilityEntry[],
  dateTranslations: Record<string, string | string[]>
): SeasonalNotificationData => {
  return availabilityEntry.reduce((acc: SeasonalNotificationData, availability: AvailabilityEntry, index: number) => {
    const postFix: string = index > 0 ? String(index + 1) : '';

    const sameYear = new LocalDate(availability.start).localYear === new LocalDate(availability.end).localYear;
    const startFormat: Pattern =
      isNotBlank(availability.start) && isNotBlank(availability.end) && sameYear
        ? DateFormat.MONTH
        : DateFormat.LONG_MONTH_AND_YEAR;

    const start = new DateFormat(dateTranslations).format(availability.start, startFormat);
    const end = new DateFormat(dateTranslations).format(availability.end, DateFormat.LONG_MONTH_AND_YEAR);

    acc[`start${postFix}`] = start;
    acc[`end${postFix}`] = end;
    return acc;
  }, {});
};

export const createPreviousSearches = (
  { locale, flights, paxAmount, travelClass, tripType, promoCode }: FlightSearchParams,
  previousSearches: PreviousSearch[]
): PreviousSearch[] => {
  const previousSearch: PreviousSearch = {
    paxAmount,
    travelClass,
    tripType,
    flights,
    creationDate: TzDate.now().millis,
    discountCode: promoCode || null,
    locale: locale,
  };

  let previousIndex = -1;

  previousIndex = previousSearches.findIndex((ps) =>
    ps.flights?.every(
      (flight, index) =>
        flight?.origin.locationCode === flights[index]?.origin.locationCode &&
        flight?.destination.locationCode === flights[index]?.destination.locationCode
    )
  );

  return previousIndex !== -1
    ? [previousSearch, ...previousSearches.slice(0, previousIndex), ...previousSearches.slice(previousIndex + 1)]
    : [previousSearch, ...previousSearches.slice(0, 1)];
};

export const getStartingFromPrice = (
  prices: DatePickerPrices,
  travelDates: GlobalBookingTripDates,
  isOneWay: boolean
): Amount => prices?.calendar?.[isOneWay ? travelDates?.departureDate?.id : travelDates?.returnDate?.id]?.amount;

export const discountCodeValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(control.value) && isNotBlank(control.value)) {
      if (GIFT_CARD_PATTERN.test(control.value)) {
        return { custom: 'giftCardPatternError' };
      }

      if (!DISCOUNT_CODE_PATTERN.test(control.value)) {
        return { custom: 'pattern' };
      }
    }

    return null;
  };
};

export const hasCorrectAmountOfFlights = (tripType: TripType, flights: GlobalBookingFlight[]): boolean => {
  switch (tripType) {
    case TripType.ONEWAY:
      return flights.length === 1;
    case TripType.RETURN:
      return flights.length === 2;
    default:
      return flights.length > 1;
  }
};
