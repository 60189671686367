<!-- Traditional booking grid -->
<div
  *ngIf="(tripType$ | async) !== TripType.MULTICITY"
  class="selector-grid grid-col-12 no-gutters"
  data-testid="booking-widget"
  [ngClass]="[layout, isGlobalBookingWidget ? WidgetTheme.DARK : WidgetTheme.LIGHT]"
>
  <fin-trip-type-selector
    [isGlobalBookingWidget]="isGlobalBookingWidget"
    [enableMultiCity]="enableMultiCity"
    [returnDateMissing]="!(this.travelDates$ | async)?.returnDate"
    [isSearchEnabled]="shouldSearchBeEnabled$ | async"
    (setTripType)="setTripType($event)"
    (startSearch)="startSearch()"
    (openCalendar)="openCalendar()"
  ></fin-trip-type-selector>
  <fin-location-selector
    [locationPair$]="locations$"
    [showPreviousSearches]="true"
    (setLocations)="setLocations($event)"
    [isGlobalBookingWidget]="isGlobalBookingWidget"
    (locationsSwitched)="startSearch()"
    (startSearch)="startSearch()"
  ></fin-location-selector>

  @if ((showCompact$ | async) === false || isGlobalBookingWidget) {
    <fin-pax-amount-selector
      [enableNewSearchAutomatically]="isGlobalBookingWidget"
      (setPaxAmount)="setPaxAmount($event)"
      (startSearch)="startSearch()"
      [isSearchEnabled]="shouldSearchBeEnabled$ | async"
    ></fin-pax-amount-selector>
    <fin-travel-dates-selector
      [tripType$]="tripType$"
      [travelClass$]="travelClass$"
      [paxAmount$]="paxAmount$"
      [locations$]="locations$"
      [travelDates$]="travelDates$"
      [priceCalendarType]="
        (bookingFlowType$ | async) === WidgetTab.AWARD ? PriceCalendarType.ONLY_DATES : PriceCalendarType.PRICES
      "
      [isOneway$]="(tripType$ | async) === TripType.ONEWAY | finToObservable"
      [showAddReturn]="(tripType$ | async) === TripType.ONEWAY"
      [enableNewSearchAutomatically]="isGlobalBookingWidget"
      [showFlexibleDatesSelection]="showFlexibleDatesSelection$ | async"
      [showTripPriceFrom]="showTripPriceFrom$ | async"
      (setTravelDates)="setTravelDates($event, false)"
      (setFlexibleDates)="setFlexibleDates($event)"
      (addReturn)="onAddReturnClick()"
      [isSearchEnabled]="shouldSearchBeEnabled$ | async"
      (startSearch)="startSearch()"
      (cancelSelection)="cancelTravelDatesSelection()"
    ></fin-travel-dates-selector>
  }
</div>

<!-- Multicity booking grid -->
<ng-container *ngIf="(tripType$ | async) === TripType.MULTICITY">
  @if (enableMultiCity && (isNewMultiCityNotificationVisible$ | async)) {
    <fcom-notification
      [theme]="NotificationTheme.INFO"
      [title]="'bookingSearch.multiCityNewNotificationTitle' | finLocalization"
      [linkText]="'bookingSearch.multiCityNewNotificationLinkText' | finLocalization"
      [linkOpenInNewWindow]="true"
      [linkUrl]="'bookingSearch.multiCityNewNotificationLinkUrl.url' | finLocalization"
      [isClosable]="true"
      (closeClick)="hideNewMultiCityNotification()"
      class="mr-medium-b"
      data-testid="booking-widget-multicity-notification"
    >
      {{ 'bookingSearch.multiCityNewNotificationDescription' | finLocalization }}
    </fcom-notification>
  }

  <div data-testid="booking-widget-multicity" class="selector-grid multicity grid-col-12 no-gutters ms-small-b">
    <fin-trip-type-selector
      [isGlobalBookingWidget]="isGlobalBookingWidget"
      [enableMultiCity]="enableMultiCity"
      (setTripType)="setTripType($event)"
    ></fin-trip-type-selector>
    <fin-pax-amount-selector (setPaxAmount)="setPaxAmount($event)"></fin-pax-amount-selector>
  </div>
  <fin-multicity-selector
    data-testid="multi-city-selector"
    [enableMultiCity]="enableMultiCity"
  ></fin-multicity-selector>
</ng-container>
<!-- Footer -->
<div
  #footer
  class="footer ps-medium-t"
  *ngIf="(showCompact$ | async) === false || isGlobalBookingWidget || (tripType$ | async) === TripType.MULTICITY"
  [class.flex]="layout === WidgetLayout.HORIZONTAL"
  [ngClass]="{
    'flex flex--space-between flex--middle white-text':
      layout === WidgetLayout.HORIZONTAL && (bookingFlowType$ | async) !== WidgetTab.AWARD,
    'flex--end': layout === WidgetLayout.HORIZONTAL && (bookingFlowType$ | async) === WidgetTab.AWARD,
  }"
  data-testid="booking-widget-footer"
>
  <div *ngIf="showDiscountCode$ | async" [class.ps-medium-b]="!isGlobalBookingWidget">
    <fin-discount-code
      [isGlobalBookingWidget]="isGlobalBookingWidget"
      (startSearch)="startSearch()"
    ></fin-discount-code>
  </div>
  <div class="flex flex--middle flex--end">
    <div class="flex-2" *ngIf="showFooterPrice$ | async" data-testid="booking-widget-footer-price">
      <ng-container *ngIf="(tripType$ | async) !== TripType.MULTICITY">
        <div class="font-body-1 regular-type">
          {{
            ((tripType$ | async) === TripType.ONEWAY
              ? 'bookingWidget.priceCalendar.oneWayPerAdult'
              : 'bookingWidget.priceCalendar.roundTripPerAdult'
            ) | finLocalization
          }}
        </div>
        <div class="nordic-900-text bold-type font-heading-4">
          {{ startingPrice$ | async | finPrice: { stripZeroCents: true, useSymbol: true } | finEmpty: '&ndash;' }}
        </div>
      </ng-container>
    </div>

    @if (!isGlobalBookingWidget) {
      <fcom-button
        class="continue-btn"
        [class.ps-xxsmall-b]="isGlobalBookingWidget"
        [theme]="ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER"
        [size]="ButtonSize.LARGE"
        [mode]="ButtonMode.FILL"
        [loading]="loading$ | async"
        [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
        [text]="(isGlobalBookingWidget ? 'bookingSearch.startNewSearch' : 'bookingSearch.search') | finLocalization"
        [disabled]="
          isGlobalBookingWidget ? (isNewSearchEnabled$ | async) === false : (continueEnabled$ | async) === false
        "
        (clickBtn)="startSearch()"
        data-testid="booking-widget-search-button"
      >
      </fcom-button>
    }
  </div>
</div>
<fin-booking-widget-notifications
  [ngClass]="{ 'white-text': isGlobalBookingWidget }"
  [locations$]="flights$"
  [tripType$]="tripType$"
  [profile$]="profile$"
></fin-booking-widget-notifications>
<fin-air-calendar
  *ngIf="airCalendarPrices$ | async"
  [paxAmount$]="paxAmount$"
  [travelDates$]="travelDates$"
  [tripType$]="tripType$"
  [prices$]="airCalendarPrices$"
  [isAward]="(bookingFlowType$ | async) === WidgetTab.AWARD"
  [totalPoints]="(profile$ | async)?.awardPoints"
  (selectDates)="setTravelDates($event, true)"
  (continue)="navigateToBookingFlow(true, $event)"
></fin-air-calendar>
