<fin-block-offer-tile
  *ngIf="blockOffer$ | async as blockOffer"
  (linkClicked)="onLinkClick($event)"
  (amContinueModalClick)="flightHotelClicked.emit($event)"
  class="fill block flex slim"
  [blockOffer$]="blockOffer$"
  [tripType]="tripType"
  [fetchImagePriority]="fetchImagePriority"
  [showPriceCalendarButton]="true"
  (calendarClicked)="emitCalendarClick($event, blockOffer)"
>
</fin-block-offer-tile>
