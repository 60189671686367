<div class="dates-selector-root relative fill grow" [class.separator]="!showSingular && (isOneway$ | async) === false">
  <ng-container *ngIf="!showSingular">
    <fin-booking-widget-selector
      [disabled]="disabled"
      (click)="openModal()"
      [double]="
        (isOneway$ | async) === false
          ? {
              first: {
                label: 'bookingSearch.boundSelection.departureDate' | finLocalization,
                placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
                filled: (travelDates$ | async)?.['departureDate'] !== undefined,
              },
              second: {
                label: 'bookingSearch.boundSelection.returnDate' | finLocalization,
                placeholder: 'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization,
                filled: (travelDates$ | async)?.['returnDate'] !== undefined,
              },
            }
          : undefined
      "
      [label]="'bookingSearch.boundSelection.departureDate' | finLocalization"
      [placeholder]="'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization"
      [filled]="(travelDates$ | async)?.['departureDate'] !== undefined"
      [attr.data-testid]="'travel-dates-selector-both'"
    >
      <span
        *ngIf="(isOneway$ | async) === true && (travelDates$ | async)?.['departureDate'] as selectedDate"
        data-testid="departure-date"
        >{{ selectedDate | finDateFormat: 'FULL_NO_YEAR' }}</span
      >
      <span first *ngIf="(travelDates$ | async)?.['departureDate'] as selectedDate" data-testid="departure-date">{{
        selectedDate | finDateFormat: 'FULL_NO_YEAR'
      }}</span>
      <div
        separator
        *ngIf="(isOneway$ | async) === false && showSeparator"
        class="flex flex--center flex--self-center absolute white-bg date-spacer ps-xxsmall z1"
      >
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.MINUS"></fcom-icon>
      </div>
      <span second *ngIf="(travelDates$ | async)?.['returnDate'] as selectedDate" data-testid="return-date">{{
        selectedDate | finDateFormat: 'FULL_NO_YEAR'
      }}</span>
    </fin-booking-widget-selector>
  </ng-container>
  <fin-booking-widget-selector
    *ngIf="showSingular as departureOrReturn"
    [disabled]="disabled"
    (click)="openModal()"
    [label]="'bookingSearch.boundSelection.' + departureOrReturn | finLocalization"
    [placeholder]="'bookingSearch.boundSelection.selectDatePlaceholder' | finLocalization"
    [filled]="!!(travelDates$ | async)?.[departureOrReturn]"
    [attr.data-testid]="'travel-dates-selector-' + departureOrReturn"
  >
    <span *ngIf="(travelDates$ | async)?.[departureOrReturn] as selectedDate" data-testid="departure-date">{{
      selectedDate | finDateFormat: 'FULL_NO_YEAR'
    }}</span>
  </fin-booking-widget-selector>
</div>

<fcom-modal
  [open]="modalOpen$ | async"
  [fitToContent]="true"
  [buttons]="null"
  [fullHeight]="true"
  [extraWide]="true"
  [roundedContent]="true"
  (close)="closePriceCalendar()"
  data-testid="travel-dates-modal"
>
  <fin-price-calendar
    *ngIf="modalOpen$ | async"
    [openWithParams$]="priceCalendarParams$"
    [showAddReturn]="showAddReturn"
    [showSubtitle]="showSubtitle"
    [disabledDateRanges$]="disabledDateRanges$"
    [showFlexibleDatesSelection]="showFlexibleDatesSelection"
    [showTripPriceFrom]="showTripPriceFrom"
    [enableNewSearchAutomatically]="enableNewSearchAutomatically"
    [isSearchEnabled]="isSearchEnabled"
    [ctaLabel]="'actions.done' | finLocalization"
    [type]="priceCalendarType"
    [enablePreFlightSearchTracking]="priceCalendarType === PriceCalendarType.PRICES"
    [showEnhancedCalendar$]="showEnhancedCalendar$"
    (modalClose)="closePriceCalendar()"
    (ctaClicked)="priceCalendarCTAClicked($event)"
    (addReturnClicked)="addReturnClicked()"
    (travelDatesChanged)="travelDatesChanged($event)"
    (cancelSelection)="cancelSelection.emit()"
    (startSearch)="startSearch.emit()"
  ></fin-price-calendar>
</fcom-modal>
