<section class="sub-header fill white-text {{ backgroundColorClass }}" [class.no-header]="noHeader">
  <div class="grid-col-12 grid-max-width ps-xsmall-y flex flex--middle" [class.sub-header-wrapper]="staticHeight">
    <section
      data-testid="sub-header-content"
      [ngClass]="{
        'col-1-10 sm-col-1-8 md-col-1-9': hasBasket,
        'col-1-12': !hasBasket,
      }"
    >
      <ng-content></ng-content>
    </section>
    <aside
      class="col-11-12 sm-col-9-12 md-col-10-12"
      data-testid="sub-header-basket"
      *ngIf="hasBasket"
      [class.border-left]="!showInfoIcon"
    >
      <div class="sub-header-button flex grow flex--middle flex--end">
        <fcom-icon-button
          *ngIf="isMobile$ | async"
          class="flex--nogrow"
          [attr.data-testid]="showInfoIcon ? 'info-icon-button' : 'shopping-cart-icon-button'"
          [icon]="{
            name: showInfoIcon
              ? SvgLibraryIcon.INFO_OUTLINE
              : isBasketDisabled || !hasActiveBasketState
                ? SvgLibraryIcon.SHOPPING_CART
                : SvgLibraryIcon.SHOPPING_CART_ACTIVE,
          }"
          [aria]="{
            label: 'bookingSummary.displayShoppingBasket' | finLocalization,
          }"
          [size]="IconButtonSize.MEDIUM"
          [theme]="IconButtonTheme.DARK_TERTIARY_GHOST"
          [disabled]="isBasketDisabled"
          (clickBtn)="openBasket.emit()"
        ></fcom-icon-button>
        <fcom-button
          *ngIf="!showInfoIcon && (isMobile$ | async) === false"
          class="flex--nogrow"
          (clickBtn)="openBasket.emit()"
          [icon]="{
            name:
              isBasketDisabled || !hasActiveBasketState
                ? SvgLibraryIcon.SHOPPING_CART
                : SvgLibraryIcon.SHOPPING_CART_ACTIVE,
            position: IconPosition.LEFT,
          }"
          [text]="'cart.title' | finLocalization"
          [theme]="ButtonTheme.DARK_TERTIARY_GHOST"
          [disabled]="isBasketDisabled || (hasBasketActionInMobileOnly && (isMobile$ | async) === false)"
        ></fcom-button>
        <button
          data-testid="modify-search"
          *ngIf="(showModifyButton$ | async) === true"
          class="modify-button font-body-1 medium-type underline"
          (click)="changeStep.emit(STEPS.ENTRY)"
        >
          {{ 'services.sidebar.modifySearch' | finLocalization }}
        </button>
      </div>
    </aside>
  </div>
</section>
