import { TzDate } from '@fcom/core/index';

export enum ThirdPartyAncillariesProvider {
  CAR_TRAWLER_RENTAL = 'CAR_TRAWLER_RENTAL',
  CAR_TRAWLER_TRANSFER = 'CAR_TRAWLER_TRANSFER',
  CAR_TRAWLER_SMARTBLOCK = 'CAR_TRAWLER_SMARTBLOCK',
}

const REMOVE_TIMEZONE = /(Z|\+|-)(\d|:){0,5}$/;

const carTrawlerRentalLangMap = {
  'dk-da': 'da',
  'de-de': 'de',
  'ch-de': 'ch',
  'at-de': 'de',
  'ee-et': 'et',
  'us-en': 'en-us',
  'es-es': 'es',
  'fr-fr': 'fr',
  'ca-fr': 'fr-ca',
  'ch-fr': 'fr',
  'it-it': 'it',
  'no-no': 'no',
  'pl-pl': 'pl',
  'fi-fi': 'fi',
  'se-sv': 'sv',
  'ru-ru': 'ru',
  'kr-ko': 'ko',
  'jp-ja': 'ja',
  'hk-zh': 'zh-hk',
  'cn-zh': 'zh',
};

const carRentalTransferLangMap = {
  'dk-da': 'da-DK',
  'ch-de': 'de-CH',
  'de-de': 'de-DE',
  'gb-en': 'en-GB',
  'us-en': 'en-US',
  'es-es': 'es-ES',
  'ee-et': 'et-EE',
  'fi-fi': 'fi-FI',
  'ca-fr': 'fr-CA',
  'fr-fr': 'fr-FR',
  'it-it': 'it-IT',
  'jp-ja': 'ja-JP',
  'kr-ko': 'ko-KR',
  'no-no': 'no-NO',
  'pl-pl': 'pl-PL',
  'ru-ru': 'ru-RU',
  'se-sv': 'sv-SE',
  'cn-zh': 'zh-CN',
  'hk-zh': 'zh-HK',
  'tw-zh': 'zh-TW',
};

const carRentalSmartBlockLangMap = {
  ...carRentalTransferLangMap,
};

export const carTrawlerCountryLangMap = {
  [ThirdPartyAncillariesProvider.CAR_TRAWLER_RENTAL]: carTrawlerRentalLangMap,
  [ThirdPartyAncillariesProvider.CAR_TRAWLER_TRANSFER]: carRentalTransferLangMap,
  [ThirdPartyAncillariesProvider.CAR_TRAWLER_SMARTBLOCK]: carRentalSmartBlockLangMap,
};

export const carTrawlerSupportedCurrencies = [
  'EUR',
  'SEK',
  'DKK',
  'NOK',
  'CHF',
  'PLN',
  'HUF',
  'CZK',
  'GBP',
  'USD',
  'AUD',
  'CAD',
  'HKD',
  'CNY',
  'SGD',
  'JPY',
  'KRW',
  'INR',
  'THB',
];

export const getCarTrawlerLang = (provider: ThirdPartyAncillariesProvider, finnairLocale: string): string => {
  return carTrawlerCountryLangMap[provider]?.[finnairLocale] || 'en';
};

export const supportedCarTrawlerCurrencies = (finnairCurrency: string): string => {
  return carTrawlerSupportedCurrencies.includes(finnairCurrency) ? finnairCurrency : 'EUR';
};

/**
 * Manipulate an ISO 8601 dateTime string into the format that CarTrawler requires.
 *
 * Example format that CarTrawler requires 2024-06-20T05:15:00
 *
 * 1) CarTrawler wants local datetime, but no timezone in the string.
 * 2) CarTrawler transfers.finnair.com site does not allow milliseconds in the string
 */
export const getCarTrawlerDateTime = (dateTime: string, addHours: number): string => {
  return new TzDate(dateTime.replace(REMOVE_TIMEZONE, 'Z')).addHours(addHours).toISOString().split('.')[0];
};
