<ng-template #modalButton let-text="text">
  <fcom-button
    class="close-modal-btn"
    [text]="text | finLocalization"
    [disabled]="!open || forceRemainOpen"
    (clickBtn)="onClose()"
    data-testid="modal-close-btn"
  >
  </fcom-button>
</ng-template>

<ng-template #buttonTmpl>
  <div class="align-center pr-medium-x pr-xsmall-y nordic-blue-25-bg rounded-bottom-large" [ngSwitch]="buttons">
    <ng-container
      *ngSwitchCase="ModalButtons.CLOSE"
      [ngTemplateOutlet]="modalButton"
      [ngTemplateOutletContext]="{ text: 'actions.close' }"
    >
    </ng-container>
    <ng-container
      *ngSwitchCase="ModalButtons.OK"
      [ngTemplateOutlet]="modalButton"
      [ngTemplateOutletContext]="{ text: 'actions.ok' }"
    >
    </ng-container>
    <ng-container *ngSwitchCase="ModalButtons.CONTINUE_CANCEL" [ngTemplateOutlet]="modalButtonContinueCancel">
    </ng-container>
    <ng-container *ngSwitchCase="ModalButtons.CUSTOM">
      <ng-content select="[buttons]"></ng-content>
    </ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="modalButton"
      [ngTemplateOutletContext]="{ text: 'actions.done' }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #modalButtonContinueCancel>
  <fcom-button
    class="close-modal-btn mr-xsmall-r"
    [text]="'actions.cancel' | finLocalization"
    [disabled]="!open || forceRemainOpen"
    [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
    (clickBtn)="onClose()"
  >
  </fcom-button>
  <fcom-button
    class="confirm-modal-btn"
    [text]="confirmTranslationKey | finLocalization"
    [disabled]="!open || !confirmEnabled || forceRemainOpen"
    (clickBtn)="onConfirm()"
  >
  </fcom-button>
</ng-template>

<ng-template #modalContent>
  <div
    #modalOverlay
    data-testid="modal-overlay"
    class="modal"
    [ngClass]="{ 'fit-content-mobile': !fullHeightMobile }"
    [attr.aria-labelledby]="'modal-' + modalId + '-title'"
    role="dialog"
    aria-modal="true"
    (click)="overlayClickable && overlayClick($event)"
  >
    <div
      class="modal-wrapper grow flex flex--column white-bg rounded-large"
      [ngClass]="{
        'fit-to-content': fitToContent,
        'full-height': fullHeight,
        'extra-wide': extraWide,
      }"
    >
      <header class="modal-header fill relative flex--column border-bottom" [class.is-hidden]="!title">
        <div class="flex--nogrow flex--basis-header flex flex--row flex--center">
          <h2
            #modalHeading
            *ngIf="title"
            class="font-heading-4 flex-1 truncate overflow-hidden nordic-blue-900-text pr-xsmall-y pr-large-l"
            [attr.id]="'modal-' + modalId + '-title'"
            data-testid="modal-title"
            tabindex="-1"
          >
            {{ title }}
          </h2>
          <button
            *ngIf="!forceRemainOpen && (modalService.hideCloseButton$ | async) === false"
            #closeButton
            class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset pr-large-x pr-xsmall-y"
            [disabled]="!open"
            [attr.title]="'actions.close' | finLocalization"
            [attr.aria-label]="'actions.close' | finLocalization"
            (click)="onClose()"
          >
            <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
          </button>
        </div>
        <ng-content select="[subtitle]"></ng-content>
      </header>
      <div
        class="modal-content-wrapper flex-1 scroll relative"
        [class.rounded-large]="roundedContent"
        tabindex="0"
        #content
      >
        <ng-content></ng-content>
      </div>
      <footer *ngIf="buttons" class="flex--nogrow">
        <ng-content select="[messageBox]"></ng-content>
        <ng-container *ngTemplateOutlet="buttonTmpl"></ng-container>
      </footer>
    </div>
  </div>
</ng-template>

<div fcomTrapFocus *ngIf="open" (keydown.escape)="onCloseEvent($event)" [@openClose] class="transition">
  <ng-container *ngTemplateOutlet="modalContent"></ng-container>
</div>
