import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, fromEvent, map, Observable, Subscription, take, tap } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { TagRoundingStyle, TagTheme } from '@fcom/ui-components';
import { ConfigService, unsubscribe } from '@fcom/core/index';
import { MediaQueryService } from '@fcom/common/services';

import { shouldDisplayBenefit } from '../../../utils/benefit.utils';
import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';
import { TaggableComponent } from '../../../../ui-components/components/tag/taggable.component';

@Component({
  selector: 'fin-fare-family-list-option',
  styleUrls: ['./fare-family-list-option.component.scss'],
  templateUrl: './fare-family-list-option.component.html',
})
export class FlightSelectionFareFamilyListOptionComponent extends TaggableComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);

  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagRoundingStyle = TagRoundingStyle;
  readonly TagTheme = TagTheme;

  @Input({ required: true }) fareFamily$: Observable<ExtendedFareFamily>;
  @Input({ required: true }) currencyCode: string;
  @Input({ required: true }) aria: {
    index: number;
    count: number;
  };

  @Input() isShowTotalPrice = false;

  @Output() selectFareFamily = new EventEmitter<boolean>();

  selected$: Observable<boolean> = this.selectFareFamily.asObservable().pipe(startWith(false));

  subscriptions = new Subscription();
  fareFamilyBenefit$: Observable<TranslatedBenefitRow[]>;
  isNotAward$: Observable<boolean>;
  isHover$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tagTheme$: Observable<TagTheme>;

  constructor(
    public configService: ConfigService,
    public mediaQueryService: MediaQueryService,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'mouseenter')
      .pipe(
        tap(() => this.isHover$.next(true)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    fromEvent(this.el.nativeElement, 'mouseleave')
      .pipe(
        tap(() => this.isHover$.next(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.isNotAward$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        const isAward = Boolean(fareFamily.points);
        return !isAward;
      })
    );

    this.fareFamilyBenefit$ = this.fareFamily$.pipe(
      map((fareFamily) => {
        return fareFamily.benefits.translatedBenefitRows.filter((benefit) => shouldDisplayBenefit(benefit));
      })
    );

    this.tagTheme$ = combineLatest([this.isHover$, this.selected$, this.fareFamily$]).pipe(
      map(([isHover, selected, fareFamily]) => {
        if (selected) {
          return TagTheme.DEFAULT;
        }

        if (isHover) {
          return TagTheme.DEFAULT;
        }

        return fareFamily.benefits.tags[0].theme as TagTheme;
      }),
      startWith(TagTheme.ACTIVE)
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  checkboxChange(event: Event): void {
    this.selectFareFamily.emit((event.target as HTMLInputElement).checked);

    this.subscriptions.add(
      this.mediaQueryService
        .isBreakpoint$('tablet_down')
        .pipe(take(1))
        .subscribe((isTabletDown) => {
          if (!isTabletDown) {
            return;
          }

          (event.target as HTMLInputElement).closest('.fare-family').scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
          });
        })
    );
  }
}
