<div [class.active]="(visible && position === ButtonPosition.GRID) || position === ButtonPosition.GRID_FULL">
  <div
    class="cta-host"
    [class.visible]="visible"
    [ngClass]="{
      grid:
        position === ButtonPosition.GRID ||
        position === ButtonPosition.GRID_FULL ||
        position === ButtonPosition.GRID_TWO_THIRDS_RIGHT,
      full: position === ButtonPosition.FULL,
    }"
  >
    <div
      class="cta-container"
      [ngClass]="{
        'grid-col-12 grid-max-width':
          position === ButtonPosition.GRID ||
          position === ButtonPosition.GRID_FULL ||
          position === ButtonPosition.GRID_TWO_THIRDS_RIGHT,
      }"
    >
      <div
        class="cta-button ps-medium-t ps-xsmall-b"
        [ngClass]="{
          'col-1-12':
            position === ButtonPosition.GRID ||
            position === ButtonPosition.GRID_FULL ||
            position === ButtonPosition.GRID_TWO_THIRDS_RIGHT,
          'sm-col-2-7 md-col-2-8': position === ButtonPosition.GRID,
          'md-col-5-12': position === ButtonPosition.GRID_TWO_THIRDS_RIGHT,
          'sm-col-3-10 xl-col-4-9': position === ButtonPosition.GRID_FULL,
          disabled: disabled,
        }"
        data-testid="cta-button"
      >
        <div
          class="cta-flex white-text font-body-1 nordic-blue-900-bg flex flex--center rounded-medium shadow"
          [ngClass]="{ 'flex--column': layout === ButtonLayout.COLUMN }"
          data-testid="cta-button-content"
        >
          <div class="flex-1 flex--basis0 fill">
            <div class="ps-xxsmall-x ps-xxsmall-t fill align-center">
              <ng-content></ng-content>
            </div>
          </div>
          <div
            class="flex-1 flex--basis0 flex fill ps-xsmall"
            [class.flex--row-reverse]="isTabletUp"
            [class.flex--column]="!isTabletUp"
          >
            <fcom-button
              class="ps-xxsmall fill"
              data-testid="cta-main-button"
              [disabled]="disabled"
              [loading]="loading"
              [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
              [text]="title"
              [theme]="theme"
              [mode]="ButtonMode.FILL"
              [icon]="icon"
              (clickBtn)="buttonClicked.emit($event)"
            ></fcom-button>
            <fcom-button
              *ngIf="showSecondaryButton"
              class="ps-xxsmall fill"
              data-testid="cta-secondary-button"
              [disabled]="secondaryButtonDisabled"
              [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
              [loading]="loading"
              [text]="secondaryButtonTitle"
              [theme]="ButtonTheme.DARK_SECONDARY_OUTLINE"
              [mode]="ButtonMode.FILL"
              (clickBtn)="secondaryButtonClicked.emit($event)"
            ></fcom-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
