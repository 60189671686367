import { Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, catchError, combineLatest, filter, map } from 'rxjs';

import { hasUnitPrices } from '@fcom/common/utils';
import { SentryLogger } from '@fcom/core/services';
import {
  Category,
  FinnairAmount,
  FinnairCharge,
  FinnairPassengerCode,
  FinnairPassengerItem,
  FinnairTotalPricesDetails,
} from '@fcom/dapi/api/models';
import { Amount, BreakdownModel, PriceService } from '@fcom/dapi/interfaces';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { ModalButtons } from '@fcom/ui-components';
import { ElementActions, ElementTypes } from '@fcom/common/interfaces';
import { GtmService } from '@fcom/common/gtm';

import {
  getCategoryServices,
  getPassengerServices,
  hasPassengers,
  passengerHasOtherServices,
  passengerHasSaf,
  showTravelExtrasTitle,
} from '../../utils/order.utils';

@Component({
  selector: 'fin-price-breakdown-renewed',
  templateUrl: './price-breakdown-renewed.component.html',
  styleUrls: ['./price-breakdown-renewed.component.scss'],
})
export class PriceBreakdownRenewedComponent implements OnInit {
  public readonly ModalButtons = ModalButtons;
  public readonly asPaxTypeKey = asPaxTypeKey;
  public readonly FinnairPassengerCode = FinnairPassengerCode;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
  public readonly Category = Category;

  @Input()
  prices$: Observable<FinnairTotalPricesDetails>;
  @Input()
  passengers$: Observable<FinnairPassengerItem[]>;
  @Input()
  showPoints = false;

  breakdowns$: Observable<BreakdownModel[]>;
  totalPrice$: Observable<Amount>;
  taxModalOpen = false;
  taxModalData: { totalTaxes: FinnairAmount };
  summary$: Observable<PriceService[]>;
  summaryModalOpen: boolean;
  summaryModalData: { prices: FinnairTotalPricesDetails };
  totalTaxes$: Observable<FinnairCharge[]>;
  showSafTitle$: Observable<boolean>;
  showTravelExtrasTitle$: Observable<boolean>;

  ENVIRONMENTAL_SURCHARGE = 'environmentalSurcharge';

  constructor(
    private sentryLogger: SentryLogger,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.breakdowns$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) =>
        passengers
          .filter((passenger) => parseFloat(prices.total.totalPerPax?.[passenger.id]?.totalAmount.amount) > 0)
          .map((passenger: FinnairPassengerItem) => {
            return {
              ...passenger,
              prices: prices.flight?.totalPerPax?.[passenger.id] || prices.services?.totalPerPax?.[passenger.id],
              services: getPassengerServices(prices.services?.totalPerCategory, passenger),
              hasSaf: passengerHasSaf(prices.services?.totalPerCategory, passenger),
              hasOtherServices: passengerHasOtherServices(prices.services?.totalPerCategory, passenger),
              total: prices.total.totalPerPax?.[passenger.id]?.totalAmount,
            };
          })
      ),
      catchError((e: unknown) => {
        this.sentryLogger.error('Exception in handling prices of passengers', e);
        return EMPTY;
      })
    );

    this.showSafTitle$ = this.prices$.pipe(
      map((prices) => Boolean(getCategoryServices(prices.services.totalPerCategory, Category.SAF)))
    );

    this.showTravelExtrasTitle$ = this.prices$.pipe(
      map((prices) => {
        return (
          showTravelExtrasTitle(prices.services.totalPerCategory) && Boolean(prices.services.totalPerCategory?.length)
        );
      })
    );

    this.summary$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) => {
        const serviceCategoryHasDiscountedServices = prices.services?.totalPerCategory?.reduce(
          (acc, categoryPriceItem) => {
            acc[categoryPriceItem.category] = Object.values(categoryPriceItem.totalPerPax || {}).some(
              (pricePerPassenger) => pricePerPassenger.price?.originalTotalAmount
            );
            return acc;
          },
          {}
        );

        const summary = passengers.reduce<{ [key: string]: PriceService }>(
          (summaryAcc, passenger: FinnairPassengerItem) => {
            const passengerSummary = getPassengerServices(prices.services?.totalPerCategory, passenger);

            return passengerSummary.reduce((acc, service) => {
              if (acc[service.id]) {
                const categoryHasDiscountedServices = serviceCategoryHasDiscountedServices?.[service.id];

                const totalAmount = parseFloat(acc[service.id].total.amount) + parseFloat(service.total.amount);
                const summaryOriginalTotal = acc[service.id].originalTotal || acc[service.id].total;
                const originalTotalAmount =
                  parseFloat(summaryOriginalTotal?.amount || acc[service.id].total.amount) +
                  parseFloat(service.originalTotal?.amount || service.total.amount);

                acc[service.id] = {
                  ...acc[service.id],
                  total: {
                    ...acc[service.id].total,
                    amount: totalAmount.toString(),
                  },
                  ...(categoryHasDiscountedServices
                    ? {
                        originalTotal: {
                          ...summaryOriginalTotal,
                          amount: originalTotalAmount.toString(),
                        },
                      }
                    : {}),
                  count: service.count + acc[service.id].count,
                };
              } else {
                acc[service.id] = { ...service };
              }
              return acc;
            }, summaryAcc);
          },
          {}
        );
        return Object.values(summary);
      })
    );

    this.totalTaxes$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices)),
      map(([passengers, prices]) => {
        const totalTaxes = passengers.reduce<{ [key: string]: FinnairCharge }>(
          (taxAcc, passenger: FinnairPassengerItem) => {
            const passengerTaxes = prices.flight?.totalPerPax?.[passenger.id]?.taxes;

            passengerTaxes?.forEach((tax) => {
              if (taxAcc[tax.code]) {
                taxAcc[tax.code].amount = (parseFloat(taxAcc[tax.code].amount) + parseFloat(tax.amount)).toString();
              } else {
                taxAcc[tax.code] = { ...tax };
              }
            });

            const passengerEnvironmentalSurcharge = prices.flight?.totalPerPax?.[passenger.id]?.environmentalSurcharge;
            const surchargeAmount = parseFloat(passengerEnvironmentalSurcharge?.amount);

            if (!surchargeAmount) {
              return taxAcc;
            }

            taxAcc[this.ENVIRONMENTAL_SURCHARGE] = taxAcc[this.ENVIRONMENTAL_SURCHARGE]
              ? {
                  ...taxAcc[this.ENVIRONMENTAL_SURCHARGE],
                  amount: (parseFloat(taxAcc[this.ENVIRONMENTAL_SURCHARGE].amount) + surchargeAmount).toString(),
                }
              : {
                  ...passengerEnvironmentalSurcharge,
                  code: this.ENVIRONMENTAL_SURCHARGE,
                };

            return taxAcc;
          },
          {}
        );

        return Object.values(totalTaxes);
      })
    );
  }

  openTaxModal(event, totalTaxes: FinnairAmount) {
    event.stopPropagation();

    this.taxModalOpen = true;
    this.taxModalData = { totalTaxes };

    this.gtmService.trackElement(
      'taxes-fees-charges-modal',
      'booking-flow_purchase-review',
      ElementTypes.BUTTON,
      '',
      ElementActions.CLICK
    );
  }

  openSummaryModal(event, prices: FinnairTotalPricesDetails) {
    event.stopPropagation();

    this.summaryModalOpen = true;
    this.summaryModalData = {
      prices,
    };

    this.gtmService.trackElement(
      'detailed-breakdown-modal',
      'booking-flow_purchase-review',
      ElementTypes.BUTTON,
      '',
      ElementActions.CLICK
    );
  }

  closeSummaryModal() {
    this.summaryModalData = null;
  }

  closeTaxModal() {
    this.taxModalData = null;
  }
}
