import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ButtonTheme, ModalButtons } from '@fcom/ui-components';
import { SelectionPhase } from '@fcom/common/interfaces/booking';
import { FinnairOrder, FinnairOrderCancelRefund } from '@fcom/dapi/api/models';

import { ExtendedBound } from '../../../cancel/interfaces';
import { ItineraryModal } from '../../modules/ancillaries/interfaces';

@Component({
  selector: 'fin-itinerary-detail',
  templateUrl: './itinerary-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryDetailComponent {
  readonly ModalButtons = ModalButtons;
  readonly ButtonTheme = ButtonTheme;
  itineraryModal: ItineraryModal = {
    open: false,
    data: null,
  };
  slideIndexSubject$ = new ReplaySubject<number>(0);

  @Input()
  cancelRefund$: Observable<FinnairOrderCancelRefund>;

  @Input()
  bounds$: Observable<ExtendedBound[]>;

  @Input()
  locations$: Observable<FinnairOrder['locations']>;

  viewDetails(index: number): void {
    const bounds$ = this.cancelRefund$.pipe(map((cancelRefund) => cancelRefund.order.bounds));
    this.slideIndexSubject$.next(index);
    this.itineraryModal = {
      open: true,
      data: {
        outbound$: bounds$.pipe(map((bounds) => bounds[0])),
        inbound$: bounds$.pipe(map((bounds) => (bounds.length > 1 ? bounds[1] : undefined))),
        locations$: this.locations$,
        selectionPhase$: this.slideIndexSubject$.pipe(
          map((slideIndex) => (slideIndex === 0 ? SelectionPhase.OUTBOUND : SelectionPhase.INBOUND))
        ),
      },
    };
  }
}
