<div
  *ngIf="fareFamily$ | async as fareFamily"
  data-testid="fare-family-card"
  class="fare-family border rounded-large overflow-hidden white-bg"
  [class.selected]="fareFamily.selected"
  [class.disabled]="fareFamily.disabled"
  [ngStyle]="{ outlineColor: fareFamily.selected ? fareFamily.benefits.brandColor : null }"
>
  @for (tag of fareFamily.benefits.tags; track tag.text) {
    <div class="absolute badge z1 ms-xsmall-t ms-xsmall-r">
      <fcom-tag [theme]="tagTheme$ | async" [roundingStyle]="castTagRoundingStyle(tag.roundingStyle)">
        @if (tag.icon && (tag.iconPosition === 'left' || tag.iconPosition === undefined)) {
          <fcom-icon
            [name]="castIconName(tag.icon.name)"
            [category]="castIconCategory(tag.icon.category)"
            class="icon-small ms-xxsmall-r"
          ></fcom-icon>
        }
        <span>{{ tag.text }}</span>
        @if (tag.icon && tag.iconPosition === 'right') {
          <fcom-icon
            [name]="castIconName(tag.icon.name)"
            [category]="castIconCategory(tag.icon.category)"
            class="icon-small ms-xxsmall-r"
          ></fcom-icon>
        }
      </fcom-tag>
    </div>
  }
  <label
    class="flex flex--column nordic-blue-25-bg"
    [style.--brand-color]="fareFamily.benefits.brandColor"
    [attr.aria-label]="
      'ticketSelection.aria.fareFamily.fareFamilyOption'
        | finLocalization
          : {
              fareFamily: fareFamily.benefits.brandName,
              price:
                {
                  amount: fareFamily.totalPrice,
                  currencyCode: currencyCode,
                } | finPrice,
              index: aria.index + 1,
              totalCount: aria.count,
            }
    "
    [attr.data-testid]="'fare-family-label-' + fareFamily.fareFamilyCode"
  >
    <input
      [attr.data-testid]="'fare-family-checkbox-' + fareFamily.fareFamilyCode"
      (change)="checkboxChange($event)"
      type="checkbox"
      class="hidden-checkbox"
      [checked]="fareFamily.selected"
      [disabled]="fareFamily.disabled"
    />

    <div class="ps-small-x ps-small-b" aria-hidden="true">
      <fcom-tag
        *ngIf="fareFamily.badgeType === FareFamilyBadgeType.POPULAR"
        [theme]="fareFamily.selected ? TagTheme.DEFAULT : TagTheme.POPULAR"
        class="absolute popular-badge ms-xsmall-t ms-xsmall-r"
        [attr.data-testid]="'badge-tag'"
      >
        {{ 'ticketSelection.fareFamily.ribbonText' | finLocalization }}
      </fcom-tag>
      <fcom-icon
        class="white-bg circle checkbox-icon"
        [dataTestId]="'fare-family-icon-' + fareFamily.fareFamilyCode"
        [name]="
          fareFamily.selected ? SvgLibraryIcon.CHECKMARK_BUTTON_SELECTED : SvgLibraryIcon.CHECKMARK_BUTTON_UNSELECTED
        "
      ></fcom-icon>
      <div class="fare-family-name font-body-1 medium-type nordic-blue-900-text ms-xsmall-t">
        {{ fareFamily.benefits.brandName }}
      </div>
      <div class="fare-family-price font-heading-4" [class.with-original-price]="fareFamily.originalTotalPrice">
        <span class="total-price-text">
          {{
            {
              amount: isShowTotalPrice ? fareFamily.totalPrice : fareFamily.price,
              currencyCode: currencyCode,
            } | finPrice
          }}
        </span>
        <span
          *ngIf="fareFamily.originalTotalPrice || fareFamily.originalPrice"
          class="strike-through regular-type font-body-2"
        >
          {{
            {
              amount: isShowTotalPrice ? fareFamily.originalTotalPrice : fareFamily.originalPrice,
              currencyCode: currencyCode,
            } | finPrice
          }}
        </span>
      </div>
    </div>
    <div class="fare-family-brand-color-bar ps-xxsmall" [style.background-color]="fareFamily.benefits.brandColor"></div>
  </label>
  <div class="fare-family-benefits ps-xxsmall-x">
    <ng-container *ngIf="isNotAward$ | async">
      <fcom-tag class="ms-small-l ms-small-t">
        <fcom-icon class="ms-xxsmall-r icon-small" [name]="SvgLibraryIcon.AVIOS_CURRENCY"></fcom-icon>
        <span *ngIf="fareFamily.totalAviosAccrual">
          {{ 'avios.collectPointsTag' | finLocalization: { points: fareFamily.totalAviosAccrual?.toLocaleString() } }}
        </span>
        <span *ngIf="!fareFamily.totalAviosAccrual">
          {{ 'avios.collectUndefinedPoints' | finLocalization }}
        </span>
      </fcom-tag>
    </ng-container>

    <div class="benefits-list flex-1 benefits ms-small-b">
      <fin-benefit
        class="block ps-small-x"
        *ngFor="let benefit of fareFamilyBenefit$ | async; let first = first"
        [benefit]="benefit"
        [enableBenefitChanges$]="true | finToObservable"
        [ngClass]="{
          'ps-xsmall-t ps-xxsmall-b': first,
          'ps-xxsmall-y': !first,
        }"
      ></fin-benefit>
    </div>
  </div>
</div>
