<fin-booking-widget-selector
  class="flex flex-1 grow"
  [label]="(usePopoverSelectors ? 'bookingSummary.paxAmount' : 'passengersAndClass.label') | finLocalization"
  [disabled]="disabled"
  (click)="openModal()"
  (close)="onClose()"
  data-testid="pax-amount-travel-class-selector"
  [popoverContent]="popoverContent"
  [popoverOptions]="popoverOptions"
>
  <div class="flex flex--middle">
    <fcom-icon
      class="icon-small"
      [name]="(totalPax$ | async) > 1 ? SvgLibraryIcon.MULTI_PAX : SvgLibraryIcon.SINGLE_PAX"
    ></fcom-icon>
    <span class="overflow-hidden truncate" data-testid="pax-amount-travel-class-text">
      @if (usePopoverSelectors) {
        {{ paxAmountBreakdown$ | async }}
      } @else {
        {{ totalPax$ | async }},
        {{
          'travelClass.' +
            ((travelClass$ | async) === GlobalBookingTravelClass.MIXED
              ? 'mixedCombined'
              : (travelClass$ | async | lowercase)) | finLocalization
        }}
      }
    </span>
  </div>
</fin-booking-widget-selector>

<ng-template #popoverContent>
  <div class="scroll rounded-large">
    <header class="popover-header fill relative border-bottom flex--column ps-medium">
      <div class="flex--nogrow flex--basis-header flex flex--row flex--center">
        <h2
          class="font-heading-4 flex-1 truncate overflow-hidden nordic-blue-900-text"
          [attr.id]="'popover-' + popoverOptions.popoverID + '-title'"
        >
          {{ 'bookingSummary.paxAmount' | finLocalization }}
        </h2>
        <button
          class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset"
          [attr.title]="'actions.close' | finLocalization"
          [attr.aria-label]="'actions.close' | finLocalization"
          (click)="enableNewSearchAutomatically ? cancelSelection() : closePopover()"
        >
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
        </button>
      </div>
    </header>
    <ng-container *ngTemplateOutlet="paxAmount; context: { displayTravelClass: false, popover: true }"></ng-container>
    <footer class="popover-footer nordic-blue-25-bg ps-medium flex flex--end">
      @if (enableNewSearchAutomatically) {
        <fcom-button
          data-testid="cancel-button"
          class="ms-small-r"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [text]="'actions.cancel' | finLocalization"
          [aria]="{ label: 'actions.cancel' | finLocalization, role: 'button' }"
          (clickBtn)="cancelSelection()"
        ></fcom-button>

        <fcom-button
          data-testid="booking-search-button"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'bookingSearch.search' | finLocalization"
          [aria]="{ label: 'bookingSearch.search' | finLocalization, role: 'button' }"
          (clickBtn)="startNewSearch()"
          [disabled]="!isSearchEnabled"
        ></fcom-button>
      } @else {
        <fcom-button
          data-testid="done-button"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'actions.done' | finLocalization"
          [aria]="{ label: 'actions.done' | finLocalization, role: 'button' }"
          (clickBtn)="closePopover()"
        ></fcom-button>
      }
    </footer>
  </div>
</ng-template>

<fcom-modal
  *ngIf="modalOpen"
  [(open)]="modalOpen"
  [title]="'passengersAndClass.label' | finLocalization"
  [fitToContent]="true"
  data-testid="pax-amount-travel-class-modal"
>
  <ng-container *ngTemplateOutlet="paxAmount; context: { displayTravelClass: true, popover: false }"></ng-container>
</fcom-modal>

<ng-template #paxAmount let-displayTravelClass="displayTravelClass" let-popover="popover">
  <div>
    <ul
      id="pax-details"
      class="flex flex--column"
      tabindex="-1"
      [ngClass]="{
        'pr-large-x': !popover,
        'ps-medium-x': popover,
      }"
    >
      <li
        *ngFor="let paxItem of paxAmount$ | async | keyvalue"
        class="flex flex--space-between flex--center fill ps-xsmall-y"
        [attr.data-testid]="'pax-amount-' + paxItem.key"
      >
        <div>
          <div class="font-body-1 capitalize">
            {{
              'bookingWidget.passenger.' + paxItem.key + (paxItem.value <= 1 ? '.singular' : '.plural')
                | finLocalization
            }}
            {{ 'bookingWidget.passenger.' + paxItem.key + '.ageDefinition' | finLocalization }}
          </div>
          <div class="font-body-2 nordic-blue-900-text">
            {{ 'bookingWidget.passenger.' + paxItem.key + '.ageRuleDefinition' | finLocalization }}
          </div>
        </div>
        <fin-plus-minus-selector
          *ngIf="paxAmountConfig$ | async as paxAmountConfig"
          class="fill nordic-blue-900-text"
          [min]="paxAmountConfig[paxItem.key].min"
          [max]="paxAmountConfig[paxItem.key].max"
          [model]="paxItem.value"
          [decreaseLabel]="'passengerLabels.removeAdult' | finLocalization"
          [increaseLabel]="'passengerLabels.addAdult' | finLocalization"
          [title]="paxItem.value.toString()"
          [iconSize]="'icon-large'"
          [textSize]="'font-heading-3'"
          [buttonPadding]="false"
          [controls]="paxItem.key + '-total-' + paxItem.value"
          (increment)="onPaxDetailsUpdate($event, paxItem.key, AmountUpdateType.INCREMENT)"
          (decrement)="onPaxDetailsUpdate($event, paxItem.key, AmountUpdateType.DECREMENT)"
        >
        </fin-plus-minus-selector>
      </li>
      <fcom-link
        data-testid="child-only-booking-info"
        class="external-link bold-type pr-xsmall-y"
        [href]="'bookingSearch.passenger.childOnly.link.url' | finLocalization"
        [external]="true"
        [openInNewTab]="true"
        [icon]="{ name: SvgLibraryIcon.EXTERNAL_TAB, position: IconPosition.RIGHT }"
        >{{ 'bookingSearch.passenger.childOnly.linkText' | finLocalization }}
      </fcom-link>
    </ul>

    <ul *ngIf="displayTravelClass" id="to-travel-class" role="radiogroup" class="flex flex--column ps-xsmall-y">
      <li
        *ngFor="let tClass of availableTravelClasses$ | async; let first = first; let last = last"
        [attr.id]="'to-travel-class-' + tClass"
        class="flex flex--middle flex--space-between focus-inset link-hover pr-large-x"
        [class.border-top]="first"
        [class.border-bottom]="!last"
        (click)="onTravelClassSelect(tClass)"
      >
        <input
          type="radio"
          class="is-vishidden"
          name="travelClass"
          [id]="'travelClass-' + tClass"
          [value]="tClass"
          [checked]="(travelClass$ | async) === tClass"
          [tabindex]="(travelClass$ | async) === tClass ? 0 : -1"
        />
        <label
          [for]="'travelClass-' + tClass"
          class="fill font-body-1 ps-medium-y focus-inset link-hover nordic-blue-900-text"
        >
          {{
            'travelClass.' + (tClass === GlobalBookingTravelClass.MIXED ? 'mixedCombined' : (tClass | lowercase))
              | finLocalization
          }}
        </label>

        <fcom-icon
          *ngIf="(travelClass$ | async) === tClass"
          class="icon-medium nordic-blue-900-text"
          [name]="SvgLibraryIcon.CHECKMARK"
        ></fcom-icon>
      </li>
    </ul>
  </div>
</ng-template>
