import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  AwsApiGwInterceptor,
  ClientNativeBridgeService,
  FormPostService,
  StateTransferService,
  WINDOW_PROVIDERS,
  WithCredentialsInterceptor,
} from '@fcom/core';
import {
  ConfigService,
  CookieService,
  GeolocationService,
  LocationRouteCffService,
  NativeBridgeService,
  TimeAndGeoipProvider,
  TimeService,
} from '@fcom/core/services';
import { CmsLocalizationProvider } from '@fcom/core/services/language/cms-localization.provider';
import { LocalizationProvider } from '@fcom/core/services/language/localization.provider';
import { DataCloudService } from '@fcom/common/datacloud';
import { GtmService } from '@fcom/common/gtm';
import { LoginTokenInterceptor } from '@fcom/common/login/interceptors';
import { DatadogHeadersInterceptor } from '@fcom/common/interceptors/datadog-headers.interceptor';
import {
  BreadcrumbService,
  HotjarService,
  NavigatedPathToContentWrapperService,
  NavigationMenuService,
  PageMetaService,
  PriceCalendarService,
  SchemaService,
  ScrollService,
} from '@fcom/common/services';
import { DapiUatInterceptor } from '@fcom/core/interceptors/dapi-uat-interceptor';
import { ErrorInterceptor } from '@fcom/core/interceptors/error.interceptor';
import { CampaignBannerService } from '@fcom/common/services/campaign-banner/campaign-banner.service';
import { FeedbackService } from '@fcom/common/services/feedback/feedback.service';
import { DapiHeadersInterceptor } from '@fcom/core/interceptors/dapi-headers-interceptor';
import { TierBenefitService } from '@fcom/core/services/tier-benefit/tier-benefit.service';

import { ClientDataCloudService } from '../services/client-datacloud.service';
import { ClientGtmService } from '../services/client-gtm.service';

export const INTERCEPTOR_PROVIDERS: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AwsApiGwInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WithCredentialsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoginTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DapiUatInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DapiHeadersInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DatadogHeadersInterceptor,
    multi: true,
  },
];

export const WEB_SERVICE_PROVIDERS: any[] = [
  CookieService,
  GeolocationService,
  ScrollService,
  TimeAndGeoipProvider,
  TimeService,
  { provide: DataCloudService, useClass: ClientDataCloudService },
  { provide: GtmService, useClass: ClientGtmService },
  ...WINDOW_PROVIDERS,
];

export const COMMON_SERVICE_PROVIDERS: any[] = [
  ConfigService,
  TierBenefitService,
  FormPostService,
  LocationRouteCffService,
  { provide: NativeBridgeService, useClass: ClientNativeBridgeService },
  NavigationMenuService,
  PageMetaService,
  StateTransferService,
  BreadcrumbService,
  NavigatedPathToContentWrapperService,
  { provide: LocalizationProvider, useClass: CmsLocalizationProvider },
  CampaignBannerService,
  FeedbackService,
  HotjarService,
  SchemaService,
  PriceCalendarService,
];
