import { Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-inform-block',
  templateUrl: './inform-block.component.html',
})
export class InformBlockComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() title: string;
}
