import { APP_BASE_HREF, CommonModule, Location } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideClientHydration } from '@angular/platform-browser';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { baseUrl } from '@finnairoyj/fcom-ui-styles';

import { IconsModule } from '@fcom/ui-components';
import { LanguageService, UiTranslateModule } from '@fcom/ui-translate';
import { ChatModule } from '@fcom/chat';
import { ComponentsModule } from '@fcom/common/components';
import { CookiePolicyModule } from '@fcom/common/components/cookie-policy/cookie-policy.module';
import { PipesModule } from '@fcom/common/pipes/pipes.module';
import { AppState, ConfigService, CookieService, SentryLogger } from '@fcom/core';
import { FinLanguageService } from '@fcom/core/services/language/language.service';
import { LocalizationProvider } from '@fcom/core/services/language/localization.provider';
import { StateTreeModule } from '@fcom/core/store';
import { PersonalizationModule } from '@fcom/loyalty-personalization/personalization.module';
import { CommonStoreModule } from '@fcom/common/store';
import { CommonBookingStoreModule } from '@fcom/common-booking/store';

import { RouteModule } from './app.routes';
import { COMMON_SERVICE_PROVIDERS, INTERCEPTOR_PROVIDERS, WEB_SERVICE_PROVIDERS } from './providers';

const disableAnimations =
  typeof window === 'undefined'
    ? true
    : !('animate' in document.documentElement) ||
      (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

export function languageServiceFactory(
  store: Store<AppState>,
  cookieService: CookieService,
  localizationProvider: LocalizationProvider,
  configService: ConfigService,
  sentryLogger: SentryLogger,
  location: Location,
  platform: object
) {
  return new FinLanguageService(
    store,
    cookieService,
    localizationProvider,
    configService,
    sentryLogger,
    location,
    platform
  );
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    StateTreeModule,
    // Application modules
    PipesModule.forRoot(),
    ComponentsModule,
    CookiePolicyModule,
    // Routes
    RouteModule,
    StoreRouterConnectingModule.forRoot(),
    IconsModule.forRoot({ baseUrl }),
    UiTranslateModule.forRoot({
      languageService: {
        provide: LanguageService,
        useFactory: languageServiceFactory,
        deps: [Store, CookieService, LocalizationProvider, ConfigService, SentryLogger, Location, PLATFORM_ID],
      },
    }),
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    ChatModule,
    PersonalizationModule.forClient(),
    CommonStoreModule,
    CommonBookingStoreModule,
  ],
  providers: [
    provideClientHydration(),
    { provide: APP_ID, useValue: 'fcom-ux' },
    ...COMMON_SERVICE_PROVIDERS,
    ...WEB_SERVICE_PROVIDERS,
    ...INTERCEPTOR_PROVIDERS,
    { provide: APP_BASE_HREF, useValue: '/' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
