<ng-container *ngIf="(openWithParams$ | async) !== null">
  <fcom-datepicker
    *ngIf="'datepicker' | finLocalization as uiLabels"
    [uiLabels]="uiLabels"
    [dateLabels]="'date' | finLocalization"
    [titleAreaConfiguration]="{
      titleAreaStyle: 'ps-large-x ps-xsmall-y',
      title: datePickerTitleLabel$ | async | finLocalization,
      titleStyle: 'font-heading-4',
    }"
    [isDateRange]="(tripType$ | async) === TripType.RETURN"
    [selectableDatesRange]="calendarRange"
    [selectedDates]="[(travelDates$ | async)?.departureDate, (travelDates$ | async)?.returnDate]"
    [doneBtnHidden]="true"
    [showTags]="false"
    [dayTemplate]="dayTemplate"
    [headerTemplate]="
      (type === PriceCalendarType.PRICES && (isLoading$ | async)) || (calendarPrices$ | async)?.histogram?.length > 0
        ? calendarHeader
        : undefined
    "
    [scrollToMonthIndex$]="scrollToHistogramMonth$"
    [showAddReturn]="(tripType$ | async) === TripType.ONEWAY && showAddReturn"
    [subTitle]="(subTitle$ | async) ? subTitleTemplate : undefined"
    [disabledDateRanges]="disabledDateRanges$ | async"
    [showEnhancedCalendar]="showEnhancedCalendar$ | async"
    (selectedDatesChange)="updateCalendarDates($event)"
    (close)="enableNewSearchAutomatically ? onClose() : closeModal()"
    (monthChange)="setHistogramMonth($event?.monthArrayIndex)"
    (addReturn)="addReturn()"
  ></fcom-datepicker>

  <ng-template #subTitleTemplate>
    <ng-container *ngIf="subTitle$ | async as subTitle">
      <h3 class="font-body-1 subTitle medium-type" [style]="CMS_STYLE_OVERRIDE">
        <span>
          {{ subTitle.origin }}
        </span>
        <span class="flex--nogrow ps-xxsmall-x">
          <fcom-icon
            class="icon-small"
            [name]="
              (tripType$ | async) === TripType.RETURN ? SvgLibraryIcon.RETURN_TRIP : SvgLibraryIcon.AIRCRAFT_HORIZONTAL
            "
          ></fcom-icon>
        </span>
        <span>
          {{ subTitle.destination }}
        </span>
      </h3>
    </ng-container>
  </ng-template>
  <!-- custom modal footer -->
  <div
    class="df-tablet-up flex flex--middle ps-large-x grey-50-bg font-body-1 ps-small"
    [ngClass]="{
      'price-calendar-sticky-footer': datesSelected$ | async,
      enhancedContainer: (showEnhancedCalendar$ | async) === true,
    }"
    data-testid="price-calendar-footer"
  >
    <span class="flex flex--align-start flex--space-between fill price-calendar-footer-container">
      <ng-container>
        @if (showTripPriceFrom && type === PriceCalendarType.PRICES) {
          <span data-testid="date-modal-price">
            <span
              >{{
                ((tripType$ | async) === TripType.ONEWAY
                  ? 'bookingWidget.priceCalendar.oneWayPerAdult'
                  : 'bookingWidget.priceCalendar.roundTripPerAdult'
                ) | finLocalization
              }}
            </span>
            <span class="bold-type">{{
              pricePerAdult$ | async | finPrice: { stripZeroCents: true, useSymbol: true } | finEmpty: '&ndash;'
            }}</span>
          </span>
        }

        @if (showFlexibleDatesSelection) {
          <span class="flex--end">
            <fcom-checkbox
              [name]="'flexibleDates'"
              [checked]="flexibleDates$ | async"
              [disabled]="false"
              (change)="setFlexibleDates($event)"
              data-testid="flexible-dates"
            >
              <span>{{ 'priceCalendar.flexibleDates' | finLocalization }}</span>
            </fcom-checkbox>
          </span>
        }
      </ng-container>
    </span>
    <div
      class="done-button align-center"
      [class.enhancedBtn]="(showEnhancedCalendar$ | async) === true"
      [class.flex]="enableNewSearchAutomatically"
    >
      @if (enableNewSearchAutomatically) {
        <fcom-button
          data-testid="travel-dates-cancel"
          class="ms-small-r"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [text]="'actions.cancel' | finLocalization"
          [aria]="{ label: 'actions.cancel' | finLocalization, role: 'button' }"
          (clickBtn)="onClose()"
        ></fcom-button>

        <fcom-button
          data-testid="travel-dates-search"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'bookingSearch.search' | finLocalization"
          [aria]="{ label: 'bookingSearch.search' | finLocalization, role: 'button' }"
          [disabled]="!isSearchEnabled"
          (clickBtn)="startNewSearch()"
        ></fcom-button>
      } @else {
        <fcom-button
          [text]="ctaLabel"
          [disabled]="ctaDisabled$ | async"
          (clickBtn)="emitCTAClicked()"
          data-testid="price-calendar-cta-button"
        ></fcom-button>
      }
    </div>
  </div>
</ng-container>

<ng-template #calendarHeader>
  <fin-histogram
    [barData$]="calendarPrices$ | finMap: 'histogram'"
    [selected$]="selectedHistogramMonth$"
    [isActive$]="(openWithParams$ | async) !== undefined | finToObservable"
    [isLoading$]="isLoading$"
    (barSelected)="handleHistogramClick($event)"
    (barsScrolled)="handleBarsScrolled($event)"
  ></fin-histogram>
</ng-template>

<ng-template
  #dayTemplate
  let-dayValue="dayValue"
  let-dayString="dayString"
  let-showEnhancedCalendar="showEnhancedCalendar"
>
  <div
    class="calendar-day"
    [class.enhancedDay]="showEnhancedCalendar"
    *finLet="(calendarPrices$ | async)?.calendar?.[dayValue.id] as price"
    [attr.data-testid]="'calendar-day-' + dayValue.id"
  >
    <div
      class="calendar-day-number"
      [ngClass]="{
        'font-heading-5': showEnhancedCalendar,
        'heather-700-text': showEnhancedCalendar && price?.isLowestPrice,
        'ps-xxsmall-t': !showEnhancedCalendar,
      }"
    >
      {{ dayString }}
    </div>
    @if ((this.isLoading$ | async) === true) {
      <div class="flex flex--center ps-xxsmall">
        <div class="grey-bar"></div>
      </div>
    } @else if ((calendarPrices$ | async)?.calendar) {
      <div
        class="calendar-day-price font-body-2 regular-type"
        [ngClass]="{
          'heather-700-text bold-type': price?.isLowestPrice,
        }"
        [attr.data-testid]="'calendar-price-' + dayValue.id"
      >
        <ng-container
          *ngTemplateOutlet="
            priceTemplate;
            context: {
              amount:
                dayValue.id >= (travelDates$ | async)?.departureDate?.id &&
                dayValue.id < (travelDates$ | async)?.returnDate?.id &&
                (tripType$ | async) === TripType.RETURN
                  ? undefined
                  : price?.amount,
              isPriceAvailable: (calendarPrices$ | async)?.histogram,
            }
          "
        ></ng-container>
      </div>
    } @else {
      <div class="calendar-day-price font-body-2 regular-type">
        <ng-container
          *ngTemplateOutlet="priceTemplate; context: { amount: undefined, isPriceAvailable: false }"
        ></ng-container>
      </div>
    }
    <ng-template #priceTemplate let-amount="amount" let-isPriceAvailable="isPriceAvailable">
      <ng-container *ngIf="isPriceAvailable">
        {{ amount | finPrice: { abbreviate: true, useCustomFormat: true } | finEmpty: '&nbsp;' }}
      </ng-container>
    </ng-template>

    @if (price) {
      <div
        class="fill absolute"
        [attr.data-testid]="'calendar-price-border-' + dayValue.id"
        [ngClass]="{
          'ps-xxsmall-x': showEnhancedCalendar,
          'birch-300-bg': !showEnhancedCalendar && !price?.noFlight,
          'grey-400-bg': !showEnhancedCalendar && price?.noFlight,
          'day-border': !showEnhancedCalendar,
        }"
      >
        @if (showEnhancedCalendar && price?.isLowestPrice) {
          <div
            class="day-border"
            [class.heather-700-bg]="price?.isLowestPrice"
            [class.white-bg]="price?.isLowestPrice"
          ></div>
        }
      </div>
    }
  </div>
</ng-template>
