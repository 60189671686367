import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CmsArticle, CmsContentType, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'fin-hero-bluewings-cluster',
  templateUrl: './hero-bluewings-cluster.component.html',
  styleUrl: './hero-bluewings-cluster.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBluewingsClusterComponent {
  readonly CmsContentType = CmsContentType;
  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;

  @Input()
  content: CmsArticle;
}
