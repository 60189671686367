<fin-booking-widget-selector
  class="flex flex-1"
  [label]="'passengers' | finLocalization"
  [disabled]="disabled"
  (click)="openModal()"
  data-testid="am-room-selector"
>
  <div class="flex flex--middle">
    <fcom-icon
      class="icon-small"
      [name]="
        paxAmountTotals.total.adults + paxAmountTotals.total.children > 1
          ? SvgLibraryIcon.MULTI_PAX
          : SvgLibraryIcon.SINGLE_PAX
      "
    >
    </fcom-icon>
    <span>
      {{ paxAmountTotals.total.adults }}
      {{ 'passenger.' + (paxAmountTotals.total.adults > 1 ? 'adults' : 'adult') | finLocalization }}
    </span>
    <span *ngIf="paxAmountTotals.total.children > 0"
      >, {{ paxAmountTotals.total.children }}
      {{ 'passenger.' + (paxAmountTotals.total.children > 1 ? 'children' : 'child') | finLocalization }}</span
    >
  </div>
</fin-booking-widget-selector>

<fcom-modal
  [(open)]="modalOpen"
  (close)="emitSelectedRooms()"
  [title]="'passengers' | finLocalization"
  [fitToContent]="true"
  data-testid="am-room-selector-modal"
>
  <ul id="rooms" class="flex flex--column pr-large-x" tabindex="-1">
    <li *ngFor="let selectedRoom of selectedRooms; let i = index" [id]="'room-' + i" class="block border-bottom">
      <div class="ms-large-t nordic-blue-900-text font-body-1 bold-type">
        {{ 'aurinkomatkat.paxAmount.room' | finLocalization }} {{ i + 1 }}
      </div>
      <div class="flex flex--space-between flex--center ps-xsmall-y fill border-bottom">
        <div class="font-body-1 capitalize">
          {{
            'bookingWidget.passenger.adults' + (selectedRoom.adults <= 1 ? '.singular' : '.plural') | finLocalization
          }}
        </div>
        <fin-plus-minus-selector
          class="fill nordic-blue-900-text"
          [min]="PAX_AMOUNT_CONFIG.adults.minPerRoom"
          [max]="
            paxAmountTotals.remaining.adults + selectedRoom.adults > PAX_AMOUNT_CONFIG.adults.maxPerRoom
              ? PAX_AMOUNT_CONFIG.adults.maxPerRoom
              : paxAmountTotals.remaining.adults + selectedRoom.adults
          "
          [model]="selectedRoom.adults"
          [decreaseLabel]="'passengerLabels.removeAdult' | finLocalization"
          [increaseLabel]="'passengerLabels.addAdult' | finLocalization"
          [title]="selectedRoom.adults.toString()"
          [iconSize]="'icon-large'"
          [textSize]="'font-heading-3'"
          [buttonPadding]="false"
          (increment)="onPaxDetailsUpdate('adults', AmountUpdateType.INCREMENT, i)"
          (decrement)="onPaxDetailsUpdate('adults', AmountUpdateType.DECREMENT, i)"
        >
        </fin-plus-minus-selector>
      </div>
      <div class="flex flex--space-between flex--center ps-xsmall-y fill">
        <div class="font-body-1">
          <div class="capitalize">
            {{
              'bookingWidget.passenger.children' + (selectedRoom.children <= 1 ? '.singular' : '.plural')
                | finLocalization
            }}
            {{ 'aurinkomatkat.paxAmount.childAgeRange' | finLocalization }}
          </div>
          <div>
            {{ 'aurinkomatkat.paxAmount.childAgeRule' | finLocalization }}
          </div>
        </div>
        <fin-plus-minus-selector
          class="fill nordic-blue-900-text"
          [min]="0"
          [max]="
            paxAmountTotals.remaining.children + selectedRoom.children > PAX_AMOUNT_CONFIG.children.maxPerRoom
              ? PAX_AMOUNT_CONFIG.children.maxPerRoom
              : paxAmountTotals.remaining.children + selectedRoom.children
          "
          [model]="selectedRoom.children"
          [decreaseLabel]="'passengerLabels.removeAmChild' | finLocalization"
          [increaseLabel]="'passengerLabels.addAmChild' | finLocalization"
          [title]="selectedRoom.children.toString()"
          [iconSize]="'icon-large'"
          [textSize]="'font-heading-3'"
          [buttonPadding]="false"
          (increment)="onPaxDetailsUpdate('children', AmountUpdateType.INCREMENT, i)"
          (decrement)="onPaxDetailsUpdate('children', AmountUpdateType.DECREMENT, i)"
        >
        </fin-plus-minus-selector>
      </div>
      <form [formGroup]="selectedRoom.childrenForm">
        <ul class="flex flex--column">
          <li
            class="flex flex--column"
            *ngFor="let control of selectedRoom.childrenForm.controls | keyvalue; let i = index"
          >
            <fin-select-input
              class="block ms-small-b"
              [controlName]="control.key"
              [parentForm]="selectedRoom.childrenForm"
              [required]="true"
              [options]="
                (selectedRoom.children > selectedRoom.adults && i + 1 > selectedRoom.adults
                  ? childAgeOptionsWithoutInfants$
                  : childAgeOptions$
                ) | async
              "
              [displayMargin]="false"
              [disableSelectOption]="true"
              [label]="('passenger.child' | finLocalization | titlecase) + ' ' + (i + 1)"
              (change)="updatePaxAmountTotals()"
            >
            </fin-select-input>
          </li>
        </ul>
      </form>
      <div class="ms-large-y" *ngIf="i > 0">
        <fcom-button
          (clickBtn)="removeRoom(i)"
          [aria]="{ controls: 'room' + i }"
          [icon]="{
            name: SvgLibraryIcon.MINUS_OUTLINE,
            position: IconPosition.LEFT,
          }"
          [text]="'aurinkomatkat.paxAmount.removeRoom' | finLocalization: { roomNumber: i + 1 }"
          [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        ></fcom-button>
      </div>
    </li>
  </ul>

  <div
    class="pr-large-x mr-small-y"
    *ngIf="PAX_AMOUNT_CONFIG.maxTotalRooms > selectedRooms.length && paxAmountTotals.remaining.adults > 0"
  >
    <fcom-button
      (clickBtn)="addRoom()"
      [aria]="{ controls: 'rooms' }"
      [icon]="{ name: SvgLibraryIcon.PLUS, position: IconPosition.LEFT }"
      [text]="'aurinkomatkat.paxAmount.addRoom' | finLocalization"
      [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
    ></fcom-button>
  </div>
</fcom-modal>
