import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { catchError, combineLatest, EMPTY, filter, map, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  FinnairCancelFlowType,
  FinnairOrder,
  FinnairOrderCancelRefund,
  FinnairPassengerItem,
  FinnairServiceItem,
  FinnairTotalPricesDetails,
  ServiceFlow,
} from '@fcom/dapi/api/models';
import { ButtonTheme, ModalButtons, NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { hasUnitPrices } from '@fcom/common/utils';
import { SentryLogger } from '@fcom/core/services';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { BreakdownModel } from '@fcom/dapi/interfaces';
import { CmsArticle } from '@fcom/core-api';
import { finShare } from '@fcom/rx';
import { FragmentService } from '@fcom/common/services';

import { getPassengerServices, hasPassengers } from '../../utils/order.utils';
import { RefundDetailNotificationType } from '../../interfaces/refund.interface';

const FLOW_TYPE_NOTIFICATION_KEYS = {
  [FinnairCancelFlowType.AUTOMATIC_RULE_24_HOUR]: 'manualAndAutomaticRule24Hour',
  [FinnairCancelFlowType.AUTOMATIC_RULE_TICKET_SLIGHTCLASSIC]: 'manualAndAutomaticRuleSlightClassic',
  [FinnairCancelFlowType.AUTOMATIC_RULE_TICKET_FLEX]: 'manualAndAutomaticRuleTicketFlex',
  [FinnairCancelFlowType.AUTOMATIC_RULE_TICKET_OTHER]: 'manualAndAutomaticTicketOther',
  [FinnairCancelFlowType.MANUAL_RULE_24_HOUR]: 'manualAndAutomaticRule24Hour',
  [FinnairCancelFlowType.MANUAL_RULE_TICKET_OTHER]: 'manualAndAutomaticTicketOther',
  [FinnairCancelFlowType.MANUAL_RULE_TICKET_FLEX]: 'manualAndAutomaticRuleTicketFlex',
  [FinnairCancelFlowType.MANUAL_RULE_TICKET_SLIGHTCLASSIC]: 'manualAndAutomaticRuleSlightClassic',
  [FinnairCancelFlowType.MANUAL_RULE_TICKET_AWARD]: 'manualAndAutomaticRule24Hour',
  [FinnairCancelFlowType.RULE_CACR]: 'cacr',
  [FinnairCancelFlowType.MANUAL_INVOLUNTARY]: 'manualAndAutomaticInvoluntary',
  [FinnairCancelFlowType.AUTOMATIC_INVOLUNTARY]: 'manualAndAutomaticInvoluntary',
  [FinnairCancelFlowType.RULE_24_HOUR]: 'manualAndAutomaticInvoluntary',
  [FinnairCancelFlowType.MANUAL_REFUNDABLE]: 'manualAndAutomaticInvoluntary',
};

const DEPRECATED_AND_INVOLUNTARY_RULES = [
  FinnairCancelFlowType.MANUAL_INVOLUNTARY,
  FinnairCancelFlowType.AUTOMATIC_INVOLUNTARY,
  FinnairCancelFlowType.RULE_24_HOUR,
  FinnairCancelFlowType.MANUAL_REFUNDABLE,
];

const KOREA_COUNTRY_CODE = 'KR';

@Component({
  selector: 'fin-refund-detail',
  templateUrl: './refund-detail.component.html',
  styleUrls: ['./refund-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundDetailComponent implements OnInit {
  readonly asPaxTypeKey = asPaxTypeKey;
  readonly ModalButtons = ModalButtons;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly ServiceFlow = ServiceFlow;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly ButtonTheme = ButtonTheme;

  @Input({ required: true })
  cancelRefund$: Observable<FinnairOrderCancelRefund>;

  @Input({ required: true })
  notificationType: RefundDetailNotificationType;

  @Input()
  services$: Observable<FinnairServiceItem[]>;

  @Output()
  goToClaimForm: EventEmitter<void> = new EventEmitter<void>();

  mainNotificationContent$: Observable<CmsArticle>;
  flightTicketNotificationKey$: Observable<string>;
  travelExtrasNotificationKey$: Observable<string>;
  displayFlightTicketNotification$: Observable<boolean>;
  displayRefundNotificationWithLink$: Observable<boolean>;
  displayMainNotification$: Observable<boolean>;
  displayGoToClaimFormButton$: Observable<boolean>;
  displayRefundAmountBlock$: Observable<boolean>;
  displayEligibleForRefundMessage$: Observable<boolean>;
  isDeprecatedOrInvoluntaryRule$: Observable<boolean>;
  showTravelExtrasBlock$: Observable<boolean>;

  summaryModalOpen: boolean;
  summaryModalData: { prices: FinnairTotalPricesDetails };
  breakdowns$: Observable<BreakdownModel[]> = of([]);
  constructor(
    private sentryLogger: SentryLogger,
    private fragmentService: FragmentService
  ) {}

  ngOnInit(): void {
    this.isDeprecatedOrInvoluntaryRule$ = this.cancelRefund$.pipe(
      map((cancelAndRefund) => DEPRECATED_AND_INVOLUNTARY_RULES.includes(cancelAndRefund.flowType)),
      finShare()
    );
    this.breakdowns$ = this.cancelRefund$.pipe(
      filter(
        (cancelRefund) => hasPassengers(cancelRefund.order.passengers) && hasUnitPrices(cancelRefund.refundAmount, true)
      ),
      map((cancelRefund) => {
        const refundAmount = cancelRefund.refundAmount;
        return cancelRefund.order.passengers
          .filter((passenger) => parseFloat(refundAmount.total.totalPerPax?.[passenger.id]?.totalAmount.amount) > 0)
          .map((passenger: FinnairPassengerItem) => ({
            ...passenger,
            prices:
              refundAmount.flight?.totalPerPax?.[passenger.id] || refundAmount.services?.totalPerPax?.[passenger.id],
            services: getPassengerServices(refundAmount.services?.totalPerCategory, passenger),
            total: refundAmount.total.totalPerPax?.[passenger.id]?.totalAmount,
          }));
      }),
      catchError((e: unknown) => {
        this.sentryLogger.error('Exception in handling prices of passengers', e);
        return EMPTY;
      })
    );
    this.displayGoToClaimFormButton$ = this.cancelRefund$.pipe(
      map(
        (cancelRefund) =>
          cancelRefund.flowType === FinnairCancelFlowType.RULE_CACR &&
          this.notificationType === RefundDetailNotificationType.CONFIRMATION
      )
    );
    this.mainNotificationContent$ = this.cancelRefund$.pipe(
      map((cancelRefund) => this.getMainNotificationKey(cancelRefund.flowType, cancelRefund.order)),
      switchMap((key) => this.fragmentService.getFragment<CmsArticle>(key)),
      finShare()
    );
    this.displayFlightTicketNotification$ = combineLatest([
      this.cancelRefund$,
      this.isDeprecatedOrInvoluntaryRule$,
      this.services$,
    ]).pipe(
      map(([cancelAndRefund, isDeprecatedOrInvoluntary, services]) => {
        return isDeprecatedOrInvoluntary
          ? this.notificationType === RefundDetailNotificationType.REVIEW || !!services?.length
          : cancelAndRefund.flowType !== FinnairCancelFlowType.MANUAL_RULE_24_HOUR &&
              cancelAndRefund.flowType !== FinnairCancelFlowType.AUTOMATIC_RULE_24_HOUR;
      })
    );
    this.flightTicketNotificationKey$ = this.displayFlightTicketNotification$.pipe(
      filter((displayNotification) => displayNotification),
      switchMap(() =>
        this.cancelRefund$.pipe(
          map((cancelRefund) => this.getFlightTicketNotificationKey(cancelRefund.flowType)),
          finShare()
        )
      )
    );
    this.displayRefundNotificationWithLink$ = combineLatest([
      this.displayFlightTicketNotification$,
      this.isDeprecatedOrInvoluntaryRule$,
    ]).pipe(
      map(
        ([displayFlightNotification, isDeprecatedOrInvoluntary]) =>
          !displayFlightNotification &&
          isDeprecatedOrInvoluntary &&
          this.notificationType === RefundDetailNotificationType.CONFIRMATION
      )
    );
    this.travelExtrasNotificationKey$ = this.cancelRefund$.pipe(
      map((cancelRefund) => this.getTravelExtrasNotificationKey(cancelRefund.flowType)),
      finShare()
    );
    this.displayMainNotification$ = combineLatest([this.isDeprecatedOrInvoluntaryRule$, this.services$]).pipe(
      map(
        ([isDeprecatedOrInvoluntary, services]) =>
          !isDeprecatedOrInvoluntary ||
          (services?.length && this.notificationType === RefundDetailNotificationType.CONFIRMATION)
      )
    );
    this.displayEligibleForRefundMessage$ = this.cancelRefund$.pipe(
      map((cancelAndRefund) => this.displayEligibleForRefundMessage(cancelAndRefund.flowType))
    );
    this.displayRefundAmountBlock$ = this.cancelRefund$.pipe(
      map(
        (cancelAndRefund) =>
          cancelAndRefund.flowType !== FinnairCancelFlowType.MANUAL_RULE_TICKET_AWARD && !!cancelAndRefund.order
      )
    );
    this.showTravelExtrasBlock$ = this.services$.pipe(map((services) => services?.length > 0));
  }

  openSummaryModal = (event: Event, prices: FinnairTotalPricesDetails): void => {
    event.stopPropagation();

    this.summaryModalOpen = true;
    this.summaryModalData = {
      prices,
    };
  };

  closeSummaryModal = (): void => {
    this.summaryModalData = null;
  };

  onGoToClaimForm(): void {
    this.goToClaimForm.emit();
  }

  private getMainNotificationKey(flow: FinnairCancelFlowType, order: FinnairOrder): string {
    if (this.is24HourKoreaRule(order, flow)) {
      return `MMB.refund.rules.koreaRule24Hour.mainNotification.${this.notificationType}.url`;
    }
    return `MMB.refund.rules.${FLOW_TYPE_NOTIFICATION_KEYS[flow]}.mainNotification.${this.notificationType}.url`;
  }

  private is24HourKoreaRule(order: FinnairOrder, flowType: FinnairCancelFlowType): boolean {
    const is24hRuleFlow =
      flowType === FinnairCancelFlowType.AUTOMATIC_RULE_24_HOUR ||
      flowType === FinnairCancelFlowType.MANUAL_RULE_24_HOUR;

    const departureLocationCode = order.bounds[0]?.departure?.locationCode;
    const locationData = order.locations?.[departureLocationCode];
    const isDepartureFromKorea = locationData?.countryCode === KOREA_COUNTRY_CODE;

    return is24hRuleFlow && isDepartureFromKorea;
  }

  private getFlightTicketNotificationKey(flow: FinnairCancelFlowType): string {
    return `MMB.refund.rules.${FLOW_TYPE_NOTIFICATION_KEYS[flow]}.flightTicketsNotification.${this.notificationType}`;
  }

  private getTravelExtrasNotificationKey(flow: FinnairCancelFlowType): string {
    return `MMB.refund.rules.${FLOW_TYPE_NOTIFICATION_KEYS[flow]}.travelExtrasNotification.${this.notificationType}`;
  }

  private displayEligibleForRefundMessage(flow: FinnairCancelFlowType): boolean {
    return flow === FinnairCancelFlowType.AUTOMATIC_RULE_24_HOUR || flow === FinnairCancelFlowType.MANUAL_RULE_24_HOUR;
  }
}
