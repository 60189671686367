import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { DirectiveModule } from '@fcom/common/directives/directive.module';

import { IconsModule } from '../icons/icons.module';
import { ButtonModule } from '../buttons/button.module';
import { NotificationComponent } from './notification/notification.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { LoaderModule } from '../loader';
import { LinkModule } from '../link/link.module';
import { MenuListComponent } from './menu-list/menu-list.component';

@NgModule({
  declarations: [NotificationComponent, InfoBoxComponent, MenuListComponent],
  imports: [CommonModule, IconsModule, ButtonModule, LoaderModule, UiTranslateModule, DirectiveModule, LinkModule],
  exports: [NotificationComponent, InfoBoxComponent, MenuListComponent],
  providers: [],
})
export class NotificationsModule {}
