/* eslint-disable sonarjs/no-duplicate-string */
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  Amount,
  CustomServiceType,
  SelectionsPerTraveler,
  ServiceAvailability,
  ServiceCatalog,
  ServiceCatalogCategory,
  ServiceCatalogService,
  ServiceCatalogServices,
  ServicePassenger,
  ServicesPerTraveler,
  TravelerService,
} from '@fcom/dapi';
import {
  capitalizeWord,
  cloneDeep,
  deepCopy,
  equals,
  isEmptyObject,
  isFalsy,
  isFinnairNorraOrWetLease,
  isNotEmpty,
  isPresent,
  isTruthy,
  unique,
  uniqueBy,
} from '@fcom/core/utils';
import {
  Category,
  FinnairBoundItem,
  FinnairCart,
  FinnairItineraryItemFlight,
  FinnairLowestPrice,
  FinnairOrder,
  FinnairPassengerCode,
  FinnairPassengerItem,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceBoundItem,
  FinnairServiceCatalogEligibilityReason,
  FinnairServiceItem,
  FinnairServiceSegmentItem,
  FinnairServiceSelectionItem,
  SubCategory,
} from '@fcom/dapi/api/models';
import { GA4Product, GtmEcommerceProduct, GtmPurchaseFlow } from '@fcom/common/interfaces';
import {
  getBoundsPerFragment,
  getGA4Product,
  getGtmServiceProduct,
  getGtmServiceProductForSeat,
  getPriceForSubCategory,
} from '@fcom/common/utils/gtm.utils';
import { finShare } from '@fcom/rx';
import { Airline } from '@fcom/common/interfaces/airlines';
import { NotificationTheme, TagTheme } from '@fcom/ui-components';

import {
  EligibilitiesForFragments,
  EligibilityWithCategory,
  ExtendedServiceSelectionItem,
  Notification,
  ProductTag,
} from '../interfaces/ancillaries-interface';
import { CartOrOrder } from '../../../interfaces';
import { isBoundBasedCategory, isJourneyBasedCategory } from './category.utils';
import { QuantityForFragmentAndPassenger } from '../../../store';
import {
  getLowestPrice,
  isBound,
  isByBusOnly,
  isFlight,
  isNotIncludedService,
} from '../../../utils/common-booking.utils';
import { resolveCategory } from '../../../utils/services-summary.utils';

export type TitleTextType =
  | 'ancillaries.seats.title'
  | 'ancillaries.bags.addBags'
  | 'ancillaries.lounge.title'
  | 'ancillaries.meals.title'
  | 'ancillaries.wifi.title'
  | 'ancillaries.cover.title'
  | 'ancillaries.sport.title'
  | 'ancillaries.pet.title'
  | 'ancillaries.cabinBaggage.title';

export interface ButtonTexts {
  [category: string]: {
    title: TitleTextType;
  };
}

export const buttonTexts: ButtonTexts = {
  [Category.CABIN_BAGGAGE]: {
    title: 'ancillaries.cabinBaggage.title',
  },
  [Category.BAGGAGE]: {
    title: 'ancillaries.bags.addBags',
  },
  [Category.SEAT]: {
    title: 'ancillaries.seats.title',
  },
  [Category.LOUNGE]: {
    title: 'ancillaries.lounge.title',
  },
  [Category.MEAL]: {
    title: 'ancillaries.meals.title',
  },
  [Category.WIFI]: {
    title: 'ancillaries.wifi.title',
  },
  [Category.COVER]: {
    title: 'ancillaries.cover.title',
  },
  [Category.SPORT]: {
    title: 'ancillaries.sport.title',
  },
  [Category.PET]: {
    title: 'ancillaries.pet.title',
  },
};

export const serviceSelectionToUpdateRequest = (
  selections: SelectionsPerTraveler<TravelerService | TravelerService[]> = {}
): FinnairServiceSelectionItem[] => {
  return Object.keys(selections).reduce((request, travelerId) => {
    []
      .concat(selections[travelerId])
      .filter(Boolean)
      .filter((service) => !service.includedInTicketType && !service.includedInTierBenefit)
      .forEach((service) => {
        request.push({
          category: service.category,
          variant: service.variant,
          quantity: service.quantity,
          travelerId,
        });
      });
    return request;
  }, []);
};

export const isFlightOrBoundHavingServices = (
  cartData: FinnairCart | FinnairOrder,
  category: Category,
  availability: ServiceAvailability,
  boundOrFlight: FinnairBoundItem | FinnairItineraryItemFlight
): boolean => {
  if (category === Category.SEAT) {
    return (
      (boundOrFlight &&
        cartData?.controlData?.sellAncillariesPerFlight[(boundOrFlight as FinnairItineraryItemFlight).flightNumber]) ||
      false
    );
  } else {
    return !availability || (boundOrFlight && isTruthy(availability?.[category]?.[boundOrFlight.id]));
  }
};

const getGA4ProductsDiff = (
  before: ExtendedServiceSelectionItem[],
  after: ExtendedServiceSelectionItem[],
  purchaseFlow: GtmPurchaseFlow,
  orderAfterUpdate: CartOrOrder
) => {
  const ids: string[] = before
    .reduce((acc, val) => acc.concat(val.fragmentId), [])
    .concat(after.reduce((acc, val) => acc.concat(val.fragmentId), []));

  return ids
    .filter(unique)
    .map((fragmentId) => mapGA4Products(before, after, fragmentId, purchaseFlow, orderAfterUpdate))
    .reduce((all, cur) => all.concat(cur), []);
};

interface GAProductDifference {
  /**
   * @deprecated used only for old google analytics
   */
  addedServices: GtmEcommerceProduct[];
  /**
   * @deprecated used only for old google analytics
   */
  removedServices: GtmEcommerceProduct[];
  addedProducts: GA4Product[];
  removedProducts: GA4Product[];
}

export const changedServices = (
  orderBeforeUpdate: CartOrOrder,
  orderAfterUpdate: CartOrOrder,
  category: Category,
  purchaseFlow: GtmPurchaseFlow
): GAProductDifference => {
  const servicesBefore = getAllSellableServices(orderBeforeUpdate, category);
  const servicesAfter = getAllSellableServices(orderAfterUpdate, category);
  const addedServices = updatedServicesList(servicesBefore, servicesAfter, orderAfterUpdate, category, purchaseFlow);
  const removedServices = updatedServicesList(servicesAfter, servicesBefore, orderAfterUpdate, category, purchaseFlow);
  const addedProducts = getGA4ProductsDiff(servicesBefore, servicesAfter, purchaseFlow, orderAfterUpdate);
  const removedProducts = getGA4ProductsDiff(servicesAfter, servicesBefore, purchaseFlow, orderAfterUpdate);

  return { addedServices, removedServices, addedProducts, removedProducts };
};

const removedOrAddedSeats = (
  beforeServices: ExtendedServiceSelectionItem[],
  afterServices: ExtendedServiceSelectionItem[],
  currentCartData: CartOrOrder,
  category: Category,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct[] => {
  return afterServices
    .map((service): GtmEcommerceProduct => {
      const beforeService = beforeServices.find((b) => b.passengerId === service.passengerId);
      if (!beforeService) {
        return getGtmServiceProductForSeat(category, currentCartData, service, purchaseFlow);
      }
      return undefined;
    })
    .filter(Boolean);
};

const mapSeats = (
  before: ExtendedServiceSelectionItem[],
  after: ExtendedServiceSelectionItem[],
  currentCartData: CartOrOrder,
  category: Category,
  fragmentId: string,
  purchaseFlow: GtmPurchaseFlow
) => {
  const beforeServices = before.filter((b) => b.flightIds.includes(fragmentId)) ?? [];
  const afterServices = after.filter((b) => b.flightIds.includes(fragmentId)) ?? [];
  return beforeServices
    .map((service): GtmEcommerceProduct => {
      const afterService = afterServices.find((s) => s.passengerId === service.passengerId);

      if (afterService && afterService.seatNumber !== service.seatNumber) {
        return getGtmServiceProductForSeat(category, currentCartData, afterService, purchaseFlow);
      }
      return undefined;
    })
    .filter(Boolean)
    .concat(removedOrAddedSeats(beforeServices, afterServices, currentCartData, category, purchaseFlow));
};

const mapRegularServices = (
  before: ExtendedServiceSelectionItem[],
  after: ExtendedServiceSelectionItem[],
  currentCartData: CartOrOrder,
  category: Category,
  fragmentId: string,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct[] => {
  const beforeServices = before.filter((b) => b.fragmentId === fragmentId) ?? [];
  const afterServices = after.filter((b) => b.fragmentId === fragmentId) ?? [];
  const changedServices = beforeServices
    .filter(uniqueBy((s) => s.variant + s.fragmentId + s.passengerId + s.flightIds.join('-') + s.quantity))
    .reduce((all, beforeService): GtmEcommerceProduct[] => {
      const afterServiceFiltered = filterSameVariantTravelerBoundServices(afterServices, beforeService);
      const beforeServiceFiltered = filterSameVariantTravelerBoundServices(beforeServices, beforeService);
      const hasChanged = afterServiceFiltered.length > 0 && afterServiceFiltered.length > beforeServiceFiltered.length;
      if (hasChanged) {
        const itemsToTrack = afterServiceFiltered.slice(beforeServiceFiltered.length);
        return all.concat(
          itemsToTrack.map((item) => getGtmServiceProduct(category, currentCartData, item, purchaseFlow))
        );
      }
      return all;
    }, [])
    .filter(Boolean);
  const newServices = afterServices
    .filter((afterService) => filterSameVariantTravelerBoundServices(beforeServices, afterService).length === 0)
    .map(
      (afterService): GtmEcommerceProduct => getGtmServiceProduct(category, currentCartData, afterService, purchaseFlow)
    )
    .filter(Boolean);

  return changedServices.concat(newServices);
};

const mapGA4Products = (
  before: ExtendedServiceSelectionItem[],
  after: ExtendedServiceSelectionItem[],
  fragmentId: string,
  purchaseFlow: GtmPurchaseFlow,
  cartOrOrder: CartOrOrder
): GA4Product[] => {
  const beforeServices = before.filter((b) => b.fragmentId === fragmentId) ?? [];
  const afterServices = after.filter((b) => b.fragmentId === fragmentId) ?? [];
  const bounds = getBoundsPerFragment(cartOrOrder, fragmentId);

  //console.trace(beforeServices, 'beforeServices');

  const changedServices = beforeServices
    .filter(uniqueBy((s) => s.variant + s.fragmentId + s.passengerId + s.flightIds.join('-') + s.quantity))
    .reduce((all, beforeService): GtmEcommerceProduct[] => {
      const afterServiceFiltered = filterSameVariantTravelerBoundServices(afterServices, beforeService);
      const beforeServiceFiltered = filterSameVariantTravelerBoundServices(beforeServices, beforeService);
      const hasChanged = afterServiceFiltered.length > 0 && afterServiceFiltered.length > beforeServiceFiltered.length;
      if (hasChanged) {
        const itemsToTrack = afterServiceFiltered.slice(beforeServiceFiltered.length);
        return all.concat(itemsToTrack.map((item) => getGA4Product(item, purchaseFlow, bounds)));
      }
      return all;
    }, [])
    .filter(Boolean);
  const newServices = afterServices
    .filter((afterService) => filterSameVariantTravelerBoundServices(beforeServices, afterService).length === 0)
    .map((afterService): GA4Product => getGA4Product(afterService, purchaseFlow, bounds))
    .filter(Boolean);

  return changedServices.concat(newServices);
};

const filterSameVariantTravelerBoundServices = (
  services: ExtendedServiceSelectionItem[],
  compareToService: ExtendedServiceSelectionItem
): ExtendedServiceSelectionItem[] =>
  services.filter(
    (s) =>
      s.variant === compareToService.variant &&
      s.passengerId === compareToService.passengerId &&
      equals(s.flightIds, compareToService.flightIds)
  );

/**
 * @deprecated This is for old analytics
 * @param before
 * @param after
 * @param currentCartData
 * @param category
 * @param purchaseFlow
 */
export const updatedServicesList = (
  before: ExtendedServiceSelectionItem[],
  after: ExtendedServiceSelectionItem[],
  currentCartData: CartOrOrder,
  category: Category,
  purchaseFlow: GtmPurchaseFlow
): GtmEcommerceProduct[] => {
  const ids: string[] = before
    .reduce((acc, val) => acc.concat(val.fragmentId), [])
    .concat(after.reduce((acc, val) => acc.concat(val.fragmentId), []));

  return ids
    .filter(unique)
    .map((fragmentId) => {
      if (category === Category.SEAT) {
        return mapSeats(before, after, currentCartData, category, fragmentId, purchaseFlow);
      }
      return mapRegularServices(before, after, currentCartData, category, fragmentId, purchaseFlow);
    })
    .reduce((all, cur) => all.concat(cur), []);
};

export const getAllSellableServices = (
  cartOrOrder: CartOrOrder,
  category: Category
): ExtendedServiceSelectionItem[] => {
  const unpaid = cartOrOrder.services.unpaid?.find((c) => c.category === category)?.bounds ?? [];
  const included = cartOrOrder.services.included?.find((c) => c.category === category)?.bounds ?? [];
  const servicesForCategory = [...unpaid, ...included];

  const reduceServices = (
    allServices: ExtendedServiceSelectionItem[],
    bound: FinnairServiceBoundItem,
    fragmentId?: string
  ): ExtendedServiceSelectionItem[] => {
    return allServices.concat(
      ...bound.segments.reduce((servicesForSegments, segment) => {
        const cabins = cartOrOrder.bounds
          .flatMap((b) => b.itinerary)
          .filter(isFlight)
          .find((i: FinnairItineraryItemFlight) => segment.id === i.id)?.cabinClass;
        return servicesForSegments.concat(
          ...segment.passengers.reduce((servicesForPassengers, passenger) => {
            return servicesForPassengers.concat(
              ...passenger.services
                .filter((s) => s.subCategory !== SubCategory.CHECKED_BAGGAGE)
                .filter((s) => !s.includedInTicketType && !s.includedInTierBenefit)
                .map(
                  (s): ExtendedServiceSelectionItem => ({
                    ...s,
                    flightIds: [segment.id],
                    passengerId: passenger.id,
                    fragmentId: fragmentId ?? segment.id,
                    category: category,
                    cabins,
                    totalPrice: getPriceForSubCategory(passenger, s),
                  })
                )
            );
          }, [])
        );
      }, [])
    );
  };

  const combineInnerServices = (
    previousServices: ExtendedServiceSelectionItem[],
    currentService: ExtendedServiceSelectionItem
  ): ExtendedServiceSelectionItem[] => {
    const previousService = previousServices.find(
      (item) => item.passengerId === currentService.passengerId && item.id === currentService.id
    );
    if (previousService) {
      previousService.flightIds = previousService.flightIds.concat(currentService.flightIds);
    } else {
      previousServices.push(currentService);
    }
    return previousServices;
  };

  return (
    servicesForCategory.reduce((allServices, bound) => {
      switch (category) {
        case Category.BAGGAGE:
        case Category.PET:
        case Category.SPORT:
        case Category.SAF:
          return reduceServices(allServices, bound, bound.id).reduce(
            combineInnerServices,
            [] as ExtendedServiceSelectionItem[]
          );
        case Category.COVER:
          return reduceServices(allServices, bound, CustomServiceType.JOURNEY).reduce(
            combineInnerServices,
            [] as ExtendedServiceSelectionItem[]
          );
        default:
          return reduceServices(allServices, bound);
      }
    }, []) ?? []
  );
};

const serviceItemForCategory = (
  category: Category,
  services$: Observable<FinnairServiceItem[]>
): Observable<FinnairServiceItem | undefined> => {
  return services$.pipe(map((serviceItems) => serviceItems.find((item) => item.category === category)));
};
export const getPriceForCategoryStream = (
  category: Category,
  services$: Observable<FinnairServiceItem[]>,
  fallbackCurrencyCode$: Observable<string>
): Observable<Amount> => {
  return combineLatest([serviceItemForCategory(category, services$), fallbackCurrencyCode$]).pipe(
    map(([item, fallbackCurrencyCode]) => item?.totalPrice || { amount: '0', currencyCode: fallbackCurrencyCode })
  );
};

const servicesContainTierBenefits = (services: FinnairServiceItem) =>
  services?.bounds?.some((bound) =>
    bound.segments?.some((segment) =>
      segment.passengers?.some((passenger) =>
        passenger.services?.some((passengerServiceItem) => passengerServiceItem.includedInTierBenefit)
      )
    )
  );

const TAGS = {
  campaign: {
    localizationKey: 'campaign.badge',
    icon: SvgLibraryIcon.DISCOUNT,
    theme: TagTheme.POPULAR,
  },
  tierBenefit: {
    localizationKey: 'ancillaries.tags.tierBenefit',
    icon: SvgLibraryIcon.FINNAIR_PLUS,
    theme: TagTheme.DEFAULT,
  },
  cheaperOnline: {
    localizationKey: 'ancillaries.tags.cheaperOnline.',
    icon: SvgLibraryIcon.STAR,
    theme: TagTheme.DEFAULT,
  },
};
type TagType = keyof typeof TAGS;
const getTag = (type: TagType, category?: Category) => {
  if (type === 'cheaperOnline') {
    const tag = TAGS[type];
    return { ...tag, localizationKey: tag.localizationKey + category };
  }
  return TAGS[type];
};

const CHEAPER_ONLINE_CATEGORIES = [Category.CABIN_BAGGAGE, Category.LOUNGE, Category.SPORT, Category.BAGGAGE];

export const getProductTag = (
  category: Category,
  campaignsCategories: Category[],
  currentServices: FinnairServiceItem,
  { showCheaperOnlineTag, enableTierBenefitTag }: { showCheaperOnlineTag: boolean; enableTierBenefitTag: boolean }
): ProductTag | undefined => {
  if (campaignsCategories.includes(category)) {
    return getTag('campaign');
  }
  if (enableTierBenefitTag && servicesContainTierBenefits(currentServices)) {
    return getTag('tierBenefit');
  }
  if (showCheaperOnlineTag && CHEAPER_ONLINE_CATEGORIES.includes(category)) {
    return getTag('cheaperOnline', category);
  }
  return undefined;
};

export const getCountForCategoryStream = (
  category: Category,
  services$: Observable<FinnairServiceItem[]>
): Observable<number> => {
  return getCategoryCount(category, services$);
};

export const getCountForCategoryAndTravelerStream = (
  category: Category,
  travellerId: string,
  services$: Observable<FinnairServiceItem[]>,
  boundId?: string,
  segmentId?: string
): Observable<number> => {
  return getCategoryCount(category, services$, travellerId, boundId, segmentId);
};

const getCategoryCount = (
  category: Category,
  services$: Observable<FinnairServiceItem[]>,
  travellerId?: string,
  boundId?: string,
  segmentId?: string
): Observable<number> => {
  return serviceItemForCategory(category, services$).pipe(
    map((item) => {
      const bounds = item?.bounds.filter((currentBound) => currentBound.id === (boundId ?? currentBound.id)) ?? [];

      if (isBoundBasedCategory(category)) {
        return bounds.reduce(
          (countTotal, currentBound) => getCategoryCountForBound(countTotal, currentBound, travellerId, segmentId),
          0
        );
      }
      const count =
        bounds
          .flatMap(({ segments }) => segments)
          .reduce(
            (countTotal, currentSegment) => getCategoryCountForSegment(countTotal, currentSegment, travellerId),
            0
          ) ?? 0;
      return category === Category.COVER && count !== 0 ? 1 : count;
    })
  );
};

const getCategoryCountForBound = (
  countTotal: number,
  currentBound: FinnairServiceBoundItem,
  travellerId?: string,
  segmentId?: string
): number => {
  return (
    countTotal +
    currentBound.segments
      .filter((currentSegment) => currentSegment.id === (segmentId ?? currentSegment.id))
      .reduce(
        (countPerBound, currentSegment) => getCategoryCountForSegment(countPerBound, currentSegment, travellerId),
        0
      ) /
      currentBound.segments.length
  );
};

const getCategoryCountForSegment = (
  countPerBound: number,
  currentSegment: FinnairServiceSegmentItem,
  travellerId?: string
): number => {
  return (
    countPerBound +
    currentSegment.passengers
      .filter((currentPassenger) => currentPassenger.id === (travellerId ?? currentPassenger.id))
      .reduce((countPerSegment, currentPassenger) => getCategoryCountForPassenger(countPerSegment, currentPassenger), 0)
  );
};

const getCategoryCountForPassenger = (
  countPerSegment: number,
  currentPassenger: FinnairPassengerServiceItem
): number => {
  return (
    countPerSegment +
    currentPassenger.services.filter(isNotIncludedService).reduce((countPassengers, currentService) => {
      return countPassengers + (currentService.quantity || 0);
    }, 0)
  );
};

export const getMaxNumberItemsForCategory = (
  category: Category,
  categories: Array<ServiceCatalogCategory>,
  booking: FinnairOrder
): Observable<number> => {
  switch (category) {
    // seats do not return a structure with maxNumberItems
    case Category.SEAT:
      return of(numberOfPassengersWithOwnSeat(booking) * numberOfFlights(booking));
    default:
      return of(maxNumberItemsForCategory(category, categories));
  }
};

const numberOfPassengersWithOwnSeat = (booking: FinnairOrder): number => {
  return (
    booking?.passengers.filter((passenger) => passenger.passengerTypeCode !== FinnairPassengerCode.INF).length || 0
  );
};

const numberOfFlights = (booking: FinnairOrder): number => {
  return (
    booking?.bounds
      .map((bound) => bound.itinerary)
      .flat()
      .filter(isFlight)
      .filter(
        (flight: FinnairItineraryItemFlight) =>
          flight.operatingAirline?.code === Airline.FINNAIR && flight.marketingAirline?.code === Airline.FINNAIR
      ).length || 0
  );
};

const maxNumberItemsForCategory = (category: Category, categories: ServiceCatalogCategory[]): number => {
  return categories
    .filter((serviceCatalogCategory) => serviceCatalogCategory.category === category)
    .flatMap((category) =>
      Object.values(category.services).flatMap((servicesPerTraveler) =>
        Object.values(servicesPerTraveler).map(
          (serviceCatalogServices) => serviceCatalogServices[0]?.maxNumberItems ?? 0
        )
      )
    )
    .reduce(
      (numberOfItemsForCategoryX, numberOfItemsForCategoryY) => numberOfItemsForCategoryX + numberOfItemsForCategoryY,
      0
    );
};

const DEFAULT_QUOTA_FOR_SERVICE = 9;

export const categoryHasQuotaLeft = (category: Category, categories: ServiceCatalogCategory[]): boolean => {
  if (category === Category.SEAT) {
    return true;
  }
  const quotas = categories
    .filter((serviceCatalogCategory) => serviceCatalogCategory.category === category)
    .flatMap((currentCategory) =>
      Object.values(currentCategory.services)
        .flatMap((servicesPerTraveler) =>
          Object.values(servicesPerTraveler).flatMap((serviceCatalogServices) =>
            serviceCatalogServices
              .filter((service) => service.isService)
              .map((service) => service.quota ?? DEFAULT_QUOTA_FOR_SERVICE)
          )
        )
        .filter(isPresent)
    );
  return quotas.length ? Math.max(...quotas) > 0 : false;
};

const getMaxedOutProductsPerFragment = (
  boundId: string,
  bound: FinnairServiceBoundItem | undefined,
  category: Category,
  categories: ServiceCatalogCategory[],
  numberOfPassengers: number,
  booking: FinnairOrder,
  availability: ServiceAvailability
): { [fragmentId: string]: boolean } => {
  const flights = booking.bounds.find((b) => b.id === boundId).itinerary.filter(isFlight);

  if (isBoundBasedCategory(category)) {
    const max = categories
      .filter((serviceCatalogCategory) => serviceCatalogCategory.category === category)
      .flatMap((serviceCategory) => {
        return Object.values(serviceCategory.services[boundId] ?? {}).map((serviceCatalogServices) => {
          const maxNumberItems = serviceCatalogServices
            .filter(uniqueBy((s) => s.variant))
            .reduce((totalMaxNumberOfItems, s) => totalMaxNumberOfItems + s.maxNumberItems, 0);
          if (maxNumberItems) {
            return maxNumberItems * flights.length;
          }
          return 0;
        });
      })
      .reduce((maxNumberAcc, numberOfItemsForCategory) => maxNumberAcc + numberOfItemsForCategory, 0);

    return {
      [boundId]: numberOfPaidServicesForBound(bound) >= max,
    };
  }
  return flights.reduce((all: { [fragmentId: string]: boolean }, flight: FinnairItineraryItemFlight) => {
    all[flight.id] =
      flight.operatingAirline.code !== Airline.FINNAIR ||
      (category === Category.LOUNGE && flight.departure.locationCode !== 'HEL') ||
      numberOfPaidServicesForSegment(bound, flight.id) >= numberOfPassengers ||
      (!!availability && isFalsy(availability?.[category]?.[flight.id]));

    return all;
  }, {});
};

const supportedCategoriesForProductCards = [
  Category.CABIN_BAGGAGE,
  Category.BAGGAGE,
  Category.SEAT,
  Category.MEAL,
  Category.WIFI,
  Category.LOUNGE,
  Category.SPORT,
  Category.PET,
];

export const filterCategories = (categories: ServiceCatalogCategory[]): ServiceCatalogCategory[] =>
  categories.filter((category) => {
    return supportedCategoriesForProductCards.indexOf(category.category) !== -1;
  });

const getMaxedOutSeatsPerFlight = (
  boundId: string,
  bound: FinnairServiceBoundItem | undefined,
  numberOfPassengers: number,
  booking: FinnairOrder
): { [flightId: string]: boolean } => {
  const seatsMaxedOutForFragments = (bound?.segments ?? []).reduce((segmentsMaxed, segment) => {
    const unModifiableServices = segment.passengers
      .flatMap((passenger) => passenger.services)
      .filter(isNotIncludedService)
      .filter((categoryService) =>
        categoryService.subCategory === SubCategory.SEAT ? !categoryService.modifiable : categoryService.ticketed
      ).length;
    segmentsMaxed[segment.id] = unModifiableServices >= numberOfPassengers;
    return segmentsMaxed;
  }, {});

  return booking.bounds
    .find((b) => b.id === boundId)
    .itinerary.filter(isFlight)
    .reduce((all: { [fragmentId: string]: boolean }, flight: FinnairItineraryItemFlight) => {
      all[flight.id] = seatsMaxedOutForFragments[flight.id] ?? flight.operatingAirline.code !== Airline.FINNAIR;
      return all;
    }, {});
};

const supportedMaxedOutSubCategories = [
  SubCategory.BAGGAGE,
  SubCategory.HEAVY_OR_LARGE,
  SubCategory.SEAT,
  SubCategory.WIFI,
  SubCategory.HOT_PRE_ORDER_MEAL,
  SubCategory.FRESH_MEAL,
  SubCategory.MEAL,
  SubCategory.LOUNGE,
  SubCategory.COVER,
  SubCategory.PET_IN_CABIN,
  SubCategory.BIKE,
  SubCategory.SKIS,
  SubCategory.GOLF,
];

export const numberOfPaidServicesForBound = (bound: FinnairServiceBoundItem | undefined): number =>
  calculateServices(bound?.segments ?? []);

export const numberOfPaidServicesForSegment = (
  bound: FinnairServiceBoundItem | undefined,
  fragmentId: string
): number => calculateServices((bound?.segments ?? []).filter((segment) => segment.id === fragmentId));

const calculateServices = (segments: FinnairServiceSegmentItem[]): number => {
  return segments
    .flatMap((segment) => segment.passengers)
    .flatMap((passenger) => passenger.services)
    .filter((service) => supportedMaxedOutSubCategories.includes(service.subCategory))
    .filter(isNotIncludedService)
    .filter((service) => service.ticketed || parseFloat(service.totalPrice?.amount) > 0).length;
};

export const categoryHasMaxPurchasesDoneForFragments = (
  numberOfPassengers: number,
  category: Category,
  services$: Observable<FinnairServiceItem[]>,
  categories: ServiceCatalogCategory[],
  booking: FinnairOrder,
  availability: ServiceAvailability
): Observable<{ [fragmentId: string]: boolean }> => {
  return services$.pipe(
    map((services) =>
      booking.bounds.reduce((allFragmentsForService, bound) => {
        const serviceBound: FinnairServiceBoundItem = services
          .filter((service) => service.category === category)
          .flatMap((service) => service.bounds)
          .find((service) => service.id === bound.id);

        if (category === Category.SEAT) {
          return {
            ...allFragmentsForService,
            ...getMaxedOutSeatsPerFlight(bound.id, serviceBound, numberOfPassengers, booking),
          };
        }
        return {
          ...allFragmentsForService,
          ...getMaxedOutProductsPerFragment(
            bound.id,
            serviceBound,
            category,
            categories,
            numberOfPassengers,
            booking,
            availability
          ),
        };
      }, {})
    ),
    finShare()
  );
};

const getBoundsOrFlights = (
  category: Category,
  order: FinnairOrder
): (FinnairBoundItem | FinnairItineraryItemFlight)[] => {
  if (isJourneyBasedCategory(category)) {
    return [];
  }
  if (isBoundBasedCategory(category)) {
    return order.bounds;
  }
  return order.bounds.reduce((flights, bound) => flights.concat(bound.itinerary.filter(isFlight)), []);
};

export const mapFlightsFromOrderData = (order: FinnairOrder): FinnairItineraryItemFlight[] => {
  return order.bounds.reduce((flights, bound) => flights.concat(bound.itinerary.filter(isFlight)), []);
};

export const isAllowedToSell = (
  category: Category,
  orderOrCart: FinnairOrder | FinnairCart,
  availability: ServiceAvailability,
  boundOrFlight: FinnairBoundItem | FinnairItineraryItemFlight
): EligibilityWithCategory[] => {
  const supportedEligibilityCategories = [
    Category.CABIN_BAGGAGE,
    Category.BAGGAGE,
    Category.LOUNGE,
    Category.SEAT,
    Category.MEAL,
    Category.WIFI,
    Category.PET,
    Category.SPORT,
  ];
  const eligibilities: EligibilityWithCategory[] = (orderOrCart.eligibilities?.serviceCatalog?.categories ?? [])
    .filter((c) => c.category === category && supportedEligibilityCategories.includes(category))
    .flatMap((categoryEligibility) =>
      Object.values(categoryEligibility.services[boundOrFlight.id] ?? {})
        .filter(uniqueBy((e) => e.reason))
        .map((eligibility) => ({
          ...eligibility,
          category: categoryEligibility.category,
          subCategory: categoryEligibility.subCategory,
          originalEligibility: eligibility,
        }))
    );

  return eligibilities.length
    ? eligibilities.map((eligibility) => {
        return {
          ...eligibility,
          isAllowedToUse:
            isFlightOrBoundHavingServices(orderOrCart, category, availability, boundOrFlight) &&
            eligibility.isAllowedToUse,
          reason: eligibility.reason,
        };
      })
    : [
        {
          category,
          isAllowedToUse: isFlightOrBoundHavingServices(orderOrCart, category, availability, boundOrFlight),
        },
      ];
};

export const categoryHasNothingToSell = (
  category: Category,
  availability$: Observable<ServiceAvailability>,
  order: FinnairOrder
): Observable<boolean> => {
  const boundsOrFlights = getBoundsOrFlights(category, order);

  return availability$.pipe(
    map((availability) => {
      return boundsOrFlights.every(
        (boundOrFlight) => !isAllowedToSell(category, order, availability, boundOrFlight)?.[0]?.isAllowedToUse
      );
    })
  );
};

export const servicesForFlightAndTravelerId = (
  item: FinnairServiceItem | null,
  flightId: string,
  travelerId: string
): FinnairPassengerServiceSelectionItem[] =>
  item?.bounds
    ?.flatMap(({ segments }) => segments)
    .filter((segment) => segment.id === flightId)
    .flatMap(({ passengers }) => passengers)
    .filter((passenger) => passenger.id === travelerId)
    .flatMap(({ services }) => services)
    .filter(isNotIncludedService) ?? [];

export const isAllowedToChangeSeat = (item: FinnairServiceItem, flightId: string, travelerId: string): boolean =>
  servicesForFlightAndTravelerId(item, flightId, travelerId).every(({ modifiable }) => modifiable);

export const combineIncludedAndUnpaidSeats = (
  includedSeats: FinnairServiceItem[],
  unpaidSeats: FinnairServiceItem[]
): FinnairServiceItem[] => {
  // TODO: Rewrite using reduce if still needed for new UX model (to be defined),
  // see loadSeatSelections in seat-map.reducer.ts
  let combinedArray: FinnairServiceItem[] = deepCopy(
    includedSeats.filter((includedItem) => includedItem.category === Category.SEAT)
  );
  if (combinedArray.length === 0) {
    combinedArray = deepCopy(unpaidSeats.filter((unpaidItem) => unpaidItem.category === Category.SEAT));
  } else {
    unpaidSeats
      .filter((unpaidItem) => unpaidItem.category === Category.SEAT)
      .forEach((unpaidItem, _index) => {
        unpaidItem.bounds?.forEach((unpaidBound) =>
          combinedArray.forEach((serviceItem) => {
            if (!serviceItem.bounds.find(({ id }) => id === unpaidBound.id)) {
              serviceItem.bounds.push(deepCopy(unpaidBound));
            } else {
              unpaidBound.segments.forEach((unpaidSegment) => {
                serviceItem.bounds
                  .filter(({ id }) => id === unpaidBound.id)
                  .forEach((serviceItemBounds) => {
                    if (!serviceItemBounds.segments.find(({ id }) => id === unpaidSegment.id)) {
                      serviceItemBounds.segments.push(deepCopy(unpaidSegment));
                    } else {
                      serviceItemBounds.segments
                        .filter(({ id }) => id === unpaidSegment.id)
                        .forEach((s) => s.passengers.push(...deepCopy(unpaidSegment.passengers)));
                    }
                  });
              });
            }
          })
        );
      });
  }
  return combinedArray;
};

export const findServiceItemsForPassengerForFragment = (
  category: Category,
  serviceItem: FinnairServiceItem,
  travelerId: string,
  fragmentId: string,
  filter = true
): FinnairPassengerServiceSelectionItem[] => {
  if (isBoundBasedCategory(category)) {
    return cloneDeep(
      serviceItem?.bounds
        .filter(({ id }) => id === fragmentId)
        .flatMap(({ segments }) => segments[0])
        .flatMap(({ passengers }) => passengers)
        .filter(({ id }) => id === travelerId)
        .flatMap(({ services }) =>
          filter
            ? services.filter(
                ({ includedInTicketType, includedInTierBenefit }) => !includedInTicketType && !includedInTierBenefit
              )
            : services
        ) ?? []
    ).reduce((totalTravelerServices, travelerService) => {
      const existingService = totalTravelerServices.find(
        ({ variant, ticketed, includedInTicketType, includedInTierBenefit, totalPrice }) => {
          return (
            variant === travelerService.variant &&
            ticketed === travelerService.ticketed &&
            includedInTicketType === travelerService.includedInTicketType &&
            includedInTierBenefit === travelerService.includedInTierBenefit &&
            totalPrice?.amount === travelerService.totalPrice?.amount
          );
        }
      );
      if (existingService) {
        existingService.quantity += travelerService.quantity;
      } else {
        totalTravelerServices.push(travelerService);
      }

      return totalTravelerServices;
    }, []);
  }
  return (
    serviceItem?.bounds
      .flatMap(({ segments }) => segments)
      .filter(({ id }) => id === fragmentId)
      .flatMap(({ passengers }) => passengers)
      .filter(({ id }) => id === travelerId)
      .flatMap(({ services }) => (filter ? services.filter(isNotIncludedService) : services)) ?? []
  );
};

export const calculatePaxCountFromServiceCatalog = (serviceCatalog: ServiceCatalog): number =>
  new Set(
    serviceCatalog.categories
      .flatMap((category) =>
        Object.values(category.services).flatMap((servFragmentEntry) => Object.keys(servFragmentEntry))
      )
      .filter((paxIdOrGroup) => paxIdOrGroup !== CustomServiceType.GROUP)
  ).size;

const getCleanedCategoryServices = (services: ServiceCatalogServices, paxCount: number) => {
  return Object.entries(services).reduce((categoryServices, [fragmentKey, servicesForFragment]) => {
    const newServicesByFragments = Object.entries(servicesForFragment).reduce(
      (serviceFragment, [travelerKey, servicesForTraveler]) => {
        const travelerServicesNotSoldOut = servicesForTraveler.filter(
          (service) => !service.quota || service.quota >= paxCount
        );
        if (travelerServicesNotSoldOut.length > 0) {
          if (
            Object.values(travelerServicesNotSoldOut).find(
              (service: ServiceCatalogService) =>
                service.isService === true || service.parameters?.isSpecialMeal === true
            )
          ) {
            serviceFragment[travelerKey] = travelerServicesNotSoldOut;
          }
        }
        return serviceFragment;
      },
      {}
    );
    if (Object.keys(newServicesByFragments).length > 0) {
      categoryServices[fragmentKey] = newServicesByFragments;
    }
    return categoryServices;
  }, {});
};

export const removeServicesWithMaxedOutQuotas = (catalog: ServiceCatalog, paxCount: number): ServiceCatalog => {
  const quotaAllowedCategories = [Category.SEAT, Category.PET];

  return {
    ...catalog,
    categories: catalog.categories.reduce((filteredCategories, category) => {
      if (quotaAllowedCategories.includes(category.category)) {
        return filteredCategories.concat(category);
      }
      const newCategoryServices = getCleanedCategoryServices(category.services, paxCount);
      if (Object.keys(newCategoryServices).length > 0) {
        return filteredCategories.concat({
          ...category,
          services: newCategoryServices,
        });
      }
      return filteredCategories;
    }, []),
  };
};

export const PETC_CONFIRM_MODAL_TRANSLATION_KEYS = {
  title: 'services.pet.confirmModal.title',
  firstContentParagraph: 'services.pet.confirmModal.firstContentParagraph',
  secondContentParagraph: 'services.pet.confirmModal.secondContentParagraph',
  closeButton: 'services.pet.confirmModal.closeButton',
  continueButton: 'services.pet.confirmModal.continueButton',
};

const PET_IN_CABIN_VARIANT = 'PETC';

export const getBoundsAndTravelersWhichHavePetcIncompatibleSeats = (
  booking: FinnairOrder | FinnairCart,
  serviceType: string
): { [boundId: string]: string[] } =>
  booking.bounds?.reduce((previousValue, bound) => {
    return {
      ...previousValue,
      [bound.id]: [
        ...new Set(
          (booking.services?.[serviceType] ?? [])
            ?.find((serviceItem) => serviceItem.category === Category.SEAT)
            ?.bounds.find((seatServiceBound) => bound.id === seatServiceBound.id)
            ?.segments.flatMap((segment) => segment.passengers)
            .flatMap((passenger) => passenger.services.map((service) => ({ ...service, passengerId: passenger.id })))
            .filter((serviceWithPassengerId) =>
              serviceWithPassengerId?.parameters?.incompatibleServiceVariants?.includes(PET_IN_CABIN_VARIANT)
            )
            .map((service) => service.passengerId) ?? []
        ),
      ],
    };
  }, {});

export const isSeatPurchaseClosedAfterCheckIn = (booking: FinnairOrder): boolean => {
  const seatEligibilityCatalog = booking.eligibilities?.serviceCatalog?.categories?.find(
    (e) => e.category === Category.SEAT
  );
  if (!seatEligibilityCatalog || !seatEligibilityCatalog.services) {
    return false;
  }
  return Object.values(seatEligibilityCatalog.services)?.every((flight) =>
    Object.values(flight)?.some(
      (paxEligibility) =>
        !paxEligibility.isAllowedToUse &&
        paxEligibility.reason ===
          FinnairServiceCatalogEligibilityReason.PURCHASE_SEAT_NOT_ELIGIBLE_AFTER_CHECK_IN_IS_CLOSED
    )
  );
};

export const isSeatPurchaseClosedAfterCheckInForFlight = (booking: FinnairOrder, flightId: string): boolean => {
  const seatEligibilityCatalog = booking.eligibilities?.serviceCatalog?.categories?.find(
    (e) => e.category === Category.SEAT
  );
  if (!seatEligibilityCatalog || !seatEligibilityCatalog.services || !seatEligibilityCatalog?.services[flightId]) {
    return false;
  }
  return Object.values(seatEligibilityCatalog.services[flightId])?.some(
    (paxEligibility) =>
      !paxEligibility.isAllowedToUse &&
      paxEligibility.reason ===
        FinnairServiceCatalogEligibilityReason.PURCHASE_SEAT_NOT_ELIGIBLE_AFTER_CHECK_IN_IS_CLOSED
  );
};

export const isPetcNotEligibleWithIncompatibleSeat = (category: Category, order: FinnairOrder): boolean => {
  const petEligibilitiesPerPassenger = Object.values(
    order?.eligibilities?.serviceCatalog?.categories?.find(
      (serviceCategory) => serviceCategory.category === Category.PET
    )?.services ?? {}
  ).reduce((previousValue, eligibilityItem) => {
    return [...previousValue, ...Object.values(eligibilityItem)];
  }, []);
  return (
    category === Category.PET &&
    petEligibilitiesPerPassenger.length > 0 &&
    petEligibilitiesPerPassenger.every(
      ({ isAllowedToUse, reason }) =>
        !isAllowedToUse &&
        reason === FinnairServiceCatalogEligibilityReason.PURCHASE_PETC_NOT_ELIGIBLE_WITH_CONFIRMED_INCOMPATIBLE_SEAT
    )
  );
};

export const getFilteredTravelers$ = (
  travelers: Observable<FinnairPassengerItem[]>
): Observable<ServicePassenger[]> => {
  return travelers.pipe(
    map((passengerItems: FinnairPassengerItem[]) => {
      const infants = passengerItems?.filter((traveler) => traveler.passengerTypeCode === FinnairPassengerCode.INF);
      return passengerItems
        ?.filter((t) => t.passengerTypeCode !== FinnairPassengerCode.INF)
        .map((traveler) => {
          const infant = infants.find((i) => i.accompanyingTravelerId === traveler.id);
          return {
            ...traveler,
            withInfant: !!infant,
            withInfantFullName: infant ? `${infant.firstName} ${infant.lastName}` : '',
            services: [],
          };
        });
    }),
    filter(isNotEmpty),
    finShare()
  );
};

export const getQuantitiesForFragmentsAndPassenger = (
  selectedServices: QuantityForFragmentAndPassenger = {},
  includedServices: QuantityForFragmentAndPassenger = {},
  cartOrOrder: CartOrOrder
): QuantityForFragmentAndPassenger => {
  const fragmentIds = cartOrOrder.bounds.flatMap((b) => [b.id].concat(b.itinerary.filter(isFlight).map((f) => f.id)));
  const passengerIds = cartOrOrder.passengers.map((p) => p.id);

  return fragmentIds.reduce((servicesForFragments, fragmentId) => {
    const selected = selectedServices[fragmentId] ?? {};
    const included = includedServices[fragmentId] ?? {};
    servicesForFragments[fragmentId] = passengerIds.reduce((servicesForPassenger, passengerId) => {
      servicesForPassenger[passengerId] = (selected[passengerId] ?? 0) + (included[passengerId] ?? 0);
      return servicesForPassenger;
    }, {});
    return servicesForFragments;
  }, {});
};

export const ancillarySaleBlockingSupportedReasonKeys = {
  [FinnairServiceCatalogEligibilityReason.PURCHASE_PET_IN_CABIN_NOT_AVAILABLE_IN_BUSINESS_CLASS]: 'petInBusinessCabin',
};

const resolveNotAvailableKey = (category: Category, isBus = false, nonAyOperatingAirline = false): string => {
  const capitalCategory = capitalizeWord(resolveCategory(category));

  switch (category) {
    case Category.CABIN_BAGGAGE:
      return `notAvailable${isBus ? 'Bus' : ''}`;
    case Category.SEAT:
      return isBus
        ? 'noSeatsAvailableBus'
        : `no${capitalCategory}Available${nonAyOperatingAirline ? 'ForOperatingAirline' : ''}`;
    default:
      return `no${capitalCategory}Available${isBus ? 'Bus' : ''}`;
  }
};

const saveTimeReasons = {
  [FinnairServiceCatalogEligibilityReason.PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_36_HOURS_EX_EXCEPT_HELSINKI]:
    'preOrderMealsAvailablePrior36Hours',
  [FinnairServiceCatalogEligibilityReason.PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_16_HOURS_EX_HELSINKI]:
    'preOrderMealsAvailablePrior16Hours',
  [FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_24_HOURS_EX_EXCEPT_HELSINKI]:
    'freshMealsAvailablePrior24Hours',
  [FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_7_HOURS_EX_HELSINKI]:
    'freshMealsAvailablePrior7Hours',
  [FinnairServiceCatalogEligibilityReason.PURCHASE_SPECIAL_DIET_ELIGIBLE_PRIOR_TO_24_HOURS]:
    'specialDietAvailablePrior24Hours',
};

const getWarningReason = (
  eligibility: EligibilityWithCategory,
  flightOrBound: FinnairItineraryItemFlight | FinnairBoundItem,
  nonAyOperatingAirline = false
) => {
  switch (eligibility.reason) {
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PET_IN_CABIN_NOT_AVAILABLE_IN_BUSINESS_CLASS:
      return 'eligibility.petInBusinessCabin';
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_WITHIN_24_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_7_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_16_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_36_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SPECIAL_MEAL_NOT_ELIGIBLE: {
      return 'eligibility.mealsNotAvailable';
    }
    case FinnairServiceCatalogEligibilityReason.PURCHASE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_MEAL_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_WIFI_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_BAGGAGE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_LOUNGE_ACCESS_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN: {
      return 'eligibility.boundFlown';
    }
    case FinnairServiceCatalogEligibilityReason.NOT_ELIGIBLE:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_NOT_ELIGIBLE_FOR_OTHER_AIRLINE: {
      return resolveNotAvailableKey(eligibility.category, isByBusOnly(flightOrBound), nonAyOperatingAirline);
    }
  }
};

const getReason = (category: Category, flightOrBound: FinnairItineraryItemFlight | FinnairBoundItem) => {
  const segments = isBound(flightOrBound)
    ? flightOrBound.itinerary.filter(isFlight).map((i: FinnairItineraryItemFlight) => i.operatingAirline)
    : [flightOrBound.operatingAirline];
  const becauseAirlines = segments
    .filter((operatingAirline) => !isFinnairNorraOrWetLease(operatingAirline.name, operatingAirline.code))
    .map((operatingAirline) => operatingAirline.name)
    .filter(unique)
    .join(', ');

  const becauseAirlineReason = isBoundBasedCategory(category)
    ? 'ancillaries.bags.noBagsAvailableAirlines'
    : 'ancillaries.seats.noSeatsAvailableAirline';

  return becauseAirlines
    ? {
        key: becauseAirlineReason,
        data: {
          airline: becauseAirlines,
          airlines: becauseAirlines,
        },
      }
    : undefined;
};

const getNotification = (
  allServices: ServiceCatalogService[],
  eligibility: EligibilityWithCategory,
  category: Category,
  flightOrBound: FinnairItineraryItemFlight | FinnairBoundItem,
  nonAyOperatingAirline = false
): Notification | undefined => {
  if (
    eligibility.category !== category ||
    (allServices.length === 0 && eligibility.originalEligibility?.isAllowedToUse !== false) ||
    (allServices.length > 0 &&
      !allServices.some(
        (service) => service.category === eligibility.category && service.subCategory === eligibility.subCategory
      ))
  ) {
    return undefined;
  }

  if (eligibility.isAllowedToUse === false && eligibility.reason === undefined) {
    return {
      iconName: SvgLibraryIcon.INFO,
      theme: NotificationTheme.WARNING,
      key: `ancillaries.${resolveCategory(category)}.${resolveNotAvailableKey(
        eligibility.category,
        isByBusOnly(flightOrBound)
      )}`,
      reason: getReason(category, flightOrBound),
      blockSelection: !eligibility.isAllowedToUse,
    };
  }

  switch (eligibility.reason) {
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_36_HOURS_EX_EXCEPT_HELSINKI:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_16_HOURS_EX_HELSINKI:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_24_HOURS_EX_EXCEPT_HELSINKI:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_7_HOURS_EX_HELSINKI:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SPECIAL_DIET_ELIGIBLE_PRIOR_TO_24_HOURS: {
      return {
        iconName: SvgLibraryIcon.SAVE_TIME,
        theme: NotificationTheme.TRANSPARENT,
        key: `ancillaries.${resolveCategory(category)}.eligibility.${saveTimeReasons[eligibility.reason]}`,
        blockSelection: !eligibility.isAllowedToUse,
      };
    }
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PET_IN_CABIN_NOT_AVAILABLE_IN_BUSINESS_CLASS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_WITHIN_24_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_7_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_16_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_36_HOURS:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SPECIAL_MEAL_NOT_ELIGIBLE:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_MEAL_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_WIFI_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_BAGGAGE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_LOUNGE_ACCESS_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN:
    case FinnairServiceCatalogEligibilityReason.NOT_ELIGIBLE:
    case FinnairServiceCatalogEligibilityReason.PURCHASE_NOT_ELIGIBLE_FOR_OTHER_AIRLINE: {
      return {
        iconName: SvgLibraryIcon.INFO,
        theme: NotificationTheme.WARNING,
        key: `ancillaries.${resolveCategory(category)}.${getWarningReason(eligibility, flightOrBound, nonAyOperatingAirline)}`,
        reason: getReason(category, flightOrBound),
        blockSelection: !eligibility.isAllowedToUse,
      };
    }
    case FinnairServiceCatalogEligibilityReason.PURCHASE_SEAT_SELECTION_NOT_ELIGIBLE_WHEN_DISCOUNTS_EXIST_FOR_CORPORATE: {
      return {
        iconName: SvgLibraryIcon.B2B_BLOCK,
        theme: NotificationTheme.INFO,
        key: 'ancillaries.seat.corporate.complimentarySeatNotAvailableTitle',
        reason: {
          key: 'ancillaries.seat.corporate.complimentarySeatNotAvailable',
          data: {},
        },
        blockSelection: true,
      };
    }
    default:
      return undefined;
  }
};

export const getServicesNotifications = (
  services: ServicesPerTraveler,
  availability: EligibilitiesForFragments,
  category: Category,
  flightOrBound: FinnairItineraryItemFlight | FinnairBoundItem,
  useFallback = true,
  nonAyOperatingAirline = false
): Notification[] => {
  const eligibilities = availability[flightOrBound.id] ?? [];
  const allServices = Object.values(services ?? {}).flat();

  const eligibilityNotifications = eligibilities
    .map((eligibility) => getNotification(allServices, eligibility, category, flightOrBound, nonAyOperatingAirline))
    .filter(Boolean)
    .filter(uniqueBy((n) => n.key));

  if (eligibilityNotifications.length > 0) {
    return eligibilityNotifications;
  }

  return useFallback && allServices.length === 0
    ? [
        {
          iconName: SvgLibraryIcon.INFO,
          theme: NotificationTheme.WARNING,
          key: `ancillaries.${resolveCategory(category)}.${resolveNotAvailableKey(
            category,
            isByBusOnly(flightOrBound)
          )}`,
          reason: getReason(category, flightOrBound),
          blockSelection: true,
        },
      ]
    : [];
};

export const getInitials = (firstName = '', lastName = ''): string => {
  return firstName.toUpperCase().charAt(0) + lastName.toUpperCase().charAt(0);
};

// This is more of a fallback mechanism (although generic) to get the lowest price for MEAL when only
// special diet options are available e.g. HEL-BKK in Economy, as the special diets are filtered out from the
// category's `lowestPrice` property in the backend.
const fallbackGetLowestPriceForCategory = (category: ServiceCatalogCategory): FinnairLowestPrice => {
  // SEAT does not contain any services as those are handled within the seat map
  if (category.category === Category.SEAT) {
    return getLowestPrice(category.lowestPrice, Object.keys(category.lowestPrice));
  }

  return Object.values(category.services)
    .flatMap((servicesPerTraveler) => Object.values(servicesPerTraveler))
    .flat()
    .reduce(
      (lowestPrice: FinnairLowestPrice, service: ServiceCatalogService): FinnairLowestPrice => ({
        money: {
          amount:
            !isPresent(lowestPrice?.money?.amount) ||
            Number(service.totalPrice?.amount) < Number(lowestPrice?.money?.amount)
              ? service.totalPrice.amount
              : lowestPrice?.money?.amount,
          currencyCode: !isPresent(lowestPrice?.money?.amount)
            ? service.totalPrice.currencyCode
            : lowestPrice?.money?.currencyCode,
        },
      }),
      { money: { amount: null, currencyCode: null } }
    );
};

/**
 *
 * @param category ServiceCatalogCategory object you want to get the lowest price for
 * @returns a FinnairLowestPrice object containing the lowest price, original price and currency code
 */
export const getLowestPriceForCategory = (category: ServiceCatalogCategory): FinnairLowestPrice => {
  const lowestPriceForCategory = category.lowestPrice;
  // Currently we filter out the special diets in the backend from the `lowestPrice` so we need
  // a special handling to display the lowest price for MEAL when only special diets are available.
  const isMealWithOnlySpecialDietsAvailable =
    category.category === Category.MEAL &&
    (!lowestPriceForCategory || Object.values(lowestPriceForCategory).every((fragment) => isEmptyObject(fragment)));
  if (isMealWithOnlySpecialDietsAvailable) {
    return fallbackGetLowestPriceForCategory(category);
  }

  return isPresent(lowestPriceForCategory)
    ? getLowestPrice(lowestPriceForCategory, Object.keys(lowestPriceForCategory))
    : undefined;
};
