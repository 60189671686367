<div class="flex" data-hj-suppress>
  <div class="flex--nogrow ps-xsmall-r">
    <fcom-icon
      [name]="
        accompanyingAdult
          ? SvgLibraryIcon.INFANT
          : accompanyingInfant
            ? SvgLibraryIcon.ADULT_INFANT
            : SvgLibraryIcon.SINGLE_PAX
      "
    ></fcom-icon>
  </div>
  <div class="flex-1 font-body-1">
    <div class="flex flex--center mobile-flex">
      <div class="flex-1 flex--self-start">
        <div class="flex flex--align-baseline mobile-flex">
          <span class="font-heading-5">{{ extendedPassenger.firstName }} {{ extendedPassenger.lastName }}</span>
          <span class="font-body-1 medium-type"
            >{{ 'passenger.' + extendedPassenger.paxTypeKey | finLocalization | titlecase }}
            <span *ngIf="accompanyingInfant"
              >({{
                'passenger.withInfant'
                  | finLocalization
                    : { withInfantFullName: accompanyingInfant.firstName + ' ' + accompanyingInfant.lastName }
                  | finToLowerCase: ''
              }})</span
            ><span *ngIf="accompanyingAdult"
              >({{
                'passengerReview.withAdult'
                  | finLocalization
                    : { withAdultFullName: accompanyingAdult.firstName + ' ' + accompanyingAdult.lastName }
                  | finToLowerCase: ''
              }})</span
            ></span
          >
        </div>
        <div>
          <div *ngIf="extendedPassenger.dateOfBirth" class="ps-xsmall-t">
            {{ 'passengerReview.dateOfBirth' | finLocalization }}
            {{ extendedPassenger.dateOfBirth | finDateFormat: 'DATE' }}
          </div>
          <div *ngIf="extendedPassenger.email" class="ps-xsmall-t">
            <fcom-icon class="icon-small" [name]="SvgLibraryIcon.EMAIL"></fcom-icon>
            {{ extendedPassenger.email | lowercase }}
          </div>
          <div *ngIf="extendedPassenger.phoneNumbers?.length > 0" class="ps-xsmall-t">
            <ng-container *ngFor="let phone of extendedPassenger.phoneNumbers; let first = first"
              ><span *ngIf="!first"> / </span
              ><span
                ><fcom-icon class="icon-small" [name]="SvgLibraryIcon.PHONE"></fcom-icon> (+{{ phone?.countryPrefix }})
                {{ phone?.number }}</span
              ></ng-container
            >
          </div>

          <fin-passenger-ff-cards [cards]="extendedPassenger.cards"></fin-passenger-ff-cards>
        </div>
        <ng-content></ng-content>
      </div>
      <div class="flex--nogrow flex--self-end mobile-padding" *ngIf="showEdit">
        <fcom-button
          (clickBtn)="edit.emit()"
          [text]="'actions.edit' | finLocalization"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [icon]="{ name: SvgLibraryIcon.EDIT, position: IconPosition.RIGHT }"
        ></fcom-button>
      </div>
    </div>
  </div>
</div>
