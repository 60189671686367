import { HttpParams } from '@angular/common/http';

import { StringNumberBooleanMap } from '@fcom/core-api/interfaces';
import { RootPaths } from '@fcom/core/constants';
import { entrySet, isPresent } from '@fcom/core/utils';
import { Step } from '@fcom/common/interfaces';

import { BookingStepWithParams } from '../interfaces/booking/booking-step.interface';
import { BOOKING_STEPS, BOOKING_STEPS_ARRAY } from '../config/booking-config';

export const getBookingStepLink = (
  lang: string,
  step: Step | BookingStepWithParams,
  params?: StringNumberBooleanMap
): string => {
  const path = `/${[lang, RootPaths.BOOKING_ROOT, step.path].filter(Boolean).join('/')}`;
  const queryParams = toQueryParamString(params);
  return path + queryParams;
};

export const getBookingStepFromUrl = (path: string): Step => {
  const match = new RegExp(`/${RootPaths.BOOKING_ROOT}/([a-zA-Z-]*)`).exec(path);
  const matchedStep = match && match.length === 2 && match[1].toLowerCase();
  return BOOKING_STEPS_ARRAY.find((step) => step.path === matchedStep) || BOOKING_STEPS.ENTRY;
};

export const toQueryParamString = (map: StringNumberBooleanMap = {}): string => {
  if (!map || Object.keys(map).length === 0) {
    return '';
  }
  const paramString = entrySet(map)
    .filter(({ value }) => isPresent(value))
    .reduce((acc, cur) => acc.set(cur.key, String(cur.value)), new HttpParams())
    .toString();
  return `?${paramString}`;
};

export const COUNTRY_SHOP_COUNTRIES = [
  'at',
  'be',
  'de',
  'dk',
  'ee',
  'es',
  'fi',
  'fr',
  'gb',
  'ie',
  'in',
  'it',
  'lt',
  'lv',
  'nl',
  'no',
  'se',
];
