import { ElementRef, Injectable, OnDestroy } from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  from,
  fromEvent,
  Observable,
  skip,
  Subject,
  Subscription,
  switchMap,
  take,
} from 'rxjs';

import { ConfigService, mapErrorForSentry, SentryLogger, WindowRef } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';

import {
  AgentforceChatConfig,
  ChatServiceQueueId,
  HelpButtonStatus,
  LiveagentChatFields,
  SplitTrafficChatFields,
} from '../interfaces';
import { SalesforceChatService } from './salesforce-chat.service';
import { SalesforceChatConfig, SalesforceChatConfigService } from './salesforce-chat-config.service';

export interface EmbeddedSvc {
  settings: { [key: string]: unknown };
  init: (...InitParams) => void;
  bootstrapEmbeddedService: () => void;
  addEventHandler: (eventName: string, callback: () => void) => void;
}

export interface EmbeddedBootstrapSvc {
  settings: { [key: string]: unknown };
  init: (deploymentId: string, buttonId: string, agentUrl: string, options: { scrt2URL: string }) => void;
  prechatAPI: {
    setHiddenPrechatFields?: (formData: SplitTrafficChatFields) => void;
  };
  utilAPI: {
    launchChat: () => Promise<void>;
  };
  userVerificationAPI: {
    clearSession: (eventName: boolean) => Promise<void>;
  };
}

type EntityFieldMap = {
  doCreate: boolean;
  doFind: boolean;
  fieldName: string;
  isExactMatch: boolean;
  label: string;
};

type EntityInfo = {
  entityName: string;
  saveToTranscript: string;
  showOnCreate: boolean;
  entityFieldMaps: EntityFieldMap[];
};

export const AGENTFORCE_EXTRAPRECHATINFO: EntityInfo[] = [
  {
    entityName: 'Contact',
    saveToTranscript: 'Contact',
    showOnCreate: false,
    entityFieldMaps: [
      { doCreate: false, doFind: false, fieldName: 'LastName', isExactMatch: true, label: 'Last Name' },
      { doCreate: false, doFind: false, fieldName: 'FirstName', isExactMatch: true, label: 'First Name' },
      { doCreate: false, doFind: true, fieldName: 'Email', isExactMatch: true, label: 'Email' },
    ],
  },
  {
    entityName: 'Account',
    showOnCreate: false,
    saveToTranscript: 'Account',
    entityFieldMaps: [{ isExactMatch: true, fieldName: 'PersonEmail', doCreate: false, doFind: true, label: 'Email' }],
  },
  {
    entityName: 'Case',
    showOnCreate: true,
    saveToTranscript: 'CaseId',
    entityFieldMaps: [
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Chat_FirstName__c',
        isExactMatch: true,
        label: 'Chat First Name',
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Chat_LastName__c',
        isExactMatch: true,
        label: 'Chat Last Name',
      },
      { doCreate: true, doFind: false, fieldName: 'Chat_Email__c', isExactMatch: true, label: 'Chat Email' },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Booking_Reference__c',
        isExactMatch: true,
        label: 'Booking Reference',
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Finnair_Plus_Number__c',
        isExactMatch: true,
        label: 'Finnair Plus Number',
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Case_Member_Tier__c',
        isExactMatch: true,
        label: 'Case Member Tier',
      },
      { doCreate: true, doFind: false, fieldName: 'Status', isExactMatch: false, label: 'Status' },
      { doCreate: true, doFind: false, fieldName: 'Origin', isExactMatch: false, label: 'Origin' },
      { doCreate: true, doFind: false, fieldName: 'RecordType', isExactMatch: false, label: 'RecordType' },
    ],
  },
];

// Sites using these queues, will be using the agentforce split/doorbell solution
export const ALLOWED_SERVICE_QUEUE_AGENTFORCE: ChatServiceQueueId[] = ['Chatbot_EN', 'Chatbot_EN_SGS'];

@Injectable()
export class AgentforceChatService implements OnDestroy {
  private subscription = new Subscription();
  chatButtonState$: BehaviorSubject<HelpButtonStatus> = new BehaviorSubject(HelpButtonStatus.VISIBLE);
  liveAgentChatTriggered$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  messagingForWebTriggered$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  openLegacyChat$: Subject<LiveagentChatFields> = new Subject<LiveagentChatFields>();
  openWebMessagingChat$: Subject<SplitTrafficChatFields> = new Subject<SplitTrafficChatFields>();

  embeddedSvc: EmbeddedSvc;
  embeddedSvcBootstrap: EmbeddedBootstrapSvc;
  agentforceConfig?: AgentforceChatConfig;

  constructor(
    private languageService: LanguageService,
    private sfChatService: SalesforceChatService,
    private sfChatConfigService: SalesforceChatConfigService,
    private windowRef: WindowRef,
    private configService: ConfigService,
    private sentryLogger: SentryLogger
  ) {
    this.agentforceConfig = this.configService.cfg.agentforceChat;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkAllowedLocales(langValue: string): boolean {
    const config: SalesforceChatConfig = this.sfChatConfigService.config;
    config.setLanguage(langValue);
    return ALLOWED_SERVICE_QUEUE_AGENTFORCE.includes(config.serviceQueueId);
  }

  liveAgentChat(user: LiveagentChatFields, target: ElementRef): void {
    const config: SalesforceChatConfig = this.sfChatConfigService.config;
    this.sfChatService
      .load(this.sfChatConfigService.scriptSrcURL)
      .then((): void => {
        this.embeddedSvc = this.windowRef.nativeWindow['embedded_svc'];
      })
      .finally((): void => {
        if (!this.embeddedSvc) {
          // Salesforce script failed to load/initialize, we don't have base settings, so unable to boot.
          return;
        }

        // config the settings we need for doorbell liveagent chat
        config.settings.extraPrechatInfo = AGENTFORCE_EXTRAPRECHATINFO;

        // set the user data we send to salesforce for the chat & case creation
        // test new fields, if there is not an appropriate field to map to, case will not create
        config.settings.extraPrechatFormDetails = [
          { label: 'First Name', value: user.FirstName, name: 'FirstName' }, // for loading window 'Hello, first name' greeting
          { label: 'Chat First Name', value: user.FirstName, name: 'Chat_FirstName__c' },
          { label: 'Chat Last Name', value: user.LastName, name: 'Chat_LastName__c' },
          { label: 'Chat Email', value: user.Email, name: 'Chat_Email__c' },
          { label: 'Booking Reference', value: user.Booking_Reference__c, name: 'Booking_Reference__c' },
          { label: 'Email', value: user.Email, name: 'Email' },
          {
            label: 'Site Origin',
            value: this.windowRef.nativeWindow.location.href,
            transcriptFields: ['SiteOrigin__c'],
          },
          ...(user.Finnair_Plus_Number__c
            ? [
                { label: 'Finnair Plus Number', value: user.Finnair_Plus_Number__c, name: 'Finnair_Plus_Number__c' },
                { label: 'Case Member Tier', value: user.Case_Member_Tier__c, name: 'Case_Member_Tier__c' },
              ]
            : []),
        ];

        this.subscription.add(
          combineLatest([this.languageService.lang, this.languageService.localizations]).subscribe(
            ([langValue, translations]): void => {
              config.setLanguage(langValue);

              if (!config.serviceQueueId) {
                return;
              }

              config.setTargetElement(target.nativeElement);

              if (translations.chat) {
                config.settings.defaultMinimizedText = translations.chat.defaultMinimizedText;
                config.settings.disabledMinimizedText = translations.chat.defaultMinimizedText;
                config.settings.offlineSupportMinimizedText = translations.chat.defaultMinimizedText;
              } else {
                delete config.settings.disabledMinimizedText;
                delete config.settings.defaultMinimizedText;
                delete config.settings.offlineSupportMinimizedText;
              }

              // when chat is fully expanded and visible
              this.embeddedSvc.addEventHandler('afterMaximize', (): void => {
                this.chatButtonState$.next(HelpButtonStatus.HIDDEN);
              });

              // when chat is closed (X is clicked)
              this.embeddedSvc.addEventHandler('afterDestroy', (): void => {
                this.chatButtonState$.next(HelpButtonStatus.VISIBLE);
              });

              this.embeddedSvc.settings = { ...this.embeddedSvc.settings, ...config.settings };
              this.embeddedSvc.init(...config.initParams);

              if (this.liveAgentChatTriggered$.value) {
                this.embeddedSvc.bootstrapEmbeddedService();
                return;
              }

              // onSettingsCallCompleted is triggered after init, used to signal when to launch
              // bootstrapEmbeddedService(), which starts the chat.
              // event is only triggered once and persists. So listener checks this and lanches from above if statement with true
              this.embeddedSvc.addEventHandler('onSettingsCallCompleted', (): void => {
                this.liveAgentChatTriggered$.next(true);
                this.embeddedSvc.bootstrapEmbeddedService();
              });
            }
          )
        );
      });
  }

  webMessagingChat(formData: SplitTrafficChatFields, target: ElementRef): void {
    this.sfChatService
      .load(this.agentforceConfig.chatScriptSrcURL)
      .then((): void => {
        this.embeddedSvcBootstrap = this.windowRef.nativeWindow['embeddedservice_bootstrap'];
      })
      .catch((error: unknown): void => {
        this.sentryLogger.error('Error loading Messaging for Web chat:', { error: mapErrorForSentry(error) });
      })
      .finally((): void => {
        if (!this.embeddedSvcBootstrap) {
          return;
        }

        const messageReady$: Observable<Event> = fromEvent(
          this.windowRef.nativeWindow,
          'onEmbeddedMessagingReady'
        ).pipe(finShare());

        // set the user data sent to salesforce
        this.subscription.add(
          messageReady$
            .pipe(take(1))
            .subscribe((): void => this.embeddedSvcBootstrap.prechatAPI.setHiddenPrechatFields(formData))
        );

        // close chat
        this.subscription.add(
          messageReady$
            .pipe(
              skip(1),
              switchMap((): Observable<void> => from(this.embeddedSvcBootstrap.userVerificationAPI.clearSession(true)))
            )
            .subscribe((): void => this.chatButtonState$.next(HelpButtonStatus.VISIBLE))
        );

        // chat has finished initializing, creates button in the background, this is when we simulate the user click to auto-launch the chat for the user.
        this.subscription.add(
          fromEvent(this.windowRef.nativeWindow, 'onEmbeddedMessagingButtonCreated')
            .pipe(
              take(1), // prevent auto re-launch on close
              switchMap((): Observable<void> => from(this.embeddedSvcBootstrap.utilAPI.launchChat()))
            )
            .subscribe((): void => {
              this.chatButtonState$.next(HelpButtonStatus.HIDDEN);
              this.messagingForWebTriggered$.next(true);
            })
        );

        // maximise chat
        this.subscription.add(
          fromEvent(this.windowRef.nativeWindow, 'onEmbeddedMessagingWindowMaximized').subscribe((): void =>
            this.chatButtonState$.next(HelpButtonStatus.HIDDEN)
          )
        );

        this.embeddedSvcBootstrap.settings.shouldShowParticipantChgEvntInConvHist = true; // SHA disable transfer alerts
        this.embeddedSvcBootstrap.settings.devMode = false;
        this.embeddedSvcBootstrap.settings.language = 'en';
        this.embeddedSvcBootstrap.settings.chatButtonPosition = '0.4rem, 0.4rem';
        this.embeddedSvcBootstrap.settings.hideChatButtonOnLoad = true;
        this.embeddedSvcBootstrap.settings.targetElement = target.nativeElement;

        this.embeddedSvcBootstrap.init(
          this.agentforceConfig.queueConfigs.DEFAULT.deploymentId,
          this.agentforceConfig.queueConfigs.DEFAULT.buttonId,
          this.agentforceConfig.queueConfigs.DEFAULT.agentUrl,
          {
            scrt2URL: this.agentforceConfig.scrt2URL,
          }
        );
      });
  }

  startWebMessagingChat(formData: SplitTrafficChatFields): void {
    if (this.embeddedSvcBootstrap) {
      this.embeddedSvcBootstrap.prechatAPI.setHiddenPrechatFields(formData);
      // Allows chat to launch on re-submission after user has ended the conversation
      this.chatButtonState$.next(HelpButtonStatus.CHAT);
      this.embeddedSvcBootstrap.utilAPI.launchChat();
    }
  }
}
