<ng-template #spinner>
  <fin-loader data-testid="general-loader" [type]="LoaderType.INLINE"></fin-loader>
</ng-template>

<cms-page *ngIf="page$ | async as page" [content]="page"></cms-page>

<ng-template #noGridLayout let-classnames="classnames">
  <fin-cms-json-content
    *ngIf="hasContent$ | async; else spinner"
    [class]="classnames"
    [template$]="template$"
    [showSideTitle$]="showSideTitle$"
    [pageViewType$]="pageViewType$"
  ></fin-cms-json-content>
</ng-template>

<ng-container [ngSwitch]="renderSwitch$ | async">
  <ng-container *ngSwitchCase="PAGETYPE.SECTION_PAGE_EXTERNAL_TEMPLATE">
    <cms-external-page-renderer [template$]="template$"></cms-external-page-renderer>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.DESTINATION_PAGE">
    <cms-destination
      *ngIf="hasContent$ | async; else spinner"
      class="main-content"
      [template$]="template$"
      [pageViewType$]="pageViewType$"
    ></cms-destination>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.EDITORIAL_LANDING_PAGE">
    <cms-editorial-main-page
      *ngIf="hasContent$ | async"
      [hasContent]="hasContent$ | async"
      class="main-content"
      [template$]="template$"
    ></cms-editorial-main-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.EDITORIAL_CATEGORY_PAGE">
    <cms-editorial-category-page
      *ngIf="hasContent$ | async; else spinner"
      class="main-content"
      [template$]="template$"
    ></cms-editorial-category-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.FORM_WIZARD_PAGE">
    <fin-render-lazy-wizard
      *ngIf="hasContent$ | async; else spinner"
      [template$]="template$"
      class="grey-25-bg"
    ></fin-render-lazy-wizard>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.EMERGENCY_IN_OPERATIONS">
    <cms-emergency-in-operations
      *ngIf="hasContent$ | async; else spinner"
      [template$]="template$"
    ></cms-emergency-in-operations>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.PREMIUM_ECONOMY_LANDING_PAGE_2021">
    <ng-container
      [ngTemplateOutlet]="noGridLayout"
      [ngTemplateOutletContext]="{ classnames: 'main-content ' + PAGETYPE.PREMIUM_ECONOMY_LANDING_PAGE_2021 }"
    >
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.BLUEWINGS_CLUSTER_LP">
    <cms-bluewings-cluster-page
      class="main-content"
      [template$]="template$"
      [hasContent]="hasContent$ | async"
    ></cms-bluewings-cluster-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.BLUEWINGS_LANDING_PAGE">
    <cms-bluewings-main-page
      class="main-content"
      [template$]="template$"
      [hasContent]="hasContent$ | async"
    ></cms-bluewings-main-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.CHILD_PAGE">
    <cms-child-page
      *ngIf="hasContent$ | async; else spinner"
      class="main-content"
      [template$]="template$"
      [title$]="page$ | finMap: 'title'"
    ></cms-child-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.CHILD_PAGE_NO_HEADING">
    <cms-child-page
      *ngIf="hasContent$ | async; else spinner"
      class="main-content"
      [template$]="template$"
    ></cms-child-page>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.NO_GRID_FULL_WIDTH_PAGE">
    <ng-container [ngTemplateOutlet]="noGridLayout" [ngTemplateOutletContext]="{ classnames: 'main-content' }">
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="PAGETYPE.NO_GRID_FULL_WIDTH_PAGE_WITH_TITLE">
    <section class="grid-col-12 grid-max-width no-row-gap">
      <h1 data-testid="cms-teaser-title" class="nordic-blue-900-text col-1-12 font-heading-2 mr-clear-t ps-xsmall-t">
        {{ teaserTitle$ | async }}
      </h1>
    </section>
    <ng-container [ngTemplateOutlet]="noGridLayout" [ngTemplateOutletContext]="{ classnames: 'main-content' }">
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <fin-cms-json-content
      *ngIf="hasContent$ | async; else spinner"
      class="main-content grid-col-12 grid-max-width no-row-gap pr-xlarge-b"
      [template$]="template$"
      [showSideTitle$]="showSideTitle$"
      [pageViewType$]="pageViewType$"
    ></fin-cms-json-content>
  </ng-container>

  <ng-container *ngIf="enablePageFeedback">
    <fin-qualtrics-embedded-page-feedback
      *ngIf="hasContent$ | async"
      [pageType]="pageViewType$ | async"
    ></fin-qualtrics-embedded-page-feedback>
  </ng-container>
</ng-container>

<div aria-live="assertive" class="page-announcer" tabindex="-1">
  <span class="is-vishidden" *ngIf="title$ | async"
    >{{ 'navigation.pageChangedTo' | finLocalization: { pageTitle: title$ | async } }}
  </span>
</div>
