<div class="flex flex--middle flex--nowrap ps-xsmall-y font-body-1">
  <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.AM_LOGO"></fcom-icon>
  <div class="ps-small-l" [innerHTML]="'aurinkomatkat.inCooperation' | finLocalization"></div>
</div>

<div
  *ngIf="showCompact$ | async"
  class="selector-grid compact grid-col-12 no-gutters"
  data-testid="widget-selector-grid"
>
  <div class="col-1-12">
    <fin-location-selector
      [locationPair$]="locations$"
      [showPreviousSearches]="false"
      [isAm]="true"
      [amOrigins$]="amOrigins$"
      [amDestinations$]="destinations$"
      [showSeparator]="false"
      (setLocations)="updateLocations($event)"
    ></fin-location-selector>
  </div>
</div>

<ng-container *ngIf="(showCompact$ | async) === false">
  <div class="selector-grid grid-col-12 no-gutters" data-testid="widget-selector-grid">
    <div class="col-1-12">
      <fin-location-selector
        [locationPair$]="locations$"
        [showPreviousSearches]="false"
        [isAm]="true"
        [amOrigins$]="amOrigins$"
        [amDestinations$]="destinations$"
        [showSeparator]="false"
        (setLocations)="updateLocations($event)"
      ></fin-location-selector>
    </div>

    <div class="md-col-1-4 col-1-12">
      <fin-am-room-selector (setRooms)="updateRooms($event)"></fin-am-room-selector>
    </div>

    <div class="md-col-5-12 col-1-12">
      <fin-am-dates-selector
        [travelDates$]="travelDates$"
        [amDestination$]="selectedAmDestination$"
        [amAvailability$]="availability$"
        (setTravelDates)="setTravelDates($event)"
        (setDuration)="setDuration($event)"
      ></fin-am-dates-selector>
    </div>

    <div class="col-1-12">
      <div class="flex flex--end flex--middle ps-medium-t" data-testid="booking-widget-footer">
        <fcom-button
          class="continue-btn"
          [theme]="ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER"
          [size]="ButtonSize.LARGE"
          [mode]="ButtonMode.FILL"
          [loading]="false"
          [text]="'bookingSearch.search' | finLocalization"
          [disabled]="(selectionIsValid$ | async) === false"
          (clickBtn)="openContinueModal()"
          data-testid="booking-widget-search-button"
        ></fcom-button>
      </div>
      <fin-booking-widget-notifications [isAm]="true"></fin-booking-widget-notifications>
    </div>
  </div>

  <ng-container *ngIf="selectedAmDestination$ | async as destination">
    <fcom-modal
      [(open)]="continueModalOpen"
      [title]="
        'aurinkomatkat.continueModal.title' | finLocalization: { holidayType: destination.holidayTypeItem.label }
      "
      [fitToContent]="true"
      [buttons]="ModalButtons.CONTINUE_CANCEL"
      (confirm)="continueToAm()"
      data-testid="am-continue-modal"
    >
      <div class="pr-large">
        <div class="flex flex--middle ps-small-b">
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.AM_LOGO"></fcom-icon>
          <div class="nordic-blue-900-text font-heading-3 mr-xsmall-l">
            {{ 'aurinkomatkat.continueModal.contentTitle' | finLocalization }}
          </div>
        </div>
        <div class="font-body-1">
          {{ 'aurinkomatkat.continueModal.content' | finLocalization }}
        </div>
      </div>
    </fcom-modal>
  </ng-container>
</ng-container>
