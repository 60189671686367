<header class="layout__booking-header layout__header--override shadow-highlight white-bg nordic-blue-900-text">
  <div class="grid-col-12 no-row-gap grid-max-width">
    <div class="col-f">
      <div class="boxes fill flex">
        <div class="flex-1 flex--basis0 flex flex--start flex--self-center">
          <fcom-icon-button
            *ngIf="showHamburgerMenu$ | async"
            class="nav ms-medium-r"
            [icon]="{ name: SvgLibraryIcon.MENU_LIST }"
            [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
            [size]="IconButtonSize.MEDIUM"
            [aria]="{ label: 'menu.open' | finLocalization }"
            (clickBtn)="menuClick.emit($event)"
          >
          </fcom-icon-button>
          @if (enableNewStepper && !isInsideNativeWebView) {
            <div class="dn-laptop-up">
              <ng-content select="[mobile]"></ng-content>
            </div>
          }
          <fcom-icon-button
            *ngIf="!enableNewStepper || isInsideNativeWebView"
            [class.hidden]="!showBack"
            class="back"
            [disabled]="!showBack"
            [icon]="{ name: SvgLibraryIcon.CHEVRON_LEFT }"
            [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
            [size]="IconButtonSize.MEDIUM"
            [aria]="{ label: 'actions.back' | finLocalization }"
            (clickBtn)="onBackClick()"
          >
          </fcom-icon-button>
          <a
            class="home-link desktop-icon ms-large-r dn-laptop-down flex"
            [attr.aria-label]="'actions.home' | finLocalization"
            [class.disabled]="isInsideNativeWebView"
            [class.ms-large-l]="!enableNewStepper"
            [href]="homeUrl"
            (click)="onHomeClick($event)"
          >
            <fcom-icon
              class="finnair-home nordic-blue-900-text"
              [category]="IconLibrary.CUSTOM"
              [name]="CustomIcon.FINNAIR_LOGO"
            ></fcom-icon>
          </a>
        </div>
        <div class="flex-1 flex flex--center flex--self-center breadcrumb-wrapper">
          <a
            class="home-link mobile-icon dn-laptop-up"
            [attr.aria-label]="'actions.home' | finLocalization"
            [class.disabled]="isInsideNativeWebView"
            [href]="homeUrl"
            (click)="onHomeClick($event)"
          >
            <fcom-icon
              class="nordic-blue-900-text"
              [category]="IconLibrary.CUSTOM"
              [name]="CustomIcon.FINNAIR_EMBLEM"
            ></fcom-icon>
          </a>
          <ul *ngIf="breadcrumbs" class="breadcrumbs flex flex-1 dn-laptop-down">
            <li
              (click)="onBreadcrumbClick(breadcrumb, i)"
              class="breadcrumb phase flex-1 flex flex--center"
              [class.selectable]="i < activeIndex"
              [class.active]="breadcrumb.active"
              *ngFor="let breadcrumb of breadcrumbs; let i = index"
            >
              <button
                role="link"
                class="flex flex--center"
                [title]="breadcrumb.name"
                [disabled]="breadcrumb.disabled || i > activeIndex"
              >
                <div class="phase-block flex--nogrow rounded"></div>
                <div
                  class="phase-text flex-1"
                  [ngClass]="breadcrumb.active ? 'heather-700-text' : 'nordic-blue-900-text'"
                >
                  {{ breadcrumb.name }}
                </div>
                <span *ngIf="phasesAria">
                  <span *ngIf="i < activeIndex" class="is-vishidden">{{ phasesAria.pastStep }}</span>
                  <span *ngIf="breadcrumb.active" class="is-vishidden">{{ phasesAria.currentStep }}</span>
                  <span *ngIf="i > activeIndex" class="is-vishidden">{{ phasesAria.futureStep }}</span>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="flex-1 flex--basis0 flex flex--end flex--self-center align-right" data-hj-suppress>
          @if (enableNewStepper && !isInsideNativeWebView) {
            <div class="dn-laptop-down">
              <ng-content select="[desktop]"></ng-content>
            </div>
          }
          <fcom-button
            data-testid="booking-header-finnair-plus-login-button"
            (clickBtn)="loginClick.emit({ loggedIn: loggedIn, event: $event })"
            [class.logged-in]="loggedIn"
            [class.hidden]="!showLogin"
            [class]="'login-btn'"
            [disabled]="!showLogin"
            [icon]="{
              name: profileIcon,
              position: IconPosition.LEFT,
            }"
            [mode]="ButtonMode.FILL"
            [text]="
              showLogin && loggedIn
                ? userName || logoutText || ('navigation.logout' | finLocalization)
                : enableNewStepper && isMobile
                  ? null
                  : loginText || ('navigation.login' | finLocalization)
            "
            [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
          ></fcom-button>
        </div>
      </div>
    </div>
    <div class="col-f dn-laptop-up breadcrumbs-mobile">
      <ul *ngIf="breadcrumbs" class="flex">
        <li
          class="breadcrumb phase flex-1 flex flex--center"
          [class.selectable]="i < activeIndex"
          [class.active]="breadcrumb.active"
          *ngFor="let breadcrumb of breadcrumbs; let i = index"
        >
          <div class="phase-block"></div>
        </li>
      </ul>
    </div>
  </div>
</header>
