<div class="timeline__cell">
  <div class="timeline__left flex--column flex--center" role="presentation">
    <div class="flex--nogrow bubble bubble-plane-start">
      <fcom-icon
        class="icon-medium nordic-blue-900-text"
        [name]="busOrTrainIconMap[flight.aircraft?.code] ?? SvgLibraryIcon.DEPARTURE"
        data-testid="itinerary-timeline-flight-departure-icon"
      ></fcom-icon>
    </div>
    <div class="flex-1 line line-solid"></div>
    <!-- This bubble will be rendered in flight stops later on -->
    @if (!flight.technicalStops || flight.technicalStops.length === 0) {
      <div class="flex--nogrow bubble bubble-plane-end">
        <fcom-icon
          class="icon-medium nordic-blue-900-text"
          [name]="busOrTrainIconMap[flight.aircraft?.code] ?? SvgLibraryIcon.ARRIVAL"
          data-testid="itinerary-timeline-flight-arrival-icon"
        ></fcom-icon>
      </div>
    }
  </div>
  <div class="timeline__right pr-xsmall-l">
    <p [class.mr-xxsmall-t]="firstLeg" [class.mr-xxlarge-b]="isBusOrTrain && flight.technicalStops && firstLeg">
      <span class="is-vishidden">{{ 'departure' | finLocalization }}</span>
      @if (flightChanges?.notification) {
        <fcom-notification
          [theme]="NotificationTheme.ALERT"
          [layout]="NotificationLayout.BLOCK"
          [iconName]="SvgLibraryIcon.ALERT_BLOCK"
          class="block timeline__right medium-type heather-700-text pr-xsmall-b"
        >
          {{ 'MMB.notifications.' + flightChanges.notification + '.text' | finLocalization }}
        </fcom-notification>
      }
      <fin-itinerary-timeline-leg-endpoint
        [endpoint]="flight.departure"
        [oldEndpointDateTime]="flightChanges?.departureDateTime"
        [oldEndpointLocationCode]="flightChanges?.departureLocationCode"
        [locations]="locations"
      ></fin-itinerary-timeline-leg-endpoint>
    </p>

    @if (!isBusOrTrain || (isBusOrTrain && !firstLeg) || (isBusOrTrain && !flight.technicalStops && !lastLeg)) {
      @if (flight.departure.terminal && !isBusOrTrain) {
        <p class="light-type">
          <span>{{ 'bookingSearch.flight.terminal' | finLocalization }}</span>
          @if (flightChanges?.departureTerminal) {
            <span class="grey-700-text strike-through mr-xxsmall-r">
              {{ flightChanges?.departureTerminal }}
            </span>
          }
          <span>
            {{ flight.departure.terminal }}
          </span>
        </p>
      }
      <p data-testid="itinenary-timeline-flight-details" class="nordic-blue-900-text mr-xsmall-y flex flex--column">
        @if (flightChanges?.flightNumber) {
          <span class="grey-700-text strike-through mr-xxsmall-r"> {{ flightChanges.flightNumber }}</span>
        }
        <span>{{ flight.flightNumber }}</span>
        <span>{{ 'bookingSummary.operatedBy' | finLocalization }} {{ flight.operatingAirline?.name }}</span>
        @if (flightChanges?.operatingAirline) {
          <span class="grey-700-text strike-through mr-xxsmall-r"> {{ flightChanges.operatingAirline }}</span>
        }
        @if (flightChanges?.aircraft) {
          <span class="grey-700-text strike-through mr-xxsmall-r">
            {{ flightChanges.aircraft }}
          </span>
        }
        <span data-testid="flight-aircraft-name">{{ flight?.aircraft?.name }}</span>
        @if (flightChanges?.duration) {
          <span class="grey-700-text strike-through mr-xxsmall-r">
            {{ 'bookingSearch.duration' | finLocalization: (flightChanges.duration | finTemporaryDurationPipe) }}
          </span>
        }
        <span>{{ 'bookingSearch.duration' | finLocalization: (flight.duration | finTemporaryDurationPipe) }}</span>
        @if (flight?.cabinClass) {
          <span>
            {{ 'travelClass.' + flight.cabinClass?.toLowerCase() | finLocalization }}
            {{ flight.bookingClass }}
          </span>
        }
        @if (isBookingFlow && isFlightUsingSAF && enableShowingSAF) {
          <fcom-tag class="ms-xxsmall-t" data-testid="itinenary-timeline-saf-chip" [theme]="TagTheme.SUSTAINABILITY">{{
            'safNotification.chipText' | finLocalization
          }}</fcom-tag>
        }
      </p>
    }

    @if (!flight.technicalStops || flight.technicalStops.length === 0) {
      <p class="mr-xsmall-t">
        <span class="is-vishidden">{{ 'arrival' | finLocalization }}</span>
        <fin-itinerary-timeline-leg-endpoint
          [endpoint]="flight.arrival"
          [oldEndpointDateTime]="flightChanges?.arrivalDateTime"
          [oldEndpointLocationCode]="flightChanges?.arrivalLocationCode"
          [locations]="locations"
        ></fin-itinerary-timeline-leg-endpoint>
      </p>
      @if (flight.arrival.terminal && !isBusOrTrain) {
        <p class="light-type">
          {{ 'bookingSearch.flight.terminal' | finLocalization }}
          @if (flightChanges?.arrivalTerminal) {
            <span class="grey-700-text strike-through mr-xxsmall-r">
              {{ flightChanges.arrivalTerminal }}
            </span>
          }
          {{ flight.arrival.terminal }}
        </p>
      }
    }
  </div>
</div>
