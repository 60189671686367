import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, PartialObserver, Subscription } from 'rxjs';
import { share, switchMap, take, tap, delay } from 'rxjs/operators';

import { SentryLogger } from '@fcom/core';
import { TripType, OfferListFetchParams } from '@fcom/common/interfaces';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { retryWithBackoff, snapshot } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';
import {
  FinnairAirBoundsRequest,
  FinnairAirBoundsRequestItinerary,
  FinnairAirBoundsRequestTravelers,
  FinnairAirBoundsResponse,
} from '@fcom/dapi/api/models';
import { OffersService } from '@fcom/dapi/api/services';
import { InstantSearchMonitoring } from '@fcom/booking-widget/interfaces/store.interface';
import { instantSearchMonitoring } from '@fcom/booking-widget/store/selectors/booking-widget.selector';
import { BookingWidgetAndAppState } from '@fcom/booking-widget/store/selectors/booking-widget-feature-state.selector';
import { stringHashCode } from '@fcom/core/utils/utils';
import { AirOffersStatus, BookingAppState } from '@fcom/common/interfaces/booking';
import { BookingWidgetActions } from '@fcom/booking-widget/store/actions';
import { NotificationWarning } from '@fcom/booking-widget/interfaces';
import { globalBookingTripType } from '@fcom/common/store';

import { BoundsActions } from '../../../store/actions';
import {
  airBoundsRequest,
  boundsStatus,
  lastRequestParams,
  outboundCache,
  selectedCheapestOffer,
  serializedLastRequestParams,
} from '../../../store/selectors';
import { mapAirBoundIdToBound, mapCityNameAndAirportToBounds } from '../../../utils';

export interface PreSelectionParams {
  departureFlightNumbers?: string;
  returnFlightNumbers?: string;
  departureFareFamily?: string;
  returnFareFamily?: string;
}

export type TicketSelectionParams = OfferListFetchParams & PreSelectionParams;

const NO_FLIGHTS_STATUSES: string[] = ['NO_FLIGHTS_FOUND', 'NO_FLIGHTS_LEFT_FOR_TODAY'];
const DENIED_BY_AKAMAI = /You don't have permission to access/;

@Injectable()
export class BookingAirBoundsService implements OnDestroy {
  static NUMBER_OF_RETRIES = 1;
  NUMBER_OF_ONE_WAY_FLIGHT = 1;

  private subscription: Subscription;
  tripType$: Observable<TripType>;
  _preselectedBoundId$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private configService: ConfigService,
    private store$: Store<BookingAppState>,
    private bookingWidgetStore$: Store<BookingWidgetAndAppState>,
    private sentryLogger: SentryLogger,
    private offersService: OffersService
  ) {
    this.tripType$ = this.store$.pipe(globalBookingTripType());
  }

  get preselectedBoundId$(): Observable<string> {
    return this._preselectedBoundId$.asObservable();
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  setPreselectedBoundId(boundId: string): void {
    this._preselectedBoundId$.next(boundId);
  }

  resetPreselectedBoundId(): void {
    if (this._preselectedBoundId$.getValue()) {
      this._preselectedBoundId$.next(null);
    }
  }

  /**
   * Triggers bound fetching for the given parameters. Stores the result
   * in the state tree under {@link AirBoundsState}
   */
  triggerFetchBounds(params: TicketSelectionParams, isRefecth = false): void {
    if (this.subscription) {
      this.subscription = unsubscribe(this.subscription);
    }
    const serialized: string = snapshot(this.store$.pipe(serializedLastRequestParams()));
    const boundStatus: AirOffersStatus = snapshot(this.store$.pipe(boundsStatus()));
    const instantSearchMonitoringData: InstantSearchMonitoring = snapshot(
      this.bookingWidgetStore$.pipe(instantSearchMonitoring())
    );

    const currentParamsSerialized = JSON.stringify(params);
    const outboundCacheHash = String(stringHashCode(currentParamsSerialized));

    // TODO: we need to fetch bounds again when they expire, even if the params stay the same
    if (!isRefecth && boundStatus === AirOffersStatus.OK && serialized === currentParamsSerialized) {
      return;
    }

    this.store$.dispatch(BoundsActions.startLoadingOutbounds());
    this.store$.dispatch(BoundsActions.setLastRequestParams({ params }));

    this.subscription = this.store$
      .pipe(outboundCache(outboundCacheHash, Date.now()))
      .pipe(
        take(1),
        switchMap((cache) => {
          const bodyParams = this.createRequest(params);
          if (params.flights.length === this.NUMBER_OF_ONE_WAY_FLIGHT) {
            Object.assign(bodyParams, instantSearchMonitoringData);
          }
          if (cache && !isRefecth) {
            return of(cache).pipe(delay(0));
          }

          return this.getBounds(bodyParams).pipe(
            retryWithBackoff(BookingAirBoundsService.NUMBER_OF_RETRIES),
            tap((bounds) => {
              if (!bounds) {
                return;
              }
              this.store$.dispatch(
                BoundsActions.setOutboundCache({
                  hash: outboundCacheHash,
                  createdAt: Date.now(),
                  bounds,
                })
              );
            })
          );
        })
      )
      .subscribe(this.handleBoundResponse(BoundsActions.setOutbounds));
  }

  /**
   * Retrieves the return bounds for a given outbound id.
   *
   * @param selectedOutboundId the selected outbound id
   * @param selectedFareFamilyCode the selected outbound fare family code
   */
  getReturnBounds(selectedOutboundId: string, selectedFareFamilyCode: string): void {
    const originalRequest: FinnairAirBoundsRequest = snapshot(this.store$.pipe(airBoundsRequest()));
    const isSelectedCheapestOffer: boolean = snapshot(this.store$.pipe(selectedCheapestOffer()));
    const instantSearchMonitoringData: InstantSearchMonitoring = snapshot(
      this.bookingWidgetStore$.pipe(instantSearchMonitoring())
    );

    if (isSelectedCheapestOffer) {
      Object.assign(originalRequest, instantSearchMonitoringData);
    }

    const itineraries = [
      Object.assign({}, originalRequest.itineraries[0], { isRequestedBound: false }),
      Object.assign({}, originalRequest.itineraries[1], { isRequestedBound: true }),
    ];
    if (this.subscription) {
      this.subscription = unsubscribe(this.subscription);
    }
    this.store$.dispatch(BoundsActions.startLoadingInbounds());
    this.subscription = this.getBounds(
      Object.assign({}, originalRequest, {
        itineraries,
        selectedBound: { id: selectedOutboundId, fareFamilyCode: selectedFareFamilyCode },
      })
    )
      .pipe(retryWithBackoff(BookingAirBoundsService.NUMBER_OF_RETRIES))
      .subscribe(this.handleBoundResponse(BoundsActions.setInbounds));
  }

  /**
   * Dispatch action to set bound id if conditions met
   *
   * @param action kind of action
   * @param requestParams params from last request
   * @param bounds received bounds
   */
  handleFlightPreSelection(
    action: typeof BoundsActions.setOutbounds | typeof BoundsActions.setInbounds,
    bounds: FinnairAirBoundsResponse
  ): void {
    const requestParams = this.getLastRequestParams();
    const isValidParams = this.validateFlightPreSelectionParams(requestParams);
    const isTwoWay =
      isValidParams &&
      requestParams.flights?.length === 2 &&
      Boolean(requestParams.departureFlightNumbers) &&
      Boolean(requestParams.returnFlightNumbers);

    if (
      isValidParams &&
      action === BoundsActions.setOutbounds &&
      ((!isTwoWay && requestParams.departureFlightNumbers) || isTwoWay)
    ) {
      this.preSelectOutboundIfFound(bounds, requestParams);
    } else if (isValidParams && action === BoundsActions.setInbounds && isTwoWay) {
      this.preSelectInboundIfFound(bounds, requestParams);
    }
  }

  /**
   * Get last request params from store
   *
   * @returns {TicketSelectionParams} object
   */
  getLastRequestParams(): TicketSelectionParams {
    return snapshot(this.store$.pipe(lastRequestParams()));
  }

  /**
   * Get flight bounds for a certain date.
   *
   * @param params the search parameters to use
   * @returns {Observable<FinnairAirBoundsResponse>} the fetched bounds
   */
  private getBounds(params: FinnairAirBoundsRequest): Observable<FinnairAirBoundsResponse> {
    return this.offersService
      .findAirBounds(this.configService.cfg.offersUrl, {
        body: params,
      })
      .pipe(share());
  }

  private buildItineraries(params: OfferListFetchParams): FinnairAirBoundsRequestItinerary[] {
    const { directFlights, flights } = params;
    return flights.map((f, index) => ({
      directFlights,
      departureLocationCode: f.origin,
      destinationLocationCode: f.destination,
      departureDate: f.departureDate.toString(),
      isRequestedBound: index === 0,
    }));
  }

  /**
   * Create air bounds request from query parameters.
   *
   * @param params the search params used to create the request
   * @param params.locale the locale to send with the request
   * @param params.origin the IATA code of the destination to fly to
   * @param params.destination the IATA code of the departure location
   * @param params.directFlights
   * @param params.cabin the travel class to query
   * @param params.paxAmount passenger amounts
   * @param params.departureDate the date of departure
   * @param params.returnDate the date of return, leave undefined for one-way trips
   * @param params.promoCode given discount code to apply
   * @returns {FinnairAirBoundsRequest} the request object to use
   */
  private createRequest(params: OfferListFetchParams): FinnairAirBoundsRequest {
    const { cabin, locale, paxAmount, promoCode } = params;
    const itineraries = this.buildItineraries(params);
    return {
      locale,
      cabin,
      travelers: {
        adults: paxAmount.adults,
        children: paxAmount.children,
        c15s: paxAmount.c15s,
        infants: paxAmount.infants,
      } as unknown as FinnairAirBoundsRequestTravelers,
      itineraries,
      promoCode,
    };
  }

  private handleBoundResponse(
    action: typeof BoundsActions.setOutbounds | typeof BoundsActions.setInbounds
  ): PartialObserver<FinnairAirBoundsResponse> {
    return {
      next: (data) => {
        if (NO_FLIGHTS_STATUSES.indexOf(data.status) !== -1) {
          this.store$.dispatch(BoundsActions.noFlightsFound());
          this.store$.dispatch(
            BookingWidgetActions.setNotificationWarning({
              key: NotificationWarning.NO_FLIGHTS,
              isActive: true,
            })
          );
        } else {
          this.store$.dispatch(action({ bounds: mapCityNameAndAirportToBounds(data) }));

          this.handleFlightPreSelection(action, data);
        }
        this.subscription = unsubscribe(this.subscription);
      },
      error: (error: HttpErrorResponse) => {
        const status: string = error?.error?.status;

        if (status === 'INVALID_INPUT') {
          this.store$.dispatch(BoundsActions.invalidInput());
        } else {
          if (!DENIED_BY_AKAMAI.test(error.error)) {
            this.sentryLogger.error(`Error fetching bounds: ${error.status} ${error.statusText}`, { error });
          }
          this.store$.dispatch(BoundsActions.error());
        }
        this.subscription = unsubscribe(this.subscription);
      },
      complete: () => {
        this.subscription = unsubscribe(this.subscription);
      },
    };
  }

  private isValidFlightNumberFormat(value: string): boolean {
    // expects that the first 2 Characters are upper case letters
    // followed by 2 to 4 digits
    // ex. AY123
    return /^([A-Z]{2})(\d{2,4})$/.test(value);
  }

  private validateFlightPreSelectionParams(requestParams: TicketSelectionParams | undefined): boolean {
    if (!requestParams) {
      return false;
    }

    const { departureFlightNumbers, returnFlightNumbers } = requestParams;

    return [departureFlightNumbers, returnFlightNumbers].every((item) => {
      return item ? item.split(',').every((token) => this.isValidFlightNumberFormat(token.toUpperCase())) : true;
    });
  }

  private preSelectOutboundIfFound(bounds: FinnairAirBoundsResponse, requestParams: TicketSelectionParams): void {
    const { departureFlightNumbers, departureFareFamily } = requestParams;
    const airBoundId = mapAirBoundIdToBound(
      bounds,
      departureFlightNumbers.toUpperCase(),
      departureFareFamily?.toUpperCase()
    );
    if (airBoundId) {
      this.store$.dispatch(BoundsActions.setOutboundAirBoundId({ outboundAirBoundId: airBoundId }));
    }
  }

  private preSelectInboundIfFound(bounds: FinnairAirBoundsResponse, requestParams: TicketSelectionParams): void {
    const { returnFlightNumbers, returnFareFamily } = requestParams;
    const airBoundId = mapAirBoundIdToBound(bounds, returnFlightNumbers.toUpperCase(), returnFareFamily?.toUpperCase());
    if (airBoundId) {
      this.store$.dispatch(BoundsActions.setInboundAirBoundId({ inboundAirBoundId: airBoundId }));
    }
  }
}
