import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { map, Observable, of, take } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { SentryLogger, WindowRef } from '@fcom/core';

import { MultivariateTestService } from '../../multivariate-test/services/multivariate-test.service';

export enum HotjarCaptureEvent {
  BOOKING_WIDGET = 'start_capture-booking_widget',
  MULTI_CITY_SOFT_LAUNCH = 'start_capture-booking_widget_multi-city',
  CORPORATE_LOGIN = 'start_capture-corporate_login',
  CORPORATE_ENROLLMENT = 'start_capture-corporate_enrollment',
}

export enum HotjarAction {
  EVENT = 'event',
  IDENTIFY = 'identify',
}

@Injectable()
export class HotjarService {
  private captureStarted: Partial<Record<HotjarCaptureEvent, boolean>> = {};
  private hasIdentified = false;
  private userId: string;

  constructor(
    private windowRef: WindowRef,
    private sentryLogger: SentryLogger,
    private multivariateTestService: MultivariateTestService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.userId = uuid();
  }

  /**
   * You must subscribe to this function in order to trigger the event to Hotjar. If you do not
   * subscribe then this will silently fail.
   */
  startCapture(event: HotjarCaptureEvent): Observable<void> {
    if (this.captureStarted[event] || isPlatformServer(this.platform)) {
      return of(null);
    }

    this.captureStarted[event] = true;

    return this.multivariateTestService.segmentsFeed$.pipe(
      take(1),
      map((segment) => {
        if (!this.hasIdentified) {
          this.executeAction(HotjarAction.IDENTIFY, null, {
            segment: segment.segment,
            userId: this.userId,
          });

          this.hasIdentified = true;
        }

        this.executeAction(HotjarAction.EVENT, event);
      })
    );
  }

  getUserId(): string {
    return this.userId;
  }

  private executeAction(action: HotjarAction, ...params: any[]): void {
    const hotjar = this.windowRef.nativeWindow['hj'];

    if (typeof hotjar === 'function') {
      try {
        hotjar(action, ...params);
      } catch (error) {
        this.sentryLogger.warn('Failed to start hotjar capture', { error });
      }
    }
  }
}
