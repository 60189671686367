<div *ngIf="cards?.length > 0" class="ps-xsmall-t flex" data-testid="ff-cards">
  <div *ngFor="let ffCard of cards" class="flex flex--wrap">
    <fcom-icon
      [class]="ffCard.companyCode === 'AY' ? 'icon-medium' : 'icon-small'"
      class="ps-xxsmall-r"
      [name]="ffCard.companyCode === 'AY' ? SvgLibraryIcon.FINNAIR_PLUS : SvgLibraryIcon.ONEWORLD_LOGO"
    ></fcom-icon>
    <div *ngIf="ffCard.companyCode === 'AY'" class="ps-xxsmall-r">
      {{ 'MMB.checkIn.funnel.steps.passengers.ffMessage' | finLocalization: { ffNumber: ffCard.cardNumber } }}
    </div>
    <span *ngIf="ffCard.companyCode !== 'AY'" class="ps-xxsmall-r">
      {{ (ffCard.translationKey | finLocalization) + ': ' + ffCard.cardNumber }}
    </span>
    <fcom-tag
      *ngIf="ffCard.companyCode === 'AY' && ffCard.tierLevelName"
      class="ps-xxsmall-r font-body-2 medium-type"
      [roundingStyle]="TagRoundingStyle.CHIP"
    >
      <fcom-icon [class]="'icon-medium'" class="ps-xxsmall-r" [name]="SvgLibraryIcon.FINNAIR_PLUS"></fcom-icon>
      {{ ffCard.tierLevelName }}
    </fcom-tag>
    <div *ngIf="ffCard.oneWorldTierName" data-testid="oneworld-tier-info">
      <span class="bold-type">one</span>world
      {{ ffCard.oneWorldTierName }}
    </div>
  </div>
</div>
