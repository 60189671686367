/* eslint-disable camelcase */
// FIXME: move to common when dependencies are solved
export interface LoginAccessToken {
  /**
   * The expiration time of the authentication token.
   */
  expiresAt: number;

  /**
   * The access token string;
   */
  token: string;
}

export enum LoginStatus {
  /**
   * The login status has not been verified
   */
  INITIAL = 'INITIAL',
  /**
   * The session verification is pending
   */
  PENDING = 'PENDING',
  /**
   * The user is logged in
   */
  LOGGED_IN = 'LOGGED_IN',
  /**
   * The user is not logged in
   */
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  /**
   * The login verification failed with an error
   */
  ERROR = 'ERROR',
}

export enum ProfileType {
  /**
   * Corporate profile
   */
  CORPORATE = 'CORPORATE',

  /**
   * FPlus profile
   */
  FPLUS = 'FPLUS',

  /**
   * Finnair Account profile
   */
  FINNAIR_ACCOUNT = 'FINNAIR_ACCOUNT',
}

export interface LoginState {
  loginStatus: LoginStatus;
  type?: ProfileType;
  accessToken?: LoginAccessToken;
  profile?: Profile;
  casProfile?: CasProfile;
  loginPhase?: LoginPhase;
}

export enum LoginStep {
  CREDENTIALS = 'CREDENTIALS',
  CORPORATE_CREDENTIALS = 'CORPORATE_CREDENTIALS',
  CORPORATE_CREDENTIALS_DIRECT = 'CORPORATE_CREDENTIALS_DIRECT',
  TWO_FACTOR_CODE = 'TWO_FACTOR_CODE',
  TWO_FACTOR_PHONE = 'TWO_FACTOR_PHONE',
  TWO_FACTOR_EMAIL = 'TWO_FACTOR_EMAIL',
  TWO_FACTOR_SMS = 'TWO_FACTOR_SMS',
  LOCKED = 'LOCKED',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  POST_LOST_PASSWORD_SENT = 'POST_LOST_PASSWORD_SENT',
}

export enum LoginPhaseError {
  LOGIN_FAILED = 'LOGIN_FAILED',
  RESEND_2FA_EMAIL = 'RESEND_2FA_EMAIL',
  POST_LOST_PASSWORD = 'POST_LOST_PASSWORD',
}

export interface LoginPhase {
  step: LoginStep;
  profile?: LoginPhaseProfile;
  executionToken?: string;
  error?: LoginPhaseError;
}

/**
 * The interface of messages received from login iframe
 */
export interface IframeLoginPostMessage {
  eventType: 'loginSuccess';

  /**
   * The access token that should be sent in `Authorization` header to subsequent requests.
   */
  access_token: string;

  token_type: 'bearer';

  /**
   * Validity period of the token in seconds
   */
  expires_in: string;

  /**
   * The nonce that was passed to /login/init is carried over from CAS in this field
   */
  state: string;
}

/**
 * The interface of messages received from login iframe
 */
export interface WebViewLoginPostMessage {
  eventType: 'mobileAppLoginSuccess' | 'mobileAppGuestLogin';

  /**
   * The access token that should be sent in `Authorization` header to subsequent requests.
   */
  access_token?: string;
}

export type LoginPostMessage = IframeLoginPostMessage | WebViewLoginPostMessage;

export interface LoginResponse {
  profile: RawUnknownProfile;
}

export enum CorporateUserRole {
  ADMIN = 'A',
  TRAVELLER = 'T',
  TRAVEL_BOOKER = 'B',
}

export enum LoginMemberStatus {
  ACTIVE = 'ACTIVE',
  TEST_MEMBER = 'TEST_MEMBER',
  INACTIVE = 'INACTIVE',
  DORMANT = 'DORMANT',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  DECEASED = 'DECEASED',
  UNKNOWN = '',
}

export interface LoginNextTier {
  flightsToNextTier: number;
  tierPointsToNextTier: number;
  nextTier?: ProfileTier;
}

export interface LoginCurrentPeriod {
  trackingPeriodEnd: string;
  trackingPeriodStart: string;
  /** tierPointsCollected is the total number of flights both ay and partners. */
  tierPointsCollected: number;
  /** Required points, earned only by Finnair flights, to gain or maintain Platinum Lumo tier. */
  aySecretTierPointsRequired?: number;
  /** Points earned only by Finnair flights, currently used only for Platinum Lumo tier related calculations. */
  aySecretTierPoints?: number;
  /** qualifyingFlightsFlown is actually total number of flights both ay and partners. */
  qualifyingFlightsFlown: number;
  /** Required ay only flights for maintaining Platinum Lumo tier. */
  aySecretNumQFlightRequired?: number;
  /** Finnair only flights flown, currently used only for Platinum Lumo tier calculations. */
  aySecretNumQFlightCalc?: number;
}

export interface LoginAddress {
  city: string;
  companyName?: string;
  country: string;
  postalCode: string;
  primary: boolean;
  stateOrProvince?: string;
  buildingNameOrPrefecture?: string;
  streetAddress: string;
  type: LoginAddressType;
}

export enum LoginAddressType {
  HOME = 'Home',
  JOB = 'Job',
  NONE = '',
}

export enum PostLostPasswordBy {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type CasError = 'expired_accessToken';
export interface LoginError {
  error: CasError[];
}

export type CasLoginResponse = LoginResponse | LoginError;

export interface CasFlowResponse {
  execution: string;
  message?: string | CasFlowResponseMessage[];
  route?: string;
  redirectUrl?: string;
  error?: string;
}

export interface CasFlowLostPasswordResponse {
  execution: string;
  message?: CasFlowResponseMessage[];
}

export interface CasFlowResponseMessage {
  severity: string;
  source: string;
  text: string;
}

export type CasFlowLoginResponseCustomData = {
  customData: {
    email: string;
  };
};

export enum ProfileRequestType {
  BASIC = 'BASIC',
  FULL = 'FULL',
}

export enum ProfileRequestCache {
  USE = 'USE',
  REFRESH = 'REFRESH',
}

export enum SiebelBoolean {
  TRUE = 'Y',
  FALSE = 'N',
}

export enum CardType {
  PAPER = 'Paper',
  PLASTIC = 'Plastic',
}

export interface CorporateDto {
  agreement1?: Record<string, string>;
  agreement2?: Record<string, string>;
  businessId?: string;
  city?: string;
  clientId?: string;
  corporateCode?: string;
  country?: string;
  language?: string;
  name?: string;
  partStatus?: PartStatus;
  prefecture?: string;
  primaryAdmin?: string;
  province?: string;
  secondaryAdmin?: string;
  state?: string;
  street?: string;
  territory?: string;
  userId?: string;
  zipcode?: string;
}

export interface ExtendedCorporateDto extends CorporateDto {
  userRole: CorporateUserRole;
}

export interface PartStatus {
  code?: 'SUCCESS' | 'OTHER_ERROR' | 'NOT_FOUND' | 'RETRY_LATER';
  versionTimestamp?: string;
}

export interface CorporateUserProfileDto {
  allowEmails?: boolean;
  birthdate?: string;
  dateOfBirth?: string;
  clientId?: string;
  contactRole?: CorporateUserRole;
  corporateCode?: string;
  email?: string;
  expires?: string;
  finnairPlus?: string;
  firstname?: string;
  issued?: string;
  jobTitle?: string;
  lastname?: string;
  mobile?: string;
  nationality?: string;
  onlineAccess?: string;
  partStatus?: PartStatus;
  passportNumber?: string;
  role?: CorporateUserRole;
  siebelId?: string;
  title?: string;
  travelClass?: string;
  userId?: string;
  workPhone?: string;
  parsedMobilePhone?: ParsedPhone;
}

export interface ExpiringPointsDto {
  date?: string;
  points?: number;
}

export interface MarketingPermissionsDto {
  suppressAllCalls?: boolean;
  suppressAllEmails?: boolean;
  suppressAllFaxes?: boolean;
  suppressAllMailings?: boolean;
  suppressAllSMS?: boolean;
  suppressPartnerPromotions?: boolean;
  suppressSMSPromos?: boolean;
  suppresseMailWeeklyOffers?: boolean;
}

export interface ParsedPhone {
  countryCode?: number;
  nationalNumber?: number;
  regionCodes?: string[];
}

export interface Passport {
  passportCountry?: string;
  passportExpirationDate?: string;
  passportIssueDate?: string;
  passportNumber?: string;
}

export interface LoginTravelProfile {
  preferredBusinessDestinations?: string[];
  preferredDepartureCity?: string;
  preferredLeisureDestinations?: string[];
  receiveFlightInfoEmail?: boolean;
  receiveFlightInfoSMS?: boolean;
}

export enum ProfileTier {
  CORPORATE = 'CORPORATE',
  ACCOUNT = 'FINNAIRID',
  BASIC = 'Basic',
  JUNIOR = 'Junior Plus',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  LUMO = 'LUMO',
}

export interface LoginPartStatus {
  code: LoginPartStatusCode;
  versionTimestamp?: string;
}

export enum LoginPartStatusCode {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
}

/** The order of the enum keys matters for the form inputs layout. */
export enum LoginGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export type LoginPhaseProfile = {
  email?: string;
};

interface RawUnknownProfileBase extends Required<LoginPhaseProfile> {
  addresses?: LoginAddress[];
  awardPoints?: number;
  cardType?: string;
  childrenBirthYears?: string[];
  corporate?: CorporateDto;
  corporateUserProfile?: CorporateUserProfileDto;
  currentPeriod?: LoginCurrentPeriod;
  dateOfBirth?: string;
  eligibleActivityDate?: string;
  exchangePoints?: boolean;
  excludeMembershipExpirationFlag?: boolean;
  expirationDate?: string;
  expiringPoints?: ExpiringPointsDto[];
  ffGroupId?: string;
  ffGroupName?: string;
  firstname: string;
  freePointExchange?: boolean;
  freePointTransfer?: boolean;
  gender?: LoginGender;
  guardianEmail?: string;
  guardianMemberNumber?: string;
  guardianName?: string;
  guardianPhoneNumber?: string;
  hasCreditCard?: boolean;
  householdSize?: number;
  joinDate?: string;
  junior?: string;
  lang?: string;
  lastname: string;
  lifetimeTier?: ProfileTier.GOLD | ProfileTier.PLATINUM;
  lifetimeTierFlag?: boolean;
  lifetimeTierPoints?: number;
  marketingPermissions?: MarketingPermissionsDto;
  memberNumber?: string;
  memberStatus?: 'ACTIVE' | 'TEST_MEMBER' | 'INACTIVE' | 'DORMANT' | 'DELETED' | 'PENDING' | 'DECEASED' | 'UNKNOWN';
  membershipExpirationDate?: string;
  middlename?: string;
  mobilePhone?: string;
  nextTier?: LoginNextTier;
  parsedMobilePhone?: ParsedPhone;
  partStatus?: PartStatus;
  passport?: Passport;
  platinumGift?: string;
  preferredMealType?: string;
  preferredSeatType?: string;
  sebCard?: boolean;
  sebDcCard?: boolean;
  sebMcCard?: boolean;
  status: string;
  /**
   * statusHold can be ignored, because it is no longer offered to members and should always be false.
   * Back-end implementation still exists in case of a future use case.
   */
  statusHold?: boolean;
  tier: ProfileTier;
  topicsOfInterest?: string[];
  transferPoints?: boolean;
  travelProfile?: LoginTravelProfile;
  cardTier?: ProfileTier;
  cardExpirationDate?: string;
  oneWorldTier?: string;
  aviosConversionProcessedAt?: string;
  awardPointsAfterConversion?: number;
  awardPointsBeforeConversion?: number;
  tierPointsAfterConversion?: number;
  tierPointsBeforeConversion?: number;
  ayTierPointsAfterConversion?: number;
  ayTierPointsBeforeConversion?: number;
  lifetimePointsAfterConversion?: number;
  lifetimePointsBeforeConversion?: number;
  pastTierPeriodAyTierQualifyingPointsAfterConversion?: number;
  pastTierPeriodAyTierQualifyingPointsBeforeConversion?: number;
  pastTierPeriodTierQualifyingPointsAfterConversion?: number;
  pastTierPeriodTierQualifyingPointsBeforeConversion?: number;
}

export enum PersonalizationParameterProfileFlagName {
  AKTIA_CREDIT_CARD = 'hasAktiaVisaCreditCard',
  AKTIA_LIGHT_CREDIT_CARD = 'hasAktiaVisaLightCreditCard',
}

export type PersonalizationParameterProfileFlags = {
  [Flag in `${(typeof PersonalizationParameterProfileFlagName)[keyof typeof PersonalizationParameterProfileFlagName]}`]?: boolean;
};

export type RawUnknownProfile = RawUnknownProfileBase & PersonalizationParameterProfileFlags;

export interface Profile extends Omit<RawUnknownProfile, 'corporateUserProfile'> {
  isJuniorMember: boolean;
  isCorporate: boolean;
  corporate?: ExtendedCorporateDto;
}

export interface RawCorporateProfile {
  status: string;
  corporateUserProfile: CorporateUserProfileDto;
  corporate: CorporateDto;
  tier: ProfileTier;
  partStatus: LoginPartStatus;
}

export enum CasCustomerType {
  FPLUS_MEMBER = 'FPLUS_MEMBER',
  CORPORATE_USER = 'CORPORATE_USER',
}

export interface CasProfile {
  firstName: string;
  lastName: string;
  customerType: CasCustomerType;
  oauthClientId: string;
  previousLoginDate: string;
  lastLoginDate: string;
}

export interface CasProfileResponse {
  service: string;
  attributes: CasProfile;
  id: string;
  client_id: string;
}
