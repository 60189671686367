<div [class.pr-small]="!paxReviewCard">
  <fcom-ufo
    [airlines]="uniqueAirlineNames"
    [departure]="(ufoAirports$ | async)?.departure"
    [arrival]="(ufoAirports$ | async)?.arrival"
    [duration]="flightOrBound.duration | finDuration | async"
    [stops]="stops"
    [showViewDetails]="!hideViewDetails"
    [tails]="tails | finTailNames"
    [isByBusOnly]="isByBusOnly"
    (viewDetails)="viewDetails.emit()"
    [focusOnViewDetails$]="viewDetailsClosed === flightOrBound.id | finToObservable"
    [flightNumbers]="flightNumbers"
  ></fcom-ufo>

  <div [ngClass]="{ 'card-wrapper flex flex--align-start flex--space-between': paxReviewCard }">
    <div *ngIf="paxReviewCard" class="ps-xsmall-t flex--column chip-and-price-container">
      <div class="chip-container flex flex--wrap flex--align-start">
        <fcom-tag data-testid="flight-review-card-fare-family-label" *ngIf="fareFamily || cabinClass">
          <span class="truncate overflow-hidden">{{ fareFamily ?? (cabinClass | finLocalization) }}</span>
        </fcom-tag>
        <fcom-tag
          *ngIf="paxReviewCard && isFlightUsingSAF && enableShowingSAF"
          data-testid="flight-review-card-saf-notification"
          [theme]="TagTheme.SUSTAINABILITY"
        >
          <span class="truncate overflow-hidden">{{ 'safNotification.chipText' | finLocalization }}</span>
        </fcom-tag>
      </div>
    </div>

    <div class="button-container">
      <fcom-button
        *ngIf="!hideModify"
        class="pr-small-t flex--self-end"
        (clickBtn)="modifyClicked.emit()"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [mode]="ButtonMode.FILL_MOBILE"
        [text]="'actions.modify' | finLocalization"
        [aria]="{ label: ('actions.modify' | finLocalization) + ' ' + (title | lowercase), role: 'button' }"
      >
      </fcom-button>
    </div>
  </div>
</div>
