<div class="product-card white-bg grow fill flex flex--column relative">
  @if (useNewProductCardLayout) {
    <div class="rounded-large overflow-hidden border">
      <div
        class="content-container flex"
        [class.nordic-blue-400-bg]="isSpecialUpsellProduct"
        data-testid="product-card-content-container"
      >
        <div class="relative flex overflow-hidden image-container">
          <fcom-image
            *ngIf="image"
            class="picture block flex--self-stretch"
            [imageData]="image"
            [ratio]="isLaptopUp ? '16x9' : '21x9'"
            [scale]="false"
            [fillParent]="isLaptopUp"
          ></fcom-image>
        </div>
        <div class="flex-1 flex flex--column pr-small-x ps-medium-y">
          <h2 class="font-heading-4" [class.white-text]="isSpecialUpsellProduct" data-testid="product-card-title">
            {{ title }}
          </h2>
          <div class="flex-1 flex flex--row flex--space-between">
            <div class="flex-1 flex flex--column flex--self-end">
              @if (!countText) {
                @if (lowestPrice) {
                  <div class="flex flex--middle">
                    <span
                      class="font-body-2"
                      [class.white-text]="isSpecialUpsellProduct"
                      data-testid="product-card-starting-from-label"
                      >{{ 'pricesStartingFrom' | finLocalization }}</span
                    >
                    @if (originalPrice) {
                      <span
                        class="font-body-2 strike-through ps-xxsmall-l"
                        [class.white-text]="isSpecialUpsellProduct"
                        data-testid="product-card-original-price"
                        >{{ originalPrice }}</span
                      >
                    }
                  </div>
                  <span
                    [class.heather-700-text]="originalPrice && !isSpecialUpsellProduct"
                    [class.white-text]="isSpecialUpsellProduct"
                    class="font-heading-5"
                    data-testid="product-card-price"
                    >{{ lowestPrice }}</span
                  >
                }

                @if (productTagText && productTagIcon) {
                  <fcom-tag data-testid="product-card-product-tag" [theme]="productTagTheme">
                    <fcom-icon class="icon-small" [name]="productTagIcon"></fcom-icon>
                    <span class="ms-xxsmall-l">{{ productTagText }}</span>
                  </fcom-tag>
                }
              }
            </div>
            <fcom-button
              class="block ps-xlarge-t flex--self-end"
              [mode]="ButtonMode.INLINE"
              [loading]="loading"
              [theme]="buttonTheme"
              [text]="buttonText"
              [icon]="{ name: buttonIcon, position: IconPosition.RIGHT }"
              [title]="buttonAriaText"
              [aria]="{ label: buttonAriaText, role: 'button', loadingLabel: 'actions.loading' | finLocalization }"
              [focus$]="focusOnBtn$"
              (clickBtn)="emitProductCategory($event)"
            >
            </fcom-button>
          </div>
        </div>
      </div>
      @if (countText) {
        <div class="border-top font-body-1 bold-type ps-xsmall-y pr-small-x" data-testid="product-card-count-text">
          {{ countText }}: {{ price }}
        </div>
      }

      <fcom-notification
        class="border-top"
        *ngIf="notificationText"
        [theme]="notificationTheme"
        [layout]="NotificationLayout.EDGE_TO_EDGE"
        [title]="notificationTitle"
        [iconName]="notificationIcon"
      >
        {{ notificationText }}
      </fcom-notification>
    </div>
  } @else {
    <fcom-image
      *ngIf="image"
      class="picture block rounded-top-large border-top border-left border-right border"
      [ratio]="'21x9'"
      [imageData]="image"
    ></fcom-image>

    <div
      class="flex-1 pr-small-x ps-medium-y flex flex--column rounded-bottom-large border-bottom border-left border-right border"
    >
      <h2 class="nordic-blue-900-text font-heading-4">{{ title }}</h2>

      @if (lowestPrice) {
        <div class="flex flex--middle">
          <span class="font-body-2 ps-xxsmall-r" data-testid="product-card-starting-from-label">{{
            'pricesStartingFrom' | finLocalization
          }}</span>
          <span
            [ngClass]="{
              'font-heading-5': !originalPrice,
              'strike-through': originalPrice,
              'font-body-2': originalPrice,
            }"
            data-testid="product-card-starting-from-price"
            >{{ originalPrice ? originalPrice : lowestPrice }}</span
          >
          @if (originalPrice) {
            <span class="font-heading-5 heather-700-text ps-xxsmall-l" data-testid="product-card-discounted-price">{{
              lowestPrice
            }}</span>
          }
        </div>
      }

      @if (productTagText && productTagIcon) {
        <fcom-tag data-testid="product-card-product-tag" [theme]="productTagTheme">
          <fcom-icon class="icon-small" [name]="productTagIcon"></fcom-icon>
          <span class="ms-xxsmall-l">{{ productTagText }}</span>
        </fcom-tag>
      }

      <p *ngIf="detailText" class="ps-xsmall-t ps-small-b font-body-1 flex-1">{{ detailText }}</p>

      <ng-container *ngIf="statusText || purchasedText">
        <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />

        <div class="ps-small-y align-right">
          <div class="nordic-blue-900-text font-body-1" *ngIf="purchasedText">
            {{ purchasedText }}
          </div>
          <div
            class="font-body-1"
            [ngClass]="{
              'medium-type': hasAddedItems,
              'nordic-nordic-blue-900-100-text': hasAddedItems,
              'nordic-blue-900-text': !hasAddedItems,
            }"
          >
            {{ statusText }}
          </div>
          <div *ngIf="price" class="font-body-1 nordic-blue-900-text">{{ price }}</div>
        </div>

        <hr class="border-top-dashed nordic-blue-50-border" aria-hidden="true" />
      </ng-container>

      <div class="align-center">
        <fcom-button
          *ngIf="!hideButton"
          class="block"
          [ngClass]="{ 'ps-small-y': !hideViewButton, 'ps-small-t': hideViewButton }"
          [mode]="ButtonMode.FILL"
          [loading]="loading"
          [theme]="buttonTheme"
          [text]="buttonText"
          [icon]="{ name: buttonIcon, position: IconPosition.RIGHT }"
          [title]="buttonAriaText"
          [aria]="{ label: buttonAriaText, role: 'button', loadingLabel: 'actions.loading' | finLocalization }"
          [focus$]="focusOnBtn$"
          (clickBtn)="emitProductCategory($event)"
        >
        </fcom-button>

        <fcom-notification
          *ngIf="notificationText"
          class="block ps-small-y ps-small-t align-left"
          [theme]="notificationTheme"
          [title]="notificationTitle"
          [iconName]="notificationIcon"
        >
          {{ notificationText }}
        </fcom-notification>

        <button
          #viewSummaryBtn
          class="view-details nordic-blue-900-text underline font-body-1"
          *ngIf="!hideViewButton"
          (click)="emitViewDetails($event)"
          [attr.aria-label]="viewSummaryText + ' ' + title"
        >
          {{ viewSummaryText }}
        </button>
      </div>
    </div>
  }
</div>
