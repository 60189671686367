<div
  class="fare-family-option rounded-large white-bg flex flex--column grow border relative"
  [style.outline-color]="extendedFareFamily.selected ? extendedFareFamily.benefits.brandColor : ''"
  [ngClass]="{
    selected: extendedFareFamily.selected,
  }"
>
  @if (extendedFareFamily.badgeType) {
    <div class="absolute badge z1 ms-xsmall-t ms-xsmall-r">
      @if (extendedFareFamily.badgeType !== FareFamilyBadgeType.CMS) {
        <fcom-tag [theme]="tagTheme$ | async">
          @if (extendedFareFamily.badgeType === FareFamilyBadgeType.POPULAR) {
            {{ 'ticketSelection.fareFamily.ribbonText' | finLocalization }}
          } @else if (extendedFareFamily.badgeType === FareFamilyBadgeType.NEW) {
            {{ 'booking.newFareFamilyLabel' | finLocalization }}
          }
        </fcom-tag>
      } @else {
        @for (tag of extendedFareFamily.benefits.tags; track tag.text) {
          <fcom-tag [theme]="tagTheme$ | async" [roundingStyle]="castTagRoundingStyle(tag.roundingStyle)">
            @if (tag.icon && (tag.iconPosition === 'left' || tag.iconPosition === undefined)) {
              <fcom-icon
                [name]="castIconName(tag.icon.name)"
                [category]="castIconCategory(tag.icon.category)"
                class="icon-small ms-xxsmall-r"
              ></fcom-icon>
            }
            <span>{{ tag.text }}</span>
            @if (tag.icon && tag.iconPosition === 'right') {
              <fcom-icon
                [name]="castIconName(tag.icon.name)"
                [category]="castIconCategory(tag.icon.category)"
                class="icon-small ms-xxsmall-r"
              ></fcom-icon>
            }
          </fcom-tag>
        }
      }
    </div>
  }
  <button
    class="fare-header border-bottom overflow-hidden rounded-top-large flex--column flex--align-stretch relative"
    [style.--hover-color]="extendedFareFamily.benefits.brandColor"
    [ngClass]="{ 'nordic-blue-25-bg': !extendedFareFamily.selected }"
    [style.background-color]="extendedFareFamily.selected ? extendedFareFamily.benefits.brandColor : ''"
    (click)="handleSelectFareFamilyClick($event, extendedFareFamily)"
    data-testid="fare-family-option-select-button"
  >
    <div class="flex flex--column ps-small">
      <fcom-icon
        class="icon-medium circle"
        [ngClass]="{
          'border white-border nordic-blue-900-bg white-text selected': extendedFareFamily.selected,
          'nordic-blue-900-border white-bg': !extendedFareFamily.selected,
        }"
        [name]="extendedFareFamily.selected ? SvgLibraryIcon.CHECKMARK : SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
      >
      </fcom-icon>
      <div class="flex flex--column ms-xsmall-t">
        <span
          class="font-body-1 medium-type align-left brand-name"
          [ngClass]="extendedFareFamily.selected ? 'white-text' : 'nordic-blue-900-text'"
        >
          {{ extendedFareFamily.benefits.brandName }}</span
        >
        <div
          class="fare-family-option-price align-left"
          [ngClass]="{
            'font-heading-3': !extendedFareFamily.points,
            'font-heading-4': extendedFareFamily.points,
          }"
        >
          <span
            class="bold-type"
            [ngClass]="
              extendedFareFamily.selected
                ? 'white-text'
                : hasSpecialOffer || (extendedFareFamily.originalPrice && extendedFareFamily.discountReasonCode)
                  ? 'heather-700-text'
                  : 'blue-900-text'
            "
          >
            <span *ngIf="extendedFareFamily.points"
              >{{
                'award.points' | finLocalization: { pointsAmount: +extendedFareFamily.points || 0 | finPoints }
              }}
              +</span
            >
            {{ extendedFareFamily.price | finToObservable | finOffersPrice: currencyCode$ | async }}
          </span>
          <span
            *ngIf="extendedFareFamily.originalPrice && extendedFareFamily.discountReasonCode"
            [ngClass]="!extendedFareFamily.selected ? 'nordic-blue-900-text' : 'white-text'"
            class="font-body-2 ps-xsmall-l strike-through"
            data-testid="fare-family-original-price"
          >
            {{ extendedFareFamily.originalPrice | finToObservable | finOffersPrice: currencyCode$ | async }}
          </span>
        </div>
      </div>
    </div>
    <div
      [style]="{ 'background-color': extendedFareFamily.benefits.brandColor }"
      class="top-border absolute fill"
    ></div>
  </button>

  <div class="flex flex--column flex-1 ps-small-t ps-small-b">
    <div
      *ngIf="extendedFareFamily.benefits.brandNameDescription && enableBenefitChanges === false"
      class="punchline flex--basis80 ps-small-x"
      [ngClass]="(isMobile$ | async) === true ? 'font-body-1' : 'font-body-2'"
    >
      {{ extendedFareFamily.benefits.brandNameDescription }}
    </div>
    <ng-container *ngIf="isNotAward">
      <fcom-tag class="ms-small-l">
        <fcom-icon class="ms-xxsmall-r icon-small" [name]="SvgLibraryIcon.AVIOS_CURRENCY"></fcom-icon>
        <span *ngIf="extendedFareFamily.totalAviosAccrual">
          {{ 'avios.collectPointsTag' | finLocalization: { points: extendedFareFamily.totalAviosAccrual | finPoints } }}
        </span>
        <span *ngIf="!extendedFareFamily.totalAviosAccrual">
          {{ 'avios.collectUndefinedPoints' | finLocalization }}
        </span>
      </fcom-tag>
    </ng-container>
    <fin-expand
      [id]="'benefit-accordion'"
      class="flex flex--column flex-1"
      [ngClass]="{ 'ms-small-t': isNotAward }"
      [key]="accordionId"
      [expandContentClass]="'flex flex-1'"
      [expandContainerClass]="'flex flex-1'"
      #expand
      *ngIf="(isMobile$ | async) === true && enableBenefitChanges === false"
      (expandOpen)="toggleExpand($event)"
    >
      <div class="trigger flex nordic-blue-900-text font-body-1 medium-type underline">
        <div class="ps-small-x">
          <ng-container *ngIf="expandOpen">
            {{ 'ticketSelection.hideDetails' | finLocalization }}
          </ng-container>
          <ng-container *ngIf="!expandOpen">
            {{ 'ticketSelection.showDetails' | finLocalization }}
          </ng-container>
        </div>
        <div class="ps-xxsmall-l flex-1 flex--nogrow flex--self-start ps-small-x">
          <fcom-icon
            class="rotate icon-medium"
            [class.rotate-active]="expandOpen"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
          ></fcom-icon>
        </div>
      </div>
      <div class="content border-top-dashed ms-small-t flex flex--column">
        <ng-container
          [ngTemplateOutlet]="benefits"
          [ngTemplateOutletContext]="{ highlightedBenefits: highlightedBenefits }"
        />
      </div>
    </fin-expand>

    <ng-container
      *ngIf="(isMobile$ | async) === false || enableBenefitChanges === true"
      [ngTemplateOutlet]="benefits"
      [ngTemplateOutletContext]="{ highlightedBenefits: highlightedBenefits }"
    >
    </ng-container>

    <ng-template #benefits let-highlightedBenefits="highlightedBenefits">
      <div class="benefits-list flex-1 benefits">
        <fin-benefit
          class="block ps-small-x"
          *ngFor="let benefit of highlightedBenefits; let first = first"
          [benefit]="benefit"
          [enableBenefitChanges$]="enableBenefitChanges | finToObservable"
          [ngClass]="{
            'ps-xsmall-t ps-xxsmall-b': enableBenefitChanges && first,
            'ps-xxsmall-y': !enableBenefitChanges || !first,
          }"
        ></fin-benefit>
      </div>
    </ng-template>
  </div>
</div>
