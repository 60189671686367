import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { asPaxTypeKey } from '@fcom/dapi/utils';
import { FinnairPassengerCode, FinnairPassengerItem } from '@fcom/dapi/api/models';
import { ButtonTheme, IconPosition, TagRoundingStyle } from '@fcom/ui-components';

import { FrequentFlyerCardItemWithTranslations } from '../../interfaces/passenger.interface';
import { getFrequentFlyerLabelForNonAyCard, getOneWorldTierName, getTierName } from '../../utils/passenger.utils';

@Component({
  selector: 'fin-passenger-info',
  templateUrl: 'passenger-info.component.html',
  styleUrls: ['./passenger-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerInfoComponent implements OnInit, OnChanges {
  readonly ButtonTheme = ButtonTheme;
  readonly FinnairPassengerCode = FinnairPassengerCode;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagRoundingStyle = TagRoundingStyle;

  @Input()
  passenger: FinnairPassengerItem;

  @Input()
  accompanyingAdult: FinnairPassengerItem;

  @Input()
  accompanyingInfant: FinnairPassengerItem;

  @Input()
  showEdit = false;

  @Output()
  edit: EventEmitter<void> = new EventEmitter();

  extendedPassenger: FinnairPassengerItem & {
    paxTypeKey: string;
    cards: FrequentFlyerCardItemWithTranslations[];
  };

  ngOnInit(): void {
    this.updatePassenger();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passenger']) {
      this.updatePassenger();
    }
  }

  private updatePassenger(): void {
    this.extendedPassenger = {
      ...this.passenger,
      paxTypeKey: asPaxTypeKey(this.passenger.passengerTypeCode).toLowerCase(),
      cards: (this.passenger.frequentFlyerCards ?? []).map((card) => ({
        ...card,
        translationKey: getFrequentFlyerLabelForNonAyCard(card),
        tierLevelName: getTierName(card),
        oneWorldTierName: getOneWorldTierName(card),
      })),
    };
  }
}
