import { createAction, props, union } from '@ngrx/store';

import {
  CasProfile,
  LoginAccessToken,
  LoginPhaseError,
  LoginStep,
  LoginPhaseProfile,
  Profile,
} from '@fcom/core-api/login';

export const setAccessToken = createAction('[LoginActions] SetToken', props<{ accessToken: LoginAccessToken }>());
export const clearAccessToken = createAction('[LoginActions] ClearToken');
export const setProfile = createAction('[LoginActions] SetProfile', props<{ profile: Profile }>());
export const setCasProfile = createAction('[LoginActions] SetCasProfile', props<{ casProfile: CasProfile }>());
export const setLoginError = createAction('[LoginActions] Error');
export const setNotLoggedIn = createAction('[LoginActions] SetNotLoggedIn');
export const setLoginPhaseExecutionToken = createAction('[LoginActions] SetExecutionToken', props<{ token: string }>());

export const setLoginPhaseStep = createAction('[LoginActions] setLoginPhaseStep', props<{ step: LoginStep }>());
export const setLoginPhaseProfile = createAction(
  '[LoginActions] SetLoginPhaseProfile',
  props<{ profile: LoginPhaseProfile }>()
);
export const setLoginPhaseError = createAction(
  '[LoginActions] setLoginPhaseError',
  props<{ error: LoginPhaseError }>()
);
export const clearLoginPhase = createAction('[LoginActions] clearLoginPhase');

const all = union({
  setAccessToken,
  clearAccessToken,
  setProfile,
  setLoginError,
  setNotLoggedIn,
  clearLoginPhase,
  setLoginPhaseStep,
  setLoginPhaseProfile,
  setLoginPhaseError,
  setLoginPhaseExecutionToken,
  setCasProfile,
});

export type LoginActionUnion = typeof all;
