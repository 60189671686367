<div class="ms-xlarge-b">
  <cms-unstyled-fragment [contentUrl$]="translatedContentUrl$" [showIcon]="true" class="block"></cms-unstyled-fragment>
</div>
<div class="flex flex--middle flex--space-between flex--nowrap font-body-1 ms-medium-b">
  <div class="flex flex--wrap">
    <div class="ps-xxsmall-r" [innerHTML]="'bookingWidgetTabs.carTrawler.poweredBy' | finLocalization"></div>
    <fcom-icon class="cartrawler-logo" [category]="IconLibrary.CUSTOM" [name]="CustomIcon.CARTRAWLER_LOGO"></fcom-icon>
  </div>
  <fcom-button
    [text]="'bookingWidgetTabs.carTrawler.ctaText' | finLocalization"
    [icon]="{ name: SvgLibraryIcon.EXTERNAL_TAB, position: IconPosition.RIGHT }"
    [href]="carTrawlerUrlRedirect"
    [openInNewTab]="true"
    [externalLink]="true"
    (click)="trackClick()"
  ></fcom-button>
</div>

<fcom-notification [layout]="NotificationLayout.BLOCK" [iconName]="SvgLibraryIcon.AVIOS_PLECTRUM">
  <p class="medium-type">
    {{ 'bookingWidget.carTrawler.aviosNotification' | finLocalization }}
  </p>
</fcom-notification>
