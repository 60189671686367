<section class="hero grid-col-12 ps-clear">
  @if (content.teaserTitle) {
    <cms-heading-tag class="col-1-12 hero-title" [level]="1" extraClasses="nordic-blue-900-text">{{
      content.teaserTitle
    }}</cms-heading-tag>
  }

  @if (content.teaserText && content.contentType === CmsContentType.CMArticle) {
    <div class="col-1-12 md-col-1-8 hero-text" [innerHTML]="content.detailText"></div>
  }

  @if (content.media) {
    @for (image of content.media; track image.contentId) {
      <figure class="col-1-12 ms-clear">
        <fin-content-image
          class="block image-height"
          [imageData$]="image | finToObservable"
          [aspectRatio$]="'21x9' | finToObservable"
        ></fin-content-image>
      </figure>
    } @empty {}
  }

  @for (item of content.related; track item.contentId) {
    <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
  } @empty {}
</section>
