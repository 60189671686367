import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  ViewChild,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  Observable,
  of,
  Subject,
  distinctUntilChanged,
  filter,
  switchMap,
  EMPTY,
  Subscription,
  withLatestFrom,
  BehaviorSubject,
  tap,
  delay,
  throttleTime,
} from 'rxjs';
import { Store } from '@ngrx/store';

import { TARGET_HEIGHT_ELEMENT } from '@fcom/common';
import { MediaQueryService } from '@fcom/common/services';
import { BookingHeaderSummary } from '@fcom/common/interfaces';
import {
  ButtonType,
  ButtonSize,
  IconPosition,
  ButtonTheme,
  PopoverService,
  IconButtonSize,
  IconButtonTheme,
} from '@fcom/ui-components';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { PseudoLocalActions } from '@fcom/core/actions';
import { ExpandComponent } from '@fcom/common/components';
import { isEmptyObjectOrHasEmptyValues } from '@fcom/core';

import { BOOKING_WIDGET_CONTEXT, GLOBAL_BOOKING_WIDGET_EXPANDER_KEY } from '../../constants';
import { WidgetLayout, ExpanderStatus } from '../../interfaces';
import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { BookingWidgetService } from '../../services/booking-widget.service';

@Component({
  selector: 'fin-global-booking-widget-summary-header',
  templateUrl: './global-booking-widget-summary-header.component.html',
  styleUrls: ['./global-booking-widget-summary-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalBookingWidgetSummaryHeaderComponent implements OnInit, AfterViewInit {
  readonly EXPANDER_KEY = 'global-booking-widget-expander';
  readonly TARGET_HEIGHT_ELEMENT = TARGET_HEIGHT_ELEMENT;
  readonly SCROLL_INITIAL_DELAY_TIME = 2000;
  readonly SCROLL_CLOSE_WIDGET_DELAY_TIME = 100;
  readonly ButtonSize = ButtonSize;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ExpanderStatus = ExpanderStatus;
  readonly WidgetLayout = WidgetLayout;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  isMobile$: Observable<boolean>;
  enableNewSearchAutomatically$: Observable<boolean>;
  lastScrollTop: number = null;
  scrollEvents$ = new Subject<Event>();
  subscription = new Subscription();
  isExpanderOpen$ = new BehaviorSubject<boolean>(false);
  modalOpen = false;

  @Input() bookingSummary: BookingHeaderSummary;
  @Input() enableModifySearch$ = of(false);

  @ViewChild('expand') expand: ExpandComponent;

  @HostListener('window:scroll', ['$event.target'])
  onScroll(event): void {
    this.scrollEvents$.next(event);
  }

  constructor(
    private mediaQueryService: MediaQueryService,
    private store$: Store<BookingAppState>,
    private popoverService: PopoverService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private bookingWidgetService: BookingWidgetService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
  }

  ngAfterViewInit(): void {
    const initTime = Date.now();
    this.subscription.add(
      this.expand.isOpen$
        .pipe(
          distinctUntilChanged(),
          tap((isOpen) => {
            this.isExpanderOpen$.next(isOpen);
          }),
          switchMap((isWidgetOpen) =>
            isWidgetOpen
              ? this.scrollEvents$.pipe(
                  throttleTime(100),
                  // filter out scroll events happening during the page load
                  filter(() => initTime + this.SCROLL_INITIAL_DELAY_TIME < Date.now()),
                  delay(this.SCROLL_CLOSE_WIDGET_DELAY_TIME)
                )
              : EMPTY
          ),
          withLatestFrom(this.isMobile$, this.bookingWidgetService.globalBookingWidgetSelectionChanges$),
          filter(([_, isMobile, hasChanges]) => !isMobile && isEmptyObjectOrHasEmptyValues(hasChanges))
        )
        .subscribe(([event]) => {
          this.handleScroll(event);
        })
    );

    this.subscription.add(
      this.expand.isOpen$.subscribe((isOpen) => {
        this.popoverService.closeByContext(BOOKING_WIDGET_CONTEXT);
        this.modalOpen = isOpen;
      })
    );

    this.subscription.add(
      this.isMobile$.pipe(filter((isMobile) => !isMobile)).subscribe(() => {
        this.dispatchExpanderStatus(ExpanderStatus.OPEN);
      })
    );
  }

  dispatchExpanderStatus(expandStatus: ExpanderStatus): void {
    this.store$.dispatch(
      PseudoLocalActions.setValue({
        key: GLOBAL_BOOKING_WIDGET_EXPANDER_KEY,
        data: `${GLOBAL_BOOKING_WIDGET_EXPANDER_KEY}-${expandStatus}`,
      })
    );
  }

  modifySearchDetails(): void {
    if (!this.modalOpen) {
      this.bookingWidgetGtmService.trackElementEvent('global-widget-modify-search-details');
      this.dispatchExpanderStatus(ExpanderStatus.OPEN);
    }
  }

  toggleDetails(): void {
    this.bookingWidgetGtmService.trackElementEvent(
      `global-widget-${this.isExpanderOpen$.getValue() ? 'cancel-search' : 'modify-search'}`
    );
    this.dispatchExpanderStatus(this.isExpanderOpen$.getValue() ? ExpanderStatus.CLOSE : ExpanderStatus.OPEN);
  }

  private handleScroll(event): void {
    const currentScrollTop = event.scrollingElement?.scrollTop ?? 0;
    if (this.lastScrollTop === null) {
      this.lastScrollTop = currentScrollTop;
      return;
    }
    if (currentScrollTop - this.lastScrollTop > 50) {
      this.dispatchExpanderStatus(ExpanderStatus.CLOSE);
      this.lastScrollTop = null;
    } else if (this.lastScrollTop - currentScrollTop > 50) {
      this.dispatchExpanderStatus(ExpanderStatus.CLOSE);
      this.lastScrollTop = null;
    }
  }
}
