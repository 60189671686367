import { FinnairFrequentFlyerCardItem } from '@fcom/dapi/api/models';
import { capitalize, FREQUENT_FLIER_AIRLINES } from '@fcom/core/utils';
import { Airline } from '@fcom/common/interfaces/airlines';

import { getCMSTier } from './service.utils';

export const getFrequentFlyerLabelForNonAyCard = (card: FinnairFrequentFlyerCardItem): string | undefined => {
  if (card.companyCode !== Airline.FINNAIR) {
    const companyCode = card.companyCode.toLowerCase();
    const companyBaseLabel = 'purchase.review.frequentFlyer';
    if (FREQUENT_FLIER_AIRLINES.some(({ code }) => code.toLowerCase() === companyCode)) {
      return `${companyBaseLabel}.${companyCode}`;
    }
    return `${companyBaseLabel}.default`;
  }
};

export const getTierName = (frequentFlyerCard: FinnairFrequentFlyerCardItem): string => {
  return capitalize(getCMSTier(frequentFlyerCard.tierLevel ?? '')?.toLowerCase() ?? '');
};

const ONEWORLD_TIERS = {
  EMER: 'Emerald',
  SAPP: 'Sapphire',
  RUBY: 'Ruby',
};

export const getOneWorldTierName = (frequentFlyerCard: FinnairFrequentFlyerCardItem): string => {
  return ONEWORLD_TIERS[frequentFlyerCard.allianceTierLevel] ?? '';
};
