import { Observable } from 'rxjs';

import {
  LoginPostMessage,
  CasFlowLostPasswordResponse,
  PostLostPasswordBy,
  LoginPhaseError,
} from '@fcom/core-api/login';
import { DdsCasAdditionalParams } from '@fcom/core';

import { RedirectToLoginParams } from './utils';

export abstract class LoginService {
  /**
   * Returns a stream that emits the status of the CAS SSO session and completes directly afterwards
   * @return {Observable<boolean>}
   */
  abstract isLoggedInSsoSession(): Observable<boolean>;

  /**
   * Trigger initialization of login iframe functionality.
   * Will not trigger redirection of browser
   */
  abstract initSsoLoginSession(): void;

  /**
   * Handles the login event from the iframe.
   * @param {LoginPostMessage} event
   */
  abstract handleLoginEvent(event: LoginPostMessage): void;

  /**
   * Redirects the user to the login page
   */
  abstract redirectToLogin(params?: RedirectToLoginParams): void;

  abstract redirectToForgotPassword(params?: RedirectToLoginParams): void;

  abstract redirectToCorporateLogin(params?: RedirectToLoginParams): void;

  /**
   * Redirects the user to the join page
   */
  abstract redirectToJoin(params?: RedirectToLoginParams): void;

  /**
   * Redirects the user to the simplified login page
   */
  abstract redirectToSimplifiedLogin(params?: RedirectToLoginParams): void;

  /**
   * Returns link to the change password page with correct params
   * @param {string} userId the AY id of the user thats password we want to change
   */
  abstract createChangePasswordUrl(memberNumber: string): string;

  /**
   * Redirects the user's browser to CAS logout page and
   * - if params is provided and set redirect url to the path
   * - if params is not provided and/or is falsy then redirect back to where you were
   */
  abstract logout(params?: RedirectToLoginParams): void;

  /**
   * Returns the URL to use for redirect to DDS MMB with CAS login session federated through CASRedirect.action.
   * @param {DdsCasAdditionalParams} additionalParams additional parameters to supply to the CASRedirect url
   */
  abstract getRedirectUrlToMmbWithCasLoginFederation(additionalParams?: DdsCasAdditionalParams): string;

  /**
   * Get the trigger that should initialize the login iframe.
   */
  abstract get loginTrigger$(): Observable<string>;

  abstract login(user: string, password: string, rememberMe: boolean): void;

  abstract login2fa(code: string, submitPin?: boolean): void;

  abstract resend2faEmail(): Observable<LoginPhaseError | undefined>;

  abstract requestSMS(): void;

  abstract postLostPassword(
    sendPasswordBy: PostLostPasswordBy,
    member: string,
    lastName: string
  ): Observable<CasFlowLostPasswordResponse>;
}
