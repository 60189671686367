import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
} from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { GlobalBookingTripDates, TripType } from '@fcom/common';
import { isPresent, LocalDate, unsubscribe } from '@fcom/core/utils';
import { ButtonMode, ButtonSize, ButtonTheme, DateRange } from '@fcom/ui-components';
import { finShare } from '@fcom/rx';

import { AmAvailability, AmHolidayType, AmHolidayTypeDuration, AmLocation, AmSearchable } from '../../interfaces';

@Component({
  selector: 'fin-am-dates-selector',
  templateUrl: 'am-dates-selector.component.html',
  styleUrls: ['./am-dates-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmDatesSelectorComponent implements OnInit, OnDestroy {
  readonly AmHolidayType = AmHolidayType;
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TripType = TripType;

  @Input()
  disabled = false;

  @Input()
  travelDates$: Observable<GlobalBookingTripDates>;

  @Input()
  amDestination$: Observable<AmLocation> = of(undefined);

  @Input()
  amAvailability$: Observable<AmAvailability> = EMPTY;

  @Output()
  setTravelDates = new EventEmitter<GlobalBookingTripDates>();

  @Output()
  setDuration = new EventEmitter<AmHolidayTypeDuration>();

  subscription = new Subscription();
  modalOpen = false;
  selectedDuration$ = new BehaviorSubject<AmHolidayTypeDuration>(undefined);
  disabledDateRanges$: Observable<DateRange[]>;
  suggestedAmTravelDays$: Observable<string[]> = EMPTY;
  datePickerTitleLabel$: Observable<string>;
  calendarRange: DateRange = [LocalDate.now(), LocalDate.now().plusDays(360)];
  datesSelected$: Observable<boolean>;

  ngOnInit(): void {
    this.datePickerTitleLabel$ = combineLatest([this.amDestination$, this.travelDates$]).pipe(
      map(([{ holidayType }, { departureDate }]) => {
        if (holidayType === AmHolidayType.AM) {
          return 'bookingWidget.priceCalendar.selectDeparture.oneWay';
        }
        return isPresent(departureDate)
          ? 'bookingWidget.priceCalendar.selectReturn'
          : 'bookingWidget.priceCalendar.selectDeparture.roundTrip';
      })
    );

    this.disabledDateRanges$ = this.amDestination$.pipe(
      switchMap((amDestination) => {
        if (amDestination.holidayType === AmHolidayType.AM) {
          return of(this.calculateDisabledDatesBasedOnSearchables(amDestination.searchable));
        }

        return combineLatest([this.amAvailability$, this.travelDates$]).pipe(
          map(([amAvailability, travelDates]) => this.getDisabledDatesForDpHolidayType(amAvailability, travelDates))
        );
      }),
      finShare()
    );

    this.datesSelected$ = this.travelDates$.pipe(
      map((travelDates) => isPresent(travelDates?.departureDate) && isPresent(travelDates?.returnDate))
    );

    this.suggestedAmTravelDays$ = combineLatest([this.amAvailability$, this.selectedDuration$]).pipe(
      filter(([availability, duration]) => !!availability && !!duration),
      map(([availability, duration]) =>
        Object.entries(availability)
          .filter((dayEntry) => !!dayEntry[1][duration.code])
          .map((dayEntry) => dayEntry[0])
      ),
      finShare()
    );
  }

  selectDuration(duration: AmHolidayTypeDuration): void {
    this.selectedDuration$.next(duration);
    this.setDuration.emit(duration);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  openModal(): void {
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  updateCalendarDates(calendarDates: [string] | [string, string]): void {
    const [departureDate, returnDate] = calendarDates;

    // Currently the calendar emits (selectedDatesChange) events even when hovering on dates (on every render), so we'll limit
    // unnecessary emissions here by checking that the values have changed
    this.subscription.add(
      this.travelDates$
        .pipe(
          take(1),
          filter(
            (travelDates) =>
              departureDate !== travelDates.departureDate?.id || returnDate !== travelDates.returnDate?.id
          )
        )
        .subscribe(() => {
          this.setTravelDates.emit({
            departureDate: departureDate ? new LocalDate(departureDate) : undefined,
            returnDate: returnDate ? new LocalDate(returnDate) : undefined,
          });
        })
    );
  }

  private calculateDisabledDatesBasedOnSearchables(searchables: AmSearchable[]): DateRange[] {
    return [...searchables]
      .sort((a, b) => (a.from > b.from ? 1 : a.from === b.from ? 0 : -1))
      .reduce((disabledDateRanges: DateRange[], searchable, currentIndex, allSearchables) => {
        const returnValue = disabledDateRanges;

        // disable all days before first searchable's "from" date
        if (currentIndex === 0) {
          returnValue.push([LocalDate.now(), new LocalDate(searchable.from).minusDays(1)]);
        }

        // disable all days after last searchable's "to" date
        if (currentIndex === allSearchables.length - 1) {
          returnValue.push([new LocalDate(searchable.to).plusDays(1), new LocalDate(searchable.to).plusYears(1)]);
        } else {
          // disable days between current and next searchable if not last
          returnValue.push([
            new LocalDate(searchable.to).plusDays(1),
            new LocalDate(allSearchables[currentIndex + 1].from).minusDays(1),
          ]);
        }

        return returnValue;
      }, []);
  }

  private getDisabledDatesForDpHolidayType(
    amAvailability: AmAvailability,
    travelDates: GlobalBookingTripDates
  ): DateRange[] {
    if (travelDates.departureDate && !travelDates.returnDate) {
      return this.calculateDisabledDaysBasedOnDepartureAndAvailableDurations(amAvailability, travelDates.departureDate);
    }
    return this.calculateDisabledDaysBasedOnAvailableDays(Object.keys(amAvailability));
  }

  private calculateDisabledDaysBasedOnDepartureAndAvailableDurations(
    amAvailability: AmAvailability,
    departureDate: LocalDate
  ): DateRange[] {
    return Object.keys(amAvailability).length > 0
      ? this.calculateDisabledDaysBasedOnAvailableDays(
          Object.keys(amAvailability[departureDate.toISOString().substring(0, 10)] || {})
            .map((duration) => parseInt(duration, 10))
            .sort((a, b) => a - b)
            .reduce((dates: LocalDate[], duration) => [...dates, departureDate.plusDays(duration)], [departureDate])
            .map((date) => date.toISOString().substring(0, 10))
        )
      : [];
  }

  private calculateDisabledDaysBasedOnAvailableDays(days: string[]): DateRange[] {
    return [...days]
      .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
      .reduce((disabledDateRanges: DateRange[], availableDay, currentIndex, availableDays) => {
        const returnValue = disabledDateRanges;

        if (currentIndex === 0) {
          // disable days before first available date
          returnValue.push([LocalDate.now(), new LocalDate(availableDay).minusDays(1)]);
        }

        // disable all days after last available day
        if (currentIndex === availableDays.length - 1) {
          returnValue.push([new LocalDate(availableDay).plusDays(1), new LocalDate(availableDay).plusYears(1)]);
        } else if (new LocalDate(availableDay).plusDays(1).lt(new LocalDate(availableDays[currentIndex + 1]))) {
          // disable days between current and next available day if not last
          returnValue.push([
            new LocalDate(availableDay).plusDays(1),
            new LocalDate(availableDays[currentIndex + 1]).minusDays(1),
          ]);
        }

        return returnValue;
      }, []);
  }
}
