<div class="location-selector flex relative fill grow">
  <fin-booking-widget-selector
    class="flex--basis-half location-button"
    [label]="'bookingSearch.from.title' | finLocalization"
    [disabled]="originDisabled || disabled"
    [position]="'left'"
    [filled]="!!(locationPair$ | async)?.origin"
    [placeholder]="'bookingSearch.boundSelection.originPlaceholder' | finLocalization"
    [attr.data-testid]="'location-selector-origin'"
    [popoverContent]="popoverContent"
    [popoverOptions]="originPopoverOptions"
    (click)="handleOpen('origin')"
    (close)="handleClose()"
    data-testid="location-selector-origin"
  >
    <ng-container
      *ngTemplateOutlet="locationInfo; context: { location: (locationPair$ | async)?.origin }"
    ></ng-container>
  </fin-booking-widget-selector>

  <ng-container *ngIf="showSeparator">
    <fcom-icon-button
      class="flex flex--center flex--self-center absolute location-switch"
      [icon]="{ name: SvgLibraryIcon.SWITCH_FLIGHTS }"
      [theme]="IconButtonTheme.LIGHT_SECONDARY_OUTLINE"
      [size]="IconButtonSize.MEDIUM"
      [aria]="{ label: 'bookingSearch.aria.fromToSwitch' | finLocalization }"
      (clickBtn)="locationsSwitched.emit()"
      data-testid="switch-locations"
    >
    </fcom-icon-button>
  </ng-container>

  <fin-booking-widget-selector
    class="flex--basis-half location-button"
    [label]="'bookingSearch.to.title' | finLocalization"
    [disabled]="destinationDisabled || disabled"
    [position]="'right'"
    [filled]="!!(locationPair$ | async)?.destination"
    [placeholder]="'bookingSearch.boundSelection.destinationPlaceholder' | finLocalization"
    [attr.data-testid]="'location-selector-destination'"
    [popoverContent]="popoverContent"
    [popoverOptions]="destinationPopoverOptions"
    (click)="handleOpen('destination')"
    (close)="handleClose()"
    data-testid="location-selector-destination"
  >
    <ng-container
      *ngTemplateOutlet="locationInfo; context: { location: (locationPair$ | async)?.destination }"
    ></ng-container>
  </fin-booking-widget-selector>
</div>

<ng-template #popoverContent>
  <div class="scroll rounded-large" *ngIf="locationType$ | async as locationType">
    <header class="popover-header fill relative flex--column ps-medium-y ps-medium-x">
      <div class="flex--nogrow flex--basis-header flex flex--row flex--center">
        <h2
          class="font-heading-4 flex-1 truncate overflow-hidden nordic-blue-900-text"
          [attr.id]="'popover-' + originPopoverOptions.popoverID + '-title'"
        >
          {{ 'bookingSearch.' + (locationType === 'origin' ? 'from' : 'to') + '.title' | finLocalization }}
        </h2>
        <button
          class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset"
          [attr.title]="'actions.close' | finLocalization"
          [attr.aria-label]="'actions.close' | finLocalization"
          (click)="closePopover()"
        >
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
        </button>
      </div>
    </header>
    <ng-container *ngTemplateOutlet="locations; context: { locationType: locationType, popover: true }"></ng-container>
  </div>
</ng-template>

<fcom-modal
  *ngIf="modalOpen"
  [(open)]="modalOpen"
  [title]="'bookingSearch.' + ((locationType$ | async) === 'origin' ? 'from' : 'to') + '.title' | finLocalization"
  [fitToContent]="false"
  [buttons]="null"
  (close)="handleClose()"
  data-testid="location-modal"
>
  <ng-container
    *ngTemplateOutlet="locations; context: { locationType: (locationType$ | async), popover: false }"
  ></ng-container>
</fcom-modal>

<ng-template #locations let-locationType="locationType" let-popover="popover">
  <div>
    <div
      class="search-form flex"
      [ngClass]="{
        'flex--middle pr-large-x ps-small-y': !popover,
        'flex--column ps-medium-x': popover,
      }"
    >
      <fcom-text-input
        class="flex-1"
        [id]="'locationSearch-input'"
        [parentForm]="locationSearchForm"
        [translationPrefix]="'bookingWidget.destinationSearch.' + (locationType === 'origin' ? 'from' : 'to')"
        [controlName]="'locationSearch'"
        [required]="false"
        [placeHolder]="'bookingSearch.' + (locationType === 'origin' ? 'from' : 'to') + '.inputPlaceholder'"
        [displayMargin]="false"
        [ariaOptions]="{ describedby: 'location-search-info' }"
        [disableAutoComplete]="true"
        (keydown.enter)="moveFocusToResults($event)"
        #searchInput
        data-testid="search-input"
        finFocus
        [autofocus]="true"
      ></fcom-text-input>

      <div *ngIf="showLocateMe && locationType === 'origin'" class="locate-me flex flex--end mr-xxsmall-l">
        <fcom-button
          *ngIf="locationType === 'origin'"
          (clickBtn)="getUserLocation()"
          [text]="'bookingSearch.locateMe' | finLocalization"
          [icon]="{
            name: SvgLibraryIcon.GPS_LOCATION,
            position: IconPosition.RIGHT,
          }"
          [mode]="ButtonMode.INLINE"
          [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
          [loading]="isGeoLocating$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          data-testid="locateMe"
        >
        </fcom-button>
      </div>

      <p id="location-search-info" class="is-vishidden">
        {{ 'bookingSearch.a11y.locationSearchDescription.' + locationType | finLocalization }}
      </p>
    </div>

    <fin-previous-searches
      *ngIf="showPreviousSearches && (enablePersonalization$ | async) === true && (queryString$ | async)?.length === 0"
      (closeLocationModal)="handleClose()"
      (focusOnSearchInput)="moveFocusToSearchInput()"
      (startSearch)="startSearch.emit()"
      [isGlobalBookingWidget]="isGlobalBookingWidget"
    ></fin-previous-searches>

    <div
      class="grey-50-bg rounded-small ps-medium-y"
      [ngClass]="{
        'pr-large-x': !popover,
        'ps-medium-x': popover,
      }"
      *ngIf="hasLocations$ | async"
      data-testid="location-list-header"
    >
      <h3
        class="font-body-1 nordic-blue-900-text"
        [attr.id]="locationType + '-list-title'"
        [tabIndex]="-1"
        #locationListHeading
      >
        <span data-testid="location-list-title">
          {{
            (queryString$ | async)?.length > 0 || isAm
              ? ((locationType === 'origin' ? 'bookingSearch.from.header' : 'bookingSearch.to.headerNoFrom')
                | finLocalization)
              : ((locationType === 'origin' ? 'bookingSearch.from.nearestAiport' : 'trendingDestinations')
                | finLocalization)
          }}
        </span>

        <span *ngIf="(queryString$ | async)?.length > 0" class="is-vishidden" data-testid="location-list-results-found">
          &nbsp;{{
            'bookingSearch.a11y.found.' + (locationType === 'origin' ? 'locations' : 'destinations')
              | finLocalization: [(locationResults$ | async)?.length]
          }}</span
        >
      </h3>
    </div>

    <ng-container *ngIf="(isLoading$ | async) === false">
      <ul
        class="flex flex--column"
        tabindex="-1"
        role="listbox"
        [attr.id]="'to-location-' + locationType"
        [attr.aria-labelledby]="locationType + '-list-title'"
        [attr.aria-activedescendant]="'to-location-' + locationType + '-' + (this.activeIndex$ | async)"
      >
        <li
          *ngFor="
            let location of (queryString$ | async)?.length > 0
              ? (locationResults$ | async)
              : (defaultLocations$ | async) as locations;
            let i = index;
            let first = first;
            let last = last
          "
          class="flex flex--middle flex--space-between focus-inset link-hover"
          [ngClass]="{
            'pr-large-x': !popover,
            'ps-medium-x': popover,
          }"
          [class.border-bottom]="!last"
          [class.nordic-blue-50-bg]="i === (this.activeIndex$ | async)"
          [class.nordic-blue-50-border]="i === (this.activeIndex$ | async)"
          [attr.id]="'to-location-' + locationType + '-' + i"
          role="none presentation"
        >
          <button
            class="flex flex--space-between flex--middle fill ps-medium-y"
            [tabindex]="
              ((this.activeIndex$ | async) === undefined && first) || (this.activeIndex$ | async) === i ? 0 : -1
            "
            [attr.aria-selected]="i === (this.activeIndex$ | async)"
            role="option"
            (click)="setLocationOrStartNewSearch(location, locationType)"
            (keydown)="handleKeyDown($event, locations)"
            (focus)="handleInitialLocationItemFocus(i)"
            #locationItem
            data-testid="location-item"
          >
            <div class="flex--column">
              <div class="font-body-1 capitalize location-title">
                <ng-container *ngTemplateOutlet="locationInfo; context: { location: location }"></ng-container>
              </div>
              <div class="font-body-2 ps-xxsmall-y nordic-blue-900-text align-left">
                <i class="flag--small flag-{{ location.countryCode }}"></i>&nbsp;{{ location.title }}
              </div>
            </div>
            <div *ngIf="!location.transportType?.includes(TransportType.Flight)">
              <fcom-icon
                *ngIf="location.transportType?.includes(TransportType.Train)"
                class="icon-medium"
                [name]="SvgLibraryIcon.TRAIN"
              ></fcom-icon>
              <fcom-icon
                *ngIf="location.transportType?.includes(TransportType.Bus)"
                class="icon-medium"
                [name]="SvgLibraryIcon.BUS"
              ></fcom-icon>
            </div>
          </button>
        </li>
      </ul>
    </ng-container>

    <div role="status" aria-live="assertive" [tabIndex]="-1">
      <p
        *ngIf="
          (queryString$ | async)?.length > 0 &&
          (isLoading$ | async) === false &&
          (locationResults$ | async)?.length === 0
        "
        class="pr-large-x pr-medium-y"
      >
        {{ 'bookingSearch.a11y.found.notFound' | finLocalization: [queryString$ | async] }}
      </p>
    </div>

    <fcom-loader *ngIf="isLoading$ | async" class="pr-large" [loaderType]="LoaderTheme.INLINE"></fcom-loader>
  </div>
</ng-template>

<ng-template #locationInfo let-location="location">
  <div *ngIf="location" class="flex fill" data-testid="location-info">
    <span class="overflow-hidden truncate">
      {{ location.cityName }}
    </span>
    <span *ngIf="!isAm">
      &nbsp;({{
        location.locationCode && location.type === 'airport'
          ? location.locationCode
          : ('bookingSearch.allAirportsLabel' | finLocalization)
      }})</span
    >
    <span class="is-vishidden">&nbsp;{{ location.country }}.</span>
  </div>
</ng-template>
