import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  CmsArticle,
  CmsCollection,
  CmsContent,
  CmsContentType,
  CmsTaxonomy,
  CmsTeaser,
  CmsViewType,
} from '@fcom/core-api';
import { isNotBlank } from '@fcom/core';

import { getFontLevel, getHeaderLevel } from '../../services/cms-utils/cms-utils';

@Component({
  selector: 'cms-articles-with-icons',
  templateUrl: './cms-articles-with-icons.component.html',
  styleUrls: ['./cms-articles-with-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsArticlesWithIconsComponent implements OnInit {
  readonly CmsContentType = CmsContentType;
  readonly CmsViewType = CmsViewType;
  readonly DEFAULT_HEADER_LEVEL = 3;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @HostBinding('class.col-1-12')
  defaultClass = true;

  @Input()
  content$: Observable<CmsCollection>;

  articles$: Observable<CmsArticle[]>;
  link$: Observable<CmsTeaser>;
  hasGridClass$: Observable<boolean>;

  public headerLevel(tags: CmsTaxonomy[]): number {
    return getHeaderLevel(tags);
  }

  public headerFontLevel(tags: CmsTaxonomy[]): string {
    return getFontLevel(tags) ? `white-text ${getFontLevel(tags)}` : 'white-text';
  }

  ngOnInit(): void {
    this.articles$ = this.content$.pipe(
      map(
        (collection: CmsCollection<CmsContent>): CmsArticle[] =>
          collection.items.filter(
            (i: CmsContent): boolean => i.contentType === CmsContentType.CMArticle
          ) as CmsArticle[]
      )
    );
    this.link$ = this.content$.pipe(
      map(
        (collection: CmsCollection<CmsContent>): CmsTeaser =>
          collection.items.find((i: CmsContent): boolean => i.contentType === CmsContentType.CMTeaser) as CmsTeaser
      ),
      filter(Boolean)
    );
    this.hasGridClass$ = this.content$.pipe(
      map((collection: CmsCollection<CmsContent>): boolean => isNotBlank(collection.gridClass))
    );
  }
}
