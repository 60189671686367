import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { TagRoundingStyle } from '../../../ui-components';
import { FrequentFlyerCardItemWithTranslations } from '../../interfaces/passenger.interface';

@Component({
  selector: 'fin-passenger-ff-cards',
  templateUrl: './passenger-ff-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerFfCardsComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagRoundingStyle = TagRoundingStyle;

  @Input() cards: FrequentFlyerCardItemWithTranslations[];
}
