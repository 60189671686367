import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  PLATFORM_ID,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { distinctUntilChanged, EMPTY, filter, map, Observable, pairwise, Subscription, throttleTime } from 'rxjs';

import { breakpoints } from '@finnairoyj/fcom-ui-styles';

import {
  findScrollContainer,
  isPresent,
  isDeepEqual,
  LocalDate,
  quantityOfMonths,
  unsubscribe,
  toMonthId,
} from '@fcom/core/utils';
import { MediaQueryService } from '@fcom/common/services/media-query/media-query.service';
import { finShare } from '@fcom/rx';
import { ScrollService } from '@fcom/common/services';
import { WindowRef } from '@fcom/core/providers';

import { Month, Day, DateSelection, CalendarViewModel } from '../../../utils/date.interface';
import { ButtonSize, ButtonTheme, IconButtonTheme, IconButtonSize } from '../../buttons';
import { CalendarService } from '../services/calendar.service';
import { TagTheme } from '../../tag';
import { CalendarNavigationType, CalendarNavigationEvent, DateRange } from '../interfaces';
import { areSelectedDatesChanged } from '../../../utils/date.utils';

// TODO: calendar header height should be read dynamic
const CALENDAR_HEADER_HEIGHT = 208;
@Component({
  selector: 'fcom-calendar',
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CalendarService],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  public readonly ButtonTheme = ButtonTheme;
  public readonly ButtonSize = ButtonSize;
  public readonly TagTheme = TagTheme;
  public readonly IconButtonTheme = IconButtonTheme;
  public readonly IconButtonSize = IconButtonSize;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
  public readonly CalendarNavigationType = CalendarNavigationType;
  public readonly CalendarNavigationEvent = CalendarNavigationEvent;

  @ViewChild('calendarMonths') calendarMonths: ElementRef;
  @ViewChildren('tag', { read: ElementRef }) tags: QueryList<ElementRef>;

  /**
   * Ranges where dates should be disabled
   * An array which includes array of two LocalDates
   */
  @Input() disabledDateRanges?: DateRange[];

  /**
   * Starting day of the calendar
   * usually is TODAY
   */
  @Input({ required: true }) minDate: LocalDate;

  /**
   * End day of the calendar
   * usually is TODAY + 360 days
   */
  @Input({ required: true }) maxDate: LocalDate;

  /**
   * Initially preselected dates
   */
  @Input() selectedDates: DateSelection;

  /**
   * Ways for navigation through months
   * arrows -> left and right arrows (single of dual month view)
   * mixed -> arrows used on desktop view and scroll used on mobile
   */
  @Input() navigationType: CalendarNavigationType = CalendarNavigationType.MIXED;

  /**
   * select range or single date
   */
  @Input() isDateRange = false;

  /**
   * Amount of months to be visible
   */
  @Input() displayMonths: 1 | 2 = 2;

  /**
   * Show month tag buttons on top of calendar
   */
  @Input() showTags = false;

  /**
   * Scroll on first selectable date on Init
   */
  @Input() scrollOnInit = true;

  /**
   * month index to be navigated to
   */
  @Input() scrollToMonthIndex$: Observable<number> = EMPTY;

  /**
   * Object of translated and localized date labels
   * (e.g. month names, date formats)
   */
  @Input({ required: true }) dateLabels: any;

  /**
   * Object of translated UI labels
   * (e.g. error messages, instructions)
   */
  @Input({ required: true }) uiLabels: any;

  @Input() dayTemplate: TemplateRef<{ dayValue: Day; dayString: number; showEnhancedCalendar: boolean }>;

  @Input() showEnhancedCalendar: boolean = false;

  @Output() monthChange: EventEmitter<Month> = new EventEmitter();

  @Output() datesSelected: EventEmitter<DateSelection> = new EventEmitter();

  visibleMonthIndex: number;
  showEmptyWeeks: boolean;
  dataModel: CalendarViewModel;
  hoveredDate: LocalDate | null = null;

  private subscription: Subscription = new Subscription();
  private breakpoints = breakpoints;
  private mobileVisibleIndex = 0;

  constructor(
    private element: ElementRef,
    private calendarService: CalendarService,
    private mediaQueryService: MediaQueryService,
    private scrollService: ScrollService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platform: object,
    private windowRef: WindowRef
  ) {
    this.subscription.add(
      this.calendarService.dataModel$.subscribe((model) => {
        const newVisibleMonthIndex =
          model.months.find(
            (month) => month.id === toMonthId(this.isVerticalScroll() ? model.focusDate : model.firstDate)
          )?.monthArrayIndex ?? 0;

        const shouldUpdateMonth = this.visibleMonthIndex !== newVisibleMonthIndex;
        const shouldUpdateDatesSelected = areSelectedDatesChanged(this.dataModel?.selectedDates, model.selectedDates);
        const shouldScrollVerticalMonth = this.isVerticalScroll() && this.dataModel?.lastDate.gte(model.lastDate);

        this.dataModel = model;
        this.cd.detectChanges();

        if (shouldUpdateMonth && isPlatformBrowser(this.platform)) {
          this.updateSelectedMonth(newVisibleMonthIndex, !this.showEnhancedCalendar, true);
        }

        if (shouldUpdateDatesSelected) {
          this.datesSelected.emit(model.selectedDates);
        }

        if (shouldScrollVerticalMonth && isPlatformBrowser(this.platform)) {
          this.updateSelectedMonth(this.mobileVisibleIndex, !shouldUpdateDatesSelected);
        }

        if (isPlatformBrowser(this.platform)) {
          this.adjustFocus();
        }

        this.visibleMonthIndex = newVisibleMonthIndex ?? 0;
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.mediaQueryService
        .isBreakpoint$('tablet_down')
        .pipe(distinctUntilChanged(), finShare())
        .subscribe((isMobile) => {
          this.showEmptyWeeks = !isMobile;
          this.calendarService.changeCalendarView(
            isMobile,
            this.dataModel.isMobile !== isMobile && !isMobile
              ? this.dataModel?.minDate?.plusMonths(this.mobileVisibleIndex)?.firstDayOfMonth
              : this.dataModel.focusDate
          );
        })
    );

    this.subscription.add(
      this.scrollToMonthIndex$.subscribe((scrollToIndex) => {
        if (isPresent(scrollToIndex) && isPresent(this.dataModel?.minDate)) {
          this.selectMonth(scrollToIndex);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calendarService.set(this.getUpdatedCalendarData(changes));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      /**
       * Listens on calendarMonths scroll and act ONLY if it is mobile and navigationType is MIXED
       * and load more months when needed
       */
      this.subscription.add(
        this.scrollService
          .listen(this.calendarMonths)
          .pipe(
            filter(
              () =>
                this.isVerticalScroll() && this.windowRef.nativeWindow.innerWidth < parseInt(this.breakpoints.laptop)
            ),
            map(() => {
              const container = findScrollContainer(this.calendarMonths.nativeElement);
              const scrollTop = container?.scrollTop ?? undefined;
              const containerHeight = container?.clientHeight
                ? container.clientHeight - CALENDAR_HEADER_HEIGHT
                : undefined;
              const scrollHeight = container?.scrollHeight
                ? container.scrollHeight - CALENDAR_HEADER_HEIGHT
                : undefined;

              if (!container || !containerHeight || !scrollHeight) {
                return undefined;
              }
              // handle month scroll for histogram
              this.determineVisibleMonthWhenScroll(container, scrollTop, containerHeight);

              // calculate how far from the bottom the user is
              return scrollHeight - (scrollTop + containerHeight);
            }),
            filter(Boolean),
            pairwise(),
            filter(([first, second]) => second < first && second < 140),
            throttleTime(100)
          )
          .subscribe(() => {
            this.handleVerticalScrollMonths(this.dataModel?.months.length + this.dataModel?.displayMonths);
          })
      );
    }
  }

  /**
   * Arrow navigation
   * @param event
   * @param eventType CalendarNavigationEvent type
   */
  onNavigateEvent(event: Event, eventType: CalendarNavigationEvent): void {
    (event.currentTarget as HTMLElement).focus();

    switch (eventType) {
      case CalendarNavigationEvent.PREV:
        this.calendarService.navigateToMonth(this.dataModel.firstDate.minusMonths(1).firstDayOfMonth);
        break;
      case CalendarNavigationEvent.NEXT:
        this.calendarService.navigateToMonth(this.dataModel.firstDate.plusMonths(1).firstDayOfMonth);
        break;
    }
  }

  /**
   * Histogram bar click or tag click
   * @param monthIndex
   */
  selectMonth(monthIndex: number): void {
    const { minDate } = this.dataModel;

    if (this.isVerticalScroll()) {
      this.handleVerticalScrollMonths(monthIndex + 1, minDate.plusMonths(monthIndex).firstDayOfMonth);
    } else {
      // navigate to the month by setting firstDate
      this.calendarService.navigateToMonth(minDate.plusMonths(monthIndex).firstDayOfMonth);
    }
  }

  /**
   * Select a day
   * @param day that is selected
   */
  selectDay(day: Day): void {
    if (!day.disabled) {
      this.calendarService.selectDate(day.date);
    }
  }

  isRange(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;

    return (
      date.equals(startDate) ||
      date.equals(endDate) ||
      (date.gt(startDate) && date.lt(endDate)) ||
      (isPresent(startDate) && !isPresent(endDate) && date.gt(startDate) && date.lt(this.hoveredDate))
    );
  }

  isInside(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;
    return date.gt(startDate) && date.lt(endDate);
  }

  isHovered(day: Day): boolean {
    const date = day.date;
    const { startDate, endDate } = this.dataModel.selectedDates;
    return (
      this.isDateRange && isPresent(startDate) && !isPresent(endDate) && date.gt(startDate) && date.lt(this.hoveredDate)
    );
  }

  private determineVisibleMonthWhenScroll(container: HTMLElement, scrollTop: number, containerHeight: number): void {
    const months = this.calendarMonths.nativeElement.children;

    let closestIndex = -1;
    let closestDistance = Number.MAX_VALUE;

    for (let i = 0; i < months.length; i++) {
      const month = months[i] as HTMLElement;
      const monthHeight = month.offsetHeight;

      const monthTop = month.offsetTop - CALENDAR_HEADER_HEIGHT;
      const monthBottom = monthTop + monthHeight;

      // calculate the distance to the viewport for the month
      let distanceToViewport: number;

      const isFirstAndScrolledToTop = i === 0 && scrollTop === 0 && monthBottom <= scrollTop + containerHeight;

      if (isFirstAndScrolledToTop || (scrollTop <= monthTop && monthBottom <= scrollTop + containerHeight)) {
        // month is fully visible in the viewport
        distanceToViewport = 0;
      } else if (monthTop <= scrollTop && scrollTop < monthBottom) {
        // month is partially visible at the top of the viewport
        distanceToViewport = scrollTop - monthTop;
      } else if (monthTop < scrollTop + containerHeight && scrollTop + containerHeight <= monthBottom) {
        // month is partially visible at the bottom of the viewport
        distanceToViewport = monthBottom - (scrollTop + containerHeight);
      } else {
        // month is outside the viewport
        distanceToViewport = Math.min(
          Math.abs(monthTop - scrollTop),
          Math.abs(monthBottom - (scrollTop + containerHeight))
        );
      }

      if (distanceToViewport <= closestDistance) {
        closestDistance = distanceToViewport;
        closestIndex = i;
      }

      if (closestDistance === 0) {
        if (scrollTop + containerHeight + CALENDAR_HEADER_HEIGHT < container.scrollHeight) {
          break;
        }
        closestIndex = months.length - 1;
      }
    }

    if (closestIndex !== this.mobileVisibleIndex) {
      // make sure that we report only once when month changes
      this.monthToChange(closestIndex, true);
    }
  }

  /**
   * Handle how months are displayed when it is vertical scroll
   * @param amount desired months
   */
  private handleVerticalScrollMonths(amount: number, focusDate?: LocalDate): void {
    const { months, minDate, maxDate } = this.dataModel;
    const maxMonths = quantityOfMonths(minDate, maxDate);
    const loadAmount = amount + 1 <= maxMonths ? amount + 1 : maxMonths;

    // month is already loaded, we need to adjust focus
    if (months.length >= loadAmount) {
      this.updateSelectedMonth(loadAmount - (months.length === loadAmount ? 1 : 2), true, true);
    } else {
      // desired month is not loaded, we need to load it first
      this.calendarService.loadMonths(loadAmount, focusDate);
    }
  }

  private updateSelectedMonth(monthIndex: number, shouldScroll = true, shouldTriggerEmission = false): void {
    this.monthWillChange(monthIndex, shouldScroll);

    this.monthToChange(monthIndex, shouldTriggerEmission);
  }

  /**
   * This combines the two scroll methods for month change
   * one is the actual scroll to the needed month when navigationType is MIXED
   * other one is the scroll of the month chips if enabled
   */
  private monthWillChange(index: number, shouldScroll: boolean): void {
    const { months } = this.dataModel;
    // this is used only by the tags
    this.visibleMonthIndex = index;

    if (this.isVerticalScroll() && index >= 0 && index <= months.length - 1 && shouldScroll) {
      this.scrollToMonth(index);
    }

    if (this.showTags) {
      this.scrollToMonthChip(index);
    }
  }

  /**
   * Emit month change event if monthIndex is present in loaded months
   * @param monthIndex
   * @param shouldTriggerEmission
   */
  private monthToChange(monthIndex: number, shouldTriggerEmission: boolean): void {
    const monthToChange = this.dataModel?.months?.find((month) => month.monthArrayIndex === monthIndex);
    if (isPresent(monthToChange) && shouldTriggerEmission) {
      this.monthChange.emit(monthToChange);
      this.mobileVisibleIndex = monthIndex;
    }
  }

  private scrollToMonthChip(index: number): void {
    // TODO: try to find more elegant way of making sure the tags are present and not using setTimeout
    setTimeout(() => {
      this.tags?.get(index)?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, 500);
  }

  private scrollToMonth(index: number): void {
    this.scrollService.smoothScroll(this.calendarMonths.nativeElement.children[index], {
      offsetY: CALENDAR_HEADER_HEIGHT,
    });
  }

  /**
   * Helper method to determine if navigation type is MIXED and it is mobile
   * @returns
   */
  private isVerticalScroll(): boolean {
    return !!(this.dataModel?.isMobile && this.dataModel?.navigationType === CalendarNavigationType.MIXED);
  }

  private adjustFocus(): void {
    this.element.nativeElement.querySelector('button.day[tabindex="0"]')?.focus();
  }

  /**
   * Gets updated values for desired Inputs and creates a Partial<CalendarViewModel>
   * @param changes SimpleChanges
   * @returns updated desired Inputs
   */
  private getUpdatedCalendarData(changes: SimpleChanges): Partial<CalendarViewModel> {
    return [
      'displayMonths',
      'isDateRange',
      'scrollOnInit',
      'showTags',
      'minDate',
      'maxDate',
      'disabledDateRanges',
      'selectedDates',
      'dateLabels',
      'uiLabels',
      'navigationType',
      'showEnhancedCalendar',
    ]
      .filter((option) => option in changes)
      .reduce((data: Partial<CalendarViewModel>, option: string) => {
        if (
          !isDeepEqual(changes[option].currentValue, changes[option].previousValue) &&
          !isDeepEqual(this.dataModel?.[option], changes[option].currentValue)
        ) {
          if (option === 'uiLabels') {
            data['selectedLabel'] = changes[option].currentValue?.['selected'];
          } else if (option === 'showEnhancedCalendar') {
            data['enableMonthScrollingAfterSelection'] = !changes[option].currentValue;
          } else {
            data[option] = changes[option].currentValue;
          }
        }
        return data;
      }, {});
  }
}
