<div (finMutationObserver)="onDomChange($event)" #target></div>
<ng-container *ngIf="chatButtonState$ | async as helpButtonStatus">
  <fin-pre-chat
    *ngIf="helpButtonStatus === HelpButtonStatus.CLICKED"
    (chatToggle)="chatTrigger($event)"
    (startChat)="startChat()"
    [usePreChatForm]="agentforceLocaleAllowed"
  ></fin-pre-chat>

  <button
    *ngIf="helpButtonStatus === HelpButtonStatus.VISIBLE"
    id="chat-help-button"
    (click)="chatTrigger(HelpButtonStatus.CLICKED)"
    class="fixed flex flex--center nordic-blue-900-bg white-text ps-small-x ps-xsmall-y bold-type font-body-1 align-left rounded-left-large"
    [class.dirty]="dirty$ | async"
    tabindex="0"
    [attr.aria-label]="'chat.defaultMinimizedText' | finLocalization"
  >
    <fcom-icon
      class="flex--nogrow icon-medium white-text"
      [dataTestId]="'icon-' + SvgLibraryIcon.CHAT"
      [name]="SvgLibraryIcon.CHAT"
      role="presentation"
    ></fcom-icon>
    <span class="dn-tablet-down ms-xsmall-l">{{ 'chat.defaultMinimizedText' | finLocalization }}</span>
  </button>

  <div
    *ngIf="helpButtonStatus === HelpButtonStatus.CHAT"
    id="chat-help-loading"
    class="fixed flex flex--center nordic-blue-900-bg white-text ps-small-x ps-xsmall-y bold-type font-body-1 align-left rounded-left-large"
  >
    <fcom-loader [loaderType]="LoaderTheme.BUTTON" [color]="LoaderColor.WHITE"></fcom-loader>
  </div>
</ng-container>
