import { TripType } from '@fcom/core/interfaces';

import { FinnairMultiCitySearchInterface, FlightSearchParams } from '../interfaces';
import { getBookingWidgetCabinClass } from './price-calendar.utils';
import { GlobalBookingFlight } from '../store';

export const createDeeplinkPathFromFlightSearchParams = (
  { locale, flights, paxAmount, travelClass, promoCode, isAward, isCorporate, tripType }: FlightSearchParams,
  corporateTravelClassLimitEnabled = false
): string => {
  const { adults, c15s, children, infants } = paxAmount;

  const urlParams: FinnairMultiCitySearchInterface = {
    flights: mapFlightsToParams(flights),
    cabin: getBookingWidgetCabinClass(
      travelClass,
      isCorporate,
      corporateTravelClassLimitEnabled,
      tripType === TripType.MULTICITY
    ),
    adults,
    c15s,
    children,
    infants,
    isAward: isAward || undefined,
    promoCode: isAward ? undefined : promoCode,
  };

  Object.keys(urlParams).forEach((key) => {
    if (urlParams[key] === null) {
      delete urlParams[key];
    }
  });

  const params = encodeURIComponent(JSON.stringify(urlParams));
  return `/${locale}/booking/flight-selection?json=${params}`;
};

export const mapFlightsToParams = (
  flights: GlobalBookingFlight[]
): { origin: string; destination: string; departureDate: string }[] => {
  return flights.map(({ origin, destination, departureDate }) => {
    return {
      origin: origin?.locationCode,
      destination: destination?.locationCode,
      departureDate: departureDate.toString(),
    };
  });
};
