import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import {
  ButtonMode,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  IconPosition,
  InputType,
  LoaderTheme,
  NotificationTheme,
} from '@fcom/ui-components';
import { loginPhaseError, loginPhase, loginStatus, loginPhaseProfile } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { AppState } from '@fcom/core/interfaces';
import { LoginActions } from '@fcom/core/actions';
import { LoginPhaseError, LoginPhaseProfile, LoginStatus, LoginStep, PostLostPasswordBy } from '@fcom/core-api/login';
import { unsubscribe } from '@fcom/core/utils';
import { LinkSize } from '@fcom/ui-components/components/link/enums';
import { ConfigService } from '@fcom/core/services';
import { LanguageService } from '@fcom/ui-translate';
import { RootPaths } from '@fcom/core/constants';

import { JoinService, LoginService } from '../../services';
import { GtmService } from '../../../gtm';
import {
  ElementActions,
  ElementTypes,
  GaContext,
  VerifyAccountTokenResponseData,
  VerifyStatusCode,
} from '../../../interfaces';
import { corporateUsernameValidator, usernameValidator } from '../../../utils/form-validators';
import { HotjarCaptureEvent, HotjarService, ScrollService } from '../../../services';

interface CorporateCredentials {
  username: FormControl<string>;
  password: FormControl<string>;
  keep: FormControl<boolean>;
}

export enum AccountVerification {
  NORMAL_LOGIN = 'NORMAL_LOGIN',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
}
type LoginStepTranslationsBase = {
  title: string;
  description: string;
  error?: string;
};

type StepToLoginTranslationsMap = {
  [key in LoginStep]: LoginStepTranslationsBase;
} & {
  [LoginStep.TWO_FACTOR_EMAIL]: LoginStepTranslationsBase & {
    resendSuccess: string;
    resendError: string;
  };
};

@Component({
  selector: 'fin-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  private trackElementNamePrefix = 'login-form-';

  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly LinkSize = LinkSize;
  readonly IconPosition = IconPosition;
  readonly LoaderTheme = LoaderTheme;
  readonly InputType = InputType;
  readonly LoginStep = LoginStep;
  readonly NotificationTheme = NotificationTheme;
  readonly PostLostPasswordBy = PostLostPasswordBy;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly AccountVerification = AccountVerification;
  readonly IconLibrary = IconLibrary;

  mfaMsg = {
    title: 'login.2faTitle',
    description: 'login.smsVerificationCode',
    error: 'login.smsCodeError',
  };

  loginTranslations: StepToLoginTranslationsMap = {
    [LoginStep.CREDENTIALS]: {
      title: 'login.title',
      description: 'login.description',
      error: 'login.failed',
    },
    [LoginStep.CORPORATE_CREDENTIALS]: {
      title: 'login.b2bTitle',
      description: 'login.b2bDescription',
      error: 'login.b2bFailed',
    },
    [LoginStep.CORPORATE_CREDENTIALS_DIRECT]: {
      title: 'login.b2bTitle',
      description: 'login.b2bDescription',
      error: 'login.b2bFailed',
    },
    [LoginStep.TWO_FACTOR_CODE]: {
      title: 'login.2faTitle',
      description: 'login.2faDescription',
      error: 'login.2faError',
    },
    [LoginStep.TWO_FACTOR_EMAIL]: {
      title: 'login.twoFactorAuthEmail.title',
      description: 'login.twoFactorAuthEmail.description',
      error: 'login.twoFactorAuthEmail.loginError',
      resendSuccess: 'login.twoFactorAuthEmail.resendSuccess',
      resendError: 'login.twoFactorAuthEmail.resendError',
    },
    [LoginStep.TWO_FACTOR_PHONE]: this.mfaMsg,
    [LoginStep.TWO_FACTOR_SMS]: this.mfaMsg,
    [LoginStep.LOCKED]: {
      title: 'login.lockedTitle',
      description: 'login.lockedDescription',
    },
    [LoginStep.FORGOT_PASSWORD]: {
      title: 'login.forgotPassword.title',
      description: 'login.forgotPassword.description',
      error: 'login.forgotPassword.failed',
    },
    [LoginStep.POST_LOST_PASSWORD_SENT]: {
      title: 'login.forgotPassword.successTitle',
      description: 'login.forgotPassword.successDescription',
    },
  };

  @Input()
  fullScreen = false;

  @Input()
  accountVerificationToken$: Observable<string> = of('');
  @Output()
  verificationFinished = new EventEmitter<void>();

  enableAccountCreationVerification = false;
  subscriptions = new Subscription();
  reactiveForm: UntypedFormGroup;
  corporateCredentialsForm: FormGroup<CorporateCredentials>;
  verificationForm: UntypedFormGroup;
  forgotPasswordForm: UntypedFormGroup;

  corporateChangePasswordUrl: string;
  resendEmailCount = 0;

  step$: Observable<LoginStep>;
  loginPhaseProfile$: Observable<LoginPhaseProfile>;
  error$: Observable<string | undefined>;
  successNotification$ = new BehaviorSubject<string>(undefined);

  icon$: Observable<SvgLibraryIcon>;
  loading$ = new BehaviorSubject(false);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent): void {
    this.closeDialog();
  }

  titleLabel$: Observable<string>;
  accountVerification$: Observable<AccountVerification>;

  constructor(
    private loginService: LoginService,
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private scrollService: ScrollService,
    private hotjarService: HotjarService,
    private configService: ConfigService,
    private languageService: LanguageService,
    private elementRef: ElementRef,
    private router: Router,
    private joinService: JoinService
  ) {
    this.enableAccountCreationVerification = configService.cfg.enableAccountCreationVerification;

    this.createForms();

    this.step$ = this.store$.pipe(
      loginPhase(),
      map((phase) => phase?.step),
      distinctUntilChanged(),
      finShare()
    );

    this.subscriptions.add(
      this.step$.subscribe((loginPhaseStep) => {
        this.loading$.next(false);
        this.verificationForm.reset();
        this.track2faViews(loginPhaseStep);
      })
    );

    this.loginPhaseProfile$ = this.store$.pipe(loginPhaseProfile(), finShare());

    this.subscriptions.add(
      this.store$.pipe(loginPhaseError()).subscribe((error) => {
        this.loading$.next(false);
        this.successNotification$.next(undefined);

        if (error === LoginPhaseError.LOGIN_FAILED) {
          this.trackButtonClickEvent('login-event', 'false');
        }
      })
    );

    this.error$ = this.store$.pipe(
      loginPhaseError(),
      withLatestFrom(this.step$),
      map(([loginError, step]) => {
        if (!loginError) {
          return undefined;
        }

        if (step === LoginStep.TWO_FACTOR_EMAIL && loginError === LoginPhaseError.RESEND_2FA_EMAIL) {
          return this.loginTranslations[step].resendError;
        } else {
          return this.loginTranslations[step].error;
        }
      })
    );

    this.icon$ = this.step$.pipe(
      map((step) => {
        switch (step) {
          case LoginStep.CREDENTIALS:
            return SvgLibraryIcon.FINNAIR_EMBLEM_BLOCK;
          case LoginStep.POST_LOST_PASSWORD_SENT:
            return SvgLibraryIcon.CHECKMARK_BLOCK;
          case LoginStep.CORPORATE_CREDENTIALS_DIRECT:
            return SvgLibraryIcon.B2B_BLOCK;
          default:
            return undefined;
        }
      })
    );

    this.subscriptions.add(
      this.store$
        .pipe(
          loginStatus(),
          filter((status) => status === LoginStatus.PENDING),
          take(1)
        )
        .subscribe(() => {
          this.trackButtonClickEvent('login-event', 'true');
          this.store$.dispatch(LoginActions.clearLoginPhase());
        })
    );

    this.corporateChangePasswordUrl = `https://${this.configService.cfg.casHost}/content/${this.languageService.localeValue}/login/corporate/forgot-password`;
  }

  ngOnInit(): void {
    this.accountVerification$ = this.accountVerificationToken$.pipe(
      switchMap((token) => {
        if (this.enableAccountCreationVerification && token) {
          return this.joinService.verifyAccountToken(token).pipe(
            map(() => AccountVerification.SUCCESS),
            catchError((error: VerifyAccountTokenResponseData) => {
              if (error?.status?.message === VerifyStatusCode.EXPIRED) {
                return of(AccountVerification.EXPIRED);
              } else {
                return of(AccountVerification.ERROR);
              }
            }),
            startWith(AccountVerification.LOADING)
          );
        }
        return of(AccountVerification.NORMAL_LOGIN);
      }),
      finShare()
    );

    this.titleLabel$ = combineLatest([this.accountVerification$, this.step$]).pipe(
      map(([accountVerification, step]) => {
        switch (accountVerification) {
          case AccountVerification.NORMAL_LOGIN:
          case AccountVerification.SUCCESS:
            return this.loginTranslations[step ?? LoginStep.CREDENTIALS].title;
          case AccountVerification.LOADING:
            return '';
          case AccountVerification.ERROR:
          case AccountVerification.EXPIRED:
            return 'login.join.basicTitle';
          default:
            return '';
        }
      })
    );

    this.subscriptions.add(
      this.accountVerification$.subscribe((val) => {
        switch (val) {
          case AccountVerification.SUCCESS:
            this.gtmService.trackElement(
              'join-form-account-verified',
              GaContext.FINNAIR_PLUS,
              ElementTypes.MODAL,
              'account-confirmed',
              ElementActions.VIEW
            );
        }
      })
    );
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  forgotPassword(): void {
    this.trackButtonClickEvent('forgot-password');
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.FORGOT_PASSWORD }));
  }

  postLostPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));
    this.loading$.next(true);

    const { postLostPasswordBy, member, lastName } = this.forgotPasswordForm.getRawValue();

    this.trackButtonClickEvent('reset-password');

    this.subscriptions.add(
      this.loginService.postLostPassword(postLostPasswordBy, member, lastName).subscribe({
        next: (res) => {
          if (res?.message?.[0]?.severity === 'ERROR') {
            this.store$.dispatch(LoginActions.setLoginPhaseError({ error: LoginPhaseError.POST_LOST_PASSWORD }));
          } else {
            this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.POST_LOST_PASSWORD_SENT }));
          }
        },
        error: () => {
          this.store$.dispatch(LoginActions.setLoginPhaseError({ error: LoginPhaseError.POST_LOST_PASSWORD }));
        },
      })
    );
  }

  login(): void {
    if (this.reactiveForm.invalid) {
      this.reactiveForm.markAllAsTouched();
      return this.scrollService.scrollToFirstInvalidInput(this.elementRef);
    }

    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));
    this.trackButtonClickEvent('submit-login');
    const { member, pwd, keep } = this.reactiveForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login(member, pwd, keep);
  }

  corporateLogin(): void {
    if (this.corporateCredentialsForm.invalid) {
      this.corporateCredentialsForm.markAllAsTouched();
      return this.scrollService.scrollToFirstInvalidInput(this.elementRef);
    }

    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));
    this.trackButtonClickEvent('submit-corporate-login');
    const { username, password, keep } = this.corporateCredentialsForm.getRawValue();
    this.loading$.next(true);
    this.loginService.login(username, password, keep);
    this.subscriptions.add(this.hotjarService.startCapture(HotjarCaptureEvent.CORPORATE_LOGIN).subscribe());
    this.redirectToCorporatePortal();
  }

  login2fa(step: LoginStep): void {
    const track2faSubmitButtonElementName = this.get2faViewTrackElementNames(step)?.submitButton;

    if (track2faSubmitButtonElementName) {
      this.trackButtonClickEvent(track2faSubmitButtonElementName);
    }

    if (this.validateVerificationFormCode() === 'invalid') {
      return;
    }

    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));
    const { code } = this.verificationForm.getRawValue();

    this.loading$.next(true);
    this.loginService.login2fa(code, step === LoginStep.TWO_FACTOR_PHONE);
  }

  resendEmail(): void {
    this.resendEmailCount++;
    this.successNotification$.next(undefined);
    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));

    if (this.resendEmailCount > 2) {
      return;
    }

    this.trackButtonClickEvent('2fa-email-modal', 'resend-code');

    if (this.resendEmailCount === 2) {
      this.gtmService.trackElement(
        `${this.trackElementNamePrefix}2fa-message`,
        GaContext.FINNAIR_PLUS,
        ElementTypes.NOTIFICATION,
        'still-no-code',
        ElementActions.VIEW
      );
    }

    this.subscriptions.add(
      this.loginService
        .resend2faEmail()
        .pipe(filter((response) => !response))
        .subscribe(() => {
          this.successNotification$.next(this.loginTranslations[LoginStep.TWO_FACTOR_EMAIL].resendSuccess);
        })
    );
  }

  requestSMS(): void {
    this.store$.dispatch(LoginActions.setLoginPhaseError({ error: undefined }));
    this.loading$.next(true);
    this.trackButtonClickEvent('request-sms-login');
    this.loginService.requestSMS();
  }

  openCorporateLoginForm(): void {
    const { member, pwd } = this.reactiveForm.getRawValue();
    const formState = member || pwd ? 'form-filled' : 'form-empty';
    this.trackButtonClickEvent('corporate-login', formState);
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CORPORATE_CREDENTIALS }));
  }

  closeDialog(): void {
    this.trackButtonClickEvent('close');
    this.verificationFinished.emit();
    this.store$.dispatch(LoginActions.clearLoginPhase());
  }

  resetLogin(event: Event): void {
    event.stopPropagation();
    this.resendEmailCount = 0;
    this.loading$.next(false);
    this.reactiveForm.reset();
    this.successNotification$.next(undefined);
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
  }

  trackAndCloseDialog(): void {
    this.closeDialog();
    this.trackButtonClickEvent('login-read-more-about-corporate');
  }

  redirectToCorporatePortal(): void {
    this.subscriptions.add(
      this.loginService.loginTrigger$.pipe(take(1)).subscribe(() => {
        if (!this.router.url.includes(RootPaths.CORPORATE)) {
          this.closeDialog();
          this.scrollService.scrollTop();
          this.router.navigate([this.languageService.langValue, RootPaths.CORPORATE, 'home']);
        }
      })
    );
  }

  onAlertNotificationLinkClicked(step: LoginStep): void {
    if (step === LoginStep.TWO_FACTOR_EMAIL) {
      this.resendEmail();
    }
  }

  private createForms(): void {
    this.reactiveForm = this.fb.group({
      member: this.fb.control('', Validators.compose([Validators.required, usernameValidator()])),
      pwd: this.fb.control('', Validators.compose([Validators.required])),
      keep: this.fb.control(false, { nonNullable: true }),
    });

    this.corporateCredentialsForm = this.fb.group({
      username: ['', [Validators.required, corporateUsernameValidator()]],
      password: ['', [Validators.required]],
      keep: [false],
    });

    this.verificationForm = this.fb.group({
      code: this.fb.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern(/^\d+$/),
        ])
      ),
    });

    this.forgotPasswordForm = this.fb.group({
      postLostPasswordBy: this.fb.control(PostLostPasswordBy.EMAIL, [Validators.required]),
      member: this.fb.control('', [Validators.required, usernameValidator()]),
      lastName: this.fb.control('', [Validators.required]),
    });
  }

  private validateVerificationFormCode(): 'valid' | 'invalid' {
    if (this.verificationForm.invalid) {
      this.verificationForm.markAllAsTouched();

      const validationErrors = this.verificationForm.get('code').errors;
      const state = validationErrors?.required
        ? 'code-required'
        : validationErrors?.minlength
          ? 'code-min-length'
          : validationErrors?.maxlength
            ? 'code-max-length'
            : 'code-pattern';

      this.gtmService.trackElement(
        `${this.trackElementNamePrefix}2fa-error`,
        GaContext.FINNAIR_PLUS,
        ElementTypes.NOTIFICATION,
        state,
        ElementActions.VIEW
      );
      return 'invalid';
    }
    return 'valid';
  }

  private trackButtonClickEvent(elementName: string, state: string = undefined): void {
    this.gtmService.trackElement(
      `${this.trackElementNamePrefix}${elementName}`,
      GaContext.FINNAIR_PLUS,
      ElementTypes.BUTTON,
      state,
      ElementActions.CLICK
    );
  }

  private track2faViews(loginStep: LoginStep): void {
    const track2faViewElementName = this.get2faViewTrackElementNames(loginStep)?.view;

    if (!track2faViewElementName) {
      return;
    }

    this.gtmService.trackElement(
      `${this.trackElementNamePrefix}${track2faViewElementName}`,
      GaContext.FINNAIR_PLUS,
      ElementTypes.MODAL,
      'open',
      ElementActions.VIEW
    );
  }

  private get2faViewTrackElementNames(loginStep: LoginStep): { submitButton: string; view: string } | undefined {
    switch (loginStep) {
      case LoginStep.TWO_FACTOR_EMAIL:
        return { view: '2fa-email-modal', submitButton: 'login-2fa-email' };
      case LoginStep.TWO_FACTOR_CODE:
        return { view: '2fa-app-modal', submitButton: 'login-2fa-app' };
      case LoginStep.TWO_FACTOR_PHONE:
        return { view: '2fa-phone-modal', submitButton: 'login-2fa-sms' };
      case LoginStep.TWO_FACTOR_SMS:
        return { view: '2fa-sms-modal', submitButton: 'login-2fa-sms' };
      default:
        return undefined;
    }
  }
}
