<div class="flex flex--space-between ps-large-t">
  <h2 class="font-heading-2">{{ 'MMB.cancelBooking.involuntary.refund.subTitle' | finLocalization }}</h2>
  <span
    *ngIf="displayEligibleForRefundMessage$ | async"
    class="font-body-1 success-900-text bold-type flex flex--center"
    data-testid="eligible-for-refund-title"
  >
    <fcom-icon class="icon-medium mr-xxsmall-r" [name]="SvgLibraryIcon.CHECKMARK"></fcom-icon
    >{{ 'MMB.refund.eligibleForRefund' | finLocalization }}
  </span>
</div>

<ng-container *ngIf="displayMainNotification$ | async">
  <fcom-notification
    data-testid="main-refund-notification"
    class="ps-medium-y flex--basis-full"
    [layout]="NotificationLayout.BLOCK"
    [excludeColumns]="true"
    [theme]="NotificationTheme.INFO"
    [title]="mainNotificationContent$ | finMap: 'teaserTitle' | async"
  >
    <div class="flex flex--column">
      <cms-unstyled-fragment [showTitle]="false" [cmsContent$]="mainNotificationContent$"></cms-unstyled-fragment>
      <fcom-button
        data-testid="go-to-claim-form-button"
        *ngIf="displayGoToClaimFormButton$ | async"
        class="flex--self-end mr-xxsmall-t"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [text]="'MMB.refund.claimsForm.text' | finLocalization"
        (clickBtn)="onGoToClaimForm()"
        [openInNewTab]="true"
      >
      </fcom-button>
    </div>
  </fcom-notification>
</ng-container>

<fin-section-review
  class="ps-medium-t"
  *ngIf="displayRefundAmountBlock$ | async"
  data-testid="refund-amount-block"
  [title]="'MMB.cancelBooking.involuntary.refund.flight.title' | finLocalization"
  [noButton]="true"
  [fullWidth]="false"
  [roundedBottom]="false"
>
  <ng-container ngProjectAs="content">
    <fin-refund-price-breakdown
      class="flex--basis-full"
      [prices$]="cancelRefund$ | finMap: 'refundAmount'"
      [passengers$]="cancelRefund$ | finMap: 'order.passengers'"
      [expandOpen]="true"
    >
    </fin-refund-price-breakdown>
    <div class="flex--basis-full block pr-small-x" *ngIf="(cancelRefund$ | async)?.order?.passengers?.length > 1">
      <div class="font-body-1 content">
        <div class="ps-small flex flex--center" *ngIf="cancelRefund$ | finMap: 'refundAmount' | async as prices">
          <button
            (click)="openSummaryModal($event, prices)"
            class="detailed-breakdown underline nordic-blue-900-text bold-type"
          >
            {{ 'purchase.priceBreakdown.detailedBreakdown' | finLocalization }}
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="displayFlightTicketNotification$ | async"
      data-testid="flights-notification"
      class="flex font-body-1 pr-small-b"
    >
      <fcom-icon
        class="message-type ms-xsmall-r flex--nogrow icon-medium"
        [name]="SvgLibraryIcon.INFO_BLOCK"
      ></fcom-icon>
      {{ flightTicketNotificationKey$ | async | finLocalization }}
    </div>
    <ng-container
      *ngIf="displayRefundNotificationWithLink$ | async"
      [ngTemplateOutlet]="refundNotificationWithLink"
    ></ng-container>
    <ng-container *ngIf="showTravelExtrasBlock$ | async">
      <div class="border-top-dashed fill"></div>
      <div class="flex flex--column fill">
        <h2 class="font-heading-4 flex flex--center flex--space-between nordic-blue-900-text pr-small-y">
          {{ 'MMB.cancelBooking.involuntary.refund.travelExtra.title' | finLocalization }}
        </h2>
        <div data-testid="travel-extras-notification" class="flex font-body-1">
          <fcom-icon
            class="message-type ms-xsmall-r flex--nogrow icon-medium"
            [name]="SvgLibraryIcon.INFO_BLOCK"
          ></fcom-icon>
          {{ travelExtrasNotificationKey$ | async | finLocalization }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</fin-section-review>
<div
  data-testid="total-refund-amount"
  class="flex nordic-blue-900-bg border-bottom border-left border-right rounded-bottom-large pr-small-x pr-xsmall-y"
>
  <h2 class="font-heading-4 flex white-text">
    {{ 'MMB.cancelBooking.involuntary.refund.totalRefund' | finLocalization }}
  </h2>
  <span class="flex-1 flex--basis0 flex font-heading-4 bold-type flex--wrap flex--end align-right white-text">{{
    (cancelRefund$ | async)?.refundAmount.total?.total?.totalAmount | finPrice: { useSymbol: true }
  }}</span>
</div>

<fcom-modal
  *ngIf="(cancelRefund$ | async)?.order?.passengers?.length > 1"
  [(open)]="summaryModalOpen"
  [title]="'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization"
  [buttons]="ModalButtons.DONE"
  (close)="closeSummaryModal()"
>
  <ng-container *ngFor="let breakdown of breakdowns$ | finObsToArray">
    <div class="pr-xsmall-t pr-large-l pr-xxlarge-r">
      <div class="trigger flex" *ngIf="breakdown | async as passenger">
        <div class="flex-1">
          <h3 class="font-heading-5 nordic-blue-50-bg">
            <span class="flex-1 flex--self-center ps-xsmall-l medium-type" data-hj-suppress>
              {{ passenger?.title ? ('passenger.title.' + passenger?.title | lowercase | finLocalization) : '' }}
              {{ passenger?.firstName }} {{ passenger?.lastName }} ({{
                'passenger.' + asPaxTypeKey(passenger.passengerTypeCode).toLowerCase() | finLocalization | titlecase
              }})
            </span>
          </h3>
        </div>
      </div>
      <div class="font-body-1 ps-xsmall-t content" *ngIf="breakdown | async as pax">
        <table class="fill price-breakdown-per-passenger">
          <caption class="is-vishidden">
            {{
              'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization
            }}
          </caption>

          <thead>
            <tr>
              <th class="flex medium-type ps-xxsmall-b" scope="col" colspan="2">
                {{ 'purchase.priceBreakdown.flights' | finLocalization }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: 'purchase.priceBreakdown.flights' | finLocalization,
                originalValue: pax.prices?.originalBaseFare ? (pax.prices?.originalBaseFare | finPrice) : undefined,
                value: (pax.prices?.baseFare | finPrice),
                bold: false,
              }"
            >
            </ng-container>

            <tr class="ps-xxsmall-b" [class.border-bottom]="pax.services.length > 0">
              <td colspan="3">
                <fin-expand
                  class="price-breakdown-modal"
                  [key]="'price-breakdown-expand-' + (breakdown | async)?.id"
                  [id]="'price-breakdown-expand-' + (breakdown | async)?.id"
                  #expand
                >
                  <div class="trigger flex">
                    <div class="flex-1">
                      <span class="underline medium-type">
                        {{ 'purchase.review.taxTotal' | finLocalization }}
                      </span>
                    </div>
                    <div>
                      <span class="medium-type"
                        >{{
                          pax.prices?.totalTax || { amount: '0', currencyCode: pax.prices?.baseFare.currencyCode }
                            | finPrice
                        }}
                      </span>
                      <span class="ps-xsmall-l">
                        <fcom-icon
                          class="flex-1 flex--nogrow rotate flex--self-start icon-medium nordic-blue-900-text"
                          [class.rotate-active]="expand.isOpen$ | async"
                          [name]="SvgLibraryIcon.CHEVRON_DOWN"
                        ></fcom-icon>
                      </span>
                    </div>
                  </div>
                  <div class="font-body-1 content">
                    <ul class="list-disc-style">
                      <li *ngFor="let tax of pax.prices.taxes">
                        <div class="flex flex--space-between">
                          <span>
                            {{ 'TAX_CODES.' + tax.code.slice(0, 2) | finLocalization | finEmpty: tax.code.slice(0, 2) }}
                          </span>
                          <span>
                            {{ tax | finPrice }}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </fin-expand>
              </td>
            </tr>
            <ng-container *ngFor="let fee of pax.prices?.fees">
              <ng-container
                [ngTemplateOutlet]="priceLine"
                [ngTemplateOutletContext]="{
                  label: 'purchase.review.' + fee.code | finLocalization,
                  value: fee | finPrice,
                  bold: false,
                }"
              >
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot class="border-top">
            <ng-container
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: ('purchase.priceBreakdown.totalPrice' | finLocalization),
                value: (pax.total | finPrice),
                bold: true,
              }"
            >
            </ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</fcom-modal>

<ng-template
  #priceLine
  let-label="label"
  let-value="value"
  let-originalValue="originalValue"
  let-bold="bold"
  let-borderBottom="borderBottom"
>
  <tr class="ps-xxsmall-b" [ngClass]="{ 'border-bottom': borderBottom }">
    <th
      scope="row"
      class="align-left ps-xxsmall-b"
      [ngClass]="{ 'light-type': !bold, 'medium-type ms-xsmall-t': bold }"
    >
      <ng-container *ngIf="label">{{ label }}</ng-container>
    </th>
    <td class="align-right ps-xxsmall-b" [ngClass]="{ 'medium-type ms-xsmall-t': bold }">
      <span class="nordic-blue-900-text strike-through pr-xxsmall-r" *ngIf="originalValue">{{ originalValue }}</span
      >{{ value }}
    </td>
  </tr>
</ng-template>

<ng-template #refundNotificationWithLink>
  <cms-unstyled-fragment
    class="font-body-1"
    [contentUrl$]="
      'MMB.cancelBooking.involuntary.refund.refundStatusChecker.link.url' | finLocalization | finToObservable
    "
    [showTitle]="false"
    [showIcon]="true"
  >
  </cms-unstyled-fragment>
</ng-template>
