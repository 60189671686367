<div *ngIf="content$ | async as content" class="nordic-blue-900-bg white-text ps-xlarge">
  <div class="grid-max-width grid-col-12">
    <div [class]="'col-1-12'" [class.md-col-2-11]="(hasGridClass$ | async) === false">
      <cms-heading-tag
        *ngIf="content.title || content.teaserTitle"
        data-testid="heading-tag"
        class="heading-tag"
        [level]="headerLevel(content.subjectTaxonomyTags || []) ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerFontLevel(content.subjectTaxonomyTags || []) ?? 'white-text'"
        >{{ content.title || content.teaserTitle }}</cms-heading-tag
      >
      <div class="flex column-wrapper">
        <div
          *ngFor="let column$ of articles$ | finObsToArray"
          class="flex-1 flex--basis0"
          data-testid="article-with-icons-article"
        >
          <cms-rich-text [content$]="column$" [extraClasses]="(link$ | async) ? '' : 'mr-large-b'"> </cms-rich-text>
          <div *ngFor="let link of (column$ | async)?.related" class="flex nowrap">
            <cms-text-link [content$]="link | finToObservable" [colorClass]="'white-text'"></cms-text-link>
          </div>
        </div>
      </div>
      <div *ngIf="link$ | async as link" class="flex flex--end mr-large-t">
        <a
          [finLink]="link.target?.url"
          [external]="link.target?.external"
          [attr.target]="link.target?.openInNewTab ? '_blank' : null"
          class="flex flex--middle white-text bold-type no-underline font-body-1 underline-hover"
          data-testid="article-with-icons-cta"
        >
          <span class="title">{{ link.teaserTitle }}</span>
          <span class="cta">
            <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
