export enum ChatStatus {
  INITIAL,
  LOADING,
  COMPLETE,
  ERROR,
}

/**
 * ServiceQueueId is our term, Salesforce uses term "SNAP_IN_NAME" for the same.
 *
 * This Id determines the customer service queue the customer ends up in, there is a separate
 * queue for each language, as they are handled by different call center providers (RTGS, SGS, TLL)
 *
 * We use this identifier also as a key to the language, buttonId and eswLiveAgentDevName
 * that are set to .settings and .init()'s parameters
 */
export type ChatServiceQueueId =
  | 'RTGS_FINNISH'
  | 'RGTS_SWEDISH' // intentional to use the typoed id in salesforce
  | 'RTGS_ENGLISH'
  | 'SGS_CHINESE'
  | 'Chatbot_EN'
  | 'Chatbot_EN_SGS' // chatbot for SGS en
  | 'SGS_FRENCH'
  | 'SGS_GERMAN'
  | 'SGS_ITALIAN'
  | 'SGS_JAPANESE'
  | 'SGS_KOREAN'
  | 'SGS_RUSSIAN'
  | 'SGS_SPANISH'
  | 'SGS_CHINESE_HK'
  | 'Chatbot_FTH';

/**
 * Only buttonId, eswLiveAgentDevName and language change from one language to another, other than that
 * the settings are always the same for all chats
 */
export interface ChatServiceQueueSpecificConfig {
  buttonId: string;
  eswLiveAgentDevName: string;
  language: string;
}

/**
 * Service Queue specific configurations (including settings like buttonId) based on the Service Queue Id (like "SGS_CHINESE")
 */
export type ChatServiceQueueConfig = { [serviceQueueId in ChatServiceQueueId]: ChatServiceQueueSpecificConfig };

export type ChatServiceConfig = {
  chatServiceQueueConfig: ChatServiceQueueConfig;
  chatInitParamsConfig: ChatInitParamsConfig;
  chatDefaultLanguage: string;
};

/**
 * The chat initialization configuration parameters that only change based on the environment but not from one language (service queue) to another.
 */
export type ChatInitParamsConfig = {
  baseCoreURL: string; // 1st parameter for salesforce chat .init() that is an URL
  communityEndpointURL: string; // 2nd parameter for salesforce chat .init() that is an URL
  gslbBaseURL: string; // 3rd parameter for salesforce chat .init() that is an URL
  orgId: string; // 4th parameter for salesforce chat .init()
  baseLiveAgentContentURL: string;
  deploymentId: string;
  baseLiveAgentURL: string;
};

export interface PrechatFields {
  firstName: string;
  lastName: string;
  email: string;
  subject?: string;
  bookingReference?: string;
}

export interface LiveagentChatFields {
  FirstName: string;
  LastName: string;
  Email: string;
  Subject?: string;
  Booking_Reference__c: string;
  Finnair_Plus_Number__c?: string;
  Case_Member_Tier__c?: string;
}

export interface SplitTrafficChatFields {
  FirstName_c: string;
  LastName_c: string;
  Email_c: string;
  Subject_c?: string;
  WebSite_Country?: string;
  WebSite_Language?: string;
  Booking_Reference?: string;
  hiddenFields?: SplitTrafficAdditionalChatFields;
}

export interface SplitTrafficAdditionalChatFields {
  Finnair_Plus_Number_c?: string;
  Case_Member_Tier_c?: string;
  AuthenticatedUser?: string;
}

/**
 * HelpButton Status
 */
export enum HelpButtonStatus {
  VISIBLE = 'visible', // Help Button is visible
  CLICKED = 'clicked', // Help Button is clicked
  USER_FORM = 'user-form', // Step before chat to the form user should fill in before intiating chat
  CHAT = 'chat', // SF Chat is waiting until open
  HIDDEN = 'hidden', // Help Button is not visible when SF Chat is open
}

export type AgentforceChatQueueId = 'DEFAULT';

export type AgentforceQueueConfig = {
  deploymentId: string;
  buttonId: string;
  agentUrl: string;
};

export type AgentforceChatConfig = {
  chatScriptSrcURL: string;
  scrt2URL: string;
  queueConfigs: {
    [queueId in AgentforceChatQueueId]: AgentforceQueueConfig;
  };
};
