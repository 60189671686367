import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private hideCloseButtonSubject$ = new BehaviorSubject<boolean>(false);
  hideCloseButton$ = this.hideCloseButtonSubject$.asObservable().pipe(distinctUntilChanged());

  setHideCloseButton(value: boolean): void {
    this.hideCloseButtonSubject$.next(value);
  }
}
