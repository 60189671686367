import { Amount } from '@fcom/dapi/interfaces';
import {
  DateFormat,
  GlobalBookingTravelClass,
  isNull,
  isPresent,
  LocalDate,
  quantityOfMonths,
  rangeFrom,
} from '@fcom/core';
import { LowestPriceOfPeriod, Price } from '@fcom/dapi/api/models';

import {
  BasePriceCalendarParams,
  CalendarPrices,
  DatePickerPrices,
  GlobalBookingTripDates,
  HistogramBar,
  PriceCalendarWithPricesParams,
  TripType,
} from '../interfaces';

export const priceCalendarBaseParamsAreValid = ({
  origin,
  destination,
  tripType,
}: Partial<BasePriceCalendarParams>): boolean => isPresent(origin) && isPresent(destination) && isPresent(tripType);

export const priceCalendarPriceParamsAreValid = ({
  travelClass,
  paxAmount,
  origin,
  destination,
  tripType,
}: Partial<PriceCalendarWithPricesParams>): boolean => {
  if (!travelClass || !paxAmount || !priceCalendarBaseParamsAreValid({ origin, destination, tripType })) {
    return false;
  }
  const travelClassIsValid = isPresent(travelClass);
  const totalPax = Object.values(paxAmount).reduce((totalPax, paxTypeAmount) => totalPax + paxTypeAmount, 0);
  const paxAmountIsValid = paxAmount.adults >= 1 && totalPax <= 9;
  return travelClassIsValid && paxAmountIsValid;
};

export const isPriceCalendarWithPricesParams = (
  params: Partial<PriceCalendarWithPricesParams>
): params is PriceCalendarWithPricesParams => priceCalendarPriceParamsAreValid(params);

export const getStartingFromPrice = (
  prices: DatePickerPrices,
  tripType: TripType,
  { departureDate, returnDate }: GlobalBookingTripDates
): Amount => {
  return prices?.calendar?.[tripType === TripType.ONEWAY ? departureDate?.id : returnDate?.id]?.amount;
};

export const createHistogramData = (
  priceResponse: LowestPriceOfPeriod,
  dates: string[],
  dateTranslations: Record<string, string | string[]>
): HistogramBar[] => {
  let histogramBarData = undefined;
  if (dates.length === 0) {
    return histogramBarData;
  }

  const lowestPriceForMonth = priceResponse?.lowestPriceForMonth;
  const now = LocalDate.now();

  histogramBarData = rangeFrom(
    0,
    quantityOfMonths(new LocalDate(dates[0]), new LocalDate(dates[dates.length - 1]))
  ).map((_, currentIndex) => {
    const current = now.firstDayOfMonth.plusMonths(currentIndex);
    const date = current.id.substring(0, 7);

    return {
      amount: date in lowestPriceForMonth ? String(lowestPriceForMonth[date]?.price) : '0',
      currencyCode: priceResponse?.currency,
      text: new DateFormat(dateTranslations).format(current, DateFormat.MONTH_SHORT),
      noFlight: date in lowestPriceForMonth ? lowestPriceForMonth[date]?.noFlight : true,
      index: currentIndex,
      ...(now.lt(current) && current.localMonth === 1 && { spacer: { value: String(current.localYear) } }),
    };
  });

  return histogramBarData;
};

export const mapInstantSearchPricesToCalendarPrices = (priceResponse: LowestPriceOfPeriod): CalendarPrices => {
  const lowestYearlyPrice =
    priceResponse?.prices?.length > 0
      ? Math.min(...priceResponse.prices.map(({ price }: Price) => price).filter((p) => !isNull(p)))
      : Infinity;

  return (
    priceResponse?.prices?.reduce(
      (allPrices: CalendarPrices, { date, price, noFlight }: Price) => ({
        ...allPrices,
        [date]: price
          ? {
              amount: {
                amount: price ? String(price) : null,
                currencyCode: priceResponse?.currency,
              },
              isLowestPrice: price === lowestYearlyPrice,
              noFlight,
            }
          : undefined,
      }),
      {}
    ) ?? {}
  );
};

export const getBookingWidgetCabinClass = (
  travelClass: GlobalBookingTravelClass,
  isCorporate: boolean,
  corporateTravelClassLimitEnabled: boolean,
  isMulticity: boolean
): GlobalBookingTravelClass => {
  if ((corporateTravelClassLimitEnabled && isCorporate) || isMulticity) {
    return travelClass;
  }
  return travelClass === GlobalBookingTravelClass.ECONOMY ? GlobalBookingTravelClass.MIXED : travelClass;
};

export const calendarDatesToGlobalBookingTripDates = (
  calendarDates: [string] | [string, string]
): GlobalBookingTripDates => {
  const [nextDep, nextRet] = calendarDates;
  return {
    departureDate: nextDep ? new LocalDate(nextDep) : undefined,
    returnDate: nextRet ? new LocalDate(nextRet) : undefined,
  };
};

export const shouldUpdateCalendarDates = (prev: GlobalBookingTripDates, next: GlobalBookingTripDates): boolean =>
  !(prev.departureDate?.id === next.departureDate?.id && prev.returnDate?.id === next.returnDate?.id);
