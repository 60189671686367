<ng-template #priceWithOriginal let-cabin="cabin" let-index="index">
  <ng-container *ngIf="(cabinIsExpanded$ | async) !== cabin">
    <ng-container *ngIf="(minPricePerCabin$ | finMap: cabin | async).quota as quota">
      <span
        *ngIf="quota < SEATS_LEFT_LIMIT && (selectedFareFamilyPerCabin$ | finMap: cabin | async) === null"
        class="heather-800-text font-body-1 ps-xsmall-r medium-type"
        data-testid="x-seats-left-text"
      >
        {{ 'leftSeatsAvailable' | finPluralLocalization: quota }}
      </span>
    </ng-container>
    <span
      *ngIf="
        (selectedFareFamilyPerCabin$ | finMap: cabin | async) === null &&
        !((minPricePerCabin$ | finMap: cabin | async).quota < SEATS_LEFT_LIMIT)
      "
      class="price-from-text font-body-2 nordic-blue-400-text ms-xxsmall-r"
    >
      {{ 'pricesStartingFrom' | finLocalization }}
    </span>

    <span
      class="price-text font-body-1 bold-type nordic-blue-900-text"
      [attr.data-testid]="'cabin-selection-price-text-' + index"
    >
      <ng-template #minPricePerCabin>
        <ng-container *ngIf="minPricePerCabin$ | finMap: cabin | async as prices">
          <span [class.heather-700-text]="isShowTotalPrice ? prices.originalTotalPrice : prices.originalPrice">
            {{ (isShowTotalPrice ? prices.totalPrice : prices.price) | finPrice }}
          </span>
          <span
            *ngIf="(isShowTotalPrice ? prices.originalTotalPrice : prices.originalPrice) && prices.discountReasonCode"
            class="strike-through regular-type ms-xxsmall-l"
            >{{ (isShowTotalPrice ? prices.originalTotalPrice : prices.originalPrice) | finPrice }}</span
          >
        </ng-container>
      </ng-template>
      <ng-container
        *ngIf="
          (cabinIsExpanded$ | async) !== cabin && (selectedFareFamilyPerCabin$ | finMap: cabin | async);
          else minPricePerCabin
        "
      >
        {{
          {
            amount: (selectedFareFamilyPerCabin$ | finMap: cabin | async).totalPrice,
            currencyCode: currencyCode,
          } | finPrice
        }}
      </ng-container>
    </span>
  </ng-container>
</ng-template>

<ng-template #cabinName let-cabin="cabin">
  <span [class.medium-type]="(cabinIsExpanded$ | async) === cabin">{{
    'travelClass.' + (cabin | lowercase) | finLocalization
  }}</span>
  <ng-container *ngIf="minPricePerCabin$ | finMap: cabin | async as cabinPrice">
    <fcom-icon
      *ngIf="
        (((isShowTotalPrice ? cabinPrice.originalTotalPrice : cabinPrice.originalPrice) &&
          cabinPrice.discountReasonCode) ||
          cabinPrice.isSpecialOffer) &&
        (cabinIsExpanded$ | async) !== cabin
      "
      class="ms-xsmall-l icon-medium heather-700-text"
      [name]="SvgLibraryIcon.DISCOUNT"
    ></fcom-icon>
  </ng-container>

  <ng-container *ngIf="(cabinIsExpanded$ | async) === cabin"
    >: {{ 'ticketSelection.header.selectTicketTypeBelow' | finLocalization }}
  </ng-container>
</ng-template>

<ng-template #selectedFareFamily let-selectedFareFamily="selectedFareFamily">
  <span class="medium-type">{{ selectedFareFamily.benefits.brandName }}</span>
  <fcom-icon class="circle ms-xsmall-l" [name]="SvgLibraryIcon.CHECKMARK_BLOCK"></fcom-icon>
</ng-template>

<ul class="no-list-style">
  <li
    *ngFor="let cabin of cabins$ | async; let index = index; let first = first; let last = last"
    [attr.data-testid]="'fare-family-list-' + cabin"
  >
    <button
      (click)="toggleCabin(cabin)"
      class="fare-family-cabin flex fill ps-small-y pr-small-x border-top flex--center"
      [ngClass]="{
        border: isShowTotalPrice,
        'rounded-top-large': first && !last && roundedTop,
        'rounded-bottom-large': ((!first && last) || (!roundedTop && first && last)) && isShowTotalPrice,
        'rounded-large': first && last && roundedTop,
        'expander-open': (cabinIsExpanded$ | async) === cabin,
        'is-selected': (cabinIsExpanded$ | async) !== cabin && (selectedFareFamilyPerCabin$ | async)?.[cabin],
        first: first,
      }"
      [attr.aria-controls]="cabin + '-' + uuid"
      [attr.aria-expanded]="cabinIsExpanded$ | finMap: cabin | async"
      [attr.aria-label]="
        (cabinIsExpanded$ | async) !== cabin && (selectedFareFamilyPerCabin$ | async)?.[cabin]
          ? ('ticketSelection.aria.fareFamily.ariaCabinButtonSelected'
            | finLocalization
              : {
                  fareFamily: (selectedFareFamilyPerCabin$ | finMap: cabin | async)?.benefits?.brandName,
                  price:
                    {
                      amount: (selectedFareFamilyPerCabin$ | finMap: cabin | async).totalPrice,
                      currencyCode: currencyCode,
                    } | finPrice,
                })
          : ('ticketSelection.aria.fareFamily.ariaCabinButtonUnselected'
            | finLocalization
              : {
                  cabin: 'cabin.' + cabin.toLowerCase() | finLocalization,
                  priceFrom: (minPricePerCabin$ | finMap: cabin | async).totalPrice | finPrice,
                })
      "
      [id]="cabin + '-' + uuid + '-button'"
      [style.background-color]="
        (cabinIsExpanded$ | async) !== cabin && (selectedFareFamilyPerCabin$ | async)[cabin]
          ? (selectedFareFamilyPerCabin$ | async)?.[cabin]?.benefits?.brandColor
          : null
      "
    >
      <div class="cabin-class-text flex flex-1 align-left font-body-1 nordic-blue-900-text">
        <div class="flex-1">
          <ng-container
            [ngSwitch]="!!((cabinIsExpanded$ | async) !== cabin && (selectedFareFamilyPerCabin$ | async)[cabin])"
          >
            <ng-container
              *ngSwitchCase="true"
              [ngTemplateOutlet]="selectedFareFamily"
              [ngTemplateOutletContext]="{ selectedFareFamily: (selectedFareFamilyPerCabin$ | async)[cabin] }"
            ></ng-container>
            <ng-container
              *ngSwitchDefault
              [ngTemplateOutlet]="cabinName"
              [ngTemplateOutletContext]="{ cabin: cabin }"
            ></ng-container>
          </ng-container>
        </div>
        <div class="flex-1 dn-laptop-up">
          <ng-container
            [ngTemplateOutlet]="priceWithOriginal"
            [ngTemplateOutletContext]="{ cabin: cabin, index: index }"
          ></ng-container>
        </div>
      </div>
      <div class="flex-1 align-right dn-laptop-down">
        <ng-container
          [ngTemplateOutlet]="priceWithOriginal"
          [ngTemplateOutletContext]="{ cabin: cabin, index: index }"
        ></ng-container>
      </div>
      <div class="flex-1 flex--nogrow">
        <fcom-icon
          class="chevron-icon rotate ms-xlarge-l"
          [class.rotate-active]="(cabinIsExpanded$ | async) === cabin"
          [name]="SvgLibraryIcon.CHEVRON_DOWN"
        ></fcom-icon>
      </div>
    </button>
    <fcom-expand
      [open]="(cabinIsExpanded$ | async) === cabin"
      [ariaId]="cabin + '-' + uuid"
      [ariaLabelledBy]="cabin + '-' + uuid + '-button'"
      [id]="cabin + '-' + uuid"
    >
      <div
        class="pr-xsmall grey-50-bg"
        [ngClass]="{
          'border-bottom rounded-bottom': last,
          'border-right border-left': isShowTotalPrice,
        }"
      >
        <fcom-carousel
          [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
          [nextButtonAriaLabel]="'carousel.next' | finLocalization"
          [contentChanged$]="cabinIsExpanded$ | async | finToObservable"
        >
          <fieldset class="flex fare-family-wrapper">
            <fin-fare-family-list-option
              [id]="fareFamily.id"
              *ngFor="
                let fareFamily of fareFamiliesByCabin$ | finMap: cabin | async;
                let last = last;
                let index = index;
                let count = count;
                trackBy: trackByFn
              "
              class="flex flex-1"
              [class.ms-small-r]="!last"
              [fareFamily$]="fareFamily | finToObservable"
              [currencyCode]="currencyCode"
              [aria]="{
                index,
                count,
              }"
              (selectFareFamily)="selectFareFamily.emit({ fareFamily, checked: $event })"
              [isShowTotalPrice]="isShowTotalPrice"
            ></fin-fare-family-list-option>
            <fin-upsell-option
              *ngIf="upsellCardFareFamily$ | async as upsellFareFamily"
              class="flex flex-1 ms-small-l"
              [fareFamily]="upsellFareFamily"
              [currencyCode$]="currencyCode | finToObservable"
              [isShortHaul$]="isShortHaul$"
              [isLongHaulLegAndAy$]="isLongHaulLegAndAy$"
              (selectNextCabinClass)="openCabinAndSelect(upsellFareFamily)"
              (openCabinClassModal)="openCabinClassModal.emit(upsellFareFamily.cabinClass)"
            ></fin-upsell-option>
          </fieldset>
        </fcom-carousel>
        <fcom-notification
          [attr.data-testid]="'ticket-type-details-notification-' + cabin"
          class="ms-small-t"
          [linkText]="'ticketSelection.baggageAndTicketTypeDetails' | finLocalization"
          [buttonStyle]="NotificationButtonStyle.LINK"
          [horizontal]="true"
          (linkClick)="isTicketTypeModalOpen = true"
        ></fcom-notification>
      </div>
    </fcom-expand>
  </li>
</ul>

<fcom-modal [(open)]="isTicketTypeModalOpen" [title]="'fareFamilyDetails.title' | finLocalization">
  <fin-fare-family-details-v2
    [dimensionsAndDetails$]="ticketTypeInfo$"
    [showKoreaNotification]="showKoreaNotification"
  ></fin-fare-family-details-v2>
</fcom-modal>
