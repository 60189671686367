import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, of, EMPTY, Subscription, delay } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

import { SentryLogger } from '@fcom/core';
import { unsubscribe } from '@fcom/core/utils';
import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, CmsContent, CmsCollection } from '@fcom/core-api';
import { ScrollService } from '@fcom/common';

import { findByType } from '../../../utils';

@Component({
  selector: 'fin-cms-json-content',
  templateUrl: './cms-json-content.component.html',
  styleUrls: ['./cms-json-content.component.scss', './premium-economy-brand-overrides.scss'],
})
export class CmsJsonContentComponent implements OnDestroy, OnInit {
  @Input()
  template$: Observable<CmsTemplate> = EMPTY;
  @Input()
  pageViewType$: Observable<string>;
  @Input()
  showSideTitle$: Observable<boolean> = of(true);

  disruptions$: Observable<CmsCollection>;
  title$: Observable<string>;

  private subscription: Subscription = new Subscription();

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;

  constructor(
    private route: ActivatedRoute,
    private scrollService: ScrollService,
    @Inject(DOCUMENT) private document: Document,
    private sentryLogger: SentryLogger
  ) {}

  ngOnInit(): void {
    this.disruptions$ = this.template$.pipe(
      map((template) =>
        template.main.reduce((acc, curr) => {
          if (curr.contentType === CmsContentType.CMPersonalized) {
            return [...acc, ...curr.items];
          }
          return [...acc, curr];
        }, [])
      ),
      findByType(CmsViewType.DISRUPTIONS)
    );
    this.title$ = this.template$.pipe(
      pluck('header', 0),
      map((item) => (item as CmsContent)?.teaserTitle || item?.title)
    );
    this.subscription.add(
      this.route.fragment.pipe(filter(Boolean), delay(0)).subscribe((fragment): void => {
        const offset: number = this.document.querySelector('header.sticky-nav')?.clientHeight || 0;
        const element: HTMLElement = this.document.getElementById(fragment);
        this.checkSegmentValidity(fragment);

        if (element) {
          this.scrollService.smoothScroll(element, { offsetY: offset });
        }
      })
    );
  }

  // Default segment when metadata URL segment not defined is contentId #
  // Check the fragment and log instances where fragment is only a string of #'s
  private checkSegmentValidity(segment: string): void {
    const isStringOfNumbers: boolean = Number.isInteger(Number(segment));

    if (isStringOfNumbers) {
      this.sentryLogger.warn('Page is deeplinking to content with numeric segment', {
        segment: segment,
      });
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}
