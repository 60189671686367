import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';

import { IconButtonTheme, IconButtonSize } from '../../../components/buttons';
import { CursorType, InputType, TextInputType } from '../enums';
import { PopoverOptions } from '../../popover';
import { AriaOptions } from '../../interfaces';
import { IconSet } from '../../../components/icons';

@Component({
  selector: 'fcom-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['text-input.component.scss'],
})
export class TextInputComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  placeHolder: string = null;

  @Input()
  placeHolderText?: string;

  @Input()
  characterCount: number;

  @Input()
  characterLimit: number;

  @Input()
  isSensitive = false;

  @Input()
  isResizeable = false;

  @Input()
  type: TextInputType = TextInputType.INPUT;

  @Input()
  rows = 6;

  @Input()
  id: string;

  @Input()
  labelPrefix: string;

  @Input()
  labelSuffix: string;

  @Input()
  label: string;

  @Input()
  displayMargin = true;

  @Input()
  visualDisable = false;

  @Input()
  required: boolean;

  @Input()
  translationErrorPrefix: string;

  @Input()
  hideLabel: boolean;

  @Input()
  inputType: InputType = InputType.TEXT;

  @Input()
  tooltipHeading: string;

  @Input()
  tooltipContent: string;

  @Input()
  useCustomTooltipAction = false;

  @Input()
  tooltipOptions: PopoverOptions;

  @Input()
  icon: IconSet;

  @Input()
  ariaOptions: AriaOptions = {};

  @Input()
  isPassword = false;

  @Input()
  clearInput = false;

  @Input()
  disableAutoComplete?: boolean;

  @Input()
  cursorType?: CursorType;

  @Output()
  tooltipClicked: EventEmitter<Event> = new EventEmitter();

  @Output()
  inputEvent: EventEmitter<Event> = new EventEmitter();

  @Output()
  iconClicked: EventEmitter<Event> = new EventEmitter();

  isRequired = false;
  showAsterix = false;
  idOrControlName: string;
  control: AbstractControl;
  isPasswordVisible: boolean = false;
  showClearIcon: boolean = false;

  @ViewChild('inputElement', { static: false })
  inputElement: ElementRef;

  @ViewChild('textareaElement', { static: false })
  textareaElement: ElementRef;

  readonly CursorType = CursorType;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly InputType = InputType;
  readonly TextInputType = TextInputType;

  private subscriptions: Subscription = new Subscription();

  get fieldRequired(): boolean {
    const hasRequiredValidator = this.ctrlField.hasValidator(Validators.required);

    /* Checking many variables here because depending on how the parent component
     * creates the validators for this field, some of these might be false or null.
     * hasRequiredValidator works correctly when parent uses Validators.required,
     * this.ctrlField.errors.required works partly when parent uses Validators.compose([Validators.required]).
     * Partly meaning that if field is pre-filled and page is refreshed, then the asterisk (*) disappears from
     * the field label. */
    return this.required || this.ctrlField.errors?.required || hasRequiredValidator;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
    this.control = this.ctrlField;
    this.isRequired = this.fieldRequired;
    this.showAsterix = this.required ?? this.isRequired;
    this.idOrControlName = this.id || this.controlName;
    this.toggleIcon();

    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        if (this.control.value) {
          return;
        }
        this.isRequired = this.fieldRequired;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  handleIconClick(): void {
    if (this.isPassword) {
      this.togglePasswordVisibility();
    } else {
      this.clearInputField();
    }
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleIcon(): void {
    this.showClearIcon = this.parentForm.get(this.controlName).value;
  }

  clearInputField(): void {
    this.parentForm.get(this.controlName).setValue('');
  }
}
