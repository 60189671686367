<ng-container *ngIf="attachments.length">
  <div class="ms-medium-t" data-testid="attachment-list">
    <ng-container *ngFor="let attachment of attachments">
      <ng-container [ngSwitch]="attachment.status">
        <fcom-notification
          *ngSwitchCase="AttachmentStatus.UPLOADING"
          class="ms-medium-t"
          data-testid="attachment-uploading"
          [iconName]="SvgLibraryIcon.ATTACHMENT_BLOCK"
          [isLoading]="true"
        >
          {{ attachment.file.name }}
        </fcom-notification>
        <fcom-notification
          class="ms-medium-t"
          *ngSwitchDefault
          [closeIcon]="{ name: SvgLibraryIcon.TRASH }"
          [iconName]="SvgLibraryIcon.ATTACHMENT_BLOCK"
          [isClosable]="true"
          (closeClick)="deleteAttachment(attachment)"
          data-testid="attachment"
        >
          {{ attachment.file.name }}
        </fcom-notification>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
