import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';

import { ButtonMode, ButtonSize, ButtonTheme, ModalButtons } from '@fcom/ui-components';
import { isPresent, unsubscribe } from '@fcom/core/utils';
import { LocationPair } from '@fcom/common/store';
import { Location } from '@fcom/core-api';
import {
  ElementActions,
  ElementTypes,
  GaContext,
  GlobalBookingTripDates,
  GtmClickOutboundLinkClass,
} from '@fcom/common';
import { finShare } from '@fcom/rx';
import { ConfigService, WindowRef, NativeBridgeService } from '@fcom/core';
import { GtmService } from '@fcom/common/gtm';

import { AmRoomSelection, LocationParam } from '../../interfaces';
import { BookingWidgetAmService } from '../../services/booking-widget-am.service';
import {
  AmLocation,
  AmHolidayType,
  AmAvailability,
  AmHolidayTypeDuration,
  AmContinueParams,
} from '../../interfaces/am-api.interface';
import { amDeepLink } from '../../utils/utils.am';

@Component({
  selector: 'fin-am-booking-widget',
  templateUrl: './am-booking-widget.component.html',
  styleUrls: ['./am-booking-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmBookingWidgetComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly ModalButtons = ModalButtons;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Output()
  setLocations = new EventEmitter<LocationPair>();

  locations$ = new BehaviorSubject<LocationPair>({});
  amOrigins$: Observable<Location[]>;
  destinationCache: { [originLocationCode: string]: AmLocation[] } = {};
  selectedRooms$ = new BehaviorSubject<AmRoomSelection[]>([]);
  travelDates$ = new BehaviorSubject<GlobalBookingTripDates>({ departureDate: undefined });
  amDestinations$ = new BehaviorSubject<AmLocation[]>([]);
  destinations$: Observable<Location[]>;
  selectedAmDestination$: Observable<AmLocation>;
  availability$: Observable<AmAvailability>;
  subscription = new Subscription();
  continueModalOpen = false;
  selectionIsValid$: Observable<boolean> = of(false);
  duration$ = new BehaviorSubject<AmHolidayTypeDuration>(undefined);
  continueParams$: Observable<AmContinueParams>;
  showCompact$: Observable<boolean>;

  constructor(
    private amService: BookingWidgetAmService,
    private configService: ConfigService,
    private windowRef: WindowRef,
    private nativeBridgeService: NativeBridgeService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.amOrigins$ = this.amService.getOrigins();

    this.selectedAmDestination$ = combineLatest([this.locations$, this.amDestinations$]).pipe(
      map(([locations, amDestinations]) =>
        amDestinations.find((destination) => destination.location.locationCode === locations.destination?.locationCode)
      ),
      filter(Boolean)
    );

    this.subscription.add(
      this.amService
        .getDestinations('HEL')
        .pipe(take(1))
        .subscribe((destinations) => {
          this.amDestinations$.next(destinations);
          this.destinationCache['HEL'] = destinations;
        })
    );

    this.destinations$ = this.amDestinations$.pipe(
      map((amDestinations) => amDestinations.map((amDestination) => amDestination.location))
    );

    this.subscription.add(
      this.amOrigins$
        .pipe(
          take(1),
          map((origins) => ({
            origin: origins.find((origin) => origin.locationCode === 'HEL'),
          }))
        )
        .subscribe((locationPair) => {
          this.locations$.next(locationPair);
        })
    );

    this.availability$ = combineLatest([this.locations$, this.selectedRooms$]).pipe(
      filter(
        ([locations, selectedRooms]) =>
          isPresent(locations.destination?.locationCode) &&
          isPresent(locations.origin?.locationCode) &&
          selectedRooms.length > 0
      ),
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      switchMap(([locations, selectedRooms]) =>
        this.amService
          .getAvailability(locations.origin.locationCode, locations.destination.locationCode, selectedRooms)
          .pipe(take(1))
      ),
      finShare()
    );

    this.continueParams$ = combineLatest([
      this.locations$,
      this.selectedRooms$,
      this.travelDates$,
      this.duration$,
      this.selectedAmDestination$,
    ]).pipe(
      map(([locations, rooms, travelDates, duration, selectedDestination]) => ({
        originLocationCode: locations.origin?.locationCode,
        destinationLocationCode: locations.destination?.locationCode,
        departureDate: travelDates.departureDate,
        returnDate: travelDates.returnDate,
        durationCode: duration?.code,
        rooms,
        flexible: false,
        holidayType: selectedDestination.holidayType,
      })),
      finShare()
    );

    this.selectionIsValid$ = this.continueParams$.pipe(map((params) => this.validateSelection(params)));

    this.showCompact$ = this.locations$.pipe(map((locations) => !isPresent(locations.destination)));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  updateRooms(selectedRooms: AmRoomSelection[]): void {
    this.selectedRooms$.next(selectedRooms);
  }

  updateLocations({ locations }: LocationParam): void {
    this.travelDates$.next({ departureDate: undefined });
    if (this.destinationCache[locations.origin.locationCode]) {
      this.amDestinations$.next(this.destinationCache[locations.origin.locationCode]);
      const filteredLocations = this.filterInvalidDestination(
        locations,
        this.destinationCache[locations.origin.locationCode]
      );
      this.locations$.next(filteredLocations);
      this.setLocations.emit(filteredLocations);
    } else {
      this.subscription.add(
        this.amService
          .getDestinations(locations.origin.locationCode)
          .pipe(take(1))
          .subscribe((destinations) => {
            this.amDestinations$.next(destinations);
            this.destinationCache[locations.origin.locationCode] = destinations;
            const filteredLocations = this.filterInvalidDestination(locations, destinations);
            this.locations$.next(filteredLocations);
            this.setLocations.emit(filteredLocations);
          })
      );
    }
  }

  setTravelDates(dates: GlobalBookingTripDates): void {
    this.travelDates$.next(dates);
  }

  openContinueModal(): void {
    this.continueModalOpen = true;

    this.subscription.add(
      this.locations$.pipe(take(1)).subscribe((locations) => {
        this.gtmService.trackElement(
          `tile-${locations.destination?.cityName?.toLowerCase().replace(/ /g, '_')}-flight-hotel`,
          GaContext.BOOKING_FLOW,
          ElementTypes.BOOKING_WIDGET,
          undefined,
          ElementActions.CLICK
        );
      })
    );
  }

  setDuration(duration: AmHolidayTypeDuration): void {
    this.duration$.next(duration);
  }

  continueToAm(): void {
    this.subscription.add(
      this.continueParams$.pipe(take(1)).subscribe((continueParams) => {
        const deepLink = amDeepLink(continueParams, this.configService.cfg.amDeeplinkUrl);

        this.gtmService.clickOutboundEvent({
          link_url: deepLink,
          link_classes: GtmClickOutboundLinkClass.BOOKING_WIDGET,
          outbound: true,
        });

        // App webview doesn't allow opening in new tab
        if (this.nativeBridgeService.isInsideNativeWebView) {
          this.windowRef.nativeWindow.location.href = deepLink;
        } else {
          this.windowRef.nativeWindow.open(deepLink, '_blank');
        }
      })
    );
  }

  validateSelection({
    originLocationCode,
    destinationLocationCode,
    rooms,
    departureDate,
    returnDate,
    durationCode,
    holidayType,
  }: AmContinueParams): boolean {
    if (holidayType === AmHolidayType.AM) {
      return Boolean(originLocationCode && destinationLocationCode && departureDate && durationCode && rooms.length);
    }

    return Boolean(originLocationCode && destinationLocationCode && departureDate && returnDate && rooms.length);
  }

  private filterInvalidDestination(locations: LocationPair, availableDestinations: AmLocation[]): LocationPair {
    return {
      origin: locations.origin,
      destination: availableDestinations.some(
        (amLocation) => amLocation.location.locationCode === locations.destination?.locationCode
      )
        ? locations.destination
        : undefined,
    };
  }
}
