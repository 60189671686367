import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Observable, Subscription, map, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

import { Amount, PaxAmount } from '@fcom/dapi';
import { finShare } from '@fcom/rx';
import { LanguageService } from '@fcom/ui-translate';
import { AirCalendarList } from '@fcom/dapi/api/models';
import { LocalDate, isPresent, unsubscribe } from '@fcom/core/utils';
import { GlobalBookingTripDates } from '@fcom/common';
import { TripType } from '@fcom/core/interfaces';
import { ButtonTheme, ButtonSize, ButtonMode, ModalButtons, NotificationTheme } from '@fcom/ui-components';
import { BookingAppState } from '@fcom/common/interfaces/booking';

import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { BookingWidgetActions } from '../../store/';
import { mapPaxAmountToPaxBreakdown } from '../../utils/utils.pax-amount';

@Component({
  selector: 'fin-air-calendar',
  templateUrl: 'air-calendar.component.html',
  styleUrls: ['./air-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirCalendarComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly TripType = TripType;
  readonly ModalButtons = ModalButtons;

  @Input()
  paxAmount$: Observable<PaxAmount>;

  @Input()
  travelDates$: Observable<GlobalBookingTripDates>;

  @Input()
  tripType$: Observable<TripType>;

  @Input()
  prices$: Observable<AirCalendarList>;

  @Input()
  isAward = false;

  @Input()
  totalPoints: number;

  @Output()
  selectDates = new EventEmitter<{ departureDate: LocalDate; returnDate: LocalDate }>();

  @Output()
  continue = new EventEmitter<string>();

  subscription = new Subscription();
  paxAmountBreakdown$: Observable<string>;
  startingPrice$ = new BehaviorSubject<Amount>({ amount: '0', currencyCode: 'EUR' });
  continueDisabled$ = new BehaviorSubject<boolean>(true);
  airCalendarOpen = false;
  readonly NotificationTheme = NotificationTheme;

  constructor(
    private languageService: LanguageService,
    private store$: Store<BookingAppState>,
    private bookingWidgetGtmService: BookingWidgetGtmService
  ) {}

  ngOnInit(): void {
    this.paxAmountBreakdown$ = this.paxAmount$.pipe(
      withLatestFrom(this.languageService.translate('passenger')),
      map(([paxAmount, passengerTranslations]) => mapPaxAmountToPaxBreakdown(paxAmount, passengerTranslations)),
      finShare()
    );

    this.subscription.add(
      this.prices$.subscribe((prices) => {
        this.airCalendarOpen = isPresent(prices);
        if (this.airCalendarOpen) {
          this.bookingWidgetGtmService.pageViewEvent('/price-matrix');
        }
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  selectCalendarDates({ dates, price }: { dates: string[]; price?: Amount }): void {
    const departureDate = new LocalDate(dates[0]);
    const returnDate = dates[1] ? new LocalDate(dates[1]) : undefined;

    this.startingPrice$.next(price);
    this.selectDates.emit({ departureDate, returnDate });
    this.bookingWidgetGtmService.trackElementEvent(
      'price-matrix',
      `DEPARTURE: ${departureDate}, RETURN: ${returnDate}`
    );
    // TODO: This is a really simple solution for enabling/disabling search from the matrix, do we need a better one?s
    this.continueDisabled$.next(false);
  }

  navigateToBookingFlow(): void {
    this.continue.emit(this.startingPrice$.getValue().amount);
  }

  closeAirCalendar(): void {
    this.store$.dispatch(BookingWidgetActions.setAirCalendarPrices({ prices: undefined }));
  }
}
