<div class="grid-col-12 col-1-12 no-gutters">
  <fin-editorial-page-head
    class="col-1-12"
    [title]="'blueWingsSEO.bluewingTitle' | finLocalization"
    [titleAsHeading]="false"
  ></fin-editorial-page-head>
</div>

<section class="grid-col-12 grid-max-width center padding-left-gutter padding-right-gutter mr-medium-y">
  <div class="col-1-12 md-col-2-11 grid-max-width no-row-gap white-bg relative rounded-large content-container">
    <fin-cms-json-content class="flex flex--column gap-large" [template$]="template$"></fin-cms-json-content>
  </div>
</section>
