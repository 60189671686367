import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  filter,
  map,
  withLatestFrom,
  distinctUntilChanged,
  combineLatest,
  startWith,
  switchMap,
  forkJoin,
  Subject,
  BehaviorSubject,
  tap,
} from 'rxjs';

import { LocationRouteCffData } from '@fcom/core-api';
import { GlobalBookingTravelClass, TripType } from '@fcom/core/interfaces';
import { finShare } from '@fcom/rx';
import { unsubscribe, isNotBlank, isPresent, LocationRouteCffService, isEmptyObject } from '@fcom/core';
import {
  CommonFeatureState,
  GlobalBookingActions,
  globalBookingTripType,
  globalBookingTravelClass,
  globalBookingAvailableTravelClasses,
  LocationPair,
  TravelClassAvailabilityMap,
  globalBookingPaxAmount,
} from '@fcom/common/store';
import { LanguageService } from '@fcom/ui-translate';
import { profile } from '@fcom/core/selectors';
import { mapCffsToTravelClasses } from '@fcom/common/utils/booking.utils';
import { PaxAmount } from '@fcom/dapi/interfaces';

import { BookingWidgetActions, BookingWidgetAppState, activeTab } from '../store';
import { WidgetTab, AmountUpdateType, PaxUpdateEvent, NotificationWarning } from '../interfaces';
import { AVAILABLE_TRIP_TYPES } from '../constants';
import { BookingWidgetGtmService } from './booking-widget-gtm.service';
import { BookingWidgetFlightService } from './booking-widget-flight.service';
import { getGtmPassengers } from '../utils/utils.gtm';

@Injectable({ providedIn: 'root' })
export class BookingWidgetTripService implements OnDestroy {
  readonly activeTab$: Observable<WidgetTab>;
  readonly selectedTripType$: Observable<TripType>;
  readonly availableTripTypes$: Observable<TripType[]>;
  readonly selectedTravelClass$: Observable<GlobalBookingTravelClass>;
  readonly availableTravelClasses$: Observable<GlobalBookingTravelClass[]>;
  readonly paxAmount$: Observable<PaxAmount>;
  private subscription = new Subscription();
  private setPaxDetailsGtm$: Subject<boolean> = new Subject<boolean>();
  private _routeCffReady$ = new BehaviorSubject<boolean>(false);

  get routeCffReady$(): Observable<boolean> {
    return this._routeCffReady$.asObservable();
  }

  constructor(
    private store$: Store<CommonFeatureState & BookingWidgetAppState>,
    private languageService: LanguageService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private locationRouteCffService: LocationRouteCffService,
    private bookingWidgetFlightService: BookingWidgetFlightService
  ) {
    this.selectedTripType$ = this.store$.pipe(
      globalBookingTripType(),
      map((tripType: TripType) => tripType ?? TripType.RETURN),
      finShare()
    );

    this.activeTab$ = this.store$.pipe(
      activeTab(),
      map((tab: WidgetTab) => tab ?? WidgetTab.FLIGHT),
      distinctUntilChanged(),
      finShare()
    );

    this.availableTripTypes$ = this.activeTab$.pipe(
      map((tab: WidgetTab) =>
        tab === WidgetTab.AWARD ? AVAILABLE_TRIP_TYPES.filter((t) => t !== TripType.MULTICITY) : AVAILABLE_TRIP_TYPES
      ),
      finShare()
    );

    this.paxAmount$ = this.store$.pipe(globalBookingPaxAmount(), finShare());

    this.availableTravelClasses$ = this.store$.pipe(globalBookingAvailableTravelClasses(), finShare());

    this.selectedTravelClass$ = this.store$.pipe(globalBookingTravelClass(), distinctUntilChanged(), finShare());

    // subscription
    this.setTripTypeBasedOnTab();
    this.getAvailableTravelClasses(this.bookingWidgetFlightService.bookingLocations$);
    this.setPaxDetailsGtm();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  setTravelClass(travelClass: GlobalBookingTravelClass): void {
    this.bookingWidgetGtmService.trackElementEvent('travel-class', travelClass);
    this.store$.dispatch(GlobalBookingActions.setTravelClass({ travelClass }));
  }

  setActiveTab(tab: WidgetTab): void {
    this.store$.dispatch(BookingWidgetActions.setActiveTab({ activeTab: tab }));
  }

  setTripType(tripType: TripType): void {
    if (!tripType) {
      return;
    }

    this.bookingWidgetGtmService.trackElementEvent('travel-type', tripType.toUpperCase());
    this.store$.dispatch(GlobalBookingActions.setTripType({ tripType }));

    if (tripType === TripType.MULTICITY) {
      // we need to reset discount code when going to multicity tab because its not in use
      this.store$.dispatch(GlobalBookingActions.setDiscountCode(null));
    }
  }

  setReturnTripType(): void {
    this.setTripType(TripType.RETURN);
    this.bookingWidgetGtmService.trackElementEvent('add-return-button');
  }

  setPaxAmount({ paxType, amount, updateType }: PaxUpdateEvent): void {
    if (updateType === AmountUpdateType.DECREMENT) {
      this.store$.dispatch(GlobalBookingActions.decreasePaxAmountField({ field: paxType, decrement: amount }));
    } else {
      this.store$.dispatch(GlobalBookingActions.increasePaxAmountField({ field: paxType, increment: amount }));
    }
    this.setPaxDetailsGtm$.next(true);
  }

  private getAvailableTravelClasses(bookingLocations$: Observable<LocationPair[]>): void {
    this.subscription.add(
      combineLatest([
        bookingLocations$.pipe(
          map((locations) =>
            locations.filter(
              ({ origin, destination }) => isNotBlank(origin?.locationCode) && isNotBlank(destination?.locationCode)
            )
          ),
          distinctUntilChanged(
            (prev, next) =>
              prev.length === next.length &&
              next.every(
                (l, i) =>
                  l.origin?.locationCode === prev[i]?.origin?.locationCode &&
                  l.destination?.locationCode === prev[i]?.destination?.locationCode
              )
          )
        ),
        this.activeTab$,
        this.store$.pipe(profile(), startWith(undefined), finShare()),
      ])
        .pipe(
          filter(
            ([locations, activeTab, profile]) =>
              locations.length > 0 &&
              (activeTab === WidgetTab.FLIGHT || (activeTab === WidgetTab.AWARD && isPresent(profile))) &&
              locations.every((location) => location.origin && location.destination)
          ),
          tap(() => this._routeCffReady$.next(false)),
          switchMap(([validLocations, tab, userProfile]) =>
            forkJoin(
              validLocations.map(({ origin, destination }) =>
                this.locationRouteCffService.routeCffsFor(
                  origin.locationCode,
                  destination.locationCode,
                  tab === WidgetTab.AWARD ? 'true' : 'false',
                  this.languageService.localeValue,
                  userProfile?.isCorporate ? 'true' : 'false'
                )
              )
            ).pipe(map((routeCffDataForFlights) => ({ routeCffDataForFlights, tab })))
          ),
          map(({ routeCffDataForFlights, tab }) => {
            if (routeCffDataForFlights.some((cffData) => isEmptyObject(cffData))) {
              this.store$.dispatch(
                BookingWidgetActions.setNotificationWarning({
                  key:
                    tab === WidgetTab.AWARD
                      ? NotificationWarning.NO_AWARD_FLIGHTS_FOR_ROUTE
                      : NotificationWarning.NO_FLIGHTS_FOR_ROUTE,
                  isActive: true,
                })
              );
              return undefined;
            }
            this._routeCffReady$.next(true);
            return this.mapCffsToAvailableTravelClasses(routeCffDataForFlights);
          }),
          filter(Boolean),
          finShare()
        )
        .subscribe((availableTravelClass) => {
          this.store$.dispatch(GlobalBookingActions.setAvailableClasses({ availability: availableTravelClass }));
        })
    );
  }

  private mapCffsToAvailableTravelClasses(locationRouteCffData: LocationRouteCffData[]): TravelClassAvailabilityMap {
    return locationRouteCffData
      .map((cffsForFlight) =>
        mapCffsToTravelClasses(cffsForFlight).filter((travelClass) => travelClass !== GlobalBookingTravelClass.FIRST)
      )
      .flat()
      .reduce((uniqueClasses, classes) => {
        uniqueClasses[classes] = true;
        return uniqueClasses;
      }, {}) as TravelClassAvailabilityMap;
  }

  private setTripTypeBasedOnTab(): void {
    // We don't offer multi-city for award flow so we default to return if multi-city is selected
    this.subscription.add(
      this.activeTab$
        .pipe(
          withLatestFrom(this.selectedTripType$),
          filter(([tab, tripType]) => tab === WidgetTab.AWARD && tripType === TripType.MULTICITY)
        )
        .subscribe(() => {
          this.setTripType(TripType.RETURN);
        })
    );
  }

  private setPaxDetailsGtm(): void {
    this.subscription.add(
      this.setPaxDetailsGtm$
        .pipe(
          filter((value) => Boolean(value)),
          withLatestFrom(this.paxAmount$),
          map(([_, paxAmount]) => paxAmount)
        )
        .subscribe((paxDetails: PaxAmount) => {
          const { adults, children, infants } = getGtmPassengers(paxDetails);
          this.bookingWidgetGtmService.trackElementEvent(
            'pax-amount',
            `MAINPAX: ${adults}, CHD: ${children}, INF: ${infants}, MAINPAXTYPE: ADT`
          );
          this.setPaxDetailsGtm$.next(false);
        })
    );
  }
}
