<div class="wrapper flex flex--column rounded-large border">
  <div class="card card-container flex">
    <div class="product-image relative">
      <fcom-image
        *ngIf="image"
        class="picture"
        [ratio]="imageRatio$ | async"
        [alt]="image.alt"
        [fillParent]="true"
        [imageData]="image"
        [style.minHeight.px]="notifications.clientHeight"
      ></fcom-image>
      <div class="notification" [ngClass]="{ 'mr-small has-image': image }" #notifications>
        <ng-content select="fcom-notification, [notifications]"></ng-content>
      </div>
    </div>

    <div class="details font-body-1 white-bg ps-medium flex-1" [ngClass]="{ 'has-image': image }">
      <div class="destinations font-heading-3 nordic-blue-900-text">
        <span *ngIf="title; else destinationList">{{ title }}</span>
        <ng-template #destinationList>
          <span [title]="destinations ? (destinations | finMap: 'name')?.join(' - ') : ''">
            <span
              *ngFor="let pair of destinationPairs; index as i; last as last; trackBy: trackByFn"
              class="destination ms-xsmall-r"
            >
              <span>{{ pair[0] }}</span>
              <fcom-icon class="icon-medium ms-xxsmall-x" [name]="journeyIcon"></fcom-icon>
              <span>{{ pair[1] }}</span>
              <span *ngIf="!last">, </span>
            </span>
          </span>
        </ng-template>
      </div>
      <div class="timeline nordic-blue-900-text ms-small-t">
        {{ departureDate }}{{ arrivalDate ? ' - ' + arrivalDate : '' }}
      </div>
      <div class="passengers pr-xsmall-r" *ngIf="passengerAmount">
        {{
          (passengerAmount === 1 ? 'tripCard.passenger' : 'tripCard.passengers') | finLocalization: { passengerAmount }
        }}
      </div>
      <div class="ms-small-t" *ngIf="journeyType || bookingRef">
        <fcom-tag class="booking-ref ms-xxsmall-r" *ngIf="bookingRef">{{
          'tripCard.bookingRef' | finLocalization: { bookingRef }
        }}</fcom-tag>
        <fcom-tag class="flight-type" *ngIf="journeyType">{{
          'tripCard.tripType.' + journeyType | finLocalization
        }}</fcom-tag>
      </div>
      <span
        *ngFor="let operatingAirlineId of operatingAirlineIds"
        [attr.data-testid]="'operating-airline-' + operatingAirlineId.airlineCode"
        class="ms-xsmall-l font-body-2 overflow-hidden truncate"
      >
        {{
          'tripCard.operatingAirlineMessage'
            | finLocalization: { airlineName: operatingAirlineId.airlineName, airlineId: operatingAirlineId.id }
        }}
      </span>
      <div
        *ngIf="!showExpandButton"
        class="flex flex--space-between flex--align-center ms-small-t font-heading-4 nordic-blue-900-text"
      >
        <div *ngIf="totalPoints || flightTotalPriceAmount">
          <span *ngIf="totalPoints" data-testid="trip-card-total-points">
            {{ 'award.points' | finLocalization: { pointsAmount: totalPoints || 0 } }}&nbsp;+
          </span>
          <span *ngIf="flightTotalPriceAmount" class="total-amount" data-testid="trip-total-amount">
            {{ flightTotalPriceAmount }}
          </span>
        </div>
        <fcom-button
          *ngIf="showStartOverButton"
          class="start-over"
          (clickBtn)="clickCta($event)"
          [icon]="{ name: SvgLibraryIcon.ARROW_BACK, position: IconPosition.RIGHT }"
          [text]="'tripCard.startOver' | finLocalization"
          [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        ></fcom-button>
      </div>

      <div *ngIf="showExpandButton" class="expand-details flex flex--end ms-small-t">
        <button
          [attr.aria-label]="(expanded ? 'tripCard.ariaHide' : 'tripCard.ariaShow') | finLocalization"
          [attr.aria-controls]="expandContainerId"
          [attr.aria-expanded]="expanded"
          [id]="expandButtonId"
          (click)="toggleExpand($event)"
          class="font-body-1 mr-small-l nordic-blue-900-text underline medium-type"
        >
          <span>{{ (expanded ? 'tripCard.hideFlights' : 'tripCard.showFlights') | finLocalization }}</span>
          <fcom-icon
            [class.chevron-open]="expanded"
            class="icon-medium ms-xxsmall-l chevron"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
          >
          </fcom-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="showExpandButton">
    <fcom-expand [open]="expanded" [ariaId]="expandContainerId" [ariaLabelledBy]="expandButtonId" class="block">
      <div class="expanded-content">
        <ng-content></ng-content>
      </div>
    </fcom-expand>
  </div>
</div>
