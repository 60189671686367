import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { map, NEVER, Observable } from 'rxjs';

import { CmsCollection, CmsTeaser } from '@fcom/core-api';

@Component({
  selector: 'cms-marketing-activity-blocks',
  templateUrl: './cms-marketing-activity-blocks.component.html',
  styleUrls: ['./cms-marketing-activity-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsMarketingActivityBlocksComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection<CmsTeaser>>;

  mappedContent$: Observable<CmsCollection<CmsTeaser>>;

  readonly NEVER = NEVER;

  ngOnInit(): void {
    this.mappedContent$ = this.content$.pipe(
      map((content) => ({
        ...content,
        items: content.items.map((item) => ({
          ...item,
          target: {
            ...item.target,
            title: item.linkText,
          },
        })),
      }))
    );
  }
}
