import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ScrollService } from '@fcom/common/services';
import { unsubscribe } from '@fcom/core/utils';

import { Direction } from '../../interfaces';

const SCROLL_BUTTON_HIDE_MARGIN = 10;

@Component({
  selector: 'fin-scroll-handle-container',
  templateUrl: './scroll-handle-container.component.html',
  styleUrls: ['./scroll-handle-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollHandleContainerComponent implements AfterViewInit, OnDestroy {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @ViewChild('container', { static: false, read: ElementRef }) container: ElementRef;
  @ViewChild('scrollHandleLeft', { static: false, read: ElementRef }) scrollHandleLeft: ElementRef;
  @ViewChild('scrollHandleRight', { static: false, read: ElementRef }) scrollHandleRight: ElementRef;

  @Input()
  elements: QueryList<ElementRef>;

  @Input()
  disableKeyboardNavigation = false;

  @Output()
  scrollElements: EventEmitter<Direction> = new EventEmitter();

  subscription: Subscription = new Subscription();

  constructor(private scrollService: ScrollService) {}

  ngAfterViewInit(): void {
    this.updateScrollHandles();
    const scrollListener$ = this.scrollService.listen(this.container, false);
    this.subscription.add(
      scrollListener$.pipe(debounceTime(100)).subscribe(() => {
        this.updateScrollHandles();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  scrollToElement(element: ElementRef): void {
    if (!(element && element.nativeElement)) {
      return;
    }
    const container = this.container.nativeElement;
    this.scrollService.smoothScroll(element.nativeElement, { container, edgeX: 'center' });
  }

  updateScrollHandles(): void {
    const [showLeftScrollButton, showRightScrollButton] = this.getScrollHandlesVisibility();

    this.scrollHandleLeft.nativeElement.hidden = !showLeftScrollButton;
    this.scrollHandleRight.nativeElement.hidden = !showRightScrollButton;
  }

  scrollRight(): void {
    this.scrollElements.emit(Direction.RIGHT);
    this.scrollToElementByIndex(this.getVisibleElementIndex() + 1);
  }

  scrollLeft(): void {
    this.scrollElements.emit(Direction.LEFT);
    this.scrollToElementByIndex(this.getVisibleElementIndex() - 1);
  }

  private getScrollHandlesVisibility(): [boolean, boolean] {
    if (!this.container || !this.container.nativeElement) {
      return [false, false];
    }

    const element = this.container.nativeElement;
    const contentWidth = element.scrollWidth;
    const visibleWidth = element.offsetWidth;
    const scrollPosition = element.scrollLeft;

    const showLeftScrollButton: boolean = scrollPosition > SCROLL_BUTTON_HIDE_MARGIN;
    const showRightScrollButton: boolean = scrollPosition + visibleWidth < contentWidth - SCROLL_BUTTON_HIDE_MARGIN;

    return [showLeftScrollButton, showRightScrollButton];
  }

  // Get index of the element closest to the container center
  private getVisibleElementIndex(): number {
    const container = this.container.nativeElement;
    const containerRect = container.getBoundingClientRect();
    const containerCenter = containerRect.width / 2;
    const allChildren = this.elements?.toArray() || [];

    const sorted = [...allChildren]
      .map((cardElement: ElementRef, index) => {
        const cardRect = cardElement.nativeElement.getBoundingClientRect();
        const cardRelativeLeft = cardRect.left - containerRect.left;
        const cardRelativeCenter = cardRect.width / 2 + cardRelativeLeft;
        return {
          distanceToCenter: Math.abs(cardRelativeCenter - containerCenter),
          index,
        };
      })
      .sort((a, b) => a.distanceToCenter - b.distanceToCenter);

    return sorted.length ? sorted[0].index : 0;
  }

  private scrollToElementByIndex(index: number): void {
    const container = this.container.nativeElement;
    const targetElement: ElementRef = this.elements?.toArray()[index];
    if (targetElement) {
      this.scrollService.smoothScroll(targetElement.nativeElement, { container, edgeX: 'center' });
    }
  }
}
