import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';

import { CustomIcon, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, map } from 'rxjs';

import { ElementTypes, GaContext } from '@fcom/common/interfaces';
import { CmsArticle, CmsTaxonomyGeneral } from '@fcom/core-api';
import { LanguageService, LocalizationPipe } from '@fcom/ui-translate';
import {
  ButtonTheme,
  IconPosition,
  NotificationButtonStyle,
  NotificationLayout,
  NotificationTheme,
} from '@fcom/ui-components';

import { SalesforceChatService } from '../../../../chat';

@Component({
  selector: 'fin-emergency-in-operations-article',
  templateUrl: './emergency-in-operations-article.component.html',
  styleUrl: './emergency-in-operations-article.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyInOperationsArticleComponent implements OnInit {
  readonly ButtonTheme = ButtonTheme;
  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;
  readonly CustomIcon = CustomIcon;
  readonly ElementTypes = ElementTypes;
  readonly GaContext = GaContext;
  readonly IconPosition = IconPosition;
  readonly NotificationButtonStyle = NotificationButtonStyle;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  homeLink$: Observable<string>;
  publishedText: string;
  updatedText: string;
  emergencyInOperationsTexts: { [key: string]: string };

  content = input<CmsArticle>();

  constructor(
    private languageService: LanguageService,
    private chatService: SalesforceChatService,
    private finLocalization: LocalizationPipe
  ) {}

  ngOnInit(): void {
    this.homeLink$ = this.languageService.lang.pipe(map((lang): string => `/${lang}`));
    this.publishedText = this.finLocalization.transform('shared.published');
    this.updatedText = this.finLocalization.transform('shared.updated');
    this.emergencyInOperationsTexts = this.finLocalization.transform('emergencyInOp');
  }

  /**
   * Use existing openChat$ logic to bypass pre-chat view
   * when element in the article is clicked
   * logic for chat to use Chatbot_FTH on darksite page is done from
   * salesforce chat config service file. (needed to be evaluated prior to
   * page rendering)
   */
  initChat(): void {
    this.chatService.openChat$.next({
      firstName: null,
      lastName: null,
      email: null,
    });
  }
}
