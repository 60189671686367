import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonIcon, ButtonMode, ButtonTheme } from '../button/index';
import { ButtonLayout, ButtonPosition } from './enums';

/**
 * This component contains the cta button used during booking flows.
 *
 * @example
 * <b>Example with basic options</b>
 *
 * <code>
 *   <pre>
 *     <fcom-cta-button
 *       [position]="position"
 *       [visible]="visible"
 *       [title]="title"
 *       [disabled]="disabled"
 *       (buttonClicked)="click($event)">
 *       <span [innerHTML]="content"></span>
 *     </fcom-cta-button>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonMode = ButtonMode;
  readonly ButtonPosition = ButtonPosition;
  readonly ButtonLayout = ButtonLayout;

  /**
   * The text to display in the button
   */
  @Input() title: string;

  @Input() secondaryButtonTitle: string;

  @Input() showSecondaryButton = false;

  /**
   * Whether the button should be visible or hidden (but still in the dom)
   */
  @Input() visible = true;

  /**
   * Disables the buttons
   */
  @Input() disabled = false;

  @Input() secondaryButtonDisabled = false;

  @Input() isTabletUp = false;

  @Input() loading = false;

  @Input() icon: ButtonIcon;

  @Input() theme: ButtonTheme = ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER;

  /**
   * Controls the positioning of the button
   */
  @Input() position: ButtonPosition = ButtonPosition.GRID;

  @Input() layout: ButtonLayout = ButtonLayout.ROW;

  @Output() buttonClicked: EventEmitter<Event> = new EventEmitter<Event>();

  @Output() secondaryButtonClicked: EventEmitter<Event> = new EventEmitter<Event>();
}
