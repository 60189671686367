export const ATTACHMENT_ALLOWED_FILE_EXTENSIONS = ['bmp', 'doc', 'docx', 'gif', 'jpeg', 'jpg', 'pdf', 'png', 'txt'];

export const ATTACHMENT_MAX_SIZE_IN_BYTES = 3670016; //3.5MB

export const MAX_ATTACHMENTS_5 = 5;

export const MAX_ATTACHMENTS_10 = 10;

export const TEXTAREA_LIMIT_100 = 100;

export const TEXTAREA_LIMIT_1000 = 1000;

export const TEXTAREA_LIMIT_3000 = 3000;
