<div class="nordic-blue-25-bg ps-small ms-small-b rounded-large font-body-1 relative">
  <fcom-text-input
    #searchInput
    [parentForm]="form"
    [controlName]="'search'"
    [translationPrefix]="'paxDetails.corporate.selectPassenger'"
    [displayMargin]="false"
    [disableAutoComplete]="true"
    [clearInput]="true"
  ></fcom-text-input>
  <div *ngIf="filteredUsers$ | async as users" class="absolute fill z1 ms-xxsmall-t ps-large-r">
    <div class="white-bg shadow border overflow-hidden rounded-large">
      <ul class="no-list-style" role="listbox">
        <li
          *ngFor="let user of users; let isLast = last"
          class="search-result"
          role="option"
          [ngClass]="{ 'border-bottom': !isLast }"
        >
          <a href="#" class="nordic-blue-900-text no-underline block ps-small" (click)="selectUser(user, $event)">
            {{ user.firstname }} {{ user.lastname }}
          </a>
        </li>
        <li *ngIf="!users.length" class="ps-small">
          {{ 'paxDetails.corporate.noMatches' | finLocalization }}
        </li>
      </ul>
    </div>
  </div>
</div>
