import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  PLATFORM_ID,
  AfterViewInit,
} from '@angular/core';
import { Location as Location_2, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';

import { map, Observable, of, Subscription, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { v4 as uuid } from 'uuid';

import { LocationPair } from '@fcom/common/store';
import { TripType, HotjarService, HotjarCaptureEvent, MultivariateTestId, TestVariant } from '@fcom/common';
import { ButtonTheme, ButtonSize, ButtonMode, TabTheme, LoaderTheme } from '@fcom/ui-components';
import { ConfigService, LocalDate } from '@fcom/core';
import { unsubscribe } from '@fcom/core/utils';
import { LoginStatus } from '@fcom/core-api/login';
import { BookingAndAppState } from '@fcom/common/interfaces/booking';
import { casProfileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors/login.selector';
import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';

import { WidgetTab, WidgetLayout } from '../../interfaces';
import { ANIMATION_DURATION } from '../../constants';
import { BookingWidgetService } from '../../services/booking-widget.service';
import { BookingWidgetTripService } from '../../services/booking-widget-trip.service';

function isWidgetTab(tab: any): tab is WidgetTab {
  return Boolean(Object.values(WidgetTab).includes(tab));
}

@Component({
  selector: 'fin-booking-widget',
  templateUrl: './booking-widget.component.html',
  styleUrl: './booking-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('smoothGrow', [
      transition('* => *', [
        animate(
          `${ANIMATION_DURATION}ms cubic-bezier(0.4, 0.0, 0.2, 1)`,
          keyframes([style({ height: '{{ startHeight }}' }), style({ height: '*' })])
        ),
      ]),
    ]),
  ],
})
export class BookingWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly TripType = TripType;
  readonly TabTheme = TabTheme;
  readonly WidgetTab = WidgetTab;
  readonly LoginStatus = LoginStatus;
  readonly LoaderTheme = LoaderTheme;
  readonly WidgetLayout = WidgetLayout;

  @Input()
  defaultLocations$: Observable<LocationPair[]> = of([]);

  @Input()
  headingLevel: 1 | 2 = 1;

  @Input()
  enableAurinkomatkat = true;

  @Input()
  enableAward = true;

  @Input()
  enablePromoCode = true;

  @Input()
  initialTripType: TripType;

  @Input()
  layout: WidgetLayout = WidgetLayout.DEFAULT;

  @Input()
  compactMode = false;

  @ViewChild('widgetRoot', { static: true })
  widgetRoot: ElementRef;

  tripType$: Observable<TripType>;
  activeTab$: Observable<WidgetTab>;
  loginStatus$: Observable<LoginStatus>;
  rootContainerStartHeight$: Observable<string> = of('auto');
  isInitialRender: boolean;
  amTabEnabled$: Observable<boolean>;
  subscription = new Subscription();
  isAm$: Observable<boolean>;
  calendarIdentifier: string;
  showCompact$ = of(true);
  isFrontPage$ = of(false);
  titleText$: Observable<string>;
  h1Style$: Observable<{ display?: string }>;
  isFrontPagePersonalisationTestVariant$: Observable<boolean>;

  carTrawlerTabEnabled: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hotjarService: HotjarService,
    private bookingWidgetService: BookingWidgetService,
    private location: Location_2,
    private configService: ConfigService,
    private bookingWidgetTripService: BookingWidgetTripService,
    private store$: Store<BookingAndAppState>,
    private languageService: LanguageService,
    private multivariateTestService: MultivariateTestService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.carTrawlerTabEnabled = this.configService.cfg.enableBwThirdPartyAncillaries;
    this.bookingWidgetService.setUsePopoverSelectors(false);
    this.calendarIdentifier = uuid();
    const activeTabFromRouteParams = this.route.snapshot?.queryParams['tab'] ?? this.route.snapshot?.data.tab;
    if (activeTabFromRouteParams && Object.values(WidgetTab).includes(activeTabFromRouteParams)) {
      this.bookingWidgetTripService.setActiveTab(activeTabFromRouteParams);
      this.router.navigate([], {
        queryParams: { tab: null },
        queryParamsHandling: 'merge',
      });
    }

    const casProfile$ = this.store$.pipe(casProfileOrUndefinedIfNotLoggedIn(), finShare());

    this.isFrontPagePersonalisationTestVariant$ = this.multivariateTestService
      .getTestVariant(MultivariateTestId.FRONT_PAGE_PERSONALISATION)
      .pipe(map(({ variant }) => variant === TestVariant.B));

    this.titleText$ = casProfile$.pipe(
      switchMap((casProfile) => {
        if (casProfile) {
          const personalisedLabelKey =
            casProfile.previousLoginDate &&
            new LocalDate(casProfile.previousLoginDate.substring(0, 10)).lt(LocalDate.now().minusMonths(6))
              ? 'bookingSearch.personalisedTitle.withoutRecentLogin'
              : 'bookingSearch.personalisedTitle.withRecentLogin';

          return this.languageService.translate(personalisedLabelKey, {
            name: casProfile.firstName,
          });
        }
        return this.languageService.translate('bookingSearch.personalisedTitle.loggedOut');
      })
    );

    this.h1Style$ = this.isFrontPagePersonalisationTestVariant$.pipe(
      switchMap((isVariantB) =>
        isVariantB
          ? isPlatformBrowser(this.platform)
            ? this.titleText$.pipe(map(() => ({ display: 'inline' })))
            : of({ display: 'none' })
          : of({ display: 'inline' })
      )
    );

    this.showCompact$ = this.bookingWidgetService.showCompact$.pipe(
      map((showCompact) => showCompact && this.compactMode)
    );
    this.amTabEnabled$ = this.bookingWidgetService.isAMTabEnabled(this.enableAurinkomatkat);

    // // Selections
    this.tripType$ = this.bookingWidgetTripService.selectedTripType$;
    // Utility
    this.activeTab$ = this.bookingWidgetTripService.activeTab$;
    this.loginStatus$ = this.bookingWidgetService.loginStatus$;

    // This is to check whether there is any location history saved (if it is the initial render or not)
    // This is coupled with the compact widget behavior since we only want to compact mode to happen on first render of the app.
    const isInitialRender = (this.location.getState() as { navigationId: number })?.navigationId === 1;
    if (isInitialRender) {
      this.bookingWidgetService.resetSelection();
      this.bookingWidgetService.setCompactWidget(true);
    }

    //TODO: remove the checker when the multicity e2e test ready
    this.isFrontPage$ = this.defaultLocations$.pipe(
      map((locations) => locations.length == 0) // Converts the value to a boolean
    );

    if (this.initialTripType) {
      this.bookingWidgetTripService.setTripType(this.initialTripType);
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      // To animate the height transition properly we need to have some specific value what the transition start is based on
      this.rootContainerStartHeight$ = this.showCompact$.pipe(
        map(() => {
          const height = (this.widgetRoot.nativeElement as HTMLElement).getBoundingClientRect().height;
          return height ? `${height}px` : 'auto';
        })
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  setActiveTab({ data }: { index: number; data: unknown }): void {
    if (isWidgetTab(data)) {
      this.bookingWidgetTripService.setActiveTab(data);
    }
  }

  startHotjarCapture(): void {
    this.subscription.add(this.hotjarService.startCapture(HotjarCaptureEvent.BOOKING_WIDGET).subscribe());
  }

  onAmLocationChanged(): void {
    this.bookingWidgetService.expandCompactWidget();
  }
}
