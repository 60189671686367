import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { BUS_CONNECTION_LOCATION_CODES, RAIL_AND_FLY_LOCATION_CODES } from '@fcom/core/constants';

interface Airport {
  dateTime: string;
  city: string;
  airportCode: string;
  airport: string;
}

/**
 * @Unstable
 * Warning! This component is in active development and is not production ready.
 *
 * Documentation can be found in `storybook/stories/flights/timeline.stories.mdx`
 */
@Component({
  selector: 'fcom-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit {
  /**
   * Object containing the information for the Departure airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of departure @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true }) departure: Airport;

  /**
   * Object containing the information for the Arrival airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of arrival @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true }) arrival: Airport;

  @Input() isByBusOnly = false;
  @Input() isByTrainOnly = false;

  departureIcon: SvgLibraryIcon;
  arrivalIcon: SvgLibraryIcon;

  ngOnInit(): void {
    if (this.isByBusOnly) {
      this.departureIcon = SvgLibraryIcon.BUS;
      this.arrivalIcon = SvgLibraryIcon.BUS;

      return;
    }

    if (this.isByTrainOnly) {
      this.departureIcon = SvgLibraryIcon.TRAIN;
      this.arrivalIcon = SvgLibraryIcon.TRAIN;

      return;
    }

    this.departureIcon = this.getIcon(this.departure.airportCode, true);
    this.arrivalIcon = this.getIcon(this.arrival.airportCode, false);
  }

  private getIcon(airportCode: string, isDeparture: boolean): SvgLibraryIcon {
    if (BUS_CONNECTION_LOCATION_CODES.includes(airportCode.toUpperCase())) {
      return SvgLibraryIcon.BUS;
    }

    if (RAIL_AND_FLY_LOCATION_CODES.includes(airportCode.toUpperCase())) {
      return SvgLibraryIcon.TRAIN;
    }

    return isDeparture ? SvgLibraryIcon.DEPARTURE : SvgLibraryIcon.ARRIVAL;
  }
}
