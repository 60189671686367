import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, share, switchMap, take, tap, timeout } from 'rxjs/operators';

import { BookingAndAppState } from '@fcom/common/interfaces/booking';
import {
  CasFlowLoginResponseCustomData,
  CasFlowLostPasswordResponse,
  CasFlowResponse,
  CasLoginResponse,
  CasProfileResponse,
  IframeLoginPostMessage,
  LoginPhaseError,
  LoginPhaseProfile,
  LoginPostMessage,
  LoginResponse,
  LoginStep,
  PostLostPasswordBy,
  ProfileRequestCache,
  ProfileRequestType,
  WebViewLoginPostMessage,
} from '@fcom/core-api/login';
import { LoginActions } from '@fcom/core/actions';
import { WindowRef } from '@fcom/core/providers';
import { ConfigService, NativeBridgeService, SentryLogger, StorageService } from '@fcom/core/services';
import { PersonalizationTrackingService } from '@fcom/loyalty-personalization';
import { finShare, retryWithBackoff, safeMap, snapshot } from '@fcom/rx';
import { buildUrl, mapError, serializeParams, unsubscribe } from '@fcom/core/utils';
import {
  createRedirectUrl,
  LoginService,
  parseProfile,
  RedirectToLoginParams,
  RedirectUrlParameters,
  secureRandomString,
} from '@fcom/common/login';
import { ddsLanguage, locale, loginPhase } from '@fcom/core/selectors';
import { DdsCasAdditionalParams, DdsCasRedirectParams, DdsPageType } from '@fcom/core/interfaces';

const isIframeMessage = (o: LoginPostMessage): o is IframeLoginPostMessage => !!o && o.eventType === 'loginSuccess';
const isWebViewMessage = (o: LoginPostMessage): o is WebViewLoginPostMessage =>
  !!o && (o.eventType === 'mobileAppLoginSuccess' || o.eventType === 'mobileAppGuestLogin');

const isSuccessResponse = (o: any): o is LoginResponse => !!o && o.profile && o.profile.partStatus;

const LOGIN_INIT_PATH = '/init';
const LOGOUT_INIT_PATH = '/logout';

const enum DisplayPath {
  LOGIN = 'login/finnair-plus',
  FORGOT_PASSWORD = 'login/finnair-plus/forgot-password',
  CORPORATE_LOGIN = 'login/corporate',
  JOIN = 'join/finnair-plus',
  SIMPLIFIED_LOGIN = 'login/simplified/finnair',
}

const enum SsoState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type SsoStateResponse = {
  state: SsoState;
};

const CAS_ROUTES = {
  '2fa/gauth': LoginStep.TWO_FACTOR_CODE,
  '2fa/gauth-otp': LoginStep.TWO_FACTOR_PHONE,
  '2fa/sms-auth': LoginStep.TWO_FACTOR_SMS,
  '2fa/email-auth': LoginStep.TWO_FACTOR_EMAIL,
  'account-locked': LoginStep.LOCKED,
};

@Injectable()
export class ClientLoginService extends LoginService implements OnDestroy {
  /**
   * Triggers the login/init functionality with nonce as the value of the subject.
   * @type {Subject<string>}
   */
  private readonly initLoginTrigger$: Subject<string> = new Subject<string>();

  /**
   * The cryptographically secure nonce that is used for heightened trust in
   * app <-> login iframe <-> CAS communication
   */
  private nonce: string;

  /**
   * For debugging purposes only
   */
  private loginCount = 0;
  private timeoutHandle: ReturnType<typeof setTimeout>;
  private crypto: Crypto;

  private subscription = new Subscription();

  constructor(
    private sentryLogger: SentryLogger,
    private store$: Store<BookingAndAppState>,
    private http: HttpClient,
    private configService: ConfigService,
    private windowRef: WindowRef,
    private ngZone: NgZone,
    private storageService: StorageService,
    private nativeBridgeService: NativeBridgeService,
    private personalizationTrackingService: PersonalizationTrackingService
  ) {
    super();
    this.crypto = this.windowRef.nativeWindow['crypto'] || this.windowRef.nativeWindow['msCrypto'];
  }

  initSsoLoginSession(): void {
    if (this.nativeBridgeService.isInsideNativeWebView && !this.nativeBridgeService.isLocalView) {
      // Mobile app login flow handled without the CAS SSO session. Allow local mimic to use normal login feature
      return;
    }

    this.loginCount++;
    const nonce = secureRandomString(this.crypto);
    this.nonce = nonce;
    this.subscription.add(
      this.isLoggedInSsoSession()
        .pipe(take(1))
        .subscribe((b) => {
          if (!b) {
            this.store$.dispatch(LoginActions.setNotLoggedIn());
          } else {
            this.initLoginTrigger$.next(nonce);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  handleLoginEvent(loginMessage: LoginPostMessage): void {
    if (isIframeMessage(loginMessage)) {
      this.handleIframeMessage(loginMessage);
    } else if (isWebViewMessage(loginMessage)) {
      this.handleWebViewMessage(loginMessage);
    }
    // loginMessage is some other window.postMessage and not intended for us
  }

  get storedNonce(): string {
    return this.nonce;
  }

  get loginTrigger$(): Observable<string> {
    return this.initLoginTrigger$.asObservable();
  }

  redirectToLogin(params?: RedirectToLoginParams): void {
    this.redirectToUrl(params);
  }

  redirectToForgotPassword(params?: RedirectToLoginParams): void {
    this.redirectToUrl(params, DisplayPath.FORGOT_PASSWORD);
  }

  redirectToCorporateLogin(params?: RedirectToLoginParams): void {
    this.redirectToUrl(params, DisplayPath.CORPORATE_LOGIN);
  }

  redirectToJoin(params?: RedirectToLoginParams): void {
    this.redirectToUrl(params, DisplayPath.JOIN);
  }

  redirectToSimplifiedLogin(params?: RedirectToLoginParams): void {
    this.redirectToUrl(params, DisplayPath.SIMPLIFIED_LOGIN);
  }

  createChangePasswordUrl(memberNumber: string): string {
    const location = this.windowRef.nativeWindow.location;
    const p = {
      returnUrl: location.href,
      userId: memberNumber,
      lang: snapshot(this.store$.pipe(locale())),
    };
    const serializedParams = serializeParams(p);
    const changePasswordURL = `https://${this.configService.cfg.casHost}/content/${p.lang}/change-password/`;
    return [changePasswordURL, '?', serializedParams].join('');
  }

  logout(params?: RedirectToLoginParams): void {
    this.storageService.SESSION.clear();
    this.personalizationTrackingService.clearSession();
    this.redirectToPathWithParams(LOGOUT_INIT_PATH, { ...params });
  }

  isLoggedInSsoSession(): Observable<boolean> {
    return this.http
      .get<SsoStateResponse>(`https://${this.configService.cfg.casHost}/cas/ssoStatus`, { withCredentials: true })
      .pipe(
        timeout(2500),
        retryWithBackoff(3),
        map((res: SsoStateResponse) => res.state === SsoState.ACTIVE),
        take(1),
        catchError(() => of(false)),
        share()
      );
  }

  getRedirectUrlToMmbWithCasLoginFederation(additionalParams?: DdsCasAdditionalParams): string {
    const betaLocale = snapshot(this.store$.pipe(locale()));
    const ddsLang = snapshot(this.store$.pipe(ddsLanguage()));

    const ddsParams: DdsCasRedirectParams = {
      PAGE: DdsPageType.responsiveDeepLink,
      LANGUAGE: ddsLang.language,
      COUNTRY_SITE: ddsLang.countrySite,
      ...additionalParams,
    };
    return `https://${this.configService.cfg.casHost}/cas/oauth2.0/authorize?lang=${betaLocale}&client_id=${
      this.configService.cfg.casClientIdDds
    }&redirect_uri=${encodeURIComponent(buildUrl(this.configService.cfg.ddsServerUrl, ddsParams))}`;
  }

  login(user: string, password: string, rememberMe = false): void {
    const body = new URLSearchParams();
    body.set('username', user);
    body.set('password', password);
    body.set('rememberMe', (rememberMe ?? false).toString());

    this.subscription.add(
      this.getExecutionToken()
        .pipe(
          take(1),
          switchMap((token) => {
            if (token) {
              body.set('_eventId', 'submit');
              this.store$.dispatch(LoginActions.setLoginPhaseExecutionToken({ token: token }));
              return this.executeLoginCasFlowAction(body);
            } else {
              return of(LoginPhaseError.LOGIN_FAILED);
            }
          }),
          catchError(() => of(LoginPhaseError.LOGIN_FAILED))
        )
        .subscribe((loginPhaseError) => {
          this.store$.dispatch(LoginActions.setLoginPhaseError({ error: loginPhaseError }));
        })
    );
  }
  /**
   * redirectToUrl is generic method for redirection
   * @param params RedirectToLoginParams
   * @param display is enum used for different pre-selection on cas portal
   */
  private redirectToUrl(params?: RedirectToLoginParams, display?: DisplayPath): void {
    const additionalParams = this.getRedirectUrlParams(params?.redirectPath, display);
    if (params?.shouldLogoutFirst) {
      const uxAuthUrl = `${this.configService.cfg.apiBaseUrl}${this.configService.cfg.uxAuthBasePath}`;
      this.logout({
        redirectPath: createRedirectUrl(
          this.windowRef.nativeWindow.location,
          uxAuthUrl,
          LOGIN_INIT_PATH,
          additionalParams
        ),
      });
    } else {
      this.redirectToPathWithParams(LOGIN_INIT_PATH, additionalParams);
    }
  }

  login2fa(code: string, submitPin = false): void {
    const body = new URLSearchParams();
    body.set('token', code);
    body.set('_eventId', submitPin ? 'submitPIN' : 'submit');

    this.subscription.add(
      this.executeLoginCasFlowAction(body).subscribe((loginPhaseError) => {
        this.store$.dispatch(LoginActions.setLoginPhaseError({ error: loginPhaseError }));
      })
    );
  }

  resend2faEmail(): Observable<LoginPhaseError | undefined> {
    const body = new URLSearchParams();
    body.set('_eventId', 'retry');

    return this.executeLoginCasFlowAction(body).pipe(
      tap((loginPhaseError) => {
        this.store$.dispatch(LoginActions.setLoginPhaseError({ error: loginPhaseError }));
      })
    );
  }

  requestSMS(): void {
    const body = new URLSearchParams();
    body.set('_eventId', 'pinpassword');

    this.subscription.add(
      this.executeLoginCasFlowAction(body).subscribe((loginPhaseError) => {
        this.store$.dispatch(LoginActions.setLoginPhaseError({ error: loginPhaseError }));
      })
    );
  }

  postLostPassword(
    postLostPasswordBy: PostLostPasswordBy,
    member: string,
    lastName: string
  ): Observable<CasFlowLostPasswordResponse> {
    return this.getExecutionToken().pipe(
      switchMap((initExecutionToken) => {
        const getLostPasswordStateBody = new URLSearchParams();
        getLostPasswordStateBody.set('_eventId', 'lostPassword');
        getLostPasswordStateBody.set('execution', initExecutionToken);
        return this.postLostPasswordHelper(getLostPasswordStateBody);
      }),
      switchMap((lostPasswordStateExecutionToken) => {
        const postLostPasswordBody = new URLSearchParams();
        postLostPasswordBody.set('_eventId', 'submitDetails');
        postLostPasswordBody.set('username', member);
        postLostPasswordBody.set('lastname', lastName);
        postLostPasswordBody.set('communicationMethod', postLostPasswordBy);
        postLostPasswordBody.set('execution', lostPasswordStateExecutionToken.execution);

        return this.postLostPasswordHelper(postLostPasswordBody);
      })
    );
  }

  private postLostPasswordHelper(body: URLSearchParams): Observable<CasFlowLostPasswordResponse> {
    return this.http.post<CasFlowLostPasswordResponse>(
      `https://${this.configService.cfg.casHost}/cas/login`,
      body.toString(),
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      }
    );
  }

  /**
   * getRedirectUrlParams
   * @param redirectPath RedirectUrlParameters
   * @param display whether to pre-select cas page
   * @returns RedirectUrlParameters
   */
  private getRedirectUrlParams(redirectPath?: string, display?: DisplayPath): RedirectUrlParameters {
    return {
      lang: snapshot(this.store$.pipe(locale())),
      nonce: secureRandomString(this.crypto),
      ...(display && { display }),
      ...(redirectPath && { redirectPath }),
    };
  }

  /**
   * Redirect the browser window to the path retaining the origin.
   * Automatically adds the redirectPath parameter to default to current path if not given as parameter.
   *
   * @param {string} path the path to redirect to, e.g. `/fi-fi`
   * @param {{}} params additional query parameters for the redirect
   */
  private redirectToPathWithParams(path: string, params: Partial<RedirectUrlParameters>) {
    const uxAuthUrl = `${this.configService.cfg.apiBaseUrl}${this.configService.cfg.uxAuthBasePath}`;
    this.windowRef.nativeWindow.location.href = createRedirectUrl(
      this.windowRef.nativeWindow.location,
      uxAuthUrl,
      path,
      params
    );
  }

  private handleIframeMessage(loginMessage: IframeLoginPostMessage) {
    if (loginMessage.state !== this.nonce) {
      this.sentryLogger.warn('Login: Received nonce from iframe that did not match the stored nonce', {
        loginCount: this.loginCount,
        nonce: this.nonce,
        receivedNonce: loginMessage.state,
      });
      return;
    }
    const expiresInMs = parseInt(loginMessage.expires_in, 10) * 1000;
    this.dispatchSetToken(loginMessage.access_token, Date.now() + expiresInMs);
    this.triggerTokenRevalidation60SecondsBeforeExpiration(expiresInMs);
    this.fetchProfile();
    this.fetchCasProfile(loginMessage.access_token);
  }

  private handleWebViewMessage(loginMessage: WebViewLoginPostMessage) {
    if (loginMessage.eventType === 'mobileAppLoginSuccess') {
      this.dispatchSetToken(loginMessage.access_token, null);
      this.fetchProfile();
      this.fetchCasProfile(loginMessage.access_token);
    } else {
      this.store$.dispatch(LoginActions.setNotLoggedIn());
    }
  }

  private dispatchSetToken = (token: string, expiresAt: number) =>
    this.store$.dispatch(
      LoginActions.setAccessToken({
        accessToken: {
          token: token,
          expiresAt,
        },
      })
    );

  private triggerTokenRevalidation60SecondsBeforeExpiration(expiresInMs: number) {
    clearTimeout(this.timeoutHandle);
    this.ngZone.runOutsideAngular(() => {
      this.timeoutHandle = setTimeout(() => this.initSsoLoginSession(), expiresInMs - 60 * 1000);
    });
  }

  private fetchProfile() {
    this.subscription.add(
      this.http
        .post<CasLoginResponse>(
          this.configService.cfg.loyaltyServices.legacy.apiProfileUrl,
          {
            profileRequest: {
              type: ProfileRequestType.BASIC,
              cache: ProfileRequestCache.USE,
            },
          },
          {
            headers: new HttpHeaders({
              Accept: 'application/json',
            }),
          }
        )
        .pipe(take(1))
        .subscribe({
          next: (response: CasLoginResponse) => {
            if (isSuccessResponse(response)) {
              this.store$.dispatch(LoginActions.setProfile({ profile: parseProfile(response.profile) }));
            } else {
              this.store$.dispatch(LoginActions.setLoginError());
              this.sentryLogger.warn('Getting profile info after login failed', {
                response,
              });
            }
          },
          error: () => {
            this.store$.dispatch(LoginActions.setLoginError());
          },
        })
    );
  }

  private fetchCasProfile(accessToken: string): void {
    this.subscription.add(
      this.http
        .post<CasProfileResponse>(`https://${this.configService.cfg.casHost}/cas/oauth2.0/profile`, null, {
          params: {
            access_token: accessToken,
          },
        })
        .pipe(take(1))
        .subscribe((response) => {
          this.store$.dispatch(LoginActions.setCasProfile({ casProfile: response.attributes }));
        })
    );
  }

  private getExecutionToken(): Observable<string> {
    const redirectUrl = encodeURIComponent(this.windowRef.nativeWindow.location.href);
    const serviceParams = `client_id=${this.configService.cfg.casClientIdDds}&redirect_uri=${redirectUrl}&response_type=token&client_name=CasOAuthClient`;
    const service = encodeURIComponent(
      `https://${this.configService.cfg.casHost}/cas/oauth2.0/callbackAuthorize?${serviceParams}`
    );
    const url = `https://${this.configService.cfg.casHost}/cas/login?service=${service}`;

    return this.http.get<CasFlowResponse>(url, { withCredentials: true }).pipe(
      timeout(2500),
      retryWithBackoff(1),
      safeMap((res) => res.execution),
      take(1),
      catchError(() => of(null)),
      share()
    );
  }

  private executeLoginCasFlowAction(body: URLSearchParams): Observable<LoginPhaseError | undefined> {
    const token = snapshot(
      this.store$.pipe(
        loginPhase(),
        map(({ executionToken }) => executionToken)
      )
    );
    body.set('execution', token);
    body.set('redirectJson', 'true');

    const actionFailureError: LoginPhaseError =
      body.get('_eventId') === 'retry' ? LoginPhaseError.RESEND_2FA_EMAIL : LoginPhaseError.LOGIN_FAILED;

    return this.http
      .post<CasFlowResponse>(`https://${this.configService.cfg.casHost}/cas/login`, body.toString(), {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(
        map((response: CasFlowResponse) => {
          if (response.execution) {
            this.store$.dispatch(LoginActions.setLoginPhaseExecutionToken({ token: response.execution }));
          }

          if (response.route) {
            if (Object.keys(CAS_ROUTES).includes(response.route)) {
              this.processCasFlowResponseForCustomData(response);
              this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: CAS_ROUTES[response.route] }));
              return undefined;
            } else {
              this.sentryLogger.error('CAS response has unhandled route', { response: response });
            }
          }

          if (response?.message === 'login.ok' || response.redirectUrl) {
            this.initSsoLoginSession();
            return undefined;
          }
          return actionFailureError;
        }),
        take(1),
        catchError((response: unknown) => {
          const errorResponse = mapError<CasFlowResponse>(response);
          if (errorResponse?.error === 'Locked' || errorResponse?.route === 'login/account-locked') {
            this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.LOCKED }));
            return of(undefined);
          }
          return of(actionFailureError);
        }),
        finShare()
      );
  }

  private processCasFlowResponseForCustomData(response: CasFlowResponse): void {
    if (response.route === '2fa/email-auth') {
      const customDataString =
        response.message instanceof Array
          ? response.message.find((msg) => msg.text.includes('customData'))?.text
          : undefined;
      try {
        const { email }: LoginPhaseProfile = customDataString
          ? (JSON.parse(customDataString) as CasFlowLoginResponseCustomData)?.customData || {}
          : {};

        if (!email) {
          throw new Error('Email not found in customData');
        }

        this.store$.dispatch(LoginActions.setLoginPhaseProfile({ profile: { email } }));
      } catch (error) {
        this.sentryLogger.error(
          'CAS response should have provided customData in one of its messages with an email property value',
          {
            error,
            response: response,
          }
        );
      }
    }
  }
}
