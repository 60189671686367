import { Component, ElementRef, Input, Output, OnInit, TemplateRef, EventEmitter } from '@angular/core';

import { PopoverOptions } from '@fcom/ui-components';

import { BookingWidgetService } from '../../services/booking-widget.service';

type Position = 'left' | 'right';

interface DoubleParams {
  readonly label: string;
  readonly placeholder: string;
  readonly filled: boolean;
}

@Component({
  selector: 'fin-booking-widget-selector',
  templateUrl: 'booking-widget-selector.component.html',
  styleUrls: ['./booking-widget-selector.component.scss'],
})
export class BookingWidgetSelectorComponent implements OnInit {
  @Input()
  disabled = false;

  @Input()
  label?: string;

  @Input()
  ariaLabel?: string;

  @Input()
  double?: {
    first: DoubleParams;
    second: DoubleParams;
  };

  @Input()
  position?: Position;

  @Input()
  filled?: boolean;

  @Input()
  placeholder?: string;

  @Input()
  popoverOptions: PopoverOptions;

  @Input()
  popoverContent: TemplateRef<ElementRef>;

  @Output()
  close = new EventEmitter<void>();

  usePopover: boolean;

  constructor(private bookingWidgetService: BookingWidgetService) {}

  ngOnInit(): void {
    this.usePopover = Boolean(
      this.bookingWidgetService.usePopoverSelectors() && this.popoverContent && this.popoverOptions
    );
  }

  handlePopoverClose(): void {
    this.close.emit();
  }
}
