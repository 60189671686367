<div class="pr-xsmall-b" data-testid="offer-grid">
  <ng-container *ngIf="title">
    <ng-container [ngSwitch]="headerLevel">
      <h1 *ngSwitchCase="1" [ngClass]="{ 'pr-xsmall-y': !noHeaderPadding }" data-testid="offer-grid-title">
        {{ title }}
      </h1>
      <h2 *ngSwitchCase="2" [ngClass]="{ 'pr-xsmall-y': !noHeaderPadding }" data-testid="offer-grid-title">
        {{ title }}
      </h2>
      <h3 *ngSwitchCase="3" [ngClass]="{ 'pr-xsmall-y': !noHeaderPadding }" data-testid="offer-grid-title">
        {{ title }}
      </h3>
      <h4 *ngSwitchCase="4" [ngClass]="{ 'pr-xsmall-y': !noHeaderPadding }" data-testid="offer-grid-title">
        {{ title }}
      </h4>
      <h3 *ngSwitchDefault [ngClass]="{ 'pr-xsmall-y': !noHeaderPadding }" data-testid="offer-grid-title">
        {{ title }}
      </h3>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="selectedOriginLocationName$ | async as selectedOriginLocationName">
    <p
      *ngIf="tripType"
      class="flex--self-center font-body-1 regular-type"
      [style]="cmsStylesOverride"
      [innerHTML]="'destinationSearch.flightsFrom' | finLocalization: { origin: selectedOriginLocationName }"
      data-testid="offer-grid-trip-type-origin-label"
    ></p>
  </ng-container>

  <ng-container *ngIf="offers$ | async as offers">
    @if ((isMobile$ | async) && mobileCarousel) {
      <div class="ms-small-t">
        <fcom-carousel
          data-testid="offer-grid-carousel"
          [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
          [nextButtonAriaLabel]="'carousel.next' | finLocalization"
        >
          <div class="mobile-offers flex nowrap flex--middle flex--nowrap flex--align-stretch ps-medium-b">
            <ng-container
              [ngTemplateOutlet]="offersTemplate"
              [ngTemplateOutletContext]="{ offers: offers }"
            ></ng-container>
          </div>
        </fcom-carousel>
      </div>
    } @else {
      <div class="offer-grid-offers fill grid-col-12 no-gutters" data-testid="offer-grid-offers-container">
        <ng-container [ngTemplateOutlet]="offersTemplate" [ngTemplateOutletContext]="{ offers: offers }"></ng-container>
      </div>
    }
  </ng-container>

  <ng-template #offersTemplate let-offers="offers">
    <fin-block-offer
      *ngFor="let offer of offers; let i = index"
      [tripType]="tripType"
      [offer]="offer"
      (finScrolledPast)="scrolledPastDestination.emit([offer, i])"
      (destinationClicked)="destinationClick($event, i)"
      (flightHotelClicked)="flightHotelClicked.emit($event)"
      (calendarClicked)="calendarClicked.emit($event)"
      [fetchImagePriority]="fetchImagePriority"
      class="offer-grid-offer fill block flex slim"
    ></fin-block-offer>
  </ng-template>
</div>
