<ng-container *finLet="accountVerification$ | async as accountVerification">
  <fin-login-form-container
    [fullScreen]="fullScreen"
    *finLet="step$ | async as step"
    (returnClicked)="resetLogin($event)"
    (closeClicked)="closeDialog()"
    (alertNotificationLinkClicked)="onAlertNotificationLinkClicked(step)"
    [title]="titleLabel$ | async"
    [description]="
      accountVerification === AccountVerification.NORMAL_LOGIN
        ? step === LoginStep.TWO_FACTOR_EMAIL
          ? (loginTranslations[step].description | finLocalization: { email: (loginPhaseProfile$ | async)?.email })
          : (loginTranslations[step]?.description | finLocalization)
        : ''
    "
    [error]="error$ | async | finLocalization"
    [errorLinkText]="
      step === LoginStep.TWO_FACTOR_EMAIL ? ('login.twoFactorAuthEmail.resend' | finLocalization) : undefined
    "
    [compact]="
      step === LoginStep.TWO_FACTOR_EMAIL ||
      step === LoginStep.CREDENTIALS ||
      step === LoginStep.CORPORATE_CREDENTIALS ||
      step === LoginStep.CORPORATE_CREDENTIALS_DIRECT
    "
    [icon]="icon$ | async"
    [closable]="accountVerification !== AccountVerification.LOADING"
    [overlay]="accountVerification !== AccountVerification.NORMAL_LOGIN"
    data-hj-suppress
    class="regular-type"
  >
    <div aria-live="assertive" class="header-notification mr-small-t">
      <fcom-notification
        *ngIf="successNotification$ | async as successNotification"
        [theme]="NotificationTheme.SUCCESS"
        [isClosable]="true"
        (closeClick)="successNotification$.next(undefined)"
        data-testid="login-form-success-notification"
        >{{ successNotification | finLocalization }}</fcom-notification
      >
    </div>

    <ng-container
      *ngIf="
        [AccountVerification.NORMAL_LOGIN, AccountVerification.SUCCESS].includes(accountVerification);
        else accountVerificationTemplate
      "
      [ngSwitch]="step"
    >
      <ng-container *ngSwitchCase="LoginStep.CREDENTIALS">
        <div *ngIf="accountVerification === AccountVerification.SUCCESS" class="ps-medium-x ps-medium-b">
          <fcom-notification
            class="block"
            [theme]="NotificationTheme.SUCCESS"
            data-testid="login-form-credentials-verification-success-notification"
            >{{ 'login.join.verificationSuccess' | finLocalization }}</fcom-notification
          >
        </div>

        <form (submit)="login()" [formGroup]="reactiveForm" class="credentials">
          <div class="ps-medium-x">
            <fcom-text-input
              [translationPrefix]="'login.fields.member'"
              [parentForm]="reactiveForm"
              [displayMargin]="false"
              [required]="false"
              [controlName]="'member'"
              [placeHolder]="'login.memberExample'"
            ></fcom-text-input>
            <fcom-text-input
              [translationPrefix]="'login.fields.password'"
              [parentForm]="reactiveForm"
              [displayMargin]="false"
              [required]="false"
              [controlName]="'pwd'"
              [isPassword]="true"
            ></fcom-text-input>

            <button
              type="button"
              class="underline nordic-blue-900-text fill align-right ms-small-y"
              [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'"
              (click)="forgotPassword()"
              data-testid="login-form-credentials-forgot-password-button"
            >
              {{ 'login.forgot' | finLocalization }}
            </button>

            <fcom-checkbox [name]="'keep'" [formControlName]="'keep'" [cid]="'keep'">
              <span [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'">{{
                'login.keepLoggedIn' | finLocalization
              }}</span>
            </fcom-checkbox>
          </div>
          <div class="button-container white-bg">
            <div class="ps-medium">
              <fcom-button
                [type]="ButtonType.SUBMIT"
                [loading]="loading$ | async"
                [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
                [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
                [icon]="
                  (loading$ | async)
                    ? {
                        name: SvgLibraryIcon.TRANSFER_LOADER,
                        position: IconPosition.RIGHT,
                      }
                    : null
                "
                [mode]="ButtonMode.FILL"
                [text]="'login.login' | finLocalization"
                class="ms-large-t ms-small-b"
                data-testid="login-form-credentials-submit-button"
              ></fcom-button>
            </div>
            <div class="nordic-blue-25-bg ps-medium-x ps-small-y">
              <div class="ms-medium-b flex">
                <div class="ms-xsmall-r">
                  <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.B2B_BLOCK"></fcom-icon>
                </div>
                <div>
                  <span class="font-heading-5">{{ 'login.b2bTitle' | finLocalization }}</span>
                </div>
              </div>
              <fcom-button
                (clickBtn)="openCorporateLoginForm()"
                [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
                [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
                [mode]="ButtonMode.FILL"
                [text]="'login.corporateLogin' | finLocalization"
                [icon]="{
                  name: SvgLibraryIcon.ARROW_RIGHT,
                  position: IconPosition.RIGHT,
                }"
              ></fcom-button>
            </div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="LoginStep.CORPORATE_CREDENTIALS">
        <ng-container *ngTemplateOutlet="corporateLoginForm"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="LoginStep.CORPORATE_CREDENTIALS_DIRECT">
        <ng-container *ngTemplateOutlet="corporateLoginForm"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="LoginStep.FORGOT_PASSWORD">
        <form (submit)="postLostPassword()" [formGroup]="forgotPasswordForm">
          <div class="ms-medium-b">
            <fcom-radio-item-group
              [options]="[{ value: PostLostPasswordBy.EMAIL }, { value: PostLostPasswordBy.SMS }]"
              [parentForm]="forgotPasswordForm"
              [controlName]="'postLostPasswordBy'"
              [isSensitive]="true"
              [translationPrefix]="'login.forgotPassword.fields.postLostPasswordBy'"
            >
            </fcom-radio-item-group>
          </div>
          <fcom-text-input
            [translationPrefix]="'login.forgotPassword.fields.member'"
            [parentForm]="forgotPasswordForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'member'"
            [placeHolder]="'login.memberExample'"
            class="block ms-medium-b"
          ></fcom-text-input>
          <fcom-text-input
            [translationPrefix]="'login.forgotPassword.fields.lastName'"
            [parentForm]="forgotPasswordForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'lastName'"
          ></fcom-text-input>

          <fcom-button
            [type]="ButtonType.SUBMIT"
            [loading]="loading$ | async"
            [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
            [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
            [icon]="
              (loading$ | async)
                ? {
                    name: SvgLibraryIcon.TRANSFER_LOADER,
                    position: IconPosition.RIGHT,
                  }
                : null
            "
            [mode]="ButtonMode.FILL"
            [text]="'login.forgotPassword.submit' | finLocalization"
            class="ms-large-t"
            data-testid="login-form-forgot-password-submit-button"
          ></fcom-button>
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="LoginStep.POST_LOST_PASSWORD_SENT">
        <fcom-button
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="{ name: SvgLibraryIcon.SINGLE_PAX, position: IconPosition.LEFT }"
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
          (clickBtn)="resetLogin($event)"
        >
        </fcom-button>
      </ng-container>

      <div *ngSwitchCase="LoginStep.TWO_FACTOR_EMAIL" class="ps-medium-x ps-medium-b ps-xsmall-t">
        <form (submit)="login2fa(step)" [formGroup]="verificationForm" class="ms-large-t">
          <fcom-text-input
            [displayMargin]="false"
            [translationPrefix]="'login.fields.twoFactorAuthEmail'"
            [parentForm]="verificationForm"
            [controlName]="'code'"
            [inputType]="InputType.TEXT"
          ></fcom-text-input>

          <fcom-button
            class="ms-large-t"
            [type]="ButtonType.SUBMIT"
            [loading]="loading$ | async"
            [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
            [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
            [icon]="
              (loading$ | async)
                ? {
                    category: IconLibrary.SVG_LIBRARY,
                    name: SvgLibraryIcon.TRANSFER_LOADER,
                    position: IconPosition.RIGHT,
                  }
                : null
            "
            [mode]="ButtonMode.FILL"
            [text]="'login.login' | finLocalization"
            data-testid="login-form-two-factor-auth-submit-button"
          ></fcom-button>
        </form>

        <p class="ms-large-t">
          {{ 'login.twoFactorAuthEmail.noCodeReceived' | finLocalization }}
          <fcom-link (afterNavigation)="resendEmail()" data-testid="login-form-two-factor-auth-email-resend-email-link">
            {{ 'actions.resend' | finLocalization }}
          </fcom-link>
        </p>

        <fcom-notification *ngIf="resendEmailCount >= 2" class="ms-large-t" [theme]="NotificationTheme.WARNING">
          <span class="font-body-1">{{ 'login.twoFactorAuthEmail.stillNoCodeReceived' | finLocalization }}</span>
        </fcom-notification>
      </div>

      <ng-container *ngSwitchCase="LoginStep.TWO_FACTOR_CODE">
        <form (submit)="login2fa(step)" [formGroup]="verificationForm">
          <fcom-text-input
            [displayMargin]="false"
            [required]="true"
            [translationPrefix]="'login.fields.2faCode'"
            [parentForm]="verificationForm"
            [controlName]="'code'"
          ></fcom-text-input>

          <fcom-button
            class="ms-small-y"
            [type]="ButtonType.SUBMIT"
            [loading]="loading$ | async"
            [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
            [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
            [icon]="
              (loading$ | async)
                ? {
                    name: SvgLibraryIcon.TRANSFER_LOADER,
                    position: IconPosition.RIGHT,
                  }
                : null
            "
            [mode]="ButtonMode.FILL"
            [text]="'login.login' | finLocalization"
            data-testid="login-form-two-factor-auth-submit-button"
          ></fcom-button>
        </form>

        <p [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'" class="ms-small-y">
          {{ 'login.2faRequestCode' | finLocalization }}
        </p>
        <fcom-button
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [mode]="ButtonMode.FILL"
          [text]="'login.2faRequestCodeBtn' | finLocalization"
          (clickBtn)="requestSMS()"
          data-testid="login-form-two-factor-auth-code-request-sms-button"
        ></fcom-button>
      </ng-container>

      <ng-container *ngSwitchCase="[LoginStep.TWO_FACTOR_PHONE, LoginStep.TWO_FACTOR_SMS].includes(step) ? step : null">
        <form (submit)="login2fa(step)" [formGroup]="verificationForm">
          <fcom-text-input
            [displayMargin]="false"
            [required]="true"
            [translationPrefix]="'login.fields.2faSmsCode'"
            [parentForm]="verificationForm"
            [controlName]="'code'"
          ></fcom-text-input>

          <fcom-button
            class="ms-small-y"
            [type]="ButtonType.SUBMIT"
            [loading]="loading$ | async"
            [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
            [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
            [icon]="
              (loading$ | async)
                ? {
                    name: SvgLibraryIcon.TRANSFER_LOADER,
                    position: IconPosition.RIGHT,
                  }
                : null
            "
            [mode]="ButtonMode.FILL"
            [text]="'login.login' | finLocalization"
            data-testid="login-form-two-factor-auth-submit-button"
          ></fcom-button>
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="LoginStep.LOCKED">
        <fcom-button
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [mode]="ButtonMode.FILL"
          [text]="'login.lockedCta' | finLocalization"
          (clickBtn)="forgotPassword()"
          data-testid="login-form-locked-forgot-password-button"
        ></fcom-button>
      </ng-container>
    </ng-container>
  </fin-login-form-container>

  <ng-template #accountVerificationTemplate>
    <div
      *ngIf="accountVerification === AccountVerification.LOADING"
      class="flex flex--column flex--center ps-medium-t ps-xxlarge-b"
    >
      <div class="nordic-blue-500-text medium-type">{{ 'login.join.verificationLoadingTitle' | finLocalization }}</div>
      <fcom-loader [loaderType]="LoaderTheme.INLINE_TEXT"> </fcom-loader>
    </div>

    <fcom-notification
      *ngIf="accountVerification === AccountVerification.EXPIRED"
      class="block ps-medium-x ps-medium-b"
      [theme]="NotificationTheme.ALERT"
      [title]="'login.join.verificationExpiredTitle' | finLocalization"
      data-testid="verification-expired"
    >
      <p class="ps-small-b">{{ 'login.join.verificationExpiredDescription' | finLocalization }}</p>
      <p>
        <fcom-link
          class="font-body-1 regular-type bold underline nordic-blue-900-text"
          (afterNavigation)="closeDialog()"
        >
          {{ 'login.join.verificationExpiredLinkText' | finLocalization }}</fcom-link
        >
      </p>
    </fcom-notification>

    <fcom-notification
      *ngIf="accountVerification === AccountVerification.ERROR"
      class="block ps-medium-x ps-medium-b"
      [theme]="NotificationTheme.ALERT"
      [title]="'login.join.verificationErrorTitle' | finLocalization"
      data-testid="verification-error"
    >
      <p>{{ 'login.join.verificationErrorDescription' | finLocalization }}</p>
    </fcom-notification>
  </ng-template>

  <ng-template #corporateLoginForm>
    <form (submit)="corporateLogin()" [formGroup]="corporateCredentialsForm" class="credentials">
      <div class="ps-medium-x">
        <fcom-text-input
          [translationPrefix]="'login.fields.corporateMember'"
          [parentForm]="corporateCredentialsForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'username'"
        ></fcom-text-input>
        <fcom-text-input
          [translationPrefix]="'login.fields.password'"
          [parentForm]="corporateCredentialsForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'password'"
          [isPassword]="true"
        ></fcom-text-input>

        <div class="align-right ms-small-y">
          <fcom-link
            [size]="fullScreen ? LinkSize.MEDIUM : LinkSize.SMALL"
            [href]="corporateChangePasswordUrl"
            [external]="true"
          >
            {{ 'login.forgot' | finLocalization }}
          </fcom-link>
        </div>

        <fcom-checkbox [name]="'keep'" [formGroup]="corporateCredentialsForm" [formControlName]="'keep'" [cid]="'keep'">
          <span [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'">{{
            'login.keepLoggedIn' | finLocalization
          }}</span>
        </fcom-checkbox>
      </div>
      <div class="button-container white-bg ps-medium-t">
        <div class="nordic-blue-25-bg ps-medium-x ps-small-y">
          <fcom-button
            [type]="ButtonType.SUBMIT"
            data-testid="login-form-corporate-login-button"
            [loading]="loading$ | async"
            [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
            [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
            [icon]="
              (loading$ | async)
                ? {
                    name: SvgLibraryIcon.TRANSFER_LOADER,
                    position: IconPosition.RIGHT,
                  }
                : null
            "
            [mode]="ButtonMode.FILL"
            [text]="'login.login' | finLocalization"
          ></fcom-button>
          <div class="ps-small-t align-center">
            <fcom-link
              [href]="'fragments.readMoreAboutCorporatePortal.url' | finLocalization"
              (beforeNavigation)="trackAndCloseDialog()"
            >
              {{ 'login.b2bReadMore' | finLocalization }}
            </fcom-link>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</ng-container>
