<fin-booking-widget-selector
  [label]="'travelType.label' | finLocalization"
  [disabled]="disabled"
  (click)="openModal()"
  (close)="onClose()"
  data-testid="trip-type-selector"
  [popoverContent]="popoverContent"
  [popoverOptions]="popoverOptions"
>
  <div class="flex flex--space-between">
    <span class="flex--self-left" data-testid="selected-trip-type">{{
      'travelType.' + CmsTripTypeMap[currentTripType$ | async] | finLocalization
    }}</span>
    <button
      *ngIf="isBrowser && (currentTripType$ | async) === TripType.MULTICITY"
      type="button"
      fcomPopover
      [content]="multicityHelpContent"
      [options]="{ enableStopPropagation: true }"
      [ariaCloseText]="'actions.close' | finLocalization"
      [attr.aria-label]="'actions.info' | finLocalization"
      class="icon nordic-blue-900-text ms-xxsmall-l"
      data-testid="trip-type-multicity-tooltip"
    >
      <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.HELP_BLOCK"></fcom-icon>
    </button>
  </div>
</fin-booking-widget-selector>

<ng-template #multicityHelpContent>
  <p class="regular-type">
    {{ (enableMultiCity ? 'multiCity.flightSelection.tooltipDescription' : 'multiCity.description') | finLocalization }}
  </p>
  <a
    class="block medium-type font-body-1 mr-xsmall-t underline link-hover nordic-blue-900-text"
    [finLink]="
      (enableMultiCity ? 'bookingSearch.multiCityNewNotificationLinkUrl.url' : 'multiCity.url.url') | finLocalization
    "
    [external]="true"
    [openInNewTab]="true"
    [attr.rel]="'noreferrer'"
    [attr.aria-describedby]="'faq-link'"
    [attr.aria-label]="'faq'"
  >
    {{ 'multiCity.linkText' | finLocalization }}
    <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.EXTERNAL_TAB"></fcom-icon>
  </a>
</ng-template>

<ng-template #popoverContent>
  <div class="scroll rounded-large">
    <header class="popover-header fill relative border-bottom flex--column ps-medium">
      <div class="flex--nogrow flex--basis-header flex flex--row flex--center">
        <h2
          class="font-heading-4 flex-1 truncate overflow-hidden nordic-blue-900-text"
          [attr.id]="'popover-' + popoverOptions.popoverID + '-title'"
        >
          {{ 'travelType.label' | finLocalization }}
        </h2>
        <button
          class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset"
          [attr.title]="'actions.close' | finLocalization"
          [attr.aria-label]="'actions.close' | finLocalization"
          (click)="closePopover()"
        >
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
        </button>
      </div>
    </header>
    <div class="ps-medium">
      <fcom-radio-item-group
        [options]="tripTypeOptions$ | async"
        [parentForm]="popOverFormGroup"
        [controlName]="FORM_CONTROL_NAME"
        [titleText]="false"
        [errorText]="false"
        finFocus
        [autofocus]="true"
      >
      </fcom-radio-item-group>
    </div>
    <footer class="popover-footer nordic-blue-25-bg ps-medium flex flex--end">
      @if (isGlobalBookingWidget) {
        <fcom-button
          data-testid="trip-type-selector-cancel"
          class="ms-small-r"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [text]="'actions.cancel' | finLocalization"
          [aria]="{ label: 'actions.cancel' | finLocalization, role: 'button' }"
          (clickBtn)="cancelSelection()"
        ></fcom-button>

        @if (returnDateMissing && (currentTripType$ | async) === TripType.RETURN) {
          <fcom-button
            data-testid="trip-type-selector-add-return"
            [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
            [text]="'uiLabels.addReturn' | finLocalization"
            [aria]="{ label: 'uiLabels.addReturn' | finLocalization, role: 'button' }"
            (clickBtn)="onAddReturnDate()"
          ></fcom-button>
        } @else {
          <fcom-button
            data-testid="trip-type-selector-search"
            [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
            [text]="'bookingSearch.search' | finLocalization"
            [aria]="{ label: 'bookingSearch.search' | finLocalization, role: 'button' }"
            (clickBtn)="startNewSearch()"
            [disabled]="!isSearchEnabled"
          ></fcom-button>
        }
      } @else {
        <fcom-button
          data-testid="trip-type-selector-done"
          [theme]="ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [text]="'actions.done' | finLocalization"
          [aria]="{ label: 'actions.done' | finLocalization, role: 'button' }"
          (clickBtn)="closePopover()"
        ></fcom-button>
      }
    </footer>
  </div>
</ng-template>

<fcom-modal
  [(open)]="modalOpen"
  [title]="'travelType.label' | finLocalization"
  [fitToContent]="true"
  [focusTarget]="selectedElement"
  data-testid="trip-type-modal"
  (close)="confirmOnClose()"
>
  <div class="pr-large-x">
    <ul id="to-trip-type" role="radiogroup" class="flex flex--column ps-xxsmall-y">
      <li
        *ngFor="let tripType of availableTripTypes$ | async; let last = last; let i = index"
        [attr.id]="'to-trip-type-' + tripType"
        class="flex flex--middle flex--space-between focus-inset link-hover trip-type"
        [class.border-bottom]="!last"
      >
        <input
          #tripTypeRadioBtn
          type="radio"
          class="is-vishidden"
          (keyup)="handleKeyPress(tripType, $event)"
          [attr.id]="'tripType-' + tripType"
          name="tripType"
          [value]="tripType"
          [checked]="(currentTripType$ | async) === tripType"
          [tabindex]="(currentTripType$ | async) === tripType ? 0 : -1"
        />
        <label
          [for]="'tripType-' + tripType"
          class="fill font-body-1 ps-medium-y link-hover nordic-blue-900-text"
          (click)="selectTripType(tripType)"
          data-testid="trip-type-label"
        >
          <span>{{ 'travelType.' + CmsTripTypeMap[tripType] | finLocalization }}</span>
          @if (tripType === TripType.MULTICITY && showNewMultiCityTag) {
            <fcom-tag class="ms-medium-l">
              {{ 'bookingSearch.multiCityNewTag' | finLocalization }}
            </fcom-tag>
          }
        </label>

        <fcom-icon
          *ngIf="(currentTripType$ | async) === tripType"
          [name]="SvgLibraryIcon.CHECKMARK"
          class="icon-medium nordic-blue-900-text"
        ></fcom-icon>
      </li>
    </ul>
  </div>
</fcom-modal>
