import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { CmsCampaign, CmsDataService, ConfigService, SentryLogger } from '@fcom/core/services';
import { LanguageService } from '@fcom/ui-translate';
import { ConsentService } from '@fcom/common/login/services/consent.service';
import { getDestinationInCampaign } from '@fcom/core/utils/cms-data-utils';
import { GlobalBookingTravelClass } from '@fcom/core/interfaces';
import { Location } from '@fcom/core-api/interfaces';

import {
  FlightSearchEventPayload,
  EventLabel,
  TrendingDestinationsResponse,
  TrendingDestinationLocation,
  TrendingDestinationsBaseLocation,
} from '../../interfaces/recommendation.interface';
import { ConsentTextId } from '../../interfaces';

@Injectable()
export class RecommendationService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private languageService: LanguageService,
    private sentryLogger: SentryLogger,
    private consentService: ConsentService,
    private cmsDataService: CmsDataService
  ) {}

  sendFlightSearchEvent(
    origin: Location,
    destination: Location,
    departureDate: string,
    returnDate?: string,
    paxAdults?: number,
    paxChildren?: number
  ): Observable<HttpResponse<Response>> {
    if (!departureDate || !origin || !destination) {
      return throwError(() => 'Invalid parameters');
    }

    return combineLatest([this.consentService.getCookieConsentStatusById(ConsentTextId.COOKIE_ANALYTICS)]).pipe(
      take(1),
      switchMap(([analyticsConsent]) => {
        const finnairCookieId = this.consentService.getCookieIdFromSnippet();
        const payload: FlightSearchEventPayload = {
          locale: this.languageService.localeValue,
          requestId: uuid(),
          data: {
            label: EventLabel.SEARCH_CONNECTIONS,
            departureAirport: this.getLocationCodeForType(origin, 'airport'),
            arrivalAirport: this.getLocationCodeForType(destination, 'airport'),
            departureCity: this.getLocationCodeForType(origin, 'city'),
            arrivalCity: this.getLocationCodeForType(destination, 'city'),
            departureDate,
            returnDate,
            passengers: {
              main: paxAdults,
              children: paxChildren,
            },
          },
          ...(analyticsConsent && finnairCookieId && { sessionId: finnairCookieId }),
        };

        return this.http
          .post<HttpResponse<Response>>(`${this.configService.cfg.recommendationApiUrl}/events`, payload)
          .pipe(
            catchError((err: unknown) => {
              this.sentryLogger.warn('Failed to send flight search event to recommendation api', { error: err });
              return throwError(() => err);
            })
          );
      })
    );
  }

  /** Get Trending Destinations
   * @param trendingDestinationLimit amount of trending destinations to be returned
   */
  getTrendingDestinations(
    origin: string,
    pageNumber = 0,
    trendingDestinationLimit = 30
  ): Observable<TrendingDestinationLocation[]> {
    return this._getTrendingDestinations(origin, trendingDestinationLimit, pageNumber * trendingDestinationLimit);
  }

  getTrendingDestinationsByContinent(origin: string, continentCode: string): Observable<TrendingDestinationLocation[]> {
    return this._getTrendingDestinations(origin, 200, 0, continentCode);
  }

  private _getTrendingDestinations(
    origin: string,
    limit = 0,
    offset = 0,
    continentCode?: string
  ): Observable<TrendingDestinationLocation[]> {
    const payload = {
      params: {
        locale: this.languageService.localeValue,
        limit,
        departureFrom: origin,
        offset,
      },
    };

    if (continentCode) {
      payload.params['continentCode'] = continentCode;
    }

    return combineLatest([
      this.http.get<TrendingDestinationsResponse<TrendingDestinationLocation>>(
        `${this.configService.cfg.recommendationApiUrl}/trending-destinations`,
        payload
      ),
      this.cmsDataService.getOngoingCampaigns(this.languageService.langValue, this.languageService.localeValue),
    ]).pipe(
      map(([trendingResponse, campaigns]) =>
        trendingResponse.locations.map(
          (location) => this.mapToCampaignLocation(location, campaigns, origin) as TrendingDestinationLocation
        )
      ),
      catchError((err: unknown) => {
        this.sentryLogger.warn('Failed to get trending destination from recommendation api', { error: err });
        return throwError(() => err);
      })
    );
  }

  private mapToCampaignLocation(
    location: TrendingDestinationsBaseLocation | TrendingDestinationLocation,
    campaigns: CmsCampaign[],
    origin: string
  ): TrendingDestinationsBaseLocation | TrendingDestinationLocation {
    const campaign = campaigns.find(
      (campaign) =>
        (campaign?.destinations?.some((dest: string) => dest === location.locationCode) ||
          campaign?.destinationsByOrigin?.[origin]?.some((dest: string) => dest === location.locationCode)) &&
        // Front page has hard coded economy prices
        campaign?.campaignTravelClass === GlobalBookingTravelClass.ECONOMY
    );
    return {
      ...location,
      isIncludedInCampaign: !!campaign,
      badgeLabel: campaign?.campaignLabel,
      enableBlackTeaser: getDestinationInCampaign(campaigns, location.locationCode)?.enableBlackTeaser,
    };
  }

  private getLocationCodeForType(location: Location, type: 'airport' | 'city'): string | undefined {
    return location?.type === type ? location.locationCityCode || location.locationCode : undefined;
  }
}
