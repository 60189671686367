<div class="chatBody">
  <div class="ps-medium" #formContainer>
    <form
      class="flex flex--column"
      [formGroup]="reactiveForm"
      [finFormErrorFocus]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <p>{{ 'chatUserForm.intro' | finLocalization }}</p>

      <div class="flex flex--column input-fields">
        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.FIRST_NAME"
          [translationPrefix]="'chatUserForm.firstName'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.LAST_NAME"
          [translationPrefix]="'chatUserForm.lastName'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-text-input
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.EMAIL"
          [translationPrefix]="'chatUserForm.emailAddress'"
          [displayMargin]="false"
        ></fcom-text-input>

        <fcom-radio-item-group
          [options]="[{ value: SalesforceChatFormHasBookingRefQ.NO }, { value: SalesforceChatFormHasBookingRefQ.YES }]"
          [parentForm]="reactiveForm"
          [controlName]="SalesforceChatFormControl.HAS_BOOKING_REFERENCE_Q"
          [translationPrefix]="'chatUserForm.bookingRefQ'"
          [isHorizontal]="true"
          [isRequired]="true"
        ></fcom-radio-item-group>

        @if (selectedBookingRef === SalesforceChatFormHasBookingRefQ.YES) {
          <fcom-text-input
            [parentForm]="reactiveForm"
            [controlName]="SalesforceChatFormControl.BOOKING_REFERENCE"
            [translationPrefix]="'chatUserForm.bookingReference'"
            [placeHolder]="'chatUserForm.bookingReference.placeholder'"
            [displayMargin]="false"
          ></fcom-text-input>
        }
      </div>

      <fcom-button
        [type]="ButtonType.SUBMIT"
        [text]="'chatUserForm.submitText' | finLocalization"
        [mode]="ButtonMode.FILL"
        [size]="ButtonSize.LARGE"
        [loading]="loading$ | async"
      ></fcom-button>
    </form>
  </div>
</div>
