import { JsConfig } from '@fcom/core';
import { isFlagEnabled } from '@fcom/core/utils/env.utils';

const apiBaseUrl = 'https://api.finnair.com';
const apiFullBaseUrl = `${apiBaseUrl}/d/fcom`;
const loyaltyApiBaseUrl = 'https://api.finnair.com/d/loyalty-service';

const isUpgradeBenefitsEffective = isFlagEnabled('2024-03-09T00:00:00.000+0300');
const isVisaLightCreditCardBannerEffective = isFlagEnabled('2024-12-12T00:00:00.000+0300');

const config: JsConfig = {
  hmr: false,
  production: true,
  /**
   * Map from lang to locale. E.g. { 'gb-en': 'en_GB' }
   */
  allowedLocales: {
    'master-en': 'en_GB',
    'at-de': 'de_AT',
    'at-en': 'en_AT',
    'au-en': 'en_AU',
    'be-en': 'en_BE',
    'ca-en': 'en_CA',
    'ca-fr': 'fr_CA',
    'ch-de': 'de_CH',
    'ch-en': 'en_CH',
    'ch-fr': 'fr_CH',
    'cn-en': 'en_CN',
    'cn-zh': 'zh_CN',
    'cz-en': 'en_CZ',
    'de-de': 'de_DE',
    'de-en': 'en_DE',
    'dk-da': 'da_DK',
    'dk-en': 'en_DK',
    'ee-en': 'en_EE',
    'ee-et': 'et_EE',
    en: 'en',
    'es-en': 'en_ES',
    'es-es': 'es_ES',
    'fi-en': 'en_FI',
    'fi-fi': 'fi_FI',
    'fi-sv': 'sv_FI',
    'fr-en': 'en_FR',
    'fr-fr': 'fr_FR',
    'gb-en': 'en_GB',
    'hk-zh': 'zh_HK',
    'hk-en': 'en_HK',
    'hu-en': 'en_HU',
    'is-en': 'en_IS',
    'ie-en': 'en_IE',
    'il-en': 'en_IL',
    'in-en': 'en_IN',
    'it-en': 'en_IT',
    'it-it': 'it_IT',
    'jp-en': 'en_JP',
    'jp-ja': 'ja_JP',
    'kr-en': 'en_KR',
    'kr-ko': 'ko_KR',
    'lt-en': 'en_LT',
    'lv-en': 'en_LV',
    'nl-en': 'en_NL',
    'no-en': 'en_NO',
    'no-no': 'no_NO',
    'pl-en': 'en_PL',
    'pl-pl': 'pl_PL',
    'ru-en': 'en_RU',
    'ru-ru': 'ru_RU',
    'se-en': 'en_SE',
    'se-sv': 'sv_SE',
    'sg-en': 'en_SG',
    'th-en': 'en_TH',
    'us-en': 'en_US',
  },
  apiBaseUrl,
  uxAuthBasePath: '/d/fcom/ux-auth-prod',
  baseUrl: 'https://www.finnair.com',
  buildEnv: 'prod',
  casAllowHttp: false,
  casClientId: 'I9AZwG5z3os',
  casClientIdDds: 'FFfOZx2as6M',
  casHost: 'auth.finnair.com',
  casEnv: 'prod',
  upgradeAvailabilityUrl: 'https://api.finnair.com/amadeus-upgrade-availability',
  checkoutUrl: 'https://pay.finnair.com',
  cmsUrl: 'https://cms.finnair.com',
  cmsEnv: 'prod',
  isCmsPreview: false,
  translationsApiUrl: `${apiFullBaseUrl}/ux-translation-prod/current`,
  ddsServerUrl: 'https://www.finnair.com/pl/AYPortal/wds/CASRedirect.action?SITE=FINRFINR',
  cartUrl: `${apiFullBaseUrl}/cart-prod/current/api/v2/cart`,
  cartUrlWithoutAPI: `${apiFullBaseUrl}/cart-prod/current`,
  configUrl: `${apiFullBaseUrl}/config-prod/current`,
  instantSearchUrl: `${apiFullBaseUrl}/instantsearch-prod/current`,
  giftCardApiKey: '5iUkA9Pwly7DYTAJ2eRe77nzWrdrEDI93MaT6GY7',
  giftCardConversionUrl: `${apiBaseUrl}/d/payments/gift-card/public/gift-cards/payout`,
  giftCardVerificationUrl: `${apiBaseUrl}/d/payments/gift-card/public/gift-cards`,
  googleMapsApiKey: 'AIzaSyDvTBJvICnNK-DI14X6M1hXF9SXB8Dfuec',
  sentryDsnServer:
    'https://bce7d6be750ade48c6bc76b73d5ed1c5:3ddaedd1938b4e97fd5226fc0f2ee2a9@o4507887724986368.ingest.de.sentry.io/4508097692893264',
  sentryDsn: 'https://bce7d6be750ade48c6bc76b73d5ed1c5@o4507887724986368.ingest.de.sentry.io/4508097692893264',
  sentryEnv: 'prod',
  sentryPrefix: 'lambda-',
  versionInfo: {
    releaseGitCommitHash: 'unkown-commit-hash',
    releaseGitTag: 'unkown-git-tag',
  },
  locationRouteCffUrl: `${apiFullBaseUrl}/locations-prod/current`,
  timeGeoUrl: 'https://api.finnair.com/timegeo',
  orderUrl: `${apiFullBaseUrl}/order-prod/current`,
  offersUrl: `${apiFullBaseUrl}/offers-prod/current`,
  tierBenefitUrl: 'https://api.finnair.com/d/fcom/benefits-prod/current/v2/tierBenefits',
  oneUpgradeRootUrl: 'https://api.finnair.com/d/business-upgrade/bookings',
  upgradeApiGwKey: 'WknUloDLslm39Xtd2EbjWctxRUHpdc',
  showMarketingOffers: true,
  pnrSummaryInMMBWidget: true,
  servicesUrl: `${apiFullBaseUrl}/ancillary-prod/current`,
  checkInPostUrl: 'https://checkin.si.amadeus.net/1ASIHSSCWEBAY/sscway/checkindirect',
  cookieSnippetUrl: 'https://privacy.finnair.com/snippet.js?locale={locale}',
  consentApiUrl: 'https://api.finnair.com/a/consent/',
  forceWithCredentials: true,
  serviceForm: {
    customerCare: {
      apiUrl: `${apiBaseUrl}/d/customer-care-api`,
      keyId: 'hEJnqZ0PWJ2W47zgpb1YC8JtkwhjDo8c27GEYCnQ',
    },
    customerCareAttachment: {
      apiUrl: `${apiBaseUrl}/d/customer-care-api/attachment/v1`,
      keyId: 'Z65C5iKKLL7okJRww5X4d5HOVRIRUeAA5Fnc4QsG',
    },
    customerFeedback: {
      apiUrl: `${apiBaseUrl}/d/customer-care-api/feedback`,
      keyId: 'hEJnqZ0PWJ2W47zgpb1YC8JtkwhjDo8c27GEYCnQ',
      options: {
        lastNameQueryParam: 'lastName',
        attachmentPath: 'attachments',
        linkAttachmentPath: 'link-attachments',
      },
    },
    customerRefunds: {
      apiUrl: `${apiBaseUrl}/d/customer-care-api/refund-case/v1`,
      keyId: 'gMh8jRxonX47xTbxhT6blaZe2OWsFDNp9kU68Z1U',
    },
    eTicketReceipt: {
      apiUrl: 'https://api.finnair.com/a/booking-service/api/member/name/emailReceipt',
      keyId: '5mBIAH9Mpzaiu2zcHtN956rX0UYEv3hU8oP7BYb1',
    },
    loggedInMissingPoints: {
      apiUrl: `${loyaltyApiBaseUrl}/legacy/current/api/claim`,
      keyId: 'Y6DcOC6tLW2IIKKUm2Ff5KLHNWd1Qd7386WGvVI0',
    },
    portalServices: {
      apiUrl: 'https://api.finnair.com/v1/portalservices',
      keyId: 'qcRfk4ZSaU7g6FLDfNUar3r7Q1bVt1kFaXwqORKI',
    },
  },
  siteSearch: {
    siteSearchRequest: {
      apiUrl: 'https://finnair-app-search.ent.eu-central-1.aws.cloud.es.io/api/as/v1/engines/www-finnair-com-{lang}/',
      searchKey: 'search-dq8nn4v4fega72csth86bxch',
    },
  },
  carRental: {
    apiUrl: 'https://cars.finnair.com',
    clientId: '395702',
  },
  ondMarketingOffersUrl: `${apiFullBaseUrl}/destination-seo-prod/current`,
  chat: {
    chatServiceQueueConfig: {
      RTGS_FINNISH: {
        language: 'fi',
        buttonId: '5731o000000GsW4',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFaUAM_16a6df87b8c',
      },
      RGTS_SWEDISH: {
        language: 'sv',
        buttonId: '5731o000000GsVQ',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFLUA2_16a6df75f3a',
      },
      RTGS_ENGLISH: {
        language: 'en',
        buttonId: '5731o000000GsVR',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFQUA2_16a6df7d9bf',
      },
      SGS_CHINESE: {
        language: 'zh-CN',
        buttonId: '5731o000000GsVU',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFfUAM_16a6df8cf73',
      },
      Chatbot_EN: {
        language: 'en',
        buttonId: '5731o0000004IiY',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1o0000008OQFEA2_170440347e3',
      },
      Chatbot_EN_SGS: {
        language: 'en',
        buttonId: '5731o000000GsWT',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1o000000GmgCEAS_170ede64e2a',
      },
      SGS_FRENCH: {
        language: 'fr',
        buttonId: '5731o000000GsVX',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFbUAM_16a6e01a08f',
      },
      SGS_GERMAN: {
        language: 'de',
        buttonId: '5731o000000GsVY',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFkUAM_16a6dfab458',
      },
      SGS_ITALIAN: {
        language: 'it',
        buttonId: '5731o000000GsVZ',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFpUAM_16a6dfc45ee',
      },
      SGS_JAPANESE: {
        language: 'ja',
        buttonId: '5731o000000GsVa',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFWUA2_16a6dfc93b5',
      },
      SGS_KOREAN: {
        language: 'ko',
        buttonId: '5731o000000GsVb',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFuUAM_16a6dfe0dc8',
      },
      SGS_RUSSIAN: {
        language: 'ru',
        buttonId: '5731o000000GsVc',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CFzUAM_16a6dfe76b7',
      },
      SGS_SPANISH: {
        language: 'es',
        buttonId: '5731o000000GsVd',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CG4UAM_16a6dfedbc4',
      },
      SGS_CHINESE_HK: {
        language: 'pt-BR',
        buttonId: '5731o000000GsVV',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000004CRMUA2_16b02321ba9',
      },
      Chatbot_FTH: {
        language: 'en',
        buttonId: '573UB0000007HXB',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IUB000000OnnZ2AS_192b420b51e',
      },
    },
    chatInitParamsConfig: {
      baseCoreURL: 'https://finnair.my.salesforce.com',
      communityEndpointURL: 'https://finnair.my.salesforce-sites.com/chat',
      gslbBaseURL: 'https://service.force.com',
      orgId: '00D24000000Zb2l',
      baseLiveAgentContentURL: 'https://c.la11-core1.sfdc-cehfhs.salesforceliveagent.com/content',
      deploymentId: '5721o000000Gs1h',
      baseLiveAgentURL: 'https://d.la11-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
    },
    chatDefaultLanguage: '_DEFAULT',
  },
  chatScriptSrcURL: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
  enableSplitChat: false,

  oneClickPaymentUrl: 'https://api.finnair.com/d/payments/paymentgw-prod/oneclick',
  oneClickPaymentApiKey: '5258bf96-5581-47d3-90ce-71c289dd009f',
  membershipCardApiUrl: 'https://api.finnair.com/a/membershipcard',
  loyaltyServices: {
    memberService: {
      gauthUrl: `${loyaltyApiBaseUrl}/member-service/gauth`,
      gauthApiKey: '08Clo0m74Z8arPmCbTpyd8ravNu3N5xd3nBrYymZ',
    },
    legacy: {
      apiUrl: `${loyaltyApiBaseUrl}/legacy/current/api`,
      apiProfileUrl: `${loyaltyApiBaseUrl}/legacy/current/api/profile`,
      apiProfileApiKey: '5mBIAH9Mpzaiu2zcHtN956rX0UYEv3hU8oP7BYb1',
      loyaltyMemberUrl: `${loyaltyApiBaseUrl}/legacy/current/loyalty/member`,
      loyaltyMemberResendUrl: `${loyaltyApiBaseUrl}/legacy/current/loyalty/member/resend`,
      loyaltyMemberVerifyUrl: `${loyaltyApiBaseUrl}/legacy/current/loyalty/member/confirm`,
      loyaltyMemberVerifyApiKey: 'cHaRX5zbFQ9onwwPgvnNP26WKPVD29wCaKp1xlAt',
      loyaltyMemberApiKey: 'eb6b23a6-d3bd-4fcf-903d-c9be9763eab0',
      loyaltyAviosLinkingUrl: `${loyaltyApiBaseUrl}/legacy/current/api/avios/linked-accounts`,
    },
  },
  britishAirwaysExchangeUrl: 'https://exchange.shopping.ba.com/partner/finnair',
  bookingServiceUrl: 'https://api.finnair.com/a/booking-service/api',
  bookingServiceApiKey: '9114c782-00f5-4a99-b78a-68c6ce513ca3',
  destinationsSearchUrl: `${apiFullBaseUrl}/fcom-service-destinations-prod/destinations`,
  destinationsSearchApiKey: 'h4xl99cRYO5M7POBECCrO70M0NFc6Pb7Nl5y6lb3',
  amApiUrl: 'https://www.aurinkomatkat.fi/api/fcom-locations/v1',
  amFlightHotelDestinationUrl: 'https://www.aurinkomatkat.fi/api/fcom-fh-destinations',
  amDeeplinkUrl: 'https://www.aurinkomatkat.fi/matkahaku/finnair',
  buyPointsIframeUrl: 'https://ssogateway.points.com/SSOGateway/gateway.do',
  recommendationApiUrl: 'https://api.finnair.com/d/finnair-personalization/recommendation',
  reaccommodationTimeout: 15,
  corporateApiUrl: `${apiBaseUrl}/d/customer/corporate-rest-api/current`,
  corporateApiKey: '51E1mQPFsa6XOOgsp1e2l79tiq93xAXL7vL5lML2',
  customerCommunicationsApiUrl: 'https://api.finnair.com/d/email-sender/customer-communications/current',
  storageRoot: 'finnaircom',
  appName: 'finnair-app',
  appIdIos: '933867978',
  manifestPathAndroid: 'assets/mobile-app/manifest-android.json',
  enableAwsXRay: true,
  enableExtraAuth: false,
  enableMockDapi: false,
  enableCorsCredentials: false,
  enableWelcomeBackMessage: true,
  enableGlobalBanner: true,
  enableReaccommodationMockData: false,
  enableUpgradeTravelClass: false,
  enableBuyPoints: false,
  enableDapiBackendSwitch: false,
  enableDevTools: false,
  enableDevToolPath: false,
  choooseUrl: 'https://finnair.chooose.today/{lang}',
  enableTicketTypeUpselling: false,
  enableQualtricsPageFeedback: true,
  enableNewsletterSignUpForm: true,
  enableUpgradeAvailabilityInBooking: true,
  enableUpgradeAvailabilityPremiumEconomyInBooking: true,
  enableClaimMissingPointsLoggedIn: true,
  enableCookiePolicy: true,
  enableLoginDialog: true,
  enableJoinDialog: true,
  enableSelfReaccFlow: true,
  finnairShopDonateUrl: 'https://finnairshop.com/{lang}/finnair-plus-awards-and-products/charity',
  enableUpgradeBenefitInUpgrade: isUpgradeBenefitsEffective,
  finnairShopUrl: 'https://finnairshop.com/{lang}',
  benefitApi: 'https://api.finnair.com/d/fcom/benefitsv2-prod/current/v2/tierBenefits',
  enableCountrySelector: false,
  enableAviosOverhaul: {
    isAviosCuttoffDateEffective: '2024-03-09T00:00:00.000+0300',
  },
  enableAdditionalEmailInBookingFlow: false,
  enableRefetchOrder: false,
  enableMultiCity: true,
  enableCorporateTravelClassLimit: true,
  disableTermsAndConditionCheckbox: true,
  enableNewClaimMissingPointFlow: true,
  enableTicketTypeCardChanges: true,
  travelReady: {
    gatewayBaseUrl: 'https://widget-api.safetravel.amadeus.com',
    gatewayClientId: 'x1ASrT2jbpxSx0nacYnCYpswFF9iHOQ5',
    gatewayClientSecret: 'gmLFbYfYQE7kbjas',
    assetBaseUrl: '/cdn/lambda/prod/browser/',
  },
  gtmContainerSrc: '//www.googletagmanager.com',
  gtmContainerId: 'GTM-5JS398',
  enableMMBThirdPartyAncillaries: true,
  enableShowingSAF: false,
  enableBwThirdPartyAncillaries: true,
  enableSalesforceDataCloud: true,
  salesforceDataCloudScriptSrcURL:
    'https://cdn.c360a.salesforce.com/beacon/c360a/db5e18f4-83ad-4c55-81d1-9c17648c979c/scripts/c360a.min.js',
  corporatePredict: false,
  enableSynchronousTicketingOnConfirmationPage: true,
  enableCorporateData: true,
  enableSafContributionCard: true,
  enableVisaLightCreditCardBanner: isVisaLightCreditCardBannerEffective,
  enableEditPaxDetailsOnReviewPage: true,
  enableGermanRailway: false,
  enableShowFlightNumbers: true,
  enableShowCheaperOnlineTag: false,
  enableAccountCreationVerification: false,
  enableEuropeUnder100: false,
  enableNewDownloadAttachmentFlow: true,
  enableCorporateSeatBenefit: true,
  enableSpecialUpsellCategory: false,
  enableNewUploadAttachmentFlow: true,
  enableTravelExtrasTierBenefitTag: false,
  enableBookingFlowSeatMapPreFetch: true,
  enableNewStepper: false,
  enableBookingFlowAncillaryBundles: false,
};

export const environment = config;
