/* eslint-disable rxjs/no-implicit-any-catch */
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { NEVER, Observable, PartialObserver, Subscription, throwError } from 'rxjs';
import { catchError, map, share, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { mapError, mapErrorForSentry, SentryLogger } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { DapiError, DapiErrorCode, DapiHttpErrorResponse, PartialCartFareRuleRecord, PaxAmount } from '@fcom/dapi';
import { retryWithBackoff, snapshot } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';
import { BookingAppState, PaxDetailsState } from '@fcom/common/interfaces/booking';
import { FareRules, FinnairCart, FinnairPassengerCode, FinnairServiceRequestItem } from '@fcom/dapi/api/models';
import { CartService } from '@fcom/dapi/api/services/cart.service';
import { CommonBookingAncillaryService } from '@fcom/common-booking/modules/ancillaries/services';
import { globalBookingPaxAmount } from '@fcom/common/store';
import { FareRuleCategory } from '@fcom/dapi/api/models/fare-rule-category';
import { DapiHttpResponse } from '@fcom/dapi/api/dapi-http-response';

import { CartActions } from '../store/actions';
import { mapTravelersAndCorporateCode } from '../utils';
import { navigateToError } from '../utils/route-utils';

@Injectable()
export class BookingCartService implements OnDestroy {
  static NUMBER_OF_RETRIES = 2;

  private cartSubscription: Subscription;
  private paxSubscription: Subscription;
  private ancillarySubscription: Subscription;
  private fareRulesSubscription: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private store$: Store<BookingAppState>,
    private languageService: LanguageService,
    private sentryLogger: SentryLogger,
    private router: Router,
    private cartService: CartService,
    private commonBookingAncillaryService: CommonBookingAncillaryService
  ) {}

  ngOnDestroy(): void {
    this.fareRulesSubscription.unsubscribe();
  }

  /**
   * Triggers cart creation for the given offer id. Stores the cart info in the app state store.
   * @param selectedOfferId the id of the selected offer, e.g., SULL-6158699516561791804-3
   * @param locale the locale for the cart to create
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @param offersHash the hash provided with the offers see {@link BaseOfferList#hash}
   */
  triggerCreateCart(
    selectedOfferId: string,
    locale: string,
    offersHash: string,
    cancelStream$: Observable<any> = NEVER
  ): Observable<FinnairCart> {
    const urlParams: HttpParams = new HttpParams().set('airOfferId', selectedOfferId);

    return this.createCart(urlParams, locale, offersHash, { airOfferId: selectedOfferId }, cancelStream$);
  }

  /**
   * Triggers cart creation for the given air bound ids. Stores the cart info in the app state store.
   * @param selectedAirBoundIds the ids of the selected air bounds
   * @param locale the locale for the cart to create
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @param boundsHash the hash provided with the bounds see {@link BookingAirBounds#hash}
   */
  triggerCreateCartByBounds(
    selectedAirBoundIds: string[],
    locale: string,
    boundsHash: string,
    cancelStream$: Observable<any> = NEVER
  ): Observable<FinnairCart> {
    return this.createCart(new HttpParams(), locale, boundsHash, { airBoundIds: selectedAirBoundIds }, cancelStream$);
  }

  /**
   * Requests the full ticket rules for the given traveler and category. Stores the rules in `cart` slice, inside the `fareRules` slice.
   * The full rules are written in cryptic format, and are not localized.
   *
   * Does not reload the cart after the update.
   * @param cartId
   * @param travelerId
   * @param ruleCategory
   * @param hash
   */
  triggerTicketRules(cartId: string, travelerId: string, ruleCategory: FareRuleCategory, hash?: string): void {
    const params = { cartId, travelerId, ruleCategory, hash };
    this.store$.dispatch(CartActions.fareRulesLoadStart({ travelerId, category: ruleCategory }));
    this.fareRulesSubscription.add(
      this.cartService
        .getFareRulesByCart$Response(this.configService.cfg.cartUrlWithoutAPI, params)
        .pipe(
          timeout(10000),
          map((res: DapiHttpResponse<FareRules>) => res.body),
          map((res) => this.createRulesFromResponse(res, ruleCategory)),
          catchError((err: unknown) => {
            this.sentryLogger.error('Error fetching full ticket rules', {
              error: mapErrorForSentry(err),
            });

            this.store$.dispatch(CartActions.fareRulesLoadEnd({ travelerId, category: ruleCategory }));
            this.store$.dispatch(CartActions.fareRulesError({ travelerId, category: ruleCategory }));

            return throwError(() => err);
          })
        )
        .subscribe((rules: PartialCartFareRuleRecord) => {
          this.store$.dispatch(CartActions.fareRulesLoadEnd({ travelerId, category: ruleCategory }));
          this.store$.dispatch(
            CartActions.fareRulesCreateCategories({
              travelerId,
              rules,
            })
          );
        })
    );
  }

  /**
   * Update services selections for given selections.
   *
   * Reloads the cart after the update. Will add `locale` parameter to request, if not present in URL.
   *
   * @experimental
   * @param requests @FinnairServiceRequestItem[]
   * @param cartUrl the url of the cart to update
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @return {Subscription} subscription fot the update that can be canceled if, e.g., a new cart creation is started
   */
  triggerUpdateServices(
    requests: FinnairServiceRequestItem[],
    cartUrl: string,
    cancelStream$: Observable<any> = NEVER
  ): Observable<FinnairCart> {
    const [baseUrl, urlParams] = this.splitUrlAndParamsAddLocaleIfNotPresent(cartUrl);
    unsubscribe(this.ancillarySubscription);
    const { subscription, stream$ } = this.createCancelableUpdateStream(
      `${baseUrl}/services`,
      urlParams,
      cancelStream$,
      requests,
      undefined,
      CartActions.updateNotCompleted.type,
      {}
    );
    this.ancillarySubscription = subscription;
    return stream$;
  }

  /**
   * Trigger setting travelers and reloading cart.
   *
   * Reloads the cart after the update. Will add `locale` parameter to request, if not present in URL.
   *
   * @param cartUrl the url of the cart to update
   * @param passengersState the passengers form data to update
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @param usePutMethod
   * @return subscription that can be canceled if, e.g., a new cart creation is started
   */
  triggerTravelersAndCorporateCodeUpdate(
    cartUrl: string,
    passengersState: PaxDetailsState,
    cancelStream$: Observable<any> = NEVER,
    usePutMethod = false
  ): Observable<FinnairCart> {
    const [baseUrl, urlParams] = this.splitUrlAndParamsAddLocaleIfNotPresent(cartUrl);
    unsubscribe(this.paxSubscription);

    const { stream$, subscription } = this.createCancelableUpdateStream(
      `${baseUrl}/passengers`,
      urlParams,
      cancelStream$,
      mapTravelersAndCorporateCode(passengersState),
      undefined,
      undefined,
      { usePutMethod, includeServiceCatalog: true }
    );
    this.paxSubscription = subscription;
    return stream$;
  }

  /**
   * Reset seat selections for all flights on the cart.
   *
   * Reloads the cart after the update. Will add `locale` parameter to request, if not present in URL.
   *
   * @param cartUrl the url of the cart to update
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   * @return {Subscription} subscription fot the update that can be canceled if, e.g., a new cart creation is started
   */
  triggerResetSeatSelections(cartUrl: string, cancelStream$: Observable<any> = NEVER): Observable<FinnairCart> {
    const [baseUrl, urlParams] = this.splitUrlAndParamsAddLocaleIfNotPresent(cartUrl);
    unsubscribe(this.ancillarySubscription);
    this.store$.dispatch(CartActions.updateStart());
    const includeServiceCatalog = false;
    const stream$ = this.http
      .delete(`${baseUrl}/seats`, {
        params: urlParams,
      })
      .pipe(
        retryWithBackoff(BookingCartService.NUMBER_OF_RETRIES),
        catchError((err: DapiHttpErrorResponse) =>
          throwError(() =>
            Object.assign(mapError(err), {
              uxErrorType: CartActions.updateNotCompleted.type,
            })
          )
        ),
        switchMap(() => this.loadCart(cartUrl, includeServiceCatalog)),
        catchError((err: DapiHttpErrorResponse) =>
          this.doOnCartUpdateError<FinnairCart>(mapError(err), baseUrl, urlParams)
        ),
        takeUntil(cancelStream$),
        share()
      );

    this.ancillarySubscription = new Subscription();
    this.ancillarySubscription.add(
      stream$.subscribe(this.updateSubscriber(this.ancillarySubscription, includeServiceCatalog))
    );

    return stream$;
  }

  triggerLoadCart(cartUrl: string, cancelStream$: Observable<any> = NEVER): Observable<FinnairCart> {
    unsubscribe(this.cartSubscription);
    const { stream$, subscription } = this.createCancelableLoadCartStream(cartUrl, cancelStream$);
    this.cartSubscription = subscription;
    return stream$;
  }

  /**
   * Triggers loading of cart from the given cart url.
   * @param cartUrl the URL of the cart
   * @return {Observable<FinnairCart>} observable of cart data
   */
  private loadCart(cartUrl: string, includeServiceCatalog: boolean): Observable<FinnairCart> {
    this.store$.dispatch(CartActions.loadStart());
    const params = new HttpParams().set('includeServiceCatalog', includeServiceCatalog);
    return this.http.get<FinnairCart>(cartUrl, { params }).pipe(
      retryWithBackoff(BookingCartService.NUMBER_OF_RETRIES),
      catchError((error: DapiHttpErrorResponse) =>
        throwError(() => Object.assign(mapError(error), { uxErrorType: CartActions.loadError.type }))
      )
    );
  }

  private createCart(
    urlParams: HttpParams,
    locale: string,
    offersHash: string,
    postData: object,
    cancelStream$: Observable<any> = NEVER
  ): Observable<FinnairCart> {
    const pax = snapshot(this.store$.pipe(globalBookingPaxAmount()));
    urlParams = urlParams.set('locale', locale).set('hash', offersHash);

    unsubscribe(this.cartSubscription);

    const { stream$, subscription } = this.createCancelableUpdateStream(
      this.configService.cfg.cartUrl,
      urlParams,
      cancelStream$,
      { ...{ passengerTypeCodes: this.paxTypeCodes(pax) }, ...postData },
      CartActions.creationStart(),
      CartActions.creationError.type,
      { includeServiceCatalog: true }
    );
    this.cartSubscription = subscription;
    return stream$;
  }

  private createCancelableUpdateStream(
    baseUrl: string,
    urlParams: HttpParams,
    cancelStream$: Observable<any>,
    body: any = {},
    startAction: TypedAction<any> = CartActions.updateStart(),
    uxErrorType: string = CartActions.updateError.type,
    { usePutMethod = false, includeServiceCatalog = false }: { usePutMethod?: boolean; includeServiceCatalog?: boolean }
  ): { subscription: Subscription; stream$: Observable<FinnairCart> } {
    this.store$.dispatch(startAction);

    const request$ = usePutMethod
      ? this.putToUrlAndReloadCart(baseUrl, urlParams, body, uxErrorType, includeServiceCatalog)
      : this.postToUrlAndReloadCart(baseUrl, urlParams, body, uxErrorType, includeServiceCatalog);

    const stream$ = request$.pipe(takeUntil(cancelStream$), share());

    const subscription = new Subscription();
    subscription.add(stream$.subscribe(this.updateSubscriber(subscription, includeServiceCatalog)));
    return { subscription, stream$ };
  }

  private createCancelableLoadCartStream(
    url: string,
    cancelStream$: Observable<any>
  ): { subscription: Subscription; stream$: Observable<FinnairCart> } {
    const includeServiceCatalog = true;
    const stream$ = this.loadCart(url, includeServiceCatalog).pipe(takeUntil(cancelStream$), share());
    const subscription = new Subscription();
    subscription.add(stream$.subscribe(this.updateSubscriber(subscription, includeServiceCatalog)));
    return { subscription, stream$ };
  }

  private postToUrlAndReloadCart(
    baseUrl: string,
    urlParams: HttpParams,
    body: any = {},
    uxErrorType,
    includeServiceCatalog: boolean
  ): Observable<FinnairCart> {
    const request$ = this.http.post(baseUrl, body, {
      params: urlParams,
      observe: 'response',
      responseType: 'text',
    });

    return this.doRequestAndReloadCart(request$, baseUrl, urlParams, uxErrorType, includeServiceCatalog);
  }

  private putToUrlAndReloadCart(
    baseUrl: string,
    urlParams: HttpParams,
    body: any = {},
    uxErrorType,
    includeServiceCatalog: boolean
  ): Observable<FinnairCart> {
    const request$ = this.http.put(baseUrl, body, {
      params: urlParams,
      observe: 'response',
      responseType: 'text',
    });

    return this.doRequestAndReloadCart(request$, baseUrl, urlParams, uxErrorType, includeServiceCatalog);
  }

  private doRequestAndReloadCart(
    request$: Observable<HttpResponse<string>>,
    baseUrl: string,
    urlParams: HttpParams,
    uxErrorType,
    includeServiceCatalog: boolean
  ) {
    return request$.pipe(
      map((res) => res.headers.get('Location')),
      retryWithBackoff(BookingCartService.NUMBER_OF_RETRIES),
      catchError((err: DapiHttpErrorResponse) => throwError(() => Object.assign(mapError(err), { uxErrorType }))),
      tap((cartLocation: string) => this.store$.dispatch(CartActions.creationSuccess({ cartUrl: cartLocation }))),
      switchMap((cartLocation: string) => this.loadCart(cartLocation, includeServiceCatalog)),
      catchError((err: DapiHttpErrorResponse) =>
        this.doOnCartUpdateError<FinnairCart>(mapError(err), baseUrl, urlParams)
      )
    );
  }

  private updateSubscriber = (
    subscription: Subscription,
    includeServiceCatalog: boolean
  ): PartialObserver<FinnairCart> => ({
    next: (cartData: FinnairCart) => {
      this.store$.dispatch(CartActions.setCartData({ cartData }));
      if (includeServiceCatalog) {
        this.commonBookingAncillaryService.setServiceCatalog(cartData);
      }
      unsubscribe(subscription);
    },
    error: (err: DapiError) => {
      if (err.key === DapiErrorCode.UNABLE_TO_RETRIEVE_OFFER) {
        navigateToError(this.router, this.languageService.langValue, 'OFFER_EXPIRED', {
          iconName: SvgLibraryIcon.SAVE_TIME,
          subtitle: 'errors.offerExpired.title',
          info: 'errors.offerExpired.description',
        });
      } else if (err.uxErrorType !== CartActions.updateNotCompleted.type) {
        navigateToError(this.router, this.languageService.langValue, 'CART_ERROR');
      }
      unsubscribe(subscription);
    },
    complete: () => unsubscribe(subscription),
  });

  private doOnCartUpdateError<T>(error: DapiError, baseUrl, urlParams): Observable<T> {
    if (error.uxErrorType === CartActions.creationError.type) {
      this.store$.dispatch(CartActions.creationError());
      const errorMessage: string =
        error.key === DapiErrorCode.UNABLE_TO_RETRIEVE_OFFER
          ? 'Offer expired when creating cart'
          : 'Error creating cart';
      this.sentryLogger.error(`${errorMessage}: [${error.key}]`, {
        baseUrl,
        urlParams,
        error: mapErrorForSentry(error),
      });
    } else if (error.uxErrorType === CartActions.loadError.type) {
      this.store$.dispatch(CartActions.loadError());
      this.sentryLogger.error(`Error fetching cart after update: [${error.key}]`, {
        baseUrl,
        urlParams,
        error: mapErrorForSentry(error),
      });
    } else if (error.uxErrorType === CartActions.updateNotCompleted.type) {
      this.store$.dispatch(CartActions.updateNotCompleted());
      this.sentryLogger.error(`Error storing ancillaries: [${error.key}]`, {
        baseUrl,
        urlParams,
        error: mapErrorForSentry(error),
      });
    } else {
      this.store$.dispatch(CartActions.updateError());
      this.sentryLogger.error(`Error updating cart: [${error.key}]`, {
        baseUrl,
        urlParams,
        error: mapErrorForSentry(error),
      });
    }
    return throwError(() => error);
  }

  private splitUrlAndParamsAddLocaleIfNotPresent(cartUrl: string): [string, HttpParams] {
    const [baseUrl, parameters] = cartUrl.split('?');

    let urlParams: HttpParams = new HttpParams({ fromString: parameters });
    if (!urlParams.get('locale')) {
      urlParams = urlParams.set('locale', this.languageService.localeValue);
    }
    return [baseUrl, urlParams];
  }

  private paxTypeCodes(pax: PaxAmount): string[] {
    const ptcMap = {
      adults: FinnairPassengerCode.ADT,
      c15s: FinnairPassengerCode.C_15,
      children: FinnairPassengerCode.CHD,
      infants: FinnairPassengerCode.INF,
    };
    return Object.keys(ptcMap)
      .sort()
      .reduce((acc: FinnairPassengerCode[], k: string) => {
        const temp = Array(pax[k])
          .fill('')
          .map(() => ptcMap[k]);
        return acc.concat(temp);
      }, []);
  }

  private createRulesFromResponse(res: FareRules, ruleCategory: FareRuleCategory): PartialCartFareRuleRecord {
    return res.fareRules.reduce(
      (ticketRules, rule) => {
        ticketRules[rule.ruleCategory] = ticketRules[rule.ruleCategory] || [];

        ticketRules[rule.ruleCategory].push({
          flightId: rule.flightId,
          rule: rule.rule,
        });

        return ticketRules;
      },
      { [ruleCategory]: [] } as PartialCartFareRuleRecord
    );
  }
}
