import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FetchPriorityEnum } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common';
import { TripType } from '@fcom/core/interfaces';
import { safeMap, snapshot } from '@fcom/rx';
import { isBlank } from '@fcom/core/utils';
import { GtmService } from '@fcom/common/gtm';
import { GlobalBookingActions } from '@fcom/common/store';
import { AppState, GlobalBookingTravelClass } from '@fcom/core';

import { BlockOfferData, MarketingOffer, BlockOffer } from '../../interfaces';
import { CheapestPriceForAllDestinationsService } from '../../services';

@Component({
  selector: 'fin-block-offer',
  templateUrl: './block-offer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockOfferComponent implements OnInit {
  @Output()
  destinationClicked: EventEmitter<[BlockOffer, GlobalBookingTravelClass]> = new EventEmitter();
  @Output()
  flightHotelClicked: EventEmitter<{ amLink: string; destination: string }> = new EventEmitter();
  @Output()
  calendarClicked: EventEmitter<[Event, BlockOffer, GlobalBookingTravelClass, TripType]> = new EventEmitter();

  @Input()
  tripType: TripType = TripType.RETURN;

  @Input()
  offer: BlockOfferData;

  @Input()
  fetchImagePriority?: keyof typeof FetchPriorityEnum;

  blockOffer$: Observable<BlockOffer>;

  constructor(
    private languageService: LanguageService,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private cheapestPriceForAllDestinationsService: CheapestPriceForAllDestinationsService
  ) {}

  ngOnInit(): void {
    this.blockOffer$ = combineLatest([
      this.offer ? this.countryName(this.offer) : of(undefined),
      this.offer ? this.marketingOffer(this.offer) : of(undefined),
    ]).pipe(
      map(([countryName, marketingOffer]: [string, MarketingOffer]) => ({
        ...this.setUpPrices(this.offer, marketingOffer, this.tripType),
        countryName,
        marketingOffer,
      }))
    );
  }

  emitCalendarClick(
    [event, travelClass, tripType]: [Event, GlobalBookingTravelClass, TripType],
    blockOffer: BlockOffer
  ): void {
    this.calendarClicked.emit([event, blockOffer, travelClass, tripType]);
  }

  private countryName({ flag }: BlockOfferData): Observable<string> {
    if (isBlank(flag)) {
      return of(undefined);
    }
    return this.languageService.translate('countryCodes').pipe(
      map((countries: any[]) => countries.find((country: any) => country.countryCode === flag.toUpperCase())),
      safeMap(({ name }) => name),
      startWith(undefined as string)
    );
  }

  private marketingOffer({ destination }: BlockOfferData): Observable<MarketingOffer> {
    return this.cheapestPriceForAllDestinationsService.offerFor(destination, this.tripType).pipe(
      map((offer: MarketingOffer) => {
        return {
          ...offer,
          prices: offer.prices.filter(Boolean),
        };
      }),
      startWith(undefined as MarketingOffer)
    );
  }

  private setUpPrices(offer: BlockOfferData, marketingOffer: MarketingOffer, tripType: TripType): BlockOfferData {
    const priceOffer = marketingOffer?.prices.find((price) => price.travelClass === GlobalBookingTravelClass.ECONOMY);

    const onewayPrice = tripType === TripType.ONEWAY ? priceOffer : undefined;
    const returnPrice = tripType === TripType.RETURN ? priceOffer : undefined;

    return { ...offer, onewayPrice, returnPrice };
  }

  onLinkClick([travelClass]: [GlobalBookingTravelClass, TripType]): void {
    this.destinationClicked.emit([this.offer, travelClass]);
    this.prefillBookingWidget(travelClass);
  }

  prefillBookingWidget(travelClass: GlobalBookingTravelClass): void {
    const offerDestination = snapshot(this.blockOffer$)?.destination;
    if (offerDestination) {
      this.store$.dispatch(GlobalBookingActions.setTripType({ tripType: this.tripType }));
      this.store$.dispatch(
        GlobalBookingActions.setTravelClass({
          travelClass: travelClass === GlobalBookingTravelClass.BUSINESS ? travelClass : GlobalBookingTravelClass.MIXED,
        })
      );
      this.gtmService.trackElement(
        `tile-${this.tripType.toLowerCase()}-${travelClass.toLowerCase()}`,
        GaContext.BLOCK_OFFER,
        ElementTypes.BANNER,
        `${offerDestination}`,
        ElementActions.CLICK
      );
    }
  }
}
