@if (content$ | async; as content) {
  <div
    class="teaser-list grid-col-12 no-gutters"
    [ngClass]="{
      'no-row-gap': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST),
      'row-gap-24': content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_NEWS_COLLECTION),
    }"
  >
    @if (items$ | async; as items) {
      <cms-heading-tag
        *ngIf="content.teaserTitle && renderHeading(content.subjectTaxonomyTags)"
        class="col-1-12 ps-xsmall-b"
        [ngClass]="{ 'mr-large-b': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) }"
        [level]="headerLevel(content.subjectTaxonomyTags || []) ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerFontLevel(content.subjectTaxonomyTags || []) ?? 'font-heading-3'"
        >{{ content.teaserTitle }}</cms-heading-tag
      >

      @if (content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_TEASER_STYLE)) {
        @for (teaserItem of items; track trackByUrl) {
          <fin-teaser
            #listItem
            [class]="content.gridClass || 'col-1-12 md-col-1-8'"
            [content]="teaserItem"
          ></fin-teaser>
        }
      } @else if (content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST)) {
        <div class="col-1-12 flex flex--column row-gap-16">
          @for (teaserItem of items; track trackByUrl) {
            <fin-text-link-left
              #listItem
              [teaserTitle$]="teaserItem.teaserTitle | finToObservable"
              [target$]="teaserItem | finToObservable"
              [useIcon]="false"
              [underline]="true"
            ></fin-text-link-left>
          }
        </div>
      } @else if (content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_TEASER_LIST_THIRD)) {
        @for (teaserItem of items; track trackByUrl) {
          <fin-teaser-card
            #listItem
            class="col-1-12 teaser-wrapper grid-third"
            [aspectRatio]="'16x9'"
            [content]="teaserItem"
          ></fin-teaser-card>
        }
      } @else if (content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_NEWS_COLLECTION)) {
        <div class="list col-1-12">
          @for (teaserItem of items; track trackByUrl) {
            <fin-news-feed-item
              #listItem
              class="block border-top"
              [ngClass]="{ 'border-bottom': $last }"
              [content]="teaserItem"
              [companyNews]="content.subjectTaxonomyTags.includes(TAXONOMY.EMERGENCY_IN_OPERATIONS_COMPANY)"
            ></fin-news-feed-item>
          }
        </div>
      } @else {
        @for (teaserItem of items; track trackByUrl) {
          <fin-teaser-card
            #listItem
            class="col-1-12 teaser-wrapper"
            [aspectRatio]="'16x9'"
            [content]="teaserItem"
          ></fin-teaser-card>
        }
      }
    }
    <div
      class="col-1-12 ms-small-y flex load-more"
      [ngClass]="{
        'ps-small-t': content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST),
        'flex--end list': content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_NEWS_COLLECTION),
      }"
      *ngIf="showLoadMore$ | async"
    >
      <fcom-button
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [text]="'actions.loadMore' | finLocalization"
        [href]="nextPageLink$ | async"
        [loading]="loading"
        [icon]="
          content.subjectTaxonomyTags.includes(TAXONOMY.QUERY_LIST_NEWS_COLLECTION)
            ? {
                name: SvgLibraryIcon.PLUS,
                position: IconPosition.LEFT,
              }
            : null
        "
        (clickBtn)="loadNextItems($event)"
      ></fcom-button>
    </div>

    @if (content.subjectTaxonomyTags.includes(TAXONOMY.TEXT_LINK_LEFT_LIST) && content.teaserText) {
      <div class="col-1-12 teaser-text flex pr-large-t" [innerHTML]="content.teaserText | finSafe: 'html'"></div>
    }
  </div>
}
