import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { FinnairBoundItem, FinnairLocation, FinnairTravelEndpoint } from '@fcom/dapi/api/models';
import { getCityName } from '@fcom/common/utils/booking.utils';
import { ButtonMode, ButtonSize, ButtonTheme, IconPosition, NotificationLayout } from '@fcom/ui-components';
import { Airport } from '@fcom/common/interfaces/booking';

import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'fcom-bound-selection-item',
  templateUrl: 'bound-selection-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoundSelectionItemComponent implements OnInit {
  @Input() bound: FinnairBoundItem;
  @Input() locations: { [key: string]: FinnairLocation };
  @Input() isDeparture: boolean;
  @Input() flightNumber: number;
  @Input() isSelected = false;
  @Input() disabled = false;
  @Input() tails: string[];
  @Input() notification: string;
  @Output() boundSelected: EventEmitter<FinnairBoundItem> = new EventEmitter<FinnairBoundItem>();

  departureCity: string;
  arrivalCity: string;

  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly IconPosition = IconPosition;
  readonly NotificationLayout = NotificationLayout;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.departureCity = getCityName(this.bound.departure.locationCode, this.locations);
    this.arrivalCity = getCityName(this.bound.arrival.locationCode, this.locations);
  }

  getLocation(leg: FinnairTravelEndpoint): Observable<Airport> {
    return this.utilsService.getLocation(leg.locationCode, leg.dateTime);
  }

  selectBound(bound: FinnairBoundItem): void {
    this.boundSelected.emit(bound);
  }
}
