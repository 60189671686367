import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsTemplate } from '@fcom/core-api';

@Component({
  selector: 'cms-bluewings-cluster-page',
  templateUrl: './cms-bluewings-cluster-page.component.html',
  styleUrls: ['./cms-bluewings-cluster-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsBluewingsClusterPageComponent {
  @Input()
  template$: Observable<CmsTemplate>;

  @Input()
  hasContent = false;
}
