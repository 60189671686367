import { Observable } from 'rxjs';

import { FinnairPassengerItem } from '@fcom/dapi/api/models';

export interface OrderDataForNative {
  recloc: string;
  travelers?: FinnairPassengerItem[];
  rawBooking: any;
}

export interface CheckInStatus {
  status: boolean;
}

export interface CheckInResult extends CheckInStatus {
  travelerIds: string[];
  flightIds: string[];
}

export abstract class NativeBridgeService {
  abstract init(): void;

  abstract setAppHideStatus(status: boolean): void;

  abstract urlForAddAJourneyInApp(recloc: string, lastName: string): string;

  abstract openNativeMenu(): void;

  abstract onShowHamburgerMenu(isOpen: boolean): void;

  abstract onBackPressed(): void;

  abstract clientReady(): void;

  abstract addBooking(booking: OrderDataForNative): void;

  abstract onAcceptCheckIn(data: CheckInResult): void;

  abstract onCheckInLoaded(data: CheckInStatus): void;

  abstract get appHideStatus(): Observable<boolean>;

  abstract get isInsideNativeWebView(): boolean;

  abstract get isInsideNativeiOSWebView(): boolean;

  abstract get isInsideNativeAndroidWebView(): boolean;

  abstract get isLocalView(): boolean;

  abstract get backButtonPressed(): Observable<void>;

  abstract get isHamburgerMenuVisible(): Observable<boolean>;
}
