<fieldset
  *ngIf="i18n"
  class="border-none file-wrapper"
  [ngClass]="{ 'grey-600-text': fieldDisabled }"
  [formGroup]="parentForm"
>
  <legend class="font-heading-4 bold-type medium-type ms-medium-b" data-testid="file-uploader-label">
    {{ labelAttach }}
    <span *ngIf="fieldRequired" data-testid="asterisk">*</span>
  </legend>
  <dl class="flex font-body-1 gap-4 mr-xsmall-b" *ngIf="maxAttachments">
    <dt data-testid="max-attachments-label">{{ i18n.maxAttachments }}:</dt>
    <dd class="bold-type" data-testid="max-attachments-value">{{ maxAttachments }}</dd>
  </dl>
  <dl class="flex font-body-1 gap-4 mr-xsmall-b" *ngIf="maxSizeInBytes">
    <dt data-testid="max-file-size-label">{{ i18n.maxFileSize }}:</dt>
    <dd class="bold-type" data-testid="max-file-size-value">{{ maxSizeMb }} {{ i18n.megaBytes }}</dd>
  </dl>
  <dl class="flex flex--half gap-4 font-body-1 mr-small-b" *ngIf="filesSupported.length">
    <dt data-testid="files-supported-label">{{ i18n.filesSupported }}:</dt>
    <dd class="bold-type" data-testid="files-supported-list">{{ filesSupported }}</dd>
  </dl>

  <label class="dn-mobile" [for]="idOrControlName" [class.disabled]="fieldDisabled">
    <div
      class="drop-area font-body-1 medium-type border border-dashed rounded-large align-center ps-large"
      [ngClass]="{
        'error error-900-border': containsErrors,
        'grey-100-bg': fieldDisabled || isUploading,
        'nordic-blue-25-bg': dragInProgress$ | async,
        'nordic-blue-400-border': !fieldDisabled && !containsErrors,
      }"
      data-testid="drop-area"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter(true)"
      (dragleave)="onDragEnter(false)"
    >
      <span class="block">{{ i18n.dragAndDrop }}</span>
      <span>{{ i18n.or }} </span>
      <span class="underline">{{ i18n.browseFiles }}</span>
    </div>

    <input
      data-testid="file-uploader-input"
      (cancel)="onCancel($event)"
      (change)="onChange($event)"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      (click)="onClick()"
      class="is-vishidden"
      [attr.accept]="acceptValues"
      [attr.aria-required]="fieldRequired"
      [disabled]="fieldDisabled || isUploading"
      [id]="idOrControlName"
      [multiple]="multiple"
      [name]="controlName"
      type="file"
      #fileInput
    />
  </label>

  <div class="dn-tablet-up">
    <fcom-button
      class="mr-xsmall-b"
      data-testid="open-file-selector"
      [disabled]="fieldDisabled || isUploading"
      [mode]="ButtonMode.FILL_MOBILE"
      [icon]="{ name: SvgLibraryIcon.UPLOAD, position: IconPosition.LEFT }"
      [text]="labelAttach"
      (click)="openFileSelector()"
    ></fcom-button>
  </div>

  <ng-container *ngIf="attachmentsWithErrors()?.length">
    <div class="error error-900-text pr-xxsmall-t" data-testid="error-message">
      @for (attachmentError of attachmentErrorKeys(); track $index) {
        <p class="flex font-body-1" [attr.data-testid]="'attachment-error-' + attachmentError">
          <fcom-icon class="icon-medium ms-small-r" [name]="SvgLibraryIcon.ALERT_TRIANGLE"></fcom-icon>
          @switch (attachmentError) {
            @case (AttachmentError.DUPLICATE) {
              <span
                [innerHTML]="
                  i18n.errors[fileErrors()[attachmentError]?.length === 1 ? 'duplicateSingle' : 'duplicateMultiple']
                    | finMsgFormat: { files: fileErrorLabels()[attachmentError] }
                    | finSafe: 'html'
                "
              ></span>
            }
            @case (AttachmentError.EXTENSION) {
              <span
                [innerHTML]="
                  i18n.errors[
                    fileErrors()[attachmentError]?.length === 1 ? 'invalidFileTypeSingle' : 'invalidFileTypeMultiple'
                  ]
                    | finMsgFormat: { files: fileErrorLabels()[attachmentError] }
                    | finSafe: 'html'
                "
              ></span>
            }
            @case (AttachmentError.MALWARE) {
              <span
                [innerHTML]="
                  i18n.errors[fileErrors()[attachmentError]?.length === 1 ? 'malwareSingle' : 'malwareMultiple']
                    | finMsgFormat: { files: fileErrorLabels()[attachmentError] }
                    | finSafe: 'html'
                "
              ></span>
            }
            @case (AttachmentError.MAX_ATTACHMENTS) {
              <span
                [innerHTML]="i18n.errors.maxAttachments | finMsgFormat: { maxAttachments } | finSafe: 'html'"
              ></span>
            }
            @case (AttachmentError.MAX_SIZE) {
              <span
                [innerHTML]="
                  i18n.errors[
                    fileErrors()[attachmentError]?.length === 1 ? 'invalidFileSizeSingle' : 'invalidFileSizeMultiple'
                  ]
                    | finMsgFormat: { files: fileErrorLabels()[attachmentError] }
                    | finSafe: 'html'
                "
              ></span>
            }
            @default {
              <span
                [innerHTML]="
                  i18n.errors.unknown | finMsgFormat: { files: fileErrorLabels()[attachmentError] } | finSafe: 'html'
                "
              ></span>
            }
          }
        </p>
      } @empty {
        <p class="font-body-1">{{ labelRequired }}</p>
      }
    </div>
  </ng-container>

  <fcom-attachment-list [attachments]="attachments" (fileDeleted)="deleteAttachment($event)"></fcom-attachment-list>
</fieldset>
