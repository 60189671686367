/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckInAcceptCancelRequest } from '../models/check-in-accept-cancel-request';
import { CheckInBoardingPassByEmailRequest } from '../models/check-in-boarding-pass-by-email-request';
import { CheckInBoardingPassByEmailResponse } from '../models/check-in-boarding-pass-by-email-response';
import { CheckInInitializeRequest } from '../models/check-in-initialize-request';
import { CheckInReassignExitSeatsRequest } from '../models/check-in-reassign-exit-seats-request';
import { FinnairBoardingPassDocumentType } from '../models/finnair-boarding-pass-document-type';


/**
 * Check-in resources in use
 */
@Injectable({
  providedIn: 'root',
})
export class CheckinService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation acceptCheckIn
   */
  static readonly AcceptCheckInPath = '/api/v2/checkin/{orderId}/accept';

  /**
   * acceptCheckIn.
   *
   * Performs check-in for a list of travelerIds and flightIds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptCheckIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptCheckIn$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInAcceptCancelRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.AcceptCheckInPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * acceptCheckIn.
   *
   * Performs check-in for a list of travelerIds and flightIds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptCheckIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptCheckIn(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInAcceptCancelRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.acceptCheckIn$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation cancelCheckIn
   */
  static readonly CancelCheckInPath = '/api/v2/checkin/{orderId}/cancel';

  /**
   * cancelCheckIn.
   *
   * Performs cancellation of check-in for a list of travelerIds and flightIds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelCheckIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCheckIn$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInAcceptCancelRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.CancelCheckInPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * cancelCheckIn.
   *
   * Performs cancellation of check-in for a list of travelerIds and flightIds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelCheckIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelCheckIn(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInAcceptCancelRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.cancelCheckIn$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation initializeCheckIn
   */
  static readonly InitializeCheckInPath = '/api/v2/checkin/{orderId}/initialize';

  /**
   * initializeCheckIn.
   *
   * Tracks check-in starts in quicksight
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initializeCheckIn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initializeCheckIn$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInInitializeRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.InitializeCheckInPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * initializeCheckIn.
   *
   * Tracks check-in starts in quicksight
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initializeCheckIn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initializeCheckIn(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInInitializeRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.initializeCheckIn$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reassignExitSeats
   */
  static readonly ReassignExitSeatsPath = '/api/v2/checkin/{orderId}/reassign-exit-seats';

  /**
   * reassignExitSeats.
   *
   * Reassigns the exit seats with non-exit seats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignExitSeats()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reassignExitSeats$Response(rootUrl: string, params: {
    orderId: string;
    hash: string;
    body: CheckInReassignExitSeatsRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.ReassignExitSeatsPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * reassignExitSeats.
   *
   * Reassigns the exit seats with non-exit seats
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignExitSeats$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reassignExitSeats(rootUrl: string, params: {
    orderId: string;
    hash: string;
    body: CheckInReassignExitSeatsRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.reassignExitSeats$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sendBoardingPass
   */
  static readonly SendBoardingPassPath = '/api/v2/checkin/{orderId}/send-boarding-pass';

  /**
   * sendBoardingPass.
   *
   * Performs sending of a boarding pass for a list of travelerIds and flightIds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendBoardingPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendBoardingPass$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInBoardingPassByEmailRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<CheckInBoardingPassByEmailResponse>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.SendBoardingPassPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<CheckInBoardingPassByEmailResponse>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * sendBoardingPass.
   *
   * Performs sending of a boarding pass for a list of travelerIds and flightIds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendBoardingPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendBoardingPass(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    body: CheckInBoardingPassByEmailRequest
  },
  context?: HttpContext

): Observable<CheckInBoardingPassByEmailResponse> {
    return this.sendBoardingPass$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<CheckInBoardingPassByEmailResponse>) => r.body as CheckInBoardingPassByEmailResponse)
    );
  }

  /**
   * Path part for operation getBoardingPassLink
   */
  static readonly GetBoardingPassLinkPath = '/api/v2/checkin/{orderId}/{flightId}/{travelerId}/get-boarding-pass';

  /**
   * getBoardingPassLink.
   *
   * Performs getting of a boarding pass for a specific flightId and travelerId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoardingPassLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoardingPassLink$Response(rootUrl: string, params: {
    orderId: string;
    flightId: string;
    travelerId: string;
    hash: string;
    type: FinnairBoardingPassDocumentType;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CheckinService.GetBoardingPassLinkPath, 'get');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.path('flightId', params.flightId, {});
      rb.path('travelerId', params.travelerId, {});
      rb.query('hash', params.hash, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getBoardingPassLink.
   *
   * Performs getting of a boarding pass for a specific flightId and travelerId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoardingPassLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoardingPassLink(rootUrl: string, params: {
    orderId: string;
    flightId: string;
    travelerId: string;
    hash: string;
    type: FinnairBoardingPassDocumentType;
  },
  context?: HttpContext

): Observable<void> {
    return this.getBoardingPassLink$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

}
