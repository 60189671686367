<ng-container *ngIf="currentOffer$ | async as details">
  <div>
    <div class="flex flex--space-between flex--middle">
      <h1 class="pr-large-y font-heading-3 ms-clear bold-type nordic-blue-900-text">
        {{ 'ticketSelection.reviewFlights' | finLocalization }}
      </h1>
      <fin-language-selector
        *ngIf="enableNewLanguageSelector && !enableNewStepper"
        [context]="LANGUAGE_SELECTOR_CONTEXT"
        [showWarning]="true"
        [skipRedirect]="true"
        (applySelection)="activateSelectionPhase(SelectionPhase.INBOUND, $event.languageCode)"
      ></fin-language-selector>
    </div>
    <fin-flight-card
      *ngFor="let boundSummary of boundSummary$ | async; let first = first; let i = index"
      class="block"
      [ngClass]="{
        'pr-xsmall-t': !first,
      }"
      [attr.data-testid]="'pax-flight-review-bound-' + i"
      [flightOrBound]="boundSummary.bound"
      [title]="
        'bookingSummary.boundTitle'
          | finLocalization
            : {
                index: i + 1,
                departureCity: boundSummary.departureCity,
                arrivalCity: boundSummary.arrivalCity,
              }
      "
      [locations]="details.locations"
      [passengers]="details.passengers"
      (viewDetails)="showTicketSummaryModal(boundSummary)"
      (modifyClicked)="returnToFlightSelection()"
    ></fin-flight-card>
  </div>

  <fcom-modal
    class="ticket-summary-modal"
    [title]="'ticketSelection.flightsAndBenefits.title' | finLocalization"
    [buttons]="ModalButtons.DONE"
    [open]="ticketSummaryModal.open"
    (close)="closeModals()"
  >
    <fcom-tabs
      [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
      [nextButtonAriaLabel]="'carousel.next' | finLocalization"
      [layoutType]="TabLayoutType.IN_MODAL"
    >
      <fcom-tab [title]="'fareFamilyDetails.title' | finLocalization">
        <fin-fare-family-details
          class="block pr-medium"
          [fareFamilies]="[details.benefits[ticketSummaryModal.bound?.fareFamilyCode]]"
        >
        </fin-fare-family-details>
      </fcom-tab>
      <fcom-tab [title]="'itinerary.title' | finLocalization">
        <fin-itinerary-timeline
          class="block pr-medium"
          [locations]="details.locations"
          [bound]="ticketSummaryModal.bound"
          [boundType]="ticketSummaryModal.boundType"
          [isBookingFlow]="true"
        ></fin-itinerary-timeline>
      </fcom-tab>
    </fcom-tabs>
  </fcom-modal>
</ng-container>
