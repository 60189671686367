<div class="flex container flex--space-between">
  <fin-promotion-banner
    class="flex flex--column fill banner-card mr-xsmall-t mr-xsmall-r white-bg border rounded-large overflow-hidden"
    [imageFromFragment]="'fragments.compensateChooose'"
    [translationPrefix]="'compensateFlight'"
    [url]="chooseUrl$ | async"
  ></fin-promotion-banner>

  <fin-promotion-banner
    class="flex flex--column fill banner-card mr-xsmall-t mr-xsmall-r white-bg border rounded-large overflow-hidden"
    *ngIf="(loggedIn$ | async) === LoginStatus.NOT_LOGGED_IN"
    [imageFromFragment]="'fragments.sustainableTravel'"
    [translationPrefix]="'sustainableTravel'"
    [url]="'sustainableTravel.link.url' | finLocalization"
  ></fin-promotion-banner>

  <fin-promotion-banner
    class="flex flex--column fill banner-card mr-xsmall-t mr-xsmall-r white-bg border rounded-large overflow-hidden"
    *ngIf="(loggedIn$ | async) === LoginStatus.LOGGED_IN"
    [imageFromFragment]="'fragments.donatePoints'"
    [translationPrefix]="'donatePoints'"
    [url]="finnairShopDonateUrl"
    [isExternalLink]="true"
  ></fin-promotion-banner>
</div>
