<ng-container *ngIf="mappedContent$ | async as content">
  <div *ngIf="content.items?.length > 0">
    <h2>{{ content.teaserTitle }}</h2>
    <div class="promo-cards fill grid-col-12 no-gutters" [class.full-width]="content.items?.length === 1">
      <fcom-promo-card
        *ngFor="let item of content.items.slice(0, 2)"
        [data$]="item | finToObservable"
        [imageData$]="item.picture ? (item.picture | finToObservable) : NEVER"
        [ctaData$]="item?.target | finToObservable"
        [specialGridSizeForLaptop]="true"
        [fullWidth]="content.items?.length === 1"
      >
      </fcom-promo-card>
    </div>
  </div>
</ng-container>
