import { Injectable, OnDestroy, StateKey, TransferState } from '@angular/core';

import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { finShare } from '@fcom/rx';

@Injectable()
export class StateTransferService implements OnDestroy {
  private requested: Map<StateKey<any>, Observable<any>>;

  constructor(private state: TransferState) {
    this.requested = new Map<StateKey<any>, Observable<any>>();
  }

  ngOnDestroy(): void {
    this.requested.clear();
  }

  wrapToStateCache<T>(stateKey: StateKey<T>, getter: () => Observable<T>): Observable<T> {
    if (this.state.hasKey<T>(stateKey)) {
      this.requested.delete(stateKey);
      return of(this.state.get<T>(stateKey, null));
    }
    if (this.requested.has(stateKey)) {
      return this.requested.get(stateKey);
    }
    const ret$ = getter().pipe(
      take(1),
      tap((val) => this.state.set<T>(stateKey, val)),
      finShare({ bufferSize: 1, refCount: false })
    );
    this.requested.set(stateKey, ret$);
    return ret$;
  }
}
