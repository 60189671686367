/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LowestPriceForFlights } from '../models/lowest-price-for-flights';
import { LowestPriceForOffers } from '../models/lowest-price-for-offers';
import { LowestPriceOfPeriod } from '../models/lowest-price-of-period';
import { Pong } from '../models/pong';
import { PostPricesForFlightsRequest } from '../models/post-prices-for-flights-request';
import { SearchSource } from '../models/search-source';


/**
 * Instant search resources
 */
@Injectable({
  providedIn: 'root',
})
export class InstantsearchService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation ping
   */
  static readonly PingPath = '/api/instantsearch/ping';

  /**
   * /ping.
   *
   * ping
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ping()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Response(rootUrl: string, params: {
    query: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<Pong>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.PingPath, 'get');

    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<Pong>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /ping.
   *
   * ping
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping(rootUrl: string, params: {
    query: string;
  },
  context?: HttpContext

): Observable<Pong> {
    return this.ping$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<Pong>) => r.body as Pong)
    );
  }

  /**
   * Path part for operation getPricesForFlights
   */
  static readonly GetPricesForFlightsPath = '/api/instantsearch/prices/flights';

  /**
   * /prices/flights.
   *
   * getPricesForFlights - Deprecated because of the HTTP get limitation reached when a origin/destination list is too big.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPricesForFlights()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getPricesForFlights$Response(rootUrl: string, params: {

    /**
     * IATA codes for airports, cities
     */
    departureLocationCodes: Array<string>;

    /**
     * IATA codes for airports, cities
     */
    destinationLocationCodes: Array<string>;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate?: string;

    /**
     * Number of days to search forward from given start date
     */
    numberOfDays?: number;

    /**
     * Length of stay
     */
    lengthOfStay?: string;

    /**
     * Travel classes
     */
    travelClasses?: Array<string>;

    /**
     * Request oneway trip
     *
     * @deprecated
     */
    oneway?: boolean;

    /**
     * Trip types
     */
    tripTypes?: Array<string>;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<LowestPriceForFlights>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.GetPricesForFlightsPath, 'get');

    if (params) {
      rb.query('departureLocationCodes', params.departureLocationCodes, {"explode":false});
      rb.query('destinationLocationCodes', params.destinationLocationCodes, {"explode":false});
      rb.query('departureDate', params.departureDate, {});
      rb.query('numberOfDays', params.numberOfDays, {});
      rb.query('lengthOfStay', params.lengthOfStay, {});
      rb.query('travelClasses', params.travelClasses, {"explode":false});
      rb.query('oneway', params.oneway, {});
      rb.query('tripTypes', params.tripTypes, {"explode":false});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<LowestPriceForFlights>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /prices/flights.
   *
   * getPricesForFlights - Deprecated because of the HTTP get limitation reached when a origin/destination list is too big.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPricesForFlights$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getPricesForFlights(rootUrl: string, params: {

    /**
     * IATA codes for airports, cities
     */
    departureLocationCodes: Array<string>;

    /**
     * IATA codes for airports, cities
     */
    destinationLocationCodes: Array<string>;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate?: string;

    /**
     * Number of days to search forward from given start date
     */
    numberOfDays?: number;

    /**
     * Length of stay
     */
    lengthOfStay?: string;

    /**
     * Travel classes
     */
    travelClasses?: Array<string>;

    /**
     * Request oneway trip
     *
     * @deprecated
     */
    oneway?: boolean;

    /**
     * Trip types
     */
    tripTypes?: Array<string>;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<LowestPriceForFlights> {
    return this.getPricesForFlights$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<LowestPriceForFlights>) => r.body as LowestPriceForFlights)
    );
  }

  /**
   * Path part for operation postPricesForFlights
   */
  static readonly PostPricesForFlightsPath = '/api/instantsearch/prices/flights';

  /**
   * /prices/flights.
   *
   * getPricesForFlights
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPricesForFlights()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPricesForFlights$Response(rootUrl: string, params: {
    body: PostPricesForFlightsRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<LowestPriceForFlights>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.PostPricesForFlightsPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<LowestPriceForFlights>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /prices/flights.
   *
   * getPricesForFlights
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postPricesForFlights$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPricesForFlights(rootUrl: string, params: {
    body: PostPricesForFlightsRequest
  },
  context?: HttpContext

): Observable<LowestPriceForFlights> {
    return this.postPricesForFlights$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<LowestPriceForFlights>) => r.body as LowestPriceForFlights)
    );
  }

  /**
   * Path part for operation getPricesForOffers
   */
  static readonly GetPricesForOffersPath = '/api/instantsearch/prices/offers';

  /**
   * /prices/offers.
   *
   * getPricesForOffers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPricesForOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForOffers$Response(rootUrl: string, params: {

    /**
     * IATA code for airport or city
     */
    departureLocationCode: string;

    /**
     * IATA codes for airports or cities
     */
    destinationLocationCodes: Array<string>;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to search forward from given start date
     */
    numberOfDays: number;

    /**
     * Length of stay
     */
    lengthOfStay?: string;

    /**
     * Travel classes
     */
    travelClasses?: Array<string>;

    /**
     * Request oneway trip
     */
    oneway?: boolean;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<LowestPriceForOffers>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.GetPricesForOffersPath, 'get');

    if (params) {
      rb.query('departureLocationCode', params.departureLocationCode, {});
      rb.query('destinationLocationCodes', params.destinationLocationCodes, {"explode":false});
      rb.query('departureDate', params.departureDate, {});
      rb.query('numberOfDays', params.numberOfDays, {});
      rb.query('lengthOfStay', params.lengthOfStay, {});
      rb.query('travelClasses', params.travelClasses, {"explode":false});
      rb.query('oneway', params.oneway, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<LowestPriceForOffers>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /prices/offers.
   *
   * getPricesForOffers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPricesForOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForOffers(rootUrl: string, params: {

    /**
     * IATA code for airport or city
     */
    departureLocationCode: string;

    /**
     * IATA codes for airports or cities
     */
    destinationLocationCodes: Array<string>;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to search forward from given start date
     */
    numberOfDays: number;

    /**
     * Length of stay
     */
    lengthOfStay?: string;

    /**
     * Travel classes
     */
    travelClasses?: Array<string>;

    /**
     * Request oneway trip
     */
    oneway?: boolean;
  },
  context?: HttpContext

): Observable<LowestPriceForOffers> {
    return this.getPricesForOffers$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<LowestPriceForOffers>) => r.body as LowestPriceForOffers)
    );
  }

  /**
   * Path part for operation getPricesForPeriod
   */
  static readonly GetPricesForPeriodPath = '/api/instantsearch/pricesforperiod';

  /**
   * /pricesforperiod.
   *
   * getPricesForPeriod
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPricesForPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForPeriod$Response(rootUrl: string, params: {

    /**
     * IATA code for airport, city
     */
    departureLocationCode: string;

    /**
     * IATA code for airport, city
     */
    destinationLocationCode: string;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    startDate: string;

    /**
     * Number of days to search forward from given startDate
     */
    numberOfDays: number;

    /**
     * Travel class
     */
    travelClass?: string;

    /**
     * Request oneway trip
     */
    oneway?: boolean;

    /**
     * length of stay for return trips
     */
    lengthOfStay?: number;

    /**
     * trip length flexibility
     */
    tripFlexibility?: number;

    /**
     * turn on debug
     */
    debug?: boolean;

    /**
     * number of adult travelers
     */
    adults?: number;

    /**
     * number of child travelers
     */
    children?: number;

    /**
     * number of infant travelers
     */
    infants?: number;

    /**
     * direct flights only
     */
    directFlights?: boolean;

    /**
     * search source
     */
    searchSource?: SearchSource;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<LowestPriceOfPeriod>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.GetPricesForPeriodPath, 'get');

    if (params) {
      rb.query('departureLocationCode', params.departureLocationCode, {});
      rb.query('destinationLocationCode', params.destinationLocationCode, {});
      rb.query('startDate', params.startDate, {});
      rb.query('numberOfDays', params.numberOfDays, {});
      rb.query('travelClass', params.travelClass, {});
      rb.query('oneway', params.oneway, {});
      rb.query('lengthOfStay', params.lengthOfStay, {});
      rb.query('tripFlexibility', params.tripFlexibility, {});
      rb.query('debug', params.debug, {});
      rb.query('adults', params.adults, {});
      rb.query('children', params.children, {});
      rb.query('infants', params.infants, {});
      rb.query('directFlights', params.directFlights, {});
      rb.query('searchSource', params.searchSource, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<LowestPriceOfPeriod>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /pricesforperiod.
   *
   * getPricesForPeriod
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPricesForPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForPeriod(rootUrl: string, params: {

    /**
     * IATA code for airport, city
     */
    departureLocationCode: string;

    /**
     * IATA code for airport, city
     */
    destinationLocationCode: string;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    startDate: string;

    /**
     * Number of days to search forward from given startDate
     */
    numberOfDays: number;

    /**
     * Travel class
     */
    travelClass?: string;

    /**
     * Request oneway trip
     */
    oneway?: boolean;

    /**
     * length of stay for return trips
     */
    lengthOfStay?: number;

    /**
     * trip length flexibility
     */
    tripFlexibility?: number;

    /**
     * turn on debug
     */
    debug?: boolean;

    /**
     * number of adult travelers
     */
    adults?: number;

    /**
     * number of child travelers
     */
    children?: number;

    /**
     * number of infant travelers
     */
    infants?: number;

    /**
     * direct flights only
     */
    directFlights?: boolean;

    /**
     * search source
     */
    searchSource?: SearchSource;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<LowestPriceOfPeriod> {
    return this.getPricesForPeriod$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<LowestPriceOfPeriod>) => r.body as LowestPriceOfPeriod)
    );
  }

  /**
   * Path part for operation getPricesForPeriodWithFixedDepartureDate
   */
  static readonly GetPricesForPeriodWithFixedDepartureDatePath = '/api/instantsearch/pricesforperiod/fixeddeparture';

  /**
   * /pricesforperiod/fixeddeparture.
   *
   * getPricesForPeriodWithFixedDepartureDate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPricesForPeriodWithFixedDepartureDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForPeriodWithFixedDepartureDate$Response(rootUrl: string, params: {

    /**
     * IATA code for airport, city
     */
    departureLocationCode: string;

    /**
     * IATA code for airport, city
     */
    destinationLocationCode: string;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to search forward from given startDate
     */
    numberOfDays: number;

    /**
     * Travel class
     */
    travelClass?: string;

    /**
     * Group by
     */
    groupBy?: string;

    /**
     * number of adult travelers
     */
    adults?: number;

    /**
     * number of child travelers
     */
    children?: number;

    /**
     * number of infant travelers
     */
    infants?: number;

    /**
     * direct flights only
     */
    directFlights?: boolean;

    /**
     * search source
     */
    searchSource?: SearchSource;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<LowestPriceOfPeriod>> {
    const rb = new DapiRequestBuilder(rootUrl, InstantsearchService.GetPricesForPeriodWithFixedDepartureDatePath, 'get');

    if (params) {
      rb.query('departureLocationCode', params.departureLocationCode, {});
      rb.query('destinationLocationCode', params.destinationLocationCode, {});
      rb.query('departureDate', params.departureDate, {});
      rb.query('numberOfDays', params.numberOfDays, {});
      rb.query('travelClass', params.travelClass, {});
      rb.query('groupBy', params.groupBy, {});
      rb.query('adults', params.adults, {});
      rb.query('children', params.children, {});
      rb.query('infants', params.infants, {});
      rb.query('directFlights', params.directFlights, {});
      rb.query('searchSource', params.searchSource, {});
      rb.query('locale', params.locale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<LowestPriceOfPeriod>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * /pricesforperiod/fixeddeparture.
   *
   * getPricesForPeriodWithFixedDepartureDate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPricesForPeriodWithFixedDepartureDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPricesForPeriodWithFixedDepartureDate(rootUrl: string, params: {

    /**
     * IATA code for airport, city
     */
    departureLocationCode: string;

    /**
     * IATA code for airport, city
     */
    destinationLocationCode: string;

    /**
     * Show prices starting from this date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to search forward from given startDate
     */
    numberOfDays: number;

    /**
     * Travel class
     */
    travelClass?: string;

    /**
     * Group by
     */
    groupBy?: string;

    /**
     * number of adult travelers
     */
    adults?: number;

    /**
     * number of child travelers
     */
    children?: number;

    /**
     * number of infant travelers
     */
    infants?: number;

    /**
     * direct flights only
     */
    directFlights?: boolean;

    /**
     * search source
     */
    searchSource?: SearchSource;

    /**
     * locale
     */
    locale?: string;
  },
  context?: HttpContext

): Observable<LowestPriceOfPeriod> {
    return this.getPricesForPeriodWithFixedDepartureDate$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<LowestPriceOfPeriod>) => r.body as LowestPriceOfPeriod)
    );
  }

}
