import { OfferListFetchParams } from '@fcom/common/interfaces';
import { OffersPerBound } from '@fcom/common/interfaces/booking/multi-city-state.interface';
import { mergeDeep } from '@fcom/core/utils';
import { FinnairSearchParameters, Offer } from '@fcom/dapi/api/models';

export interface PricesPerFareFamily {
  [fareFamilyCode: string]: string;
}

export interface PriceCombinations {
  outboundPrices: PricesPerFareFamily;
  inboundPrices: PricesPerFareFamily;
}

export type TicketTypeSelection = {
  outbound: string;
  inbound: string;
};

const cheapestPrices = (
  offers: Offer[],
  fareFamilyCodeFn: { (o: Offer): string },
  priceFn: { (o: Offer): string }
): PricesPerFareFamily => {
  return offers
    .filter((o) => !!fareFamilyCodeFn(o))
    .reduce((all, o) => {
      const fareFamilyCode: string = fareFamilyCodeFn(o);
      const offerPrice: string = priceFn(o);

      if (!all[fareFamilyCode]) {
        all[fareFamilyCode] = offerPrice;
        return all;
      }

      const currentAmount = all[fareFamilyCode];
      all[fareFamilyCode] = +currentAmount < +offerPrice ? currentAmount : offerPrice;

      return all;
    }, {});
};

const pricesForCombination = (
  offers: Offer[],
  offerFilterFn: { (o: Offer): boolean },
  fareFamilyCodeFn: { (o: Offer): string },
  priceFn: { (o: Offer): string }
): PricesPerFareFamily => {
  return offers.filter(offerFilterFn).reduce((all, offer) => {
    all[fareFamilyCodeFn(offer)] = priceFn(offer);
    return all;
  }, {});
};

export const calculatePriceCombinations = ([offers, { outbound, inbound }]: [
  Offer[],
  TicketTypeSelection,
]): PriceCombinations => {
  const cheapestOutboundPrices: PricesPerFareFamily = cheapestPrices(
    offers,
    (o) => o.outboundFareFamily,
    (o) => o.outboundPrice
  );
  const outboundPrices: PricesPerFareFamily = pricesForCombination(
    offers,
    (o) => o.inboundFareFamily === inbound && !!o.outboundPrice && !!o.outboundFareFamily,
    (o) => o.outboundFareFamily,
    (o) => o.outboundPrice
  );
  const cheapestInboundPrices: PricesPerFareFamily = cheapestPrices(
    offers,
    (o) => o.inboundFareFamily,
    (o) => o.inboundPrice
  );
  const inboundPrices: PricesPerFareFamily = pricesForCombination(
    offers,
    (o) => o.outboundFareFamily === outbound && !!o.inboundPrice && !!o.inboundFareFamily,
    (o) => o.inboundFareFamily,
    (o) => o.inboundPrice
  );

  return {
    outboundPrices: mergeDeep(cheapestOutboundPrices, outboundPrices),
    inboundPrices: mergeDeep(cheapestInboundPrices, inboundPrices),
  };
};

export const convertOfferListFetchParamsToFinnairSearchParameters = (
  params: OfferListFetchParams
): FinnairSearchParameters => {
  return {
    ...params.paxAmount,
    cabin: params.cabin,
    directFlights: params.directFlights,
    isAward: params.isAward,
    itinerary: params.flights.map((flight) => {
      return {
        departureLocationCode: flight.origin,
        destinationLocationCode: flight.destination,
        departureDate: flight.departureDate.id,
      };
    }),
    locale: params.locale,
    promoCode: params.promoCode,
  };
};

export const sortByPriceAndDeparture = (a: OffersPerBound, b: OffersPerBound): number => {
  const priceComparison = +a.fareFamily.totalPrice - +b.fareFamily.totalPrice;
  if (priceComparison !== 0) {
    return priceComparison;
  }
  return new Date(a.boundInfo.departure.dateTime).getTime() - new Date(b.boundInfo.departure.dateTime).getTime();
};
