import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import {
  FinnairBoundItem,
  FinnairDisruptedBoundItem,
  FinnairItineraryItemFlight,
  FinnairItineraryItemType,
} from '@fcom/dapi/api/models';
import { COMMON_BOOKING_TRAVEL_CLASSES } from '@fcom/core';

import { isDifferentDay } from '../../../../reaccommodation/utils/bound.utils';
import { isBound, isBusOrTrain } from '../../../utils/common-booking.utils';

@Component({
  selector: 'fin-flight-summary',
  templateUrl: './flight-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightSummaryComponent implements OnInit, OnChanges {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() flightOrBound: FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight;

  returnIsDifferentDay: boolean;
  uniqueAirlineNames: string[];
  stops: number;
  fareFamily: string;
  cabinClass: string;
  icon: SvgLibraryIcon;

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flightOrBound']) {
      this.update();
    }
  }

  private update(): void {
    this.returnIsDifferentDay = isDifferentDay(
      this.flightOrBound.departure.dateTime,
      this.flightOrBound.arrival.dateTime
    );

    if (isBound(this.flightOrBound)) {
      this.uniqueAirlineNames = this.flightOrBound.uniqueAirlineNames;
      this.stops = this.flightOrBound.stops;
      this.fareFamily = this.flightOrBound.fareFamily?.name;

      if (!this.fareFamily) {
        this.cabinClass = COMMON_BOOKING_TRAVEL_CLASSES[this.flightOrBound['cabinClass']]?.translationKey;
      }
      this.icon = this.getIcon(
        this.flightOrBound.itinerary
          .filter((itinerary) => itinerary.type === FinnairItineraryItemType.FLIGHT)
          .some((flight: FinnairItineraryItemFlight) => isBusOrTrain(flight))
      );
    } else {
      this.uniqueAirlineNames = [this.flightOrBound.operatingAirline.name];
      this.stops = 0;
      this.cabinClass = COMMON_BOOKING_TRAVEL_CLASSES[this.flightOrBound.cabinClass].translationKey;
      this.icon = this.getIcon(isBusOrTrain(this.flightOrBound));
    }
  }

  private getIcon(isTrainOrBus: boolean): SvgLibraryIcon {
    if (isTrainOrBus) {
      return SvgLibraryIcon.MINUS;
    }
    return SvgLibraryIcon.AIRCRAFT_HORIZONTAL;
  }
}
