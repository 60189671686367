import { LoginState, LoginStatus, ProfileTier, ProfileType } from '@fcom/core-api/login';
import { DataUtils } from '@fcom/core/utils';

import { LoginActions } from '../actions';

export const initialState: LoginState = Object.seal({ loginStatus: LoginStatus.INITIAL });

const ACCESS_TOKEN: keyof LoginState = 'accessToken';
const LOGIN_STATUS: keyof LoginState = 'loginStatus';
const PROFILE_TYPE: keyof LoginState = 'type';
const USER_PROFILE: keyof LoginState = 'profile';
const CAS_PROFILE: keyof LoginState = 'casProfile';
const LOGIN_PHASE: keyof LoginState = 'loginPhase';

export function loginReducer(state = initialState, action: LoginActions.LoginActionUnion): LoginState {
  switch (action.type) {
    case LoginActions.setAccessToken.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_STATUS, LoginStatus.PENDING)
        .setIn(ACCESS_TOKEN, action.accessToken)
        .value();

    case LoginActions.setProfile.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_STATUS, LoginStatus.LOGGED_IN)
        .setIn(USER_PROFILE, action.profile)
        .setIn(
          PROFILE_TYPE,
          action.profile.tier === ProfileTier.CORPORATE
            ? ProfileType.CORPORATE
            : action.profile.tier === ProfileTier.ACCOUNT
              ? ProfileType.FINNAIR_ACCOUNT
              : ProfileType.FPLUS
        )
        .value();

    case LoginActions.setCasProfile.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_STATUS, LoginStatus.LOGGED_IN)
        .setIn(CAS_PROFILE, action.casProfile)
        .value();

    case LoginActions.setNotLoggedIn.type:
      return DataUtils.wrap(initialState)
        .setIn(LOGIN_STATUS, LoginStatus.NOT_LOGGED_IN)
        .setIn(LOGIN_PHASE, state.loginPhase)
        .value();

    case LoginActions.setLoginError.type:
      return DataUtils.wrap(initialState).setIn(LOGIN_STATUS, LoginStatus.ERROR).value();

    case LoginActions.setLoginPhaseStep.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_PHASE, {
          ...state.loginPhase,
          step: action.step,
          error: undefined,
        })
        .value();

    case LoginActions.setLoginPhaseExecutionToken.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_PHASE, {
          ...state.loginPhase,
          executionToken: action.token,
        })
        .value();

    case LoginActions.setLoginPhaseProfile.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_PHASE, {
          ...state.loginPhase,
          profile: action.profile,
        })
        .value();

    case LoginActions.setLoginPhaseError.type:
      return DataUtils.wrap(state)
        .setIn(LOGIN_PHASE, {
          ...state.loginPhase,
          error: action.error,
        })
        .value();

    case LoginActions.clearLoginPhase.type:
      return DataUtils.wrap(state).deleteIn(LOGIN_PHASE).value() as LoginState;

    case LoginActions.clearAccessToken.type:
      return initialState;
    default:
      return state;
  }
}
