import { Component, ContentChild, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { AbstractControlOptions, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subject } from 'rxjs';

import { ButtonTheme, IconPosition } from '@fcom/ui-components';

@Component({
  selector: 'fin-form-array',
  templateUrl: 'form-array.component.html',
  styleUrls: ['form-array.component.scss'],
})
export class FormArrayComponent implements OnInit, OnChanges {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  formGroupConfig: { [key: string]: any };

  @Input()
  formGroupOptions: AbstractControlOptions = {};

  @Input()
  minGroups = 0;

  @Input()
  maxGroups = 100;

  @Input()
  hideGroups = false;

  @Input()
  displayLabels = false;

  @Input()
  isNestedArray = false;

  @Input()
  displayContainerStyles = true;

  @Input()
  compressedList = false;

  @Input()
  disabled = false;

  @ContentChild(TemplateRef, { static: true }) formGroup: TemplateRef<any>;

  focusToButton$: Subject<boolean> = new Subject<boolean>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    for (let i = this.formArray.length; i < this.minGroups; i++) {
      this.formArray.push(this.fb.group(this.formGroupConfig, this.formGroupOptions));
    }
  }

  ngOnChanges(): void {
    while (this.maxGroups < this.formArray.length) {
      this.formArray.removeAt(this.formArray.length - 1);
    }
  }

  addFormGroup(): void {
    if (this.formArray.length < this.maxGroups) {
      this.formArray.push(this.fb.group(this.formGroupConfig, this.formGroupOptions));
      this.parentForm.markAsDirty();
    }
  }

  removeFormGroup(index: number): void {
    this.focusToButton$.next(true);
    this.formArray.removeAt(index);
    this.parentForm.markAsDirty();
  }

  get formArray(): UntypedFormArray {
    return this.parentForm.get(this.controlName) as UntypedFormArray;
  }
}
