import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { map, Observable } from 'rxjs';

import { ConfigService, NativeBridgeService } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { ProfileType } from '@fcom/core-api/login';

import { ButtonMode, ButtonTheme, IconButtonSize, IconButtonTheme } from '../../buttons';
import { IconPosition } from '../../icons';
import { Breadcrumb, LoginEvent, PhasesAria } from './interfaces';

/**
 * This component contains the booking header used during booking flows. Please note that this
 * header is intended to be be position:fixed with page content below. To position this content
 * below correctly, please use the following spacings.
 *
 * default - margin-top: 2.9rem;<br>
 * tablet-up - margin-top: 3.1rem;<br>
 * laptop-up - margin-top: 3.5rem;<br>
 * desktop - margin-top: 3.7rem;<br>
 *
 * @example
 * <b>Example with basic options</b>
 *
 * <code>
 *   <pre>
 *     <fcom-booking-header
 *       [loggedIn]="true"
 *       [homeUrl]="'https://finnair.com/fi-fi"
 *       [breadcrumbs]="Breadcrumb[]"
 *       [showLogin]="true"
 *       [showHamburgerMenu]="true"
 *       [showBack]="true"
 *       [userName]="'Pekka Pekkanen"
 *       [loginText]="'Login'"
 *       [logoutText]="'Logout"
 *       (loginClick)=""
 *       (menuClick)=""
 *       (homeClick)=""
 *       (breadcrumbClick)="">
 *     </fcom-booking-header>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-booking-header',
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.scss'],
})
export class BookingHeaderComponent implements OnInit, OnChanges {
  /**
   * What url the finnair logo should link to (including language)
   */
  @Input() homeUrl: string;

  /**
   * Template reference for the language selector component.
   * This allows the parent component to pass a custom template
   * for the language selector, which will be rendered within
   * the booking header.
   */
  @Input() languageSelector: TemplateRef<any>;

  @Input() isMobile = false;

  /**
   * An array of breadcrumbs and their state
   */
  @Input() breadcrumbs: Breadcrumb[] = [];

  /**
   * Whether we should show the login/logout text
   */
  @Input() loggedIn = true;

  /**
   * Controls the display of the login/logout button. The button should be hidden where the user
   * shouldn't be able to login or out.
   */
  @Input() showLogin = true;

  /**
   * Controls the display of the back button. The button should be hidden whether it isn't possible
   * to go back (for example on purchase confirmation pages)
   */
  @Input() showBack = true;

  /**
   * Controls the propagation of the back button click event. If it is true, the event will be propagated
   * to be handled by the caller.
   */
  @Input() propagateBackClick = false;

  /**
   * Shows a username instead of the logout text. Its recommended to use this when you have the username.
   */
  @Input() userName: string | null;

  /**
   * Indicates whether the user is F+ member or corporate user. This is used to show the correct icon.
   */
  @Input() profileType: ProfileType;

  /**
   * The translated text to display for instead of "Login". If not text is provided then a default
   * will be used.
   */
  @Input() loginText: string;

  /**
   * The translated text to display instead of "Logout". If not text is provided then a default
   * will be used.
   */
  @Input() logoutText: string;

  /**
   * The translated text to be read in voice over.
   */
  @Input({ required: true }) phasesAria: PhasesAria;

  /**
   * The event emitted when the login button is clicked.
   */
  @Output() loginClick: EventEmitter<LoginEvent> = new EventEmitter();

  /**
   * The event emitted when the menu button is clicked.
   */
  @Output() menuClick: EventEmitter<Event> = new EventEmitter();

  /**
   * The event emitted when a breadcrumb item is clicked. This does not emit
   * when the breadcrumb.disabled property is true.
   */
  @Output() breadcrumbClick: EventEmitter<Breadcrumb> = new EventEmitter();

  /**
   * The event emitted when home button is clicked.
   *
   * If there is no listener for the event, the link will work as a regular a tag and redirect the
   * page. If there is a listener you must redirect the user to the page required (via angular
   * routing for example)
   */
  @Output() homeClick: EventEmitter<string> = new EventEmitter();

  /**
   * The event emitted when back button is clicked. This does not emit
   * when the propagateBack property is false.
   */
  @Output() backClick: EventEmitter<Event> = new EventEmitter();

  showHamburgerMenu$: Observable<boolean>;
  activeIndex: number;
  isInsideNativeWebView: boolean;
  enableNewStepper: boolean;
  profileIcon: SvgLibraryIcon;

  readonly ButtonMode = ButtonMode;
  readonly ButtonTheme = ButtonTheme;
  readonly CustomIcon = CustomIcon;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private nativeBridgeService: NativeBridgeService,
    private languageService: LanguageService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.findActiveIndex();
    this.isInsideNativeWebView = this.nativeBridgeService.isInsideNativeWebView;
    this.enableNewStepper = this.configService.cfg.enableNewStepper;

    this.showHamburgerMenu$ = this.nativeBridgeService.isHamburgerMenuVisible?.pipe(
      map((visible) => (!this.enableNewStepper || this.isInsideNativeWebView) && visible)
    );
    this.setProfileIcon();
  }

  ngOnChanges(): void {
    this.setProfileIcon();
    this.findActiveIndex();
  }

  findActiveIndex(): void {
    (this.breadcrumbs || []).forEach((breadcrumb, i) => {
      if (breadcrumb.active) {
        this.activeIndex = i;
      }
    });
  }

  onBreadcrumbClick(breadcrumb, index): void {
    if (breadcrumb.disabled) {
      return;
    }

    if (index < this.activeIndex) {
      this.breadcrumbClick.emit(breadcrumb);
    }
  }

  onBackClick(): void {
    if (this.propagateBackClick) {
      this.backClick.emit();
    } else if (this.activeIndex > 0) {
      this.breadcrumbClick.emit(this.breadcrumbs[this.activeIndex - 1]);
    }
  }

  onHomeClick(event: Event): void {
    event.preventDefault();
    this.homeClick.emit(this.homeUrl ?? this.languageService.langValue);
  }

  private setProfileIcon(): void {
    this.profileIcon =
      this.showLogin && this.loggedIn
        ? this.profileType === ProfileType.CORPORATE
          ? SvgLibraryIcon.B2B
          : SvgLibraryIcon.LOGGED_IN
        : SvgLibraryIcon.SINGLE_PAX;
  }
}
