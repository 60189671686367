<div
  *ngIf="profile$ | async as profile"
  class="profile-quick-view white-bg grow relative {{
    (tierDataMap[profile.tier] || tierDataMap[ProfileTier.ACCOUNT]).className
  }}"
>
  <div class="top-section ps-large-y ps-medium-x" data-hj-suppress>
    <ng-container *ngIf="!profile.isCorporate">
      <ng-container *ngTemplateOutlet="fPlusHeader; context: { profile }"></ng-container>
    </ng-container>
    <ng-container *ngIf="profile.isCorporate">
      <ng-container *ngTemplateOutlet="corporateHeader; context: { profile }"></ng-container>
    </ng-container>
  </div>
  <div class="ps-xsmall-y">
    <fcom-menu-list [items]="menuItems$ | async" (itemClicked)="handleClick($event)"></fcom-menu-list>
  </div>
</div>

<ng-template #fPlusHeader let-profile="profile">
  <span class="font-body-2">
    <ng-container *ngIf="profile.isJuniorMember">
      {{ tierDataMap[profile.tier].juniorName || tierDataMap[ProfileTier.JUNIOR].name | finLocalization }}
    </ng-container>
    <ng-container *ngIf="!profile.isJuniorMember">
      {{ (tierDataMap[profile.tier] || tierDataMap[ProfileTier.ACCOUNT]).name | finLocalization }}
    </ng-container>
  </span>
  <h4 class="font-heading-5 ms-large-t">{{ profile.firstname }}&nbsp;{{ profile.lastname }}</h4>
  <span
    class="font-body-1 regular-type"
    [innerHTML]="'finnairPlus.awardPoints' | finLocalization: { awardPoints: profile.awardPoints || 0 | finPoints }"
  ></span>
</ng-template>

<ng-template #corporateHeader let-profile="profile">
  <span class="font-body-2">
    {{ profile.corporate.name }}
  </span>
  <h4 class="font-heading-5 ms-large-t ms-xxsmall-b">{{ profile.firstname }}&nbsp;{{ profile.lastname }}</h4>
  <span class="font-body-1 regular-type">{{ 'corporate.roles.' + profile.corporate.userRole | finLocalization }}</span>
</ng-template>
