<h2 class="font-heading-4 nordic-blue-100 ms-medium-b">
  {{ (isDeparture ? 'itinerary.departure' : 'itinerary.return') | finLocalization }}: {{ departureCity }} -
  {{ arrivalCity }}
</h2>
<div
  *ngIf="bound.disruptedBound?.disruptedBound as oldBound"
  class="bound-group rounded-large border white-bg ms-medium-b"
>
  <header class="pr-small-x pr-xsmall-y font-body-1 border-bottom flex flex--space-between flex--middle">
    <div>
      <h3 class="font-body-1">{{ 'MMB.view.changeFlightsModal.flight' | finLocalization }} {{ flightNumber }}</h3>
      {{ oldBound?.departure?.dateTime | finDateFormat: 'EE d MMM yyyy' }}
    </div>
  </header>

  <div class="pr-small">
    <div id="{{ oldBound.id }}">
      <fcom-ufo
        class="disabled-area"
        [arrival]="getLocation(oldBound.arrival) | async"
        [departure]="getLocation(oldBound.departure) | async"
        [tails]="tails | finTailNames"
        [airlines]="oldBound.uniqueAirlineNames"
        [duration]="oldBound.duration | finDuration | async"
        [stops]="oldBound.stops"
        [showViewDetails]="false"
        [flightNumbers]="bound.itinerary | finFlightNumbers"
      ></fcom-ufo>
    </div>
  </div>
</div>
<div class="bound-group rounded-large border white-bg">
  <header class="pr-small-x pr-xsmall-y font-body-1 border-bottom flex flex--space-between flex--middle">
    <div>
      <h3 class="font-body-1">{{ 'MMB.view.changeFlightsModal.flight' | finLocalization }} {{ flightNumber }}</h3>
      {{ bound?.departure?.dateTime | finDateFormat: 'EE d MMM yyyy' }}
    </div>
    <fcom-tag *ngIf="bound.cabinClass"
      >{{ 'travelClass.' + bound.cabinClass.toLowerCase() | finLocalization }}
    </fcom-tag>
  </header>

  <div class="pr-small">
    <div id="{{ bound.id }}">
      <fcom-ufo
        [arrival]="getLocation(bound.arrival) | async"
        [departure]="getLocation(bound.departure) | async"
        [tails]="tails | finTailNames"
        [airlines]="bound.uniqueAirlineNames"
        [duration]="bound.duration | finDuration | async"
        [stops]="bound.stops"
        [showViewDetails]="false"
        [attr.id]="bound.id"
        [flightNumbers]="bound.itinerary | finFlightNumbers"
      ></fcom-ufo>
    </div>
    <div class="align-right" *ngIf="!disabled">
      <fcom-button
        data-testid="change-flight-select"
        class="pr-xsmall-t align-right"
        [mode]="ButtonMode.INLINE"
        [icon]="isSelected ? { name: SvgLibraryIcon.CHECKMARK, position: IconPosition.RIGHT } : null"
        [theme]="isSelected ? ButtonTheme.LIGHT_SECONDARY_OUTLINE : ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
        [text]="'MMB.reaccommodation.boundSelection.changeButton.title' | finLocalization | titlecase"
        (clickBtn)="selectBound(bound)"
        [aria]="{
          describedby: bound.id,
        }"
      ></fcom-button>
    </div>
    <fcom-notification *ngIf="notification" class="fill pr-small-t" [layout]="NotificationLayout.BLOCK">
      {{ notification | finLocalization }}
    </fcom-notification>
  </div>
</div>
