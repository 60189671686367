import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LoginEvent, PopoverService } from '@fcom/ui-components';
import { AppState } from '@fcom/core/interfaces';
import { LoginStep } from '@fcom/core-api/login';
import { LoginActions } from '@fcom/core/actions';
import { ConfigService } from '@fcom/core/services';
import { getCurrentBreakPoint } from '@fcom/common/utils/layout.utils';
import { loginIsOpen } from '@fcom/core/selectors';

import { Breakpoint } from '../../../interfaces';
import { LoginWidgetComponent, LoginWidgetContext } from '../login-widget/login-widget.component';
import { LoginService } from '../../services';

@Component({
  selector: 'fin-login-dialog',
  templateUrl: './login-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent implements OnInit {
  @ViewChild('loginWidget', { static: true }) loginWidget: LoginWidgetComponent;

  @Input()
  context: LoginWidgetContext;

  loginOpen$: Observable<boolean>;
  mobileOrTablet: boolean;
  constructor(
    private loginService: LoginService,
    private store$: Store<AppState>,
    private configService: ConfigService,
    private popoverService: PopoverService
  ) {}

  toggleLogin(loginEvent: LoginEvent): void {
    this.popoverService.close();
    const currentBreakpoint = getCurrentBreakPoint();

    loginEvent.event.stopPropagation();

    this.mobileOrTablet = [Breakpoint.MOBILE, Breakpoint.TABLET].includes(currentBreakpoint);
    if (loginEvent.loggedIn || this.mobileOrTablet) {
      this.loginWidget.open();
    } else if (this.configService.cfg.enableLoginDialog) {
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
    } else {
      this.loginService.redirectToLogin();
    }
  }

  ngOnInit(): void {
    this.loginOpen$ = this.store$.pipe(loginIsOpen());
  }
}
