<ng-container *ngIf="previousSearches$ | async as previousSearches">
  <div
    *ngIf="previousSearches.length > 0 && !((isMulticityListOnly$ | async) && isGlobalBookingWidget)"
    data-testid="previous-searches"
  >
    <h3
      id="previous-searches-title"
      class="grey-50-bg rounded-small ps-medium-y font-body-1 nordic-blue-900-text"
      [ngClass]="{
        'pr-large-x': !isGlobalBookingWidget,
        'ps-medium-x': isGlobalBookingWidget,
      }"
    >
      {{ 'bookingSearch.previousSearches' | finLocalization }}
    </h3>

    <ul [attr.id]="'previousSearches'" class="flex flex--column" role="list" aria-labelledby="previous-searches-title">
      <ng-container *ngFor="let search of previousSearches; let last = last; let index = index">
        <li
          class="flex flex--middle flex--space-between focus-inset link-hover"
          [ngClass]="{
            'pr-large-x': !isGlobalBookingWidget,
            'ps-medium-x': isGlobalBookingWidget,
          }"
          [class.border-bottom]="!last"
          data-testid="previous-search-item"
          [id]="'previous-search-' + index"
          *ngIf="!(isGlobalBookingWidget && search.tripType === TripType.MULTICITY)"
        >
          <ng-container *ngIf="search.tripType !== TripType.MULTICITY; else multicityPreviousSearches">
            <button
              #previousSearchItems
              class="fill align-left ps-medium-y overflow-hidden"
              (click)="setSearch(search)"
              [attr.aria-label]="
                'bookingSearch.selectPreviousSearch'
                  | finLocalization
                    : {
                        origin: search?.flights[0]?.origin?.cityName,
                        destination: search?.flights[0]?.destination?.cityName,
                        date:
                          (search?.flights[0]?.departureDate | finDateFormat: 'DATE').toString() +
                          (search?.flights[1]?.departureDate
                            ? ' - ' + (search?.flights[1]?.departureDate | finDateFormat: 'DATE')?.toString()
                            : ''),
                      }
              "
              data-testid="previous-search-set-item"
            >
              <div class="font-body-1 capitalize location-title">
                <div *ngIf="search" class="flex fill">
                  <span class="ps-xxsmall-r overflow-hidden truncate">{{ search?.flights[0]?.origin.cityName }}</span>
                  <span
                    >({{
                      search?.flights[0]?.origin.locationCode && search?.flights[0]?.origin.type === 'airport'
                        ? search?.flights[0]?.origin?.locationCode
                        : ('bookingSearch.allAirportsLabel' | finLocalization)
                    }})</span
                  >
                  <span class="ps-xxsmall-r overflow-hidden truncate"
                    >&nbsp;– {{ search?.flights[0]?.destination.cityName }}</span
                  >
                  <span
                    >({{
                      search?.flights[0]?.destination.locationCode && search?.flights[0]?.destination.type === 'airport'
                        ? search?.flights[0]?.destination.locationCode
                        : ('bookingSearch.allAirportsLabel' | finLocalization)
                    }})</span
                  >
                </div>
              </div>
              <div class="font-body-2 ps-xxsmall-y nordic-blue-900-text">
                {{ search?.flights[0]?.departureDate | finDateFormat: 'DATE' }}
                <span *ngIf="search?.flights[1]?.departureDate">
                  - {{ search?.flights[1]?.departureDate | finDateFormat: 'DATE' }}</span
                >
              </div>
            </button>

            <fcom-icon-button
              (click)="removePreviousSearchByCreationDate$.next(search.creationDate)"
              title="{{ 'actions.remove' | finLocalization }}"
              data-testid="remove-previous-search-button"
              [size]="IconButtonSize.MEDIUM"
              [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
              [icon]="{ name: SvgLibraryIcon.TRASH }"
              [aria]="{
                label:
                  'bookingSearch.deletePreviousSearchRoute'
                  | finLocalization
                    : {
                        origin: search?.flights[0]?.origin?.cityName,
                        destination: search?.flights[0]?.destination?.cityName,
                        date:
                          (search?.flights[0].departureDate | finDateFormat: 'DATE').toString() +
                          (search?.flights[1]?.departureDate
                            ? ' - ' + (search?.flights[1]?.departureDate | finDateFormat: 'DATE')?.toString()
                            : ''),
                      },
                controls: 'previous-search-' + index,
              }"
            ></fcom-icon-button>
          </ng-container>

          <ng-template #multicityPreviousSearches>
            <button
              #previousSearchItems
              class="fill align-left ps-medium-y overflow-hidden"
              (click)="setSearch(search)"
              [attr.aria-label]="
                'bookingSearch.selectPreviousSearch'
                  | finLocalization
                    : {
                        origin: search?.flights[0]?.origin?.cityName,
                        destination: search?.flights[0]?.destination?.cityName,
                        date:
                          (search?.flights[0]?.departureDate | finDateFormat: 'DATE').toString() +
                          (search?.flights[1]?.departureDate
                            ? ' - ' + (search?.flights[1]?.departureDate | finDateFormat: 'DATE')?.toString()
                            : ''),
                      }
              "
              data-testid="previous-search-set"
            >
              <ol [attr.id]="'multicityPreviousSearches'" class="flex flex--column ms-medium-l">
                <ng-container *ngFor="let flight of search.flights; let first = first; let last = last; let i = index">
                  <li
                    class="flex flex--middle flex--space-between focus-inset link-hover pr-large-r list-item"
                    data-testid="previous-search-item"
                    [id]="'previous-search-' + index"
                  >
                    <div
                      #previousSearchItems
                      class="fill align-left overflow-hidden"
                      (click)="setSearch(search)"
                      [attr.aria-label]="
                        'bookingSearch.selectPreviousSearch'
                          | finLocalization
                            : {
                                origin: flight.origin?.cityName,
                                destination: flight?.destination?.cityName,
                                date: (flight.departureDate | finDateFormat: 'DATE').toString(),
                              }
                      "
                      data-testid="previous-search-set-item"
                    >
                      <div class="font-body-1 capitalize location-title">
                        <div *ngIf="search" class="flex fill">
                          <span class="ps-xxsmall-r overflow-hidden truncate">{{ flight.origin.cityName }}</span>
                          <span
                            >({{
                              flight.origin.locationCode && flight.origin.type === 'airport'
                                ? flight.origin?.locationCode
                                : ('bookingSearch.allAirportsLabel' | finLocalization)
                            }})</span
                          >
                          <span class="ps-xxsmall-r overflow-hidden truncate"
                            >&nbsp;&ndash; {{ flight.destination.cityName }}</span
                          >
                          <span
                            >({{
                              flight.destination.locationCode && flight.destination.type === 'airport'
                                ? flight.destination.locationCode
                                : ('bookingSearch.allAirportsLabel' | finLocalization)
                            }})</span
                          >
                        </div>
                      </div>
                      <div class="font-body-2 ps-xxsmall-y nordic-blue-900-text">
                        <span> {{ flight.departureDate | finDateFormat: 'DATE' }}</span>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ol>
            </button>
            <fcom-icon-button
              class="flex flex--center"
              (click)="removePreviousSearchByCreationDate$.next(search.creationDate)"
              title="{{ 'actions.remove' | finLocalization }}"
              data-testid="remove-previous-search-button"
              [size]="IconButtonSize.MEDIUM"
              [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
              [icon]="{ name: SvgLibraryIcon.TRASH }"
              [aria]="{
                label: 'bookingSearch.deletePreviousSearchRoute' | finLocalization,
                controls: 'previous-search-' + index,
              }"
            ></fcom-icon-button>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
