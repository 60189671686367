import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, EMPTY, fromEvent, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinnairBoundFareFamily, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { stopPropagation } from '@fcom/core/utils';
import { NotificationLayout, NotificationTheme, TagTheme } from '@fcom/ui-components';
import { MediaQueryService } from '@fcom/common/services';
import { LocationRouteType } from '@fcom/core-api';
import { ConfigService } from '@fcom/core/services';
import { TripType } from '@fcom/core/interfaces';

import { ExtendedFareFamily, FareFamilyBadgeType } from '../../../interfaces';
import { shouldDisplayBenefit } from '../../../utils/benefit.utils';
import { TaggableComponent } from '../../../../ui-components/components/tag/taggable.component';

@Component({
  selector: 'fin-fare-family-option',
  styleUrls: ['./fare-family-option.component.scss'],
  templateUrl: './fare-family-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirBoundsFareFamilyOptionComponent extends TaggableComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  expandOpen = false;

  @Input() context: string;

  @Input() accordionId: string;

  @Input() currencyCode$: Observable<string>;

  @Input()
  set fareFamily(fareFamily: ExtendedFareFamily) {
    this.selected$.next(fareFamily.selected);
    this.filterBenefits(fareFamily);
  }

  @Input() routeType: LocationRouteType;

  @Input() isShortHaul: boolean;

  @Input() hasSpecialOffer = false;

  @Input() originCountryCode: string;

  @Input() destinationCountryCode: string;

  @Input() departureDate: string;

  @Input() tripType$: Observable<TripType> = EMPTY;

  @Output() selectFareFamily: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();
  @Output() showDimensionsAndDetails: EventEmitter<FinnairBoundFareFamily> = new EventEmitter();

  extendedFareFamily: ExtendedFareFamily;
  selected$ = new BehaviorSubject(false);
  tagTheme$: Observable<TagTheme>;
  highlightedBenefits: TranslatedBenefitRow[] = [];
  isMobile$: Observable<boolean>;
  isHover$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isNotAward: boolean;
  enableBenefitChanges: boolean;

  readonly FareFamilyBadgeType = FareFamilyBadgeType;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TagTheme = TagTheme;

  constructor(
    private mediaQueryService: MediaQueryService,
    private configService: ConfigService,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    const isAward = !!this.extendedFareFamily.points;
    this.isNotAward = !isAward;
    this.enableBenefitChanges = this.configService.cfg.enableTicketTypeCardChanges;

    fromEvent(this.el.nativeElement, 'mouseenter')
      .pipe(
        tap(() => this.isHover$.next(true)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    fromEvent(this.el.nativeElement, 'mouseleave')
      .pipe(
        tap(() => this.isHover$.next(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.tagTheme$ = combineLatest([this.isHover$, this.selected$]).pipe(
      map(([isHover, selected]) => {
        if (selected) {
          return TagTheme.DEFAULT;
        }

        if (isHover && this.extendedFareFamily.badgeType === FareFamilyBadgeType.CMS) {
          return TagTheme.DEFAULT;
        }

        switch (this.extendedFareFamily.badgeType) {
          case FareFamilyBadgeType.CMS:
            return this.extendedFareFamily.benefits.tags[0].theme as TagTheme;
          case FareFamilyBadgeType.NEW:
            return TagTheme.ACTIVE;
          case FareFamilyBadgeType.POPULAR:
          default:
            return TagTheme.POPULAR;
        }
      })
    );
  }

  handleSelectFareFamilyClick(e: Event, { ...baseBoundFareFamily }: ExtendedFareFamily): void {
    stopPropagation(e);
    this.selectFareFamily.emit(baseBoundFareFamily as FinnairBoundFareFamily);
  }

  private filterBenefits(fareFamily: ExtendedFareFamily) {
    this.extendedFareFamily = fareFamily;
    this.highlightedBenefits = fareFamily.benefits.translatedBenefitRows.filter((benefit) =>
      shouldDisplayBenefit(benefit)
    );
  }

  toggleExpand(isOpen: boolean): void {
    this.expandOpen = isOpen;
  }

  handleShowDetails(fareFamily: ExtendedFareFamily): void {
    this.showDimensionsAndDetails.emit(fareFamily as FinnairBoundFareFamily);
  }
}
