import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, type UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { ConfigService, PNR_PATTERN, type AppState } from '@fcom/core';
import { EMAIL_REGEX, NAME_PATTERN } from '@fcom/core/utils';
import { type Profile } from '@fcom/core-api/login';
import { ButtonMode, ButtonSize, ButtonType, TextInputType } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common';
import { GtmService } from '@fcom/common/gtm';

import {
  PrechatFields,
  SplitTrafficChatFields,
  SplitTrafficAdditionalChatFields,
  AgentforceChatConfig,
  LiveagentChatFields,
  HelpButtonStatus,
} from '../../interfaces';
import { AgentforceChatService } from '../../services/agentforce-chat.service';

export enum SalesforceChatFormControl {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  HAS_BOOKING_REFERENCE_Q = 'bookingRefQ',
  BOOKING_REFERENCE = 'bookingReference',
}

export interface SalesforceChatForm {
  [SalesforceChatFormControl.FIRST_NAME]: string;
  [SalesforceChatFormControl.LAST_NAME]: string;
  [SalesforceChatFormControl.EMAIL]: string;
  [SalesforceChatFormControl.HAS_BOOKING_REFERENCE_Q]: string;
  [SalesforceChatFormControl.BOOKING_REFERENCE]: string;
}

export enum SalesforceChatFormHasBookingRefQ {
  YES = 'yesBookingReference',
  NO = 'noBookingReference',
}

@Component({
  selector: 'fin-chat-user-form',
  templateUrl: './chat-user-form.component.html',
  styleUrl: './chat-user-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUserFormComponent implements OnInit, OnDestroy {
  readonly ButtonType = ButtonType;
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly SalesforceChatFormControl = SalesforceChatFormControl;
  readonly textareaLimit50 = 50;
  readonly TextInputType = TextInputType;
  readonly SalesforceChatFormHasBookingRefQ = SalesforceChatFormHasBookingRefQ;

  private subscriptions: Subscription = new Subscription();

  agentforceConfig?: AgentforceChatConfig;
  hiddenFields: SplitTrafficAdditionalChatFields;
  reactiveForm: UntypedFormGroup;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  liveAgentChatEnabled = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private languageService: LanguageService,
    private configService: ConfigService,
    private afChatService: AgentforceChatService,
    private gtmService: GtmService
  ) {
    this.agentforceConfig = this.configService.cfg.agentforceChat;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store$.pipe(profileOrUndefinedIfNotLoggedIn(), take(1)).subscribe((profile: Profile): void => {
        this.createForm(profile);
        this.addFormWatcher();
        this.hiddenFields = this.setHiddenFields(profile);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get selectedBookingRef(): SalesforceChatFormHasBookingRefQ {
    return this.reactiveForm.controls[SalesforceChatFormControl.HAS_BOOKING_REFERENCE_Q].value ?? null;
  }

  createForm(profile?: Profile): void {
    this.reactiveForm = this.fb.group({
      [SalesforceChatFormControl.FIRST_NAME]: [
        profile?.firstname || '',
        [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(this.textareaLimit50)],
      ],
      [SalesforceChatFormControl.LAST_NAME]: [
        profile?.lastname || '',
        [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(this.textareaLimit50)],
      ],
      [SalesforceChatFormControl.EMAIL]: [profile?.email || '', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      [SalesforceChatFormControl.HAS_BOOKING_REFERENCE_Q]: this.fb.control(
        '' as SalesforceChatFormHasBookingRefQ,
        Validators.required
      ),
      [SalesforceChatFormControl.BOOKING_REFERENCE]: ['', [Validators.pattern(PNR_PATTERN), Validators.maxLength(6)]],
    });
  }

  addFormWatcher(): void {
    this.subscriptions.add(
      this.reactiveForm.controls[SalesforceChatFormControl.HAS_BOOKING_REFERENCE_Q].valueChanges.subscribe(
        (value): void => {
          value === SalesforceChatFormHasBookingRefQ.YES
            ? this.enableBookingReference()
            : this.disableBookingReference();
        }
      )
    );
  }

  private enableBookingReference(): void {
    this.reactiveForm.controls[SalesforceChatFormControl.BOOKING_REFERENCE].setValidators([
      Validators.pattern(PNR_PATTERN),
      Validators.maxLength(6),
      Validators.required,
    ]);
    this.reactiveForm.get([SalesforceChatFormControl.BOOKING_REFERENCE]).reset();
    this.reactiveForm.controls[SalesforceChatFormControl.BOOKING_REFERENCE].enable();
    this.liveAgentChatEnabled = true;
  }

  private disableBookingReference(): void {
    this.liveAgentChatEnabled = false;
    this.reactiveForm.controls[SalesforceChatFormControl.BOOKING_REFERENCE].setValidators(null);
    this.reactiveForm.get([SalesforceChatFormControl.BOOKING_REFERENCE]).reset();
  }

  setHiddenFields(profile: Profile): SplitTrafficAdditionalChatFields {
    if (profile) {
      return {
        Finnair_Plus_Number_c: profile.memberNumber,
        Case_Member_Tier_c: profile.tier,
        AuthenticatedUser: 'true', // Must be a string
      };
    }
    return {
      AuthenticatedUser: 'false', // Must be a string
    };
  }

  liveAgentChatData = (formData: PrechatFields, profile?: Profile): LiveagentChatFields => {
    return {
      FirstName: formData.firstName,
      LastName: formData.lastName,
      Email: formData.email,
      Booking_Reference__c: formData.bookingReference,
      ...(profile
        ? {
            Finnair_Plus_Number__c: profile.memberNumber,
            Case_Member_Tier__c: profile.tier,
          }
        : {}),
    };
  };

  webMessagingChatData = (formData: PrechatFields): SplitTrafficChatFields => {
    return {
      FirstName_c: formData.firstName,
      LastName_c: formData.lastName,
      Email_c: formData.email,
      ...this.hiddenFields,
      WebSite_Country: this.languageService.ddsLocaleValue.countrySite.toLowerCase(),
      WebSite_Language: this.languageService.langKeyValue,
    };
  };

  onSubmit(): void {
    const formData: PrechatFields = this.reactiveForm.value;

    if (!this.reactiveForm.valid) {
      this.reactiveForm.markAllAsTouched();
      this.reactiveForm.updateValueAndValidity();

      return;
    }

    this.afChatService.chatButtonState$.next(HelpButtonStatus.CHAT);
    this.loading$.next(true);

    // Direct user with a booking reference to liveagent chat.
    if (this.liveAgentChatEnabled) {
      this.trackElement('start-the-chat_booking');
      this.afChatService.openLegacyChat$.next(this.liveAgentChatData(formData));

      return;
    }

    this.trackElement('start-the-chat');
    this.afChatService.openWebMessagingChat$.next(this.webMessagingChatData(formData));
  }

  private trackElement(label: string): void {
    this.gtmService.trackElement(label, GaContext.CHAT, ElementTypes.BUTTON, undefined, ElementActions.CLICK);
  }
}
