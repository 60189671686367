import {
  FareFamily,
  FareInformation,
  FinnairBoundFareFamily,
  FinnairBoundItem,
  FinnairItineraryItemFlight,
  FinnairTravelEndpoint,
} from '@fcom/dapi/api/models';

export interface FinnairTravelLocation extends FinnairTravelEndpoint {
  cityName?: string;
  name?: string;
  countryCode?: string;
}

export interface FinnairBoundItemWithLocation extends FinnairBoundItem {
  departure: FinnairTravelLocation;
  arrival: FinnairTravelLocation;
}

export interface ExtendedFareFamily extends FinnairBoundFareFamily {
  benefits: FareFamily;
  fareInformation: Array<FareInformation>;
  selected: boolean;
  disabled?: boolean;
  points?: string;
  badgeType?: FareFamilyBadgeType;
  brandName?: string;
  brandColor?: string;
  highlightedBenefits?: string[];
  isSpecialOffer?: boolean;
  isStartingFromPrice?: boolean;
}

export enum SortBy {
  DURATION = 'duration',
  PRICE = 'price',
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure',
  BEST = 'best',
}

export enum FareFamilyBadgeType {
  NEW = 'NEW',
  POPULAR = 'POPULAR',
  CMS = 'CMS',
}

export interface DimensionsAndDetails {
  extendedFareFamilies: ExtendedFareFamily[];
  cabinClass: string;
  itineraries?: FinnairItineraryItemFlight[][];
}
