import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { Category } from '../api/models';

export const asServicePriceKey = (category: Category | string): string => {
  switch (category) {
    case Category.SEAT:
      return 'seats';
    case Category.MEAL:
      return 'meals';
    case Category.WIFI:
      return 'wifi';
    case Category.LOUNGE:
      return 'lounge';
    case Category.BAGGAGE:
      return 'baggage';
    case Category.COVER:
      return 'cover';
    default:
      return '';
  }
};

export const asCartId = (category: Category | string): string => {
  switch (category) {
    case Category.MEAL:
      return 'meals';
    case Category.SEAT:
      return 'seats';
    default:
      return category;
  }
};

export enum AdditionalCategories {
  TICKETS = 'tickets',
  TIER = 'tier',
}

const categoryIcons: Record<AdditionalCategories[number] | Category[number], SvgLibraryIcon> = {
  [Category.SEAT]: SvgLibraryIcon.SEAT_OR_ECONOMY,
  [Category.BAGGAGE]: SvgLibraryIcon.CHECKED_IN_BAGGAGE,
  [Category.CABIN_BAGGAGE]: SvgLibraryIcon.CARRY_ON_BAG,
  [Category.MEAL]: SvgLibraryIcon.FOOD,
  [Category.WIFI]: SvgLibraryIcon.WIFI,
  [Category.LOUNGE]: SvgLibraryIcon.LOUNGE,
  [Category.COVER]: SvgLibraryIcon.INSURANCE,
  [Category.SAF]: SvgLibraryIcon.SAF,
  [Category.PET]: SvgLibraryIcon.PET,
  [Category.SPORT]: SvgLibraryIcon.BIKE,
  [Category.SPECIAL]: SvgLibraryIcon.EXTRA_CHECK_IN_BAGGAGE,
  [Category.FIREARM]: SvgLibraryIcon.FIRE_ARMS,
  [Category.MEDIC]: SvgLibraryIcon.MEDICAL_ASSISTANCE,
  [Category.SPECIAL_NEED]: SvgLibraryIcon.PERSON_SPECIAL_ASSIST,
  [Category.CHILD]: SvgLibraryIcon.CHILD,
  [Category.OTHER]: SvgLibraryIcon.INFO,
  [AdditionalCategories.TIER]: SvgLibraryIcon.FINNAIR_PLUS,
  [AdditionalCategories.TICKETS]: SvgLibraryIcon.FLIGHT_STATUS,
};

export const getIconForServiceCategory = (category: Category | string): SvgLibraryIcon =>
  (categoryIcons[category] ?? categoryIcons.other) as SvgLibraryIcon;
