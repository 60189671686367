import { HttpClient } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService, SentryLogger, StateTransferService } from '@fcom/core/services';

import { AmDestinationResponseItem } from '../interfaces/destination-search.interface';

@Injectable()
export class AmDestinationService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private sentryLogger: SentryLogger,
    private stateTransferService: StateTransferService
  ) {}

  static createStateKey = (): StateKey<AmDestinationResponseItem[]> =>
    makeStateKey<AmDestinationResponseItem[]>('amdestinations');

  public amDestinationList(): Observable<AmDestinationResponseItem[]> {
    if (this.configService.cfg.buildEnv === 'prod') {
      return this.stateTransferService.wrapToStateCache(AmDestinationService.createStateKey(), () =>
        this.fetchAmDestinationList()
      );
    }
    return this.fetchAmDestinationList();
  }

  private fetchAmDestinationList(): Observable<AmDestinationResponseItem[]> {
    const apiUrl = this.configService.cfg.amFlightHotelDestinationUrl;
    const apiUrlLocalized = `${apiUrl}?dep=HEL`;
    return this.http.get<AmDestinationResponseItem[]>(apiUrlLocalized).pipe(
      catchError((err: unknown) => {
        this.sentryLogger.warn('Failed to fetch AM flight + hotel list', { error: err });
        return of([]);
      })
    );
  }
}
