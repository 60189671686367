import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UiComponentsModule } from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { ComponentsModule } from '@fcom/common/components/components.module';
import { DirectiveModule } from '@fcom/common/directives';
import { GtmModule } from '@fcom/common/gtm/gtm.module';
import { PipesModule } from '@fcom/common/pipes/pipes.module';

import { CommonServiceFormsModule } from './components/common/common-service-forms.module';
import { RadioListComponent } from './components/radio-list/radio-list.component';
import { SubmitService, PrefillService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    GtmModule,
    PipesModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    CommonServiceFormsModule,
    UiComponentsModule,
    UiTranslateModule,
  ],
  declarations: [RadioListComponent],
  exports: [RadioListComponent],
  providers: [SubmitService, PrefillService],
})
export class ServiceFormsModule {}
