import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule,
  CarouselModule,
  IconsModule,
  ModalModule,
  PopoverModule,
  UiComponentsModule,
} from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { SiteSearchHeaderWidgetStarterComponent } from '@fcom/site-search-header-widget-starter';

import { GtmModule } from '../gtm/gtm.module';
import { DirectiveModule } from '../directives';
import { PipesModule } from '../pipes';
import { LocationsPipeModule } from '../../locations';
import { WelcomeBackMessageService } from '../services';
import { CmsUnstyledFragmentComponent, CmsUnstyledTeaserComponent, ContentWithModalComponent } from './cms';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ErrorComponent } from './error/error.component';
import { ExpandContentComponent } from './expand/expand-content.component';
import { ExpandComponent } from './expand/expand.component';
import { FooterComponent } from './footer/footer.component';
import { ClientOnlyComponent } from './helpers/client-only.component';
import { ImagesModule } from './images';
import { LoaderComponent } from './loader/loader.component';
import { SkipLinkComponent } from './skip-link/skip-link.component';
import { WelcomeBackMessageComponent } from './welcome-back-message/welcome-back-message.component';
import { SidebarNavigationItemComponent } from './sidebar-navigation-item/sidebar-navigation-item.component';
import { LinkComponent } from './link/link.component';
import { TotalExpandComponent } from './total-expand';
import { StepProgressCircleComponent } from './step-progress-circle/step-progress-circle.component';
import { PromotionBannersComponent } from './promotion-banners/promotion-banners.component';
import { SustainabilityReviewCardComponent } from './sustainability-review-card/sustainability-review-card.component';
import { NewsletterNotificationComponent } from './newsletter-notification/newsletter-notification.component';
import { HistogramComponent } from './histogram/histogram.component';
import { ScrollHandleContainerComponent } from './scroll-handle-container/scroll-handle-container.component';
import { PriceMatrixComponent } from './price-matrix/price-matrix.component';
import { SchemaComponent } from './schema/schema.component';
import { BookingHeaderSummaryComponent } from './booking-header-summary/booking-header-summary.component';
import { BookingHeaderSummaryItemComponent } from './booking-header-summary-item/booking-header-summary-item.component';
import { TripLocationInfoComponent } from './trip-location-info/trip-location-info.component';
import { CountryAndLanguageSelectorComponent } from './country-and-language-selector/country-and-language-selector.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { PromotionBannerComponent } from './promotion-banner/promotion-banner.component';
import { PriceCalendarComponent } from './price-calendar/price-calendar.component';

const COMPONENTS = [
  ClientOnlyComponent,
  DisclaimerComponent,
  ExpandComponent,
  ExpandContentComponent,
  LoaderComponent,
  CmsUnstyledFragmentComponent,
  CmsUnstyledTeaserComponent,
  ContentWithModalComponent,
  SiteSearchHeaderWidgetStarterComponent,
  FooterComponent,
  ErrorComponent,
  SkipLinkComponent,
  WelcomeBackMessageComponent,
  SidebarNavigationItemComponent,
  LinkComponent,
  TotalExpandComponent,
  StepProgressCircleComponent,
  PromotionBannersComponent,
  SustainabilityReviewCardComponent,
  NewsletterNotificationComponent,
  ScrollHandleContainerComponent,
  HistogramComponent,
  PriceMatrixComponent,
  SchemaComponent,
  BookingHeaderSummaryComponent,
  BookingHeaderSummaryItemComponent,
  TripLocationInfoComponent,
  CountryAndLanguageSelectorComponent,
  LanguageSelectorComponent,
  PromotionBannerComponent,
  PriceCalendarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    DirectiveModule,
    FormsModule,
    ImagesModule,
    PipesModule,
    LocationsPipeModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    GtmModule,
    UiTranslateModule,
    CarouselModule,
    IconsModule,
    ModalModule,
    PopoverModule,
    ButtonModule,
    UiComponentsModule,
  ],
  declarations: [...COMPONENTS],
  providers: [WelcomeBackMessageService],
  exports: [ImagesModule, ...COMPONENTS],
})
export class ComponentsModule {}
